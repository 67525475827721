import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {FlexCol} from '@quarks';

export const LinkWrapper = styled(NavLink)<{$isToday: boolean; $isActive: boolean; $isPast: boolean}>`
  padding: 8px 0;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: ${({$isPast}) => $isPast && 'not-allowed'};

  @media (hover) {
    &:hover {
      text-decoration: none;
      & > div {
        background: ${({$isToday, $isPast, theme}) =>
          $isPast ? undefined : $isToday ? theme.card.beige500.hover.background : theme.card.white.hover.background};
      }
    }
  }

  &:focus-visible {
    text-decoration: none;
  }
`;

export const DateWrapper = styled(FlexCol)<{$isToday: boolean; $isActive: boolean}>`
  align-items: center;
  background: ${({$isToday, theme}) => ($isToday ? theme.card.beige500.background : 'transparent')};
  border: 2px solid ${({$isActive, theme}) => ($isActive ? theme.text.color.body : 'transparent')};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2px;
  padding: 10px 6px;
  text-decoration: none;
  width: 2rem;

  a:focus-visible > & {
    border-color: ${({theme}) => theme.brand};
  }
`;
