import {ModalCellButtonProps} from './types';

import {assertNever, pxToRem} from '@utils';
import {Caret, Cell, CellButton} from './styles';

export const ModalCellButton = ({
  as = 'button',
  children,
  className,
  onClick,
  style,
  to,
  right,
  ...props
}: ModalCellButtonProps) => {
  switch (as) {
    case 'button':
      return (
        <Cell width="100%">
          <CellButton
            as="button"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            onClick={onClick}
            {...props}>
            {children}
            {right ? (
              right
            ) : (
              <Caret
                icon="caretRight"
                size={pxToRem(24)}
              />
            )}
          </CellButton>
        </Cell>
      );
    case 'link':
      return (
        <Cell width="100%">
          <CellButton
            as="a"
            alignItems="center"
            justifyContent="space-between"
            paddingBottom={8}
            paddingTop={8}
            width="100%"
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            {...props}>
            {children}
            <Caret
              icon="caretRight"
              size={pxToRem(24)}
            />
          </CellButton>
        </Cell>
      );
    default:
      return assertNever(as);
  }
};
