import {pxToRem} from '@utils';
import {StyledContainer, StyledIcon, StyledWrapper} from './styles';
import {ModalCellItemIconProps} from './types';

export const ModalCellItemIcon = ({
  backgroundColor,
  className,
  disabled,
  icon,
  imageURL,
  selected,
  style,
  iconUrl,
}: ModalCellItemIconProps) => {
  return (
    <StyledWrapper
      $disabled={disabled}
      $selected={selected}
      className={className}
      style={style}>
      <StyledContainer
        $background={backgroundColor}
        $disabled={disabled}
        $imageURL={imageURL ?? undefined}>
        {!imageURL && (
          <StyledIcon
            $disabled={disabled}
            icon={icon}
            color="currentColor"
            size={pxToRem(24)}
            iconUrl={iconUrl}
          />
        )}
      </StyledContainer>
    </StyledWrapper>
  );
};
