import {ConnectionsCardsProps} from './types';
import {useTranslation} from 'react-i18next';

import {getConnectedUsersGroupedByStatusForWeb, getIsLoadingConnections} from '@lib/store';
import {useAppSelector} from '@hooks';

import {ConnectionsCard, ConnectionsSuggestionsList} from '@organisms';
import {FlexCol, P} from '@quarks';
import {StyledCard} from '../styles';
import {Illustration} from '@atoms';

export const ConnectionsCards = ({}: ConnectionsCardsProps) => {
  const {acceptedConnections, outgoingConnections, incomingConnections} = useAppSelector(
    getConnectedUsersGroupedByStatusForWeb,
  );
  const isLoadingConnections = useAppSelector((state) => getIsLoadingConnections(state));

  const {t} = useTranslation('connection');

  return (
    <FlexCol rowGap={32}>
      {incomingConnections.length > 0 && (
        <ConnectionsCard
          connections={incomingConnections}
          heading={t('NewConnectionRequests', {count: incomingConnections.length})}
          status="incoming"
        />
      )}
      {!isLoadingConnections && <ConnectionsSuggestionsList />}
      {acceptedConnections.length > 0 && (
        <ConnectionsCard
          connections={acceptedConnections}
          heading={t('ConnectedColleagues', {count: acceptedConnections.length})}
          status="accepted"
        />
      )}
      {outgoingConnections.length > 0 && (
        <ConnectionsCard
          connections={outgoingConnections}
          heading={t('PendingOutgoingRequests', {count: outgoingConnections.length})}
          status="outgoing"
        />
      )}
      {!acceptedConnections.length && !incomingConnections.length && !outgoingConnections.length && (
        <StyledCard>
          <FlexCol
            alignItems="center"
            gap={24}>
            <Illustration illustration="connections" />
            <P data-testid="organisms-connectionsCard-emptyConnectionParagraph">{t('ConnectionsEmptyState')}</P>
          </FlexCol>
        </StyledCard>
      )}
    </FlexCol>
  );
};
