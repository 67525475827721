import {useAppSelector, useBreakPoint} from '@hooks';
import {DayName, getAllWorkdays, getFutureWorkdaysWithExtraInformation} from '@lib/store';
import {addDays, format, parseISO} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {LoaderTile, Tile} from '../Tile';
import {FlexCol, FlexRow, H4, P} from '@quarks';
import {Icon} from '@atoms';
import {toISODateString} from '@lib/utils';
import {Avatar, AvatarStack, Button, IconButton} from '@molecules';
import {useNavigate} from 'react-router-dom';

type Props = {
  date: Date;
};

export const NextWorkdaysTile = ({date}: Props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const breakPoint = useBreakPoint();
  const smallBreakPoint = breakPoint === 'small';
  const MAX_NUMBER_WORKDAYS_TO_SHOW = 5;
  const dateKey = format(date, 'yyyy-MM-dd');
  const workdayLoading = useAppSelector((state) => state.data.workday.isLoading);
  const allWorkdays = useAppSelector(getAllWorkdays);
  const firstNonWorkingDay = allWorkdays[allWorkdays.length - 1]
    ? addDays(parseISO(allWorkdays[allWorkdays.length - 1]?.startDate), 1)
    : addDays(new Date(dateKey), 1);

  const firstNonWorkingDayFormatted = format(firstNonWorkingDay, 'yyyy-MM-dd');

  const futureWorkdaysWithConnections = useAppSelector((state) =>
    getFutureWorkdaysWithExtraInformation(state, toISODateString(date)),
  )
    .filter((wk) => wk.status === 'OfficeDay')
    .slice(0, MAX_NUMBER_WORKDAYS_TO_SHOW);

  function translateDayOfWeek(passedDate: string) {
    const dayOfWeek = format(new Date(passedDate), 'EEEE') as DayName;
    return t(dayOfWeek);
  }

  function handleNaviagateToWorkday(startDate: string) {
    return navigate(`/calendar/${format(new Date(startDate), 'yyyy-MM-dd')}`);
  }

  return workdayLoading ? (
    <LoaderTile />
  ) : (
    <Tile title={t('translation:YourNextOfficeDays')}>
      {futureWorkdaysWithConnections.length ? (
        futureWorkdaysWithConnections.map(({id, nodeName, connections, parking, workspaceReservation, startDate}) => {
          return (
            <FlexRow
              gap={24}
              justifyContent="space-between"
              alignItems="center"
              key={id}
              className="w-full cursor-pointer"
              onClick={() => handleNaviagateToWorkday(startDate)}>
              <FlexRow
                gap={16}
                alignItems="center">
                <FlexCol
                  justifyContent="center"
                  alignItems="center"
                  className=" bg-energizing-yellow-500* h-12 w-12 rounded-lg shrink-0">
                  <Icon
                    icon="office"
                    size="24px"
                  />
                </FlexCol>
                <FlexCol
                  justifyContent="center"
                  gap={8}>
                  <div>
                    <H4>{`${translateDayOfWeek(startDate)} ${format(new Date(startDate), 'd MMMM')}`}</H4>
                    <P>{nodeName}</P>
                  </div>
                  {smallBreakPoint ? (
                    <FlexRow
                      alignItems="center"
                      gap={16}>
                      {connections.length === 1 ? (
                        <Avatar user={connections[0]} />
                      ) : connections.length > 1 ? (
                        <AvatarStack
                          users={connections}
                          date={dateKey}
                          overlap={true}
                        />
                      ) : null}
                    </FlexRow>
                  ) : null}
                </FlexCol>
              </FlexRow>
              <FlexRow gap={16}>
                {!smallBreakPoint ? (
                  <FlexRow
                    alignItems="center"
                    gap={16}>
                    {connections.length === 1 ? (
                      <Avatar user={connections[0]} />
                    ) : connections.length > 1 ? (
                      <AvatarStack
                        users={connections}
                        date={dateKey}
                        overlap={true}
                      />
                    ) : null}

                    {workspaceReservation ? (
                      <Icon
                        icon="desk"
                        size="24px"
                      />
                    ) : null}
                    {parking ? (
                      <Icon
                        icon="parking"
                        size="24px"
                      />
                    ) : null}
                  </FlexRow>
                ) : null}

                <IconButton
                  icon="caretRight"
                  iconButton="tertiary"
                  aria-label={t('GoToAWorkdayDay', {startDate})}
                  onClick={(e) => {
                    handleNaviagateToWorkday(startDate);
                    e.stopPropagation();
                  }}
                  className="rounded-lg"
                />
              </FlexRow>
            </FlexRow>
          );
        })
      ) : (
        <FlexCol
          justifyContent="center"
          alignItems="center"
          className="h-64 w-full bg-safe-mauve-500* rounded-lg"
          gap={16}>
          <FlexCol
            justifyContent="center"
            alignItems="center">
            <h3 className="text-white">{t('NoOfficeDaysPlanned')}</h3>
            <p className="text-white text-center">{t('LetYourConnectionsKnowWhenOnSite')}</p>
          </FlexCol>
          <Button
            button={'primary light'}
            onClick={() => navigate(`/calendar/${firstNonWorkingDayFormatted}`)}>
            {t('PlanYourNextOfficeDay')}
          </Button>
        </FlexCol>
      )}
    </Tile>
  );
};
