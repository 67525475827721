import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {en, nl, fr} from '@lib/locales';
import {setAppLanguage, AppDispatch} from '@lib/store';
import {AppLanguage, knownLocales} from '@lib/utils';
import 'intl-pluralrules';

export const initI18Next = (dispatch: AppDispatch) => {
  i18next.on('languageChanged', (lng) => {
    document.documentElement.lang = lng;
    dispatch(setAppLanguage(<AppLanguage>lng));
  });

  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources: {
        en: en,
        fr: fr,
        nl: nl,
      },
      compatibilityJSON: 'v3',
      supportedLngs: Object.keys(knownLocales),
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });
};

export default i18next;
