import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

export const Heading = styled.div`
  align-items: center;
  display: grid;
  grid-area: heading / content;
  grid-auto-flow: column;
  grid-template-columns: [content-start] 1fr [content-end];
  grid-template-rows: [fullbleed-start content-start] 1fr [content-end fullbleed-end];
  justify-content: space-between;

  ${BREAKPOINTS.small`
    background: ${({theme}) => theme.background.white};
    border-bottom: 1px solid ${({theme}) => theme.divider.grey};
    grid-area: header / fullbleed;
    grid-template-columns: [fullbleed-start] 16px [content-start] 1fr [content-end] 16px [fullbleed-end];
    left: 0;
    position: sticky;
    top: 0;
    z-index: 2;
  `}
`;
