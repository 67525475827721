import axios from 'axios';
import {IAuthManager, ITranslationService} from '@lib/infrastructure';
import {handleApiError} from './apiErrorHandler';
import {IApiClient, RequestConfig, ApiRequest} from '@lib/infrastructure';
import {AxiosResponse} from 'axios';

// if (isDev) {
//   axios.interceptors.request.use((request) => {
//     log('Starting Request', JSON.stringify(request, null, 2));
//     return request;
//   });

//   axios.interceptors.response.use((response) => {
//     log('Response:', JSON.stringify(response, null, 2));
//     return response;
//   });
// }

export const apiBaseURL =
  window.location.hostname === 'localhost' ? import.meta.env.MAPIQ_API_BASE_URL! : window.location.origin + '/api/v1';

export const apiClient = (authManager: IAuthManager, translate: ITranslationService): IApiClient => {
  const createAuthenticatedRequest = async (manager: IAuthManager, config: RequestConfig): Promise<ApiRequest> => {
    const {url, method, data, apiVersion, headers} = config;
    const token = await manager.getAccessToken();
    const appLanguage = translate.getLanguage();
    if (token) {
      const actualHeaders = {
        Authorization: `Bearer ${token}`,
        'x-api-version': apiVersion ?? '2.0',
        'Accept-Language': appLanguage,
        ...headers,
      };

      return {
        baseURL: apiBaseURL,
        url,
        method,
        data,
        headers: actualHeaders,
      };
    } else {
      throw Error('Sign-in needs to be performed before doing requests.');
    }
  };

  const _request = async <TReturn>(config: RequestConfig) => {
    try {
      const request = await createAuthenticatedRequest(authManager, config);
      const response = await axios.request({
        ...request,
        headers: {
          ...request.headers,
          'Content-Type': 'application/json',
        },
      });
      return response as AxiosResponse<TReturn>;
    } catch (error) {
      handleApiError(error);
      // This is an exceptional case and should not happen since we handle all supported cases above.
      // This makes the correct type to be resolved
      throw new Error('Oops. Something wrong just happened and we could not identify exactly what.');
    }
  };

  return {
    request: <T>(config: RequestConfig) => _request<T>(config).then((r) => r.data),
    requestRaw: <T>(config: RequestConfig) =>
      _request<T>(config).then(({data, headers, status, statusText}) => ({data, headers, status, statusText})),
  };
};
