import {useTranslation} from 'react-i18next';
import {useState, useEffect} from 'react';
import {Icon, IconNames} from '@atoms';
import {useAppDispatch, useAppSelector, useBreakPoint, useFeatureToggle, useModal} from '@hooks';
import {
  getIsLoadingUserCheckIn,
  getUserCheckInStatus,
  getWorkdayByDate,
  getWorkspaceReservationByDate,
  updateCheckInStatusNew,
  withAsyncThunkErrorHandling,
  getIsUserAllowedToReserveParking,
  loadParkingLotsByBuilding,
  loadParkingCount,
  getCanShowCheckInTile,
  getParkingReservationByDate,
  getDefaultBuilding,
} from '@lib/store';
import {Button} from '@molecules';
import {BookingLocationStatusCard, LoaderTile, Tile} from '@organisms';
import {FlexCol, FlexRow, H4, Subtitle} from '@quarks';
import {CheckInEventType, CheckInSourceType, trackEvent, trackPageComponentedRendered} from '@lib/infrastructure';
import {format, parseISO} from 'date-fns';
import {WorkspaceReservationRow} from './WorkspaceReservationRow';
import {ParkingReservationRow} from './ParkingReservationRow';
import toast from 'react-hot-toast';

export const WorkdayTile = ({date, time}: {date: Date; time: Date}) => {
  const [expanded, setExpanded] = useState(time.getHours() < 10);
  const enabledFeatures = useFeatureToggle();

  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {addPages, openModal} = useModal();
  const breakPoint = useBreakPoint();
  const smallBreakPoint = breakPoint === 'small';

  const dateKey = format(date, 'yyyy-MM-dd');
  const workday = useAppSelector((state) => getWorkdayByDate(state, dateKey)) ?? null;
  const canShowCheckInTile = useAppSelector((state) => getCanShowCheckInTile(state, dateKey));
  const buildingId = workday?.nodeId;
  const workdayLoading = useAppSelector((state) => state.data.workday.isLoading);
  const isUserAllowedToReserveParking = useAppSelector((state) =>
    getIsUserAllowedToReserveParking(state, parseISO(dateKey)),
  );
  const defaultBuilding = useAppSelector(getDefaultBuilding);

  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, date)) ?? null;
  const workspaceReservationLoading = useAppSelector((state) => state.data.workspaceReservations.isLoading);
  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, dateKey)) ?? null;

  const isLoadingCheckInStatus = useAppSelector(getIsLoadingUserCheckIn);
  const {eventType: checkInEventType, buildingId: checkInBuildingId} = useAppSelector(getUserCheckInStatus);
  const isOfficeDay = workday?.status === 'OfficeDay';

  const checkInDisabled =
    checkInEventType === CheckInEventType.NotAtTheOffice && (!workday || workday.status !== 'OfficeDay');
  const isNotCheckedInYet = checkInEventType === CheckInEventType.NotCheckedInYet;
  const isNotAtTheOffice = checkInEventType === CheckInEventType.NotAtTheOffice;

  // There does not seem to be an "updating" state in the store, so for now we'll manage this in component.
  // Should be improved in the future
  const [requestedOnServer, setRequestedOnServer] = useState<CheckInEventType | null>(null);
  const isUpdatingCheckIn = requestedOnServer !== null && requestedOnServer !== checkInEventType;
  const CheckInButtonTitle = isNotCheckedInYet || isNotAtTheOffice ? t('workplace:CheckIn') : t('workplace:CheckOut');

  const topRowIcon: IconNames =
    workday?.status === 'OfficeDay'
      ? 'office'
      : workday?.status === 'NotWorking'
      ? 'calendarX'
      : workday?.status === 'WorkingRemotely'
      ? 'remote'
      : 'locationUnknown';
  const topRowHeader = workday?.nodeName
    ? workday.nodeName
    : workday?.status === 'NotWorking'
    ? t('workplace:NotWorking')
    : workday?.status === 'WorkingRemotely'
    ? t('workplace:WorkingRemotely')
    : t('workplace:WhereWillYouBe');

  const allowExpand = (workspaceReservation || isUserAllowedToReserveParking) && isOfficeDay;

  const expand = () => {
    setExpanded(true);
    trackEvent('Home_WorkdayTile__Expand');
  };

  const collapse = () => {
    setExpanded(false);
    trackEvent('Home_WorkdayTile__Collapse');
  };

  const hideShowCheckInButtonsUI = (
    <FlexRow gap={8}>
      {allowExpand ? (
        <Button
          onClick={expanded ? collapse : expand}
          button="tertiary"
          className="flex-1">
          {expanded ? t('HideBookings') : t('ShowBookings')}
        </Button>
      ) : null}
      {canShowCheckInTile && workday?.status === 'OfficeDay' ? (
        <Button
          disabled={isUpdatingCheckIn}
          button={checkInEventType === CheckInEventType.AtTheOffice ? 'secondary' : 'primary'}
          onClick={checkInEventType === CheckInEventType.AtTheOffice ? updateToNotAtTheOffice : updateToAtTheOffice}
          className="flex-1">
          {CheckInButtonTitle}
        </Button>
      ) : null}
    </FlexRow>
  );

  useEffect(() => {
    if (buildingId) {
      dispatch(
        withAsyncThunkErrorHandling(() =>
          loadParkingLotsByBuilding({
            buildingId,
          }),
        ),
      );
      dispatch(withAsyncThunkErrorHandling(() => loadParkingCount({selectedDate: dateKey})));
    }
  }, [dispatch, buildingId, date, dateKey]);

  async function handleUpdateCheckInStatus(status: CheckInEventType) {
    setRequestedOnServer(status);

    const {success} = await dispatch(
      withAsyncThunkErrorHandling(() =>
        updateCheckInStatusNew({
          buildingId: status === CheckInEventType.AtTheOffice ? workday?.nodeId : checkInBuildingId,
          eventType: status,
          sourceType: CheckInSourceType.Manual,
        }),
      ),
    );
    if (success && enabledFeatures.CustomerBranding) {
      toast.success(
        status === CheckInEventType.AtTheOffice ? t('workplace:YouAreCheckedIn') : t('workplace:YouAreCheckedOut'),
      );
    }
  }

  function updateToNotAtTheOffice() {
    if (isLoadingCheckInStatus || checkInDisabled) return;
    trackEvent('Home_WorkdayTile__ChangeCheckInStatus', {
      from: checkInEventType,
      to: CheckInEventType.NotAtTheOffice,
    });

    handleUpdateCheckInStatus(CheckInEventType.NotAtTheOffice);
  }

  function updateToAtTheOffice() {
    if (isLoadingCheckInStatus || checkInDisabled) return;
    setExpanded(false);

    trackEvent('Home_WorkdayTile__ChangeCheckInStatus', {
      from: checkInEventType,
      to: CheckInEventType.AtTheOffice,
    });

    handleUpdateCheckInStatus(CheckInEventType.AtTheOffice);
  }

  const contentLoading = workdayLoading || workspaceReservationLoading;

  // The workday tile is technically "just another tile", but in reality it represents an important
  // first part of the home page. That's why once it's loaded we consider the home page to have meaningful
  // content and we track its performance.
  useEffect(() => {
    trackPageComponentedRendered('home', !contentLoading);
  }, [contentLoading]);

  return contentLoading ? (
    <LoaderTile />
  ) : (
    <Tile>
      <FlexCol
        width="100%"
        {...(isOfficeDay ? {gap: 16} : {})}>
        <FlexRow
          alignItems="center"
          className="cursor-pointer"
          aria-label={t('ChangeLocationRow')}
          onClick={() => {
            addPages([<BookingLocationStatusCard date={dateKey} />]);
            openModal();
          }}>
          <FlexRow
            justifyContent="center"
            alignItems="center"
            gap={16}>
            <FlexCol
              justifyContent="center"
              alignItems="center"
              className=" bg-energizing-yellow-500* h-12 w-12 rounded-lg shrink-0">
              <Icon
                icon={topRowIcon}
                size="24px"
              />
            </FlexCol>
            <FlexCol>
              <H4>{topRowHeader}</H4>
              {!workday && defaultBuilding ? (
                <Subtitle>{t('YourDefaultOfficeIsShown', {officeName: defaultBuilding.name})}</Subtitle>
              ) : null}
            </FlexCol>
          </FlexRow>
          <FlexRow
            alignItems="center"
            gap={16}>
            <FlexRow gap={16}>
              {workspaceReservation ? (
                <Icon
                  icon="desk"
                  size="24px"
                />
              ) : null}
              {parkingReservation && isOfficeDay ? (
                <Icon
                  icon="parking"
                  size="24px"
                />
              ) : null}
            </FlexRow>
            <Icon
              icon={'caretRight'}
              size="24px"
            />
          </FlexRow>
        </FlexRow>
        {!expanded ? hideShowCheckInButtonsUI : null}
        {expanded && isOfficeDay ? (
          <FlexCol gap={16}>
            <WorkspaceReservationRow
              workspaceReservation={workspaceReservation}
              dateKey={dateKey}
            />
            {isUserAllowedToReserveParking ? <ParkingReservationRow dateKey={dateKey} /> : null}
            {smallBreakPoint || expanded ? hideShowCheckInButtonsUI : null}
          </FlexCol>
        ) : null}
      </FlexCol>
    </Tile>
  );
};
