import {Illustration} from '@atoms';
import {WorkspacesListWrapper} from './styles';
import {FlexCol, P, Span} from '@quarks';
import {useTranslation} from 'react-i18next';
import {useScrollParentForChild} from '@hooks';
import {HaNWorkspaceCell} from '@organisms';
import {AreaWithSensorInfo} from '@lib/store';

type HaNWorkspacesListProps = {
  onClick: (area: AreaWithSensorInfo) => void;
  areas: AreaWithSensorInfo[];
  selectedWorkspace: AreaWithSensorInfo | null;
  onHover: (spotlightId: string | null) => void;
};

export const HaNWorkspacesList = ({onClick, areas, selectedWorkspace, onHover}: HaNWorkspacesListProps) => {
  const {t} = useTranslation();
  const indexOfElement = selectedWorkspace ? areas.indexOf(selectedWorkspace) : undefined;

  const [scrollRef, childWrapperRef] = useScrollParentForChild(indexOfElement);

  return (
    <WorkspacesListWrapper ref={scrollRef}>
      {areas.length !== 0 ? (
        <FlexCol
          rowGap={8}
          ref={childWrapperRef}
          style={{padding: '8px 12px'}}>
          {areas.map((area) => (
            <Span
              key={area.id}
              onMouseEnter={() => onHover(area.id)}
              onMouseLeave={() => onHover(null)}>
              <HaNWorkspaceCell
                area={area}
                onClick={onClick}
              />
            </Span>
          ))}
        </FlexCol>
      ) : (
        <FlexCol
          alignItems="center"
          justifyContent="center"
          padding={32}
          flex="1"
          gap={16}>
          <Illustration illustration="noResults" />
          <P
            color="currentColor"
            textAlign="center">
            {t('hereAndNow:NoAreasAvailable')}
          </P>
        </FlexCol>
      )}
    </WorkspacesListWrapper>
  );
};
