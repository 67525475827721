import {useTranslation} from 'react-i18next';

import {ProfileCell} from '../styles';
import {P, Subtitle} from '@quarks';

export const VersionCell = () => {
  const {t} = useTranslation('profile');

  return (
    <ProfileCell>
      <P data-testid="oranisms-versionCell-text">{t('Version')}</P>
      <Subtitle data-testid="oranisms-versionCell-version">v{import.meta.env.MAPIQ_APP_VERSION}</Subtitle>
    </ProfileCell>
  );
};
