import {HaNRoomsMap, HaNWorkspacesMap, isArea, isMeetingRoom, useTabByPathname} from '@organisms';
import {HereAndNowMapsProps} from './types';
import {
  getIsWorkspacesOnHereAndNowEnabled,
  getMeetingRoomSuggestionByBuildingIdLoadingStatus,
  useAppSelector,
} from '@lib/store';
import {Loader} from '@atoms';
import {Div} from '@quarks';
import {HaNExploreMap} from './HaNExploreMap';

export const HaNMaps = ({
  rooms,
  floorId,
  buildingId,
  onObjectClick,
  viewportRestrictions,
  selectedObject,
  hoverObjectId,
  workspaces,
  exploreState,
  onOutsideClick,
}: HereAndNowMapsProps) => {
  const tab = useTabByPathname();
  const isWorkspacesOnHereAndNowEnabled = useAppSelector(getIsWorkspacesOnHereAndNowEnabled);
  const meetingRoomLoadingStatus = useAppSelector((state) =>
    getMeetingRoomSuggestionByBuildingIdLoadingStatus(state, buildingId),
  );
  const loadingMeetingRooms = meetingRoomLoadingStatus === 'loading' || meetingRoomLoadingStatus === undefined;

  if (loadingMeetingRooms && tab !== 'explore') {
    return (
      <Div>
        <Loader />
      </Div>
    );
  }

  if (
    (tab === 'rooms' || (!isWorkspacesOnHereAndNowEnabled && !tab)) &&
    (selectedObject === null || isMeetingRoom(selectedObject))
  ) {
    return (
      <HaNRoomsMap
        rooms={rooms!}
        floorId={floorId}
        buildingId={buildingId}
        onRoomClick={onObjectClick}
        viewportRestrictions={viewportRestrictions}
        selectedRoom={selectedObject}
        onOutsideClick={onOutsideClick}
        hoverRoom={rooms!.find((room) => room.id === hoverObjectId) || null}
      />
    );
  } else if (tab === 'workspaces' && (selectedObject === null || isArea(selectedObject))) {
    return (
      <HaNWorkspacesMap
        floorId={floorId}
        workspaces={workspaces}
        buildingId={buildingId}
        hoverArea={workspaces.find((area) => area.id === hoverObjectId) || null}
        onAreaClick={onObjectClick}
        selectedArea={selectedObject}
        onOutsideClick={onOutsideClick}
        viewportRestrictions={viewportRestrictions}
      />
    );
  } else if (tab === 'explore') {
    return (
      <HaNExploreMap
        exploreState={exploreState}
        viewportRestrictions={viewportRestrictions}
        onOutsideClick={onOutsideClick}
      />
    );
  }
};
