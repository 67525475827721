import {ConnectionsCardProps} from './types';

import {ConnectionsCardCell} from '@organisms';
import {H2} from '@quarks';
import {StyledCard} from '../styles';

export const ConnectionsCard = ({connections, heading, status}: ConnectionsCardProps) => {
  return (
    <StyledCard>
      <H2 data-testid="organisms-connectionCard-h2">{heading}</H2>
      {connections.map((user) => (
        <ConnectionsCardCell
          user={user}
          key={user.id}
          status={status}
        />
      ))}
    </StyledCard>
  );
};
