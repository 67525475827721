import {ModalCell, ToggleCell} from '@molecules';
import {ModalPage} from '@organisms';
import {FlexCol} from '@quarks';
import styled from 'styled-components';

export const StyledModalPage = styled(ModalPage)`
  --modal-padding-x: 0;
  --modal-padding-y: 0;
  --padding-x: 24px;
  --padding-y: 24px;
  background: ${({theme}) => theme.background.white};

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid ${({theme}) => theme.brand};
  }
`;

export const StyledToggleCell = styled(ToggleCell)`
  & + & {
    border-top: ${({theme}) => `1px solid ${theme.divider.grey}`};
  }
  padding: 22px 0;
  line-height: 20px;
`;

export const StyledModalCell = styled(ModalCell)`
  border: none;
  display: flex;
  flex-direction: column;
`;

export const StyledFlexCol = styled(FlexCol)`
  // reset styling of legend tag
  float: left;
  padding-inline-start: initial;
  padding-inline-end: initial;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
`;
