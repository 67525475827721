import styled from 'styled-components';
import {animated} from 'react-spring';
import {Div, FlexCol, FlexRow} from '@quarks';
import {Icon} from '@atoms';

export const BookingDetailsWrapper = styled(animated.div)`
  background: ${({theme}) => theme.background.white};
  display: flex;
  flex-direction: column;
  height: var(--modal-height);
  overflow-x: hidden;
`;

export const StyledForm = styled(FlexCol)`
  justify-content: flex-start;
  padding-bottom: 2px;
  background: ${({theme}) => theme.background.white};
  gap: 8px;
`;

export const StyledCell = styled(Div)`
  background: ${({theme}) => theme.card.white.background};
  padding: 16px 20px;
  width: 100%;
`;

export const CellButton = styled(FlexRow)`
  background: ${({theme}) => theme.card.transparent.background};
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 0 2px transparent;
  color: inherit;
  cursor: pointer;
  padding: 8px 0;
  transition: background var(--speed-fast), border var(--speed-fast);

  @media (hover) {
    &:hover {
      background: ${({theme}) => theme.card.transparent.hover.background};
      color: inherit;
      text-decoration: none;
      box-shadow: 0 0 0 2px transparent;
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${({theme}) => theme.brand};
    color: inherit;
    outline: none;
    text-decoration: none;
  }
`;

export const Caret = styled(Icon)`
  transition: transform var(--speed-fast);

  @media (hover) {
    ${CellButton}:hover & {
      transform: translate3d(4px, 0, 0);
    }
  }

  ${CellButton}:focus-visible & {
    transform: translate3d(4px, 0, 0);
  }
`;
