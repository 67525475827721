import {createContext, useReducer} from 'react';
import {Action, DialogActions, DialogTypes, DialogProviderProps, DialogContextProps, Reducer, State} from './types';

const initialState: State = {
  buttonLabel: undefined,
  icon: undefined,
  isDialogOpen: false,
  message: '',
  title: '',
  type: DialogTypes.confirm,
};

const initDialog = {
  state: initialState,
  dispatch: () => void 0,
};

export const DialogContext = createContext<DialogContextProps>(initDialog);

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case DialogActions.show:
      return {
        buttonLabel: action.payload.buttonLabel,
        icon: action.payload.icon,
        isDialogOpen: true,
        message: action.payload.message,
        title: action.payload.title,
        type: action.payload.type,
      };
    case DialogActions.hide:
      return {
        buttonLabel: state.buttonLabel,
        icon: state.icon,
        isDialogOpen: false,
        message: state.message,
        title: state.title,
        type: state.type,
      };
    case DialogActions.clear:
      return initialState;
    default:
      return initialState;
  }
};

export const DialogProvider = ({children}: DialogProviderProps) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);

  return <DialogContext.Provider value={{state, dispatch}}>{children}</DialogContext.Provider>;
};
