import {BREAKPOINTS} from '@constants';
import {FlexRow} from '@quarks';
import styled from 'styled-components';

export const StyledFlexRow = styled(FlexRow)`
  background: ${({theme}) => theme.background.white};
  border-bottom: 1px solid ${({theme}) => theme.divider.grey};
  border-right: 1px solid ${({theme}) => theme.divider.grey};
  position: sticky;
  top: 0;
  z-index: 1;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  max-height: 30vh;
  overflow-y: auto;

  ${BREAKPOINTS.small`
    position: relative;
    align-items: center;
    overflow-x: auto;
  `}
`;
