import {UserCellProps} from './types';
import {StyledUserCell} from './styles';

import {Avatar} from '@molecules';
import {P, Subtitle} from '@quarks';

export const UserCell = ({
  className,
  right,
  shouldShowCheckIn,
  style,
  subtitle,
  user,
  padding,
  separator,
  ...props
}: UserCellProps) => {
  return (
    <StyledUserCell
      padding={padding}
      separator={separator}
      className={className}
      left={
        <Avatar
          shouldShowCheckIn={shouldShowCheckIn}
          user={user}
        />
      }
      middle={
        <>
          <P>{user.displayName || user.name || user.email}</P>
          <Subtitle>{subtitle}</Subtitle>
        </>
      }
      right={right}
      style={style}
      {...props}
    />
  );
};
