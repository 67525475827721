import type {AreaWithSensorInfo, MeetingRoom} from '@lib/store';
import {FacilityResource} from '@lib/store/src/data/buildingResources';
import {useLocation} from 'react-router-dom';

export function isMeetingRoom(entity: AreaWithSensorInfo | MeetingRoom | FacilityResource): entity is MeetingRoom {
  return entity.type === 'meetingRoom';
}

export function isArea(entity: AreaWithSensorInfo | MeetingRoom | FacilityResource): entity is AreaWithSensorInfo {
  return entity.type === 'area';
}

export const useTabByPathname = () => {
  const {pathname} = useLocation();
  const tab = {
    '/now/rooms': 'rooms',
    '/now/workspaces': 'workspaces',
    '/now/explore': 'explore',
  }[pathname];

  return tab;
};
