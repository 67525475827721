import {KeyboardEvent} from 'react';

import {useTranslation} from 'react-i18next';

import {FlexCol, FlexRow, Span, Subtitle} from '@quarks';
import {Avatar, Button, SquareButton} from '@molecules';
import {CalendarWorkdayInvitationCellProps} from './types';
import {getGroupInvitationExpiryTime} from '@lib/utils';
import {useAcceptDeclineWorkdayInvitation, useModal} from '@hooks';
import {BookingDetailsCard} from '@organisms';

export const CalendarWorkdayInvitationCell = ({
  date,
  workspace,
  color = 'energizingYellow',
}: CalendarWorkdayInvitationCellProps) => {
  const {id, owner, currentMember, groupReservationId, status} = workspace;
  const {t} = useTranslation();
  const {openModal, setModalPages} = useModal();
  const {acceptInvitation, declineInvitation, loading} = useAcceptDeclineWorkdayInvitation({
    date,
    workspaceReservationId: id,
  });

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.click();
    }
  };

  if (!owner || status !== 'InvitationPending') return <></>;

  return (
    <SquareButton
      as="div"
      aria-label={t('workplace:GroubBookingInvitationAriaLabel', {displayName: owner.user.displayName})}
      role="button"
      onKeyDown={handleKeyDown}
      key={id}
      tabIndex={0}
      alignItems="flex-start"
      left={
        <Avatar
          size={'small'}
          user={owner.user}
        />
      }
      onClick={() => {
        setModalPages([
          <BookingDetailsCard
            date={date}
            workspaceReservation={workspace}
          />,
        ]);
        openModal();
      }}
      iconRight="caretRight"
      squareButton={color}>
      <FlexCol
        fontWeight="regular"
        gap={4}>
        <Span fontWeight="bold">{t('workplace:GroupBooking')}</Span>
        <Span>
          {t('workplace:GroupReservationBookedBy', {
            firstName: owner.user.firstName,
            lastName: owner.user.lastName,
          })}
        </Span>
        {currentMember?.expiryDateTime && (
          <Subtitle as="span">{getGroupInvitationExpiryTime(t, currentMember.expiryDateTime)}</Subtitle>
        )}
        <FlexRow
          justifyContent="flex-start"
          paddingTop={8}
          gap={8}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (groupReservationId) {
                declineInvitation();
              }
            }}
            loading={loading}
            button="secondary"
            size="small">
            {t('translation:Decline')}
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (groupReservationId) {
                acceptInvitation();
              }
            }}
            loading={loading}
            button="primary"
            size="small">
            {t('translation:Accept')}
          </Button>
        </FlexRow>
      </FlexCol>
    </SquareButton>
  );
};
