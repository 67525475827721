import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  getAllTimeZones,
  getAreTimeZonesLoading,
  getIsUpdatingMeetingUserPreferences,
  getMeetingPreferencesWorkingHours,
  loadTimeZones,
  patchMeetingUserPreferences,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {ProfileCell} from '../styles';
import {Option, Select} from '@molecules';
import {ModalPage, ModalPageBottom} from '@organisms';
import {Loader} from '@atoms';
import {createWorkingHoursDto, localTimeDisplayName, timeOptionsBetween} from '@lib/utils/src/util/workingHourHelpers';
import {FlexCol, FlexRow} from '@quarks';
import {useTheme} from 'styled-components';
import {END_WORKDAY_MAX_HOUR, END_WORKDAY_MIN_HOUR, START_WORKDAY_MAX_HOUR, START_WORKDAY_MIN_HOUR} from '@constants';

export const WorkingHoursModal = () => {
  const {t} = useTranslation();
  const {closeModal} = useModal();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const initialWorkingHours = useAppSelector(getMeetingPreferencesWorkingHours);

  const [timezone, setTimezone] = useState(initialWorkingHours?.timeZoneId);
  const [dayStart, setDayStart] = useState(initialWorkingHours?.days[0].times[0].startTimeLocal);
  const [dayEnd, setDayEnd] = useState(initialWorkingHours?.days[0].times[0].endTimeLocal);

  const timezoneOptions = useAppSelector(getAllTimeZones);
  const alphabeticTimezones = useMemo(
    () => Array.from(timezoneOptions).sort((a, b) => a.id.localeCompare(b.id)),
    [timezoneOptions],
  );

  const hasChanges =
    timezone !== initialWorkingHours?.timeZoneId ||
    dayStart !== initialWorkingHours?.days[0].times[0].startTimeLocal ||
    dayEnd !== initialWorkingHours?.days[0].times[0].endTimeLocal;

  const updating = useAppSelector(getIsUpdatingMeetingUserPreferences);
  const loading = useAppSelector(getAreTimeZonesLoading);

  // Load timezones
  useEffect(
    function ensureTimezonesLoaded() {
      const timezonesHydrated = timezoneOptions.length > 0;
      if (!timezonesHydrated) {
        dispatch(withAsyncThunkErrorHandling(() => loadTimeZones()));
      }
    },
    [dispatch, timezoneOptions],
  );

  const dayStartOptions = timeOptionsBetween(START_WORKDAY_MIN_HOUR, START_WORKDAY_MAX_HOUR);
  const dayEndOptions = timeOptionsBetween(END_WORKDAY_MIN_HOUR, END_WORKDAY_MAX_HOUR);

  const handleConfirm = () => {
    if (timezone && dayStart && dayEnd && hasChanges) {
      dispatch(
        withAsyncThunkErrorHandling(() =>
          patchMeetingUserPreferences({
            workingHours: createWorkingHoursDto(timezone, dayStart, dayEnd),
          }),
        ),
      );
    }

    closeModal();
  };

  return (
    <ModalPage
      bottom={
        <ModalPageBottom
          onClick={handleConfirm}
          buttonDisabled={!hasChanges}
          buttonLabel={t('translation:Save')}
        />
      }
      onClose={closeModal}
      title={t('screen:WorkingHours')}>
      {updating || loading ? (
        <FlexRow
          height="100%"
          alignItems="center"
          justifyContent="center">
          <Loader />
        </FlexRow>
      ) : (
        <FlexCol
          gap={1}
          background={theme.divider.grey}>
          <ProfileCell>
            {t('screen:TimeZone')}
            <Select
              value={timezone || ''}
              setValue={setTimezone}>
              {alphabeticTimezones.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}>
                  {item.idWithUtcOffset}
                </Option>
              ))}
            </Select>
          </ProfileCell>

          <ProfileCell>
            {t('profile:WorkDayStarts')}
            <Select
              value={dayStart || ''}
              setValue={setDayStart}>
              {dayStartOptions.map((item) => (
                <Option
                  key={item}
                  value={item}>
                  {localTimeDisplayName(item)}
                </Option>
              ))}
            </Select>
          </ProfileCell>

          <ProfileCell>
            {t('profile:WorkDayEnds')}
            <Select
              value={dayEnd || ''}
              setValue={setDayEnd}>
              {dayEndOptions.map((item) => (
                <Option
                  key={item}
                  value={item}>
                  {localTimeDisplayName(item)}
                </Option>
              ))}
            </Select>
          </ProfileCell>
        </FlexCol>
      )}
    </ModalPage>
  );
};
