import {P} from '@quarks';
import styled from 'styled-components';

export const Message = styled(P)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
`;
