import {MapToastProps} from './types';

import {StyledMapToast, StyledTextWrap} from './styles';
import {FlexRow, Span} from '@quarks';
import {Avatar} from '../Avatar';
import {Card} from '@atoms';

export const MapToast = ({toast}: MapToastProps) => {
  return (
    <StyledMapToast>
      <Card
        paddingBottom={8}
        paddingTop={8}
        paddingLeft={16}
        paddingRight={16}>
        <FlexRow
          gap={8}
          alignItems="center">
          <Avatar
            style={{flexShrink: 0}}
            user={toast.user}
          />
          <StyledTextWrap>
            <Span fontWeight="bold">{toast.displayName}</Span>
          </StyledTextWrap>
          {toast?.deskName && <Span flexShrink={0}>{` - ${toast.deskName}`}</Span>}
        </FlexRow>
      </Card>
    </StyledMapToast>
  );
};
