import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {useLocation} from 'react-router-dom';
import {getIsHybridMeetingsFeatureEnabled, getIsOccupancyEnabled} from '@lib/store';

import {useAdminPermission, useAppSelector, useBreakPoint, useFeatureToggle, useSidePanel} from '@hooks';
import {getPendingIncomingConnectionCount, getUnreadNotifications, getUser, getUserInitials} from '@lib/store';

import {StyledNavLink, StyledButton, StyledNavAvatar} from './styles';
import {FlexRow} from '@quarks';
import {Icon} from '@atoms';
import {Badge, Button} from '@molecules';
import {Notifications} from '@pages';

const iconSize = '1.5625rem';

export const Navigation = ({footer}: {footer: boolean}) => {
  const bp = useBreakPoint();
  const enabledFeatures = useFeatureToggle();
  const {hasAdminPermission} = useAdminPermission();
  const location = useLocation();
  const {pathname} = location;
  const theme = useTheme();
  const {t} = useTranslation();

  const user = useAppSelector(getUser);
  const userInitials = useAppSelector(getUserInitials);
  const isHybridMeetingsFeatureEnabled = useAppSelector(getIsHybridMeetingsFeatureEnabled);
  const isOccupancyEnabled = useAppSelector(getIsOccupancyEnabled);
  const hereAndNowTabToTarget = isHybridMeetingsFeatureEnabled || isOccupancyEnabled ? 'rooms' : 'explore';

  const {openSidePanel, setSidePanelContent} = useSidePanel();
  const hasIncomingConnections = !!useAppSelector(getPendingIncomingConnectionCount);
  const hasIncomingNotifications = !!useAppSelector(getUnreadNotifications).length;

  const showProfileButton = bp !== 'small';

  const getAvatar = () => {
    if (enabledFeatures.CustomerBranding) {
      return (
        <StyledNavAvatar
          $active={pathname.includes('profile')}
          size="small"
          user={{...user, initials: userInitials}}
          shouldShowCheckIn
        />
      );
    }

    return user.imageHash ? (
      <StyledNavAvatar
        $active={pathname.includes('profile')}
        size="small"
        user={{...user, initials: userInitials}}
      />
    ) : (
      <Icon
        color={pathname.includes('profile') ? theme.icon.active : theme.icon.inactive}
        icon="profile"
        size={iconSize}
      />
    );
  };

  return (
    <FlexRow
      alignItems="center"
      columnGap={footer ? undefined : 32}
      justifyContent="space-around"
      sm={{
        display: footer ? 'flex' : 'none',
        justifyContent: footer ? 'space-around' : 'space-between',
        padding: footer ? '0' : '0 20px',
      }}>
      <>
        <StyledNavLink
          $footer={footer}
          aria-label={t('screen:HomeNav')}
          data-testid="organisms-navigation-home"
          to="/">
          <Icon
            color={pathname === '/' ? theme.icon.active : theme.icon.inactive}
            icon="home"
            size={iconSize}
          />
        </StyledNavLink>

        <StyledNavLink
          $footer={footer}
          aria-label={t('meeting:CalendarTabTitle')}
          data-testid="organisms-navigation-calendar"
          to="/calendar">
          <Icon
            color={pathname.includes('calendar') ? theme.icon.active : theme.icon.inactive}
            icon="calendar"
            size={iconSize}
          />
        </StyledNavLink>
      </>

      <StyledNavLink
        $footer={footer}
        aria-label={t('hereAndNow:HereAndNow')}
        data-testid="organisms-navigation-now"
        to={`now/${hereAndNowTabToTarget}`}>
        <Icon
          color={pathname.includes('now') ? theme.icon.active : theme.icon.inactive}
          icon="location"
          size={iconSize}
        />
      </StyledNavLink>

      <StyledNavLink
        $footer={footer}
        aria-label={t('screen:Connections')}
        data-testid="organisms-navigation-connections"
        to="connections">
        {hasIncomingConnections && (
          <Badge
            badge="notification"
            right="0"
            size="medium"
            top="0"
          />
        )}
        <Icon
          color={pathname.includes('connections') ? theme.icon.active : theme.icon.inactive}
          icon="users"
          size={iconSize}
        />
      </StyledNavLink>

      {footer ? (
        <StyledNavLink
          $footer={footer}
          aria-label={t('screen:Notifications')}
          data-testid="organisms-navigation-notifications-link"
          to="notifications">
          {hasIncomingNotifications && (
            <Badge
              badge="notification"
              right="0"
              size="medium"
              top="0"
            />
          )}
          <Icon
            color={pathname.includes('notifications') ? theme.icon.active : theme.icon.inactive}
            icon="bell"
            size={iconSize}
          />
        </StyledNavLink>
      ) : (
        <StyledButton
          $footer={footer}
          aria-label={t('screen:Notifications')}
          data-testid="organisms-navigation-notifications-button"
          onClick={() => {
            setSidePanelContent(<Notifications />, {
              eventData: {pageId: 'notifications'},
            });
            openSidePanel();
          }}>
          {hasIncomingNotifications && (
            <Badge
              badge="notification"
              right="0"
              size="medium"
              top="0"
            />
          )}
          <Icon
            color={pathname.includes('notifications') ? theme.icon.active : theme.icon.inactive}
            icon="bell"
            size={iconSize}
          />
        </StyledButton>
      )}

      {showProfileButton && (
        <StyledNavLink
          $footer={footer}
          aria-label={t('screen:Profile')}
          data-testid="organisms-navigation-profile"
          to="profile">
          {getAvatar()}
        </StyledNavLink>
      )}

      {hasAdminPermission && !footer && (
        <Button
          as="a"
          /** @ts-expect-error */
          href={import.meta.env.MAPIQ_ADMIN_BASE_URL}
          target="_blank"
          rel="noopener noreferrer"
          button="secondary">
          {t('Admin')}
        </Button>
      )}
    </FlexRow>
  );
};
