import {ChangeEvent, useEffect, useState} from 'react';
import {AddInviteesProps} from './types';
import {useTranslation} from 'react-i18next';
import {TransitionGroup} from 'react-transition-group';

import {MeetingInviteeSearchResult, searchInvitees, withAsyncThunkErrorHandling} from '@lib/store';
import {findByEmail, useDebounce} from '@lib/utils';

import {useAppDispatch} from '@hooks';

import {ChipSelect, InviteeCardCell} from '@molecules';
import {AddInviteesCardHint} from '@organisms';
import {Div, FlexCol} from '@quarks';
import {StyledGrid} from './styles';

export const AddInviteesCardContent = ({
  setInvitees,
  childWrapperRef,
  setSelectedInvitee,
  selectedInvitee,
  invitees,
}: AddInviteesProps) => {
  const {t} = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  const [isSearching, setIsSearching] = useState(false);
  const [resultsArray, setResultsArray] = useState<MeetingInviteeSearchResult[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleSearch = async () => {
      const result = await dispatch(withAsyncThunkErrorHandling(() => searchInvitees(debouncedSearchTerm)));

      if (result.success) {
        const payload = result.result as MeetingInviteeSearchResult[];
        setIsSearching(false);
        setResultsArray(payload);
      } else {
        setIsSearching(false);
        throw new Error();
      }
    };

    if (debouncedSearchTerm.length >= 2) handleSearch();
    if (debouncedSearchTerm.length < 2) {
      setResultsArray([]);
      setIsSearching(false);
    }
  }, [dispatch, debouncedSearchTerm]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length >= 2) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    setSearchTerm(e.target.value);
  };

  const filteredResultsArray = resultsArray.filter((result) => !findByEmail(invitees, result.email));

  const handleOnClickInvitee = (user: MeetingInviteeSearchResult) => {
    setInvitees((prevInvitees) => [...prevInvitees, user]);
    setSearchTerm('');
    setResultsArray([]);
  };

  return (
    <FlexCol
      gap={16}
      height="100%">
      <ChipSelect
        isLoading={isSearching}
        onChange={handleChange}
        optionsArray={filteredResultsArray}
        placeholder={t('translation:SearchInviteePlaceholder')}
        isPlaceholderTransparent={!!invitees.length}
        selectedOptionIndex={selectedInvitee}
        selection={invitees}
        setSelectedOptionIndex={setSelectedInvitee}
        setSelection={setInvitees}
        setValue={setSearchTerm}
        value={searchTerm}
      />
      <hr />
      <StyledGrid>
        <Div gridArea="full">
          <AddInviteesCardHint
            debouncedSearchTerm={debouncedSearchTerm}
            isSearching={isSearching}
            length={resultsArray.length}
            searchTerm={searchTerm}
          />
        </Div>
        <FlexCol
          columnGap={8}
          gridArea="full">
          <div ref={childWrapperRef}>
            <TransitionGroup component={null}>
              {filteredResultsArray.map((user, i) => (
                <InviteeCardCell
                  key={user.id}
                  i={i}
                  length={filteredResultsArray.length}
                  onClick={() => handleOnClickInvitee(user)}
                  selected={selectedInvitee === i}
                  user={user}
                />
              ))}
            </TransitionGroup>
          </div>
        </FlexCol>
      </StyledGrid>
    </FlexCol>
  );
};
