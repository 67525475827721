import {useTranslation} from 'react-i18next';

import {
  getCalendarIntegrationType,
  getIsUpdatingCalendarConsent,
  redirectForCalendarConsent,
  updateUserClosedConsentTilePreference,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppDispatch, useAppSelector} from '@hooks';

import {FlexCol, P} from '@quarks';
import {StyledWrapper} from './styles';
import {Button, IconButton} from '@molecules';

type HaNCalendarSyncTileProps = {
  stickToTheTop?: boolean;
};

export const HaNCalendarSyncTile = ({stickToTheTop = false}: HaNCalendarSyncTileProps) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const calendarIntegrationType = useAppSelector(getCalendarIntegrationType);
  const isUpdatingCalendarConsent = useAppSelector(getIsUpdatingCalendarConsent);

  const handleCloseCalendarSyncTile = () => {
    dispatch(withAsyncThunkErrorHandling(() => updateUserClosedConsentTilePreference(true)));
  };

  const handleSync = () => {
    dispatch(withAsyncThunkErrorHandling(() => redirectForCalendarConsent()));
  };

  return (
    <StyledWrapper
      stickToTheTop={stickToTheTop}
      data-testid="organisms-hereAndNow-calendarSyncTile-wrapper">
      <FlexCol
        gap={16}
        flex="1 0 0"
        alignItems="flex-start">
        <FlexCol gap={4}>
          <P fontWeight="bold">{`${t('hereAndNow:ConsentBannerTitle')} `}</P>
          <P>{t('hereAndNow:ConsentBannerMessage')}</P>
        </FlexCol>
        <Button
          button="primary light"
          data-testid="organisms-calendar-CalendarEventsList_connect-calendar-button-banner"
          loading={isUpdatingCalendarConsent}
          disabled={isUpdatingCalendarConsent}
          onClick={handleSync}>
          {t('meeting:CalendarBannerButton', {calendarIntegrationType})}
        </Button>
      </FlexCol>
      <FlexCol
        gap={8}
        alignItems="flex-end"
        alignSelf="stretch">
        <IconButton
          data-testid="organisms-calendar-CalendarEventsList_close-connect-calendar-button-banner"
          aria-label={t('translation:Close')}
          icon="close"
          iconButton="tertiary light"
          onClick={handleCloseCalendarSyncTile}
          size="small"
        />
      </FlexCol>
    </StyledWrapper>
  );
};
