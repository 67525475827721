import {useTranslation} from 'react-i18next';

import {ExtrasTileProps} from './types';
import {ExtrasSelectionCellProps} from '../ExtrasSelectionCell/types';
import {StyledExtraItemWrapper, StyledFlexCol} from '../ExtrasSelectionCard/styles';
import {StyledWrapper} from '../../eventDetails/MeetingDetailsCard/styles';

import {getExtrasBySpecificType, ExtrasTypes, getIsWorkdayLoading} from '@lib/store';
import {useAppSelector, useFeatureToggle, useModal} from '@hooks';
import {byProp} from '@lib/utils';
import {assertNever, getExtraItem, pxToRem} from '@utils';

import {FlexCol, P, Span, Subtitle} from '@quarks';
import {Icon, ThreeColumnCell} from '@atoms';
import {ClickableCellWrapper, SquareButton} from '@molecules';
import {ExtrasSelectionCard} from '@organisms';

export const ExtrasTile = ({extrasType, max = 1}: ExtrasTileProps) => {
  const {t} = useTranslation('extras');
  const {addPages, openModal} = useModal();
  const {ExtrasFeature: isFeatureEnabled} = useFeatureToggle();

  const isLoadingWorkday = useAppSelector(getIsWorkdayLoading);
  const allExtras = useAppSelector((state) => getExtrasBySpecificType(state, extrasType))
    .map(getExtraItem)
    .sort(byProp('name'));

  if (!isFeatureEnabled || !allExtras.length) return <></>;

  const handleShowExtrasInModal = () => {
    addPages([
      <ExtrasSelectionCard
        extrasType={extrasType}
        extrasItems={allExtras}
      />,
    ]);
    openModal();
  };

  const visibleExtras =
    allExtras.length > max
      ? [
          {
            id: extrasType,
            iconLeft: 'extras',
            iconRight: 'caretRight',
            description: '',
            onClick: handleShowExtrasInModal,
            name: t('AddExtras'),
          } as ExtrasSelectionCellProps,
        ]
      : allExtras.slice(0, max);

  const visibleExtraItems = visibleExtras.map(({id, name, description, iconLeft, iconRight, onClick}) =>
    extrasType === ExtrasTypes.WORKDAY ? (
      <SquareButton
        key={id}
        squareButton="energizingYellow"
        iconLeft={iconLeft}
        iconRight={iconRight}
        onClick={onClick}
        disabled={isLoadingWorkday}>
        <FlexCol>
          <Span fontWeight="bold">{t(`${name}`)}</Span>
          <P
            color="currentColor"
            fontWeight="regular">
            {description}
          </P>
        </FlexCol>
      </SquareButton>
    ) : (
      <ClickableCellWrapper
        key={id}
        onClick={onClick}>
        <ThreeColumnCell
          padding={'small'}
          separator={false}
          left={
            <Icon
              icon={iconLeft}
              size={pxToRem(24)}
            />
          }
          middle={
            <FlexCol>
              <P fontWeight={extrasType === ExtrasTypes.HYBRID_MEETINGS ? 'bold' : 'regular'}>{t(`${name}`)}</P>
              <Subtitle>{description}</Subtitle>
            </FlexCol>
          }
          right={
            <Icon
              icon={iconRight}
              size={pxToRem(24)}
            />
          }
        />
      </ClickableCellWrapper>
    ),
  );

  switch (extrasType) {
    case ExtrasTypes.WORKDAY:
      return <>{visibleExtraItems.map((item) => item)}</>;
    case ExtrasTypes.USER_PROFILE:
      return (
        <StyledFlexCol>
          {visibleExtraItems.map((item) => (
            <StyledExtraItemWrapper key={item.key}>{item}</StyledExtraItemWrapper>
          ))}
        </StyledFlexCol>
      );
    case ExtrasTypes.HYBRID_MEETINGS:
      return (
        <>
          {visibleExtraItems.map((item) => (
            <StyledWrapper key={item.key}>{item}</StyledWrapper>
          ))}
        </>
      );
    default:
      return assertNever(extrasType);
  }
};
