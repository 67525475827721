import {AvatarComponentProps, AvatarProps} from './types';

import {useProfilePic, useAppSelector} from '@hooks';
import {getCheckInStatusByUserIdOrDirectoryObjectId} from '@lib/store';
import {CheckInEventType} from '@lib/infrastructure';

import {Icon} from '@atoms';
import {Badge} from '@molecules';
import {P} from '@quarks';
import {StyledAvatar} from './styles';
import {avatarSizeToPx} from './utils';
import {useTheme} from 'styled-components';

export const Avatar = ({border, className, size, disabled, shouldShowCheckIn = false, style, user}: AvatarProps) => {
  const {email, id = undefined, imageHash, inviteResponse, initials, name, displayName} = user;
  const imageURL = useProfilePic(imageHash, avatarSizeToPx(size));
  const {eventType} = useAppSelector((state) => getCheckInStatusByUserIdOrDirectoryObjectId(state, id)) || {};

  return (
    <AvatarComponent
      disabled={disabled}
      border={border}
      className={className}
      email={email}
      imageURL={imageHash && imageURL}
      initials={initials}
      inviteResponse={inviteResponse}
      shouldShowCheckIn={shouldShowCheckIn && eventType === CheckInEventType.AtTheOffice}
      name={name || displayName || email}
      size={size}
      style={style}
    />
  );
};

export const AvatarComponent = ({
  border,
  className,
  disabled,
  email,
  imageURL,
  initials,
  inviteResponse,
  shouldShowCheckIn,
  name,
  size = 'medium',
  style,
  ...props
}: AvatarComponentProps) => {
  const theme = useTheme();
  const BadgeColor =
    inviteResponse === 'Accepted' || inviteResponse === 'Organizer'
      ? 'success'
      : inviteResponse === 'Declined'
      ? 'error'
      : 'unknown';

  const BadgeIcon =
    inviteResponse === 'Accepted' || inviteResponse === 'Organizer'
      ? 'check'
      : inviteResponse === 'Declined'
      ? 'close'
      : 'help';

  return (
    <StyledAvatar
      aria-label={name || email}
      className={className}
      role="img"
      $disabled={disabled}
      $imageURL={imageURL}
      $initials={initials}
      $email={email}
      $border={border}
      $size={size}
      style={style}
      {...props}>
      {!imageURL && initials && (
        <P
          font="inherit"
          fontWeight="bold">
          {initials.toUpperCase()}
        </P>
      )}
      {!initials && (
        <Icon
          icon="user"
          size="55%"
        />
      )}
      {shouldShowCheckIn && (
        <Badge
          border={`1px solid ${theme.avatar.border}`}
          badge="success"
          size={size === 'large' ? 'medium' : size === 'xlarge' ? 'large' : size}
          top="-2px"
          right="-2px"
        />
      )}
      {inviteResponse && (
        <Badge
          border={`1px solid ${theme.avatar.border}`}
          badge={BadgeColor}
          icon={BadgeIcon}
          size={size === 'small' ? 'medium' : size === 'medium' ? 'large' : size}
          bottom="-4px"
          right="-4px"
        />
      )}
    </StyledAvatar>
  );
};
