import {Div} from '@quarks';
import styled from 'styled-components';

export const FacilityInformationWrapper = styled(Div)`
  background: ${({theme}) => theme.background.white};
  display: flex;
  height: var(--modal-height);
  flex-direction: column;
  width: inherit;
`;
