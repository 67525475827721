import {NotificationCategory, NotificationCategoryType} from '@lib/store';
import {useTranslation} from 'react-i18next';
import {FlexCol, H3, Subtitle} from '@quarks';
import {NotificationSettingsItem} from './NotificationSettingsItem';
import {StyledFlexCol, StyledModalCell} from './styles';

export const NotificationSettingsSection = ({
  category,
  types,
}: {
  category: NotificationCategory;
  types: NotificationCategoryType[];
}) => {
  const {t} = useTranslation();

  return (
    <StyledModalCell as="fieldset">
      <StyledFlexCol
        as="legend"
        gap={8}>
        <H3
          as="h2"
          className="px-0">
          {t('profile:NotificationSettings.sectionTitle', {context: category})}
        </H3>
        <Subtitle>{t('profile:NotificationSettings.sectionDescription', {context: category})}</Subtitle>
      </StyledFlexCol>

      <FlexCol>
        {types.map((type, itemIndex) => (
          <NotificationSettingsItem
            category={category}
            type={type}
            key={`${itemIndex}${type}`}
          />
        ))}
      </FlexCol>
    </StyledModalCell>
  );
};
