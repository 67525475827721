import styled from 'styled-components';

export const CardWrapperEmpty = styled.div`
  display: grid;
  gap: 20px;
  justify-items: center;
  padding: 70px 40px;
  text-align: center;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
