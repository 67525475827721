import {Icon} from '@atoms';
import {useAppDispatch, useAppSelector, useBreakPoint, useRoomImage} from '@hooks';
import {Resource, getBuildingResources, loadBuildingResources} from '@lib/store';
import {LoaderTile, Tile} from '@organisms';
import {ReactNode, memo, useEffect, useMemo} from 'react';

import {cn} from '@utils';
import {CategoryConfig, useCategoryTiles} from './useCategoryTiles';
import {StyledSwiperHorizontal} from '../../hereAndNow/HaNRoomListHorizontal/styles';
import {useTranslation} from 'react-i18next';

// This tile checks the loading state and resolves resources for the correct building
export const DiscoverTheOfficeTile = ({buildingId}: {buildingId: string}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const resourceData = useAppSelector((state) => getBuildingResources(state, buildingId));

  const loadedResources = resourceData?.resources ?? [];
  const loading = !resourceData || resourceData.status === 'loading';

  // Note: cannot move out of effect due to faulty setState
  useEffect(
    function loadResourceData() {
      if (!resourceData && buildingId) {
        dispatch(loadBuildingResources(buildingId));
      }
    },
    [buildingId, resourceData, dispatch],
  );

  if (loading) return <LoaderTile rows={Array(2).fill([180, 180, 180])} />;

  return (
    <Tile title={t('FindFacilities')}>
      <CategoryTable
        resources={loadedResources}
        buildingId={buildingId}
      />
    </Tile>
  );
};

// This component transforms a bunch of resources for a building to a relevant set of clickable tiles
const CategoryTable = ({buildingId, resources}: {buildingId: string; resources: Resource[]}) => {
  // Pick a random image seed at first render; keep it until unmounted
  const randomImageSeed = useMemo(() => Math.random(), []);

  const tiles = useCategoryTiles(buildingId, resources, randomImageSeed);
  const breakPoint = useBreakPoint();

  const RowContainer =
    breakPoint === 'small'
      ? ({children}: {children: ReactNode[]}) => (
          <StyledSwiperHorizontal spaceBetween={16}>{children}</StyledSwiperHorizontal>
        )
      : ({children}: {children: ReactNode[]}) => (
          <div className={cn('grid grid-cols-6 w-full gap-8', {'grid-rows-2': tiles.length > 3})}> {children} </div>
        );

  return (
    <RowContainer>
      {tiles.map((t, i) => (
        <CategoryTile
          key={t.title}
          tile={t}
          className={cn(
            'flex gap-8',
            {
              'col-span-3': tiles.length >= 3 && i < 2,
              'col-span-2': i >= 2 && tiles.length === 5,
            },
            {
              'col-span-2': tiles.length === 6 || tiles.length === 3,
              'col-span-3': tiles.length === 4 || tiles.length === 2,
              'col-span-6': tiles.length === 1,
            },
          )}
        />
      ))}
    </RowContainer>
  );
};

// This component renders a tile, resolving the image and attaching click handlers
const CategoryTile = memo(({tile, className}: {tile: CategoryConfig; className: string}) => {
  const {title, imageHash, icon, onClick, buildingId} = tile;
  const image = useRoomImage(title ?? '', imageHash ?? undefined, buildingId, 250, true);

  return (
    <button
      data-testid="HomePage-discover-tile"
      onClick={onClick}
      className={cn(
        className,
        'outline-offset-8 outline-gray-400',
        'group relative rounded-lg bg-slate-100 flex flex-col gap-2',
        'justify-between overflow-hidden',
        'bg-cover bg-center text-white hover:cursor-pointer',
        'p-4 w-full aspect-[255/150] h-[176px]',
      )}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7)), url(${image})`,
      }}>
      <div className="bg-white rounded-lg size-12 shadow-mapiq-small flex items-center justify-center flex-none">
        <Icon
          icon={icon}
          size={'24px'}
        />
      </div>
      <div className="truncate transition-transform group-hover:-translate-y-1">{title}</div>
    </button>
  );
});
