import {StyledButtonProps} from './types';
import styled from 'styled-components';
import {Loader} from '@atoms';
import {pxToRem} from '@utils';

export const LoaderWrapper = styled.span`
  height: ${pxToRem(20)};
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: ${pxToRem(20)};
`;

export const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  background: ${({$button, theme}) => theme.button[$button].background};
  border: 1.5px solid ${({$button, theme}) => theme.button[$button].border};
  border-radius: 6px;
  color: ${({$button, $loading, theme}) => (!$loading ? theme.button[$button].color : 'transparent')};
  cursor: pointer;
  display: flex;
  font-size: ${({theme}) => theme.text.size.md};
  font-weight: ${({theme}) => theme.text.weight.bold};
  gap: ${({$size}) => ($size === 'small' ? '0 4px' : '0 8px')};
  justify-content: center;
  line-height: 1;
  padding: ${({$button, $size}) =>
    $button === 'text' ? 0 : $size === 'full' ? '19px 24px' : $size === 'small' ? '7px 16px' : '11px 20px'};
  position: relative;
  text-align: center;
  transition: color, var(--speed-fast), background var(--speed-fast), border var(--speed-fast);
  width: ${({$size}) => $size === 'full' && '100%'};

  &:disabled {
    background: ${({$button, $loading, theme}) =>
      !$loading ? theme.button[$button].disabled.background : theme.button[$button].background};
    border: 1.5px solid
      ${({$button, $loading, theme}) =>
        !$loading ? theme.button[$button].disabled.border : theme.button[$button].border};
    color: ${({$button, $loading, theme}) => (!$loading ? theme.button[$button].disabled.color : 'transparent')};

    @media (hover) {
      &:hover {
        cursor: ${({$loading}) => (!$loading ? 'not-allowed' : 'wait')};
      }
    }
  }

  @media (hover) {
    &:hover:not(:disabled) {
      text-decoration: none;
      background: ${({$button, theme}) => theme.button[$button].hover.background};
      border: 1.5px solid ${({$button, theme}) => theme.button[$button].hover.border};
      color: ${({$button, $loading, theme}) => (!$loading ? theme.button[$button].hover.color : 'transparent')};
    }
  }

  & > ${LoaderWrapper} > ${Loader} > path {
    stroke: ${({$button, theme}) => theme.button[$button].color};
  }

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.brand};
    text-decoration: none;
    outline-offset: 2px;
    color: ${({$button, $loading, theme}) => (!$loading ? theme.button[$button].hover.color : 'transparent')};
  }
`;
