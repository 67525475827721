import {BREAKPOINTS} from '@constants';
import {truncateString} from '@utils';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

export const PageHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-area: content;
  justify-content: space-between;

  ${BREAKPOINTS.small`
    display: grid;
    grid-template-rows: [logo-start button-end title-start] 1fr [title-end button-end logo-end];
    grid-template-columns: [title-start] auto [title-end button-start] 1fr [button-end];

    & > h1 {
      ${truncateString}
    }
  `}
`;

export const StyledNavLink = styled(NavLink)`
  border-radius: 2px;
  display: flex;
  align-items: center;

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.brand};
    outline-offset: 2px;
  }
`;
