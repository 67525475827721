import {ToggleProps} from './types';
import {StyledToggle} from './styles';

export const Toggle = ({checked, className, onChange, style, ...props}: ToggleProps) => {
  return (
    <StyledToggle
      checked={checked}
      className={className}
      onChange={onChange}
      role="switch"
      style={style}
      type="checkbox"
      {...props}
    />
  );
};
