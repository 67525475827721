import {Skeleton} from '@molecules';
import {FlexCol, FlexRow, H3} from '@quarks';

export const ConnectionsLoader = () => {
  const placeholderCount = 5;

  return (
    <FlexCol
      gap={16}
      justifyContent="center">
      <H3 className="pl-0">Your connections</H3>
      {Array.from({length: placeholderCount}, (_, i) => (
        <FlexRow
          gap={8}
          key={`placeholder_${i}`}
          className="h-full w-full">
          <Skeleton className="size-8 rounded-full bg-mapiq-black-100 flex-none" />
          <FlexCol
            className="w-full"
            gap={8}>
            <Skeleton className="h-4 w-full bg-mapiq-black-100" />
            <Skeleton className="h-3 w-2/3 bg-mapiq-black-100" />
          </FlexCol>
        </FlexRow>
      ))}
    </FlexCol>
  );
};
