import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  getAllBuildings,
  getDefaultBuildingId,
  getProfileConfig,
  savePreferredBuilding,
  withAsyncThunkErrorHandling,
} from '@lib/store';

import {Option} from '@molecules';
import {useAppDispatch, useAppSelector} from '@hooks';
import {ProfileCell, ProfileSelect} from '../styles';
import {P} from '@quarks';

export const DefaultOfficeCell = () => {
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const {isBuildingSelectorShown} = useAppSelector(getProfileConfig);
  const buildings = useAppSelector(getAllBuildings);
  const defaultBuilding = useAppSelector(getDefaultBuildingId);
  const dispatch = useAppDispatch();
  const {t} = useTranslation('screen');

  useEffect(() => {
    if (defaultBuilding) {
      setSelectedBuilding(defaultBuilding);
    }
  }, [defaultBuilding]);

  const handleSelectBuilding = (building: string) => {
    setSelectedBuilding(building);
    dispatch(withAsyncThunkErrorHandling(() => savePreferredBuilding(building)));
  };

  if (!isBuildingSelectorShown) {
    return <></>;
  }

  return (
    <ProfileCell>
      <P>{t('BuildingSelection')}</P>
      <ProfileSelect
        value={selectedBuilding}
        /*@ts-expect-error*/
        setValue={handleSelectBuilding}>
        {buildings.map((building) => (
          <Option
            key={building.id}
            value={building.id}>
            {building.name}
          </Option>
        ))}
      </ProfileSelect>
    </ProfileCell>
  );
};
