import {animated} from 'react-spring';
import styled from 'styled-components';

export const ModalBackgroundLayout = styled(animated.div)`
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
`;
