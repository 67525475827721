import {pxToRem, truncateString} from '@utils';
import styled from 'styled-components';
import {ModalCell} from '../ModalCell';

export const StyledMapToast = styled(ModalCell)`
  position: absolute;
  bottom: ${pxToRem(16)};
  max-width: 100%;
  background: none;
  width: auto;
`;

export const StyledTextWrap = styled.span`
  ${truncateString}
`;
