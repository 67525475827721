import {toast} from 'react-hot-toast';

import {
  createMeeting,
  getMeetingCreationStatus,
  MeetingCreationRequestData,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useModal, useAppDispatch, useAppSelector} from '@hooks';

export const useCreateEvent = (callback?: () => void) => {
  const {closeModal} = useModal();
  const dispatch = useAppDispatch();
  const isCreatingEvent = useAppSelector(getMeetingCreationStatus) === 'Loading';

  const createEvent = async (eventDto: MeetingCreationRequestData, successFeedbackMessage: string) => {
    if (isCreatingEvent) return;

    const {success} = await dispatch(withAsyncThunkErrorHandling(() => createMeeting(eventDto)));

    if (success) {
      callback?.();
      toast.success(successFeedbackMessage);
      closeModal();
    }
  };

  return {createEvent, isCreatingEvent};
};
