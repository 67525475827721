import {AvatarSize} from 'mapiq-molecules';

export const avatarSizeToPx = (size: AvatarSize | undefined): number => {
  switch (size) {
    case 'small':
      return 24;
    case 'medium':
      return 32;
    case 'large':
      return 40;
    case 'xxlarge':
      return 270;
    default:
      return 48;
  }
};
export const avatarSizeToFontSize = (size: AvatarSize | undefined): number => {
  if (size === 'small') return 9;
  if (size === 'xxlarge') return 48;

  return 11;
};
