import {P} from '@quarks';
import {animated} from 'react-spring';
import styled from 'styled-components';

export const StyledAnimatedDiv = styled(animated.div)`
  transform-origin: top center;
  color: ${({theme}) => theme.text.color.subtitle};
`;

export const StyledWarning = styled(P)`
  padding-bottom: 16px;
  color: ${({theme}) => theme.validation.error};
`;
