import {FlexRow, P} from '@quarks';
import styled from 'styled-components';

export const StyledText = styled(P)`
  padding: 16px 0px 0px var(--padding-x);
`;

export const StyledContainer = styled(FlexRow)`
  padding-top: 24px;
`;
