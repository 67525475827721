import {PropsWithChildren, useEffect, useMemo} from 'react';

import {Routes, Route, useParams, Navigate} from 'react-router-dom';

import {
  getEnabledFeaturesLoadingStatus,
  getIsLoadingSettings,
  getIsSignedIn,
  getMeetingPreferencesLoadingStatus,
  getUser,
  loadAppData,
  loadNotifications,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppSelector, useAppDispatch, useDetectRedirect, useCalculateCssVhVariable, useFeatureToggle} from '@hooks';

import {Canvas, Layout} from '@templates';

import {Dialog, Footer, Header, Modal, SidePanel} from '@organisms';
import {Calendar, Connections, Notifications, NotFound, Onboarding, PrivateRoute, Profile, HereAndNow} from '@pages';
import {Toaster} from '@molecules';
import {AppInsights} from './config/AppInsights';
import {useMsal} from '@azure/msal-react';
import {useShowErrorToUser} from './hooks/useShowErrorToUser';
import * as IntercomServices from '@utils';
import {Loader} from './components/atoms/Loader';
import format from 'date-fns/format';
import {Home} from './components/pages/Home';
import {HaNRedirect} from './components/HaNRedirect';
import {PageId, trackNavigateToPage} from '@lib/infrastructure';
let isFirstPageOfSession = true;

export const App = () => {
  const dispatch = useAppDispatch();
  const {instance} = useMsal();
  const signedInUser = instance.getActiveAccount();
  const subId = signedInUser?.idTokenClaims?.subscription_id || 'failed-to-get-sub-id-from-token';
  const userId = signedInUser?.idTokenClaims?.sub || 'failed-to-get-user-id-from-token';
  const {Intercom: isIntercomEnabled} = useFeatureToggle();
  const user = useAppSelector(getUser);
  const tokenClaims = signedInUser?.idTokenClaims;
  const isSignedIn = useAppSelector(getIsSignedIn);
  const flagsLoadingStatus = useAppSelector(getEnabledFeaturesLoadingStatus);

  useDetectRedirect();
  useCalculateCssVhVariable();
  // Handle errors thrown by api calls
  useShowErrorToUser();

  useEffect(() => {
    if (isSignedIn) {
      AppInsights.setAuthenticatedUserContext(userId, subId as string);
      if (isIntercomEnabled) {
        IntercomServices.login(tokenClaims, user);
      }
    } else {
      AppInsights.clearAuthenticatedUserContext();
      if (isIntercomEnabled) {
        IntercomServices.logout();
      }
    }
  }, [isSignedIn, subId, userId, isIntercomEnabled, tokenClaims, user]);

  useEffect(() => {
    if (isSignedIn) {
      dispatch(withAsyncThunkErrorHandling(() => loadAppData()));
    }
  }, [dispatch, isSignedIn]);

  useEffect(() => {
    // This is the initial load of the notifications to avoid flickering
    // of the notification route when the side panel is openned
    // false is for the isPollMode param
    if (isSignedIn) dispatch(withAsyncThunkErrorHandling(() => loadNotifications()));
  }, [dispatch, isSignedIn]);

  return (
    <Layout>
      <Header />
      <Routes>
        {/* This route handles silent login flows. It will only be used by hidden iframes. It will not trigger any MSAL stuff. */}
        <Route
          path="silent-login"
          element={<h1>Silent login, welcome back</h1>}
        />

        <Route element={<PrivateRoute />}>
          {flagsLoadingStatus === 'Loading' || flagsLoadingStatus === 'NotLoaded' ? (
            <Route
              path="*"
              element={<WithPrerequisites />}
            />
          ) : (
            <Route
              element={
                <WithPrerequisites>
                  <Onboarding />
                </WithPrerequisites>
              }>
              {MainRoutes()}
            </Route>
          )}
        </Route>

        <Route
          path="fourohfour"
          element={<NotFound />}
        />
      </Routes>

      <Footer />
      <SidePanel />
      <Modal />
      <Dialog />
      <Toaster />
    </Layout>
  );
};

const MainRoutes = () => {
  return (
    <>
      <Route
        path="/"
        element={
          <TrackNavigation pageId="home">
            <WithPrerequisites>
              <Home />
            </WithPrerequisites>
          </TrackNavigation>
        }
      />

      <Route
        path="/calendar/:date?"
        element={
          <TrackNavigation pageId="calendar">
            <WithPrerequisites>
              <Calendar />
            </WithPrerequisites>
          </TrackNavigation>
        }
      />

      <Route>
        <Route
          path="/now"
          element={<HaNRedirect />}
        />
        <Route
          path="/now/rooms"
          element={
            <TrackNavigation pageId="hereAndNowRooms">
              <WithPrerequisites>
                <HereAndNow tab="rooms" />
              </WithPrerequisites>
            </TrackNavigation>
          }
        />
        <Route
          path="/now/workspaces"
          element={
            <TrackNavigation pageId="hereAndNowWorkspaces">
              <WithPrerequisites>
                <HereAndNow tab="workspaces" />
              </WithPrerequisites>
            </TrackNavigation>
          }
        />
        <Route
          path="/now/explore"
          element={
            <TrackNavigation pageId="hereAndNowExplore">
              <WithPrerequisites>
                <HereAndNow tab="explore" />
              </WithPrerequisites>
            </TrackNavigation>
          }
        />
      </Route>

      <Route
        path="connections"
        element={
          <TrackNavigation pageId="connections">
            <WithPrerequisites>
              <Connections />
            </WithPrerequisites>
          </TrackNavigation>
        }
      />
      <Route
        path="notifications"
        element={
          <TrackNavigation pageId="notifications">
            <WithPrerequisites>
              <Notifications />
            </WithPrerequisites>
          </TrackNavigation>
        }
      />
      <Route
        path="profile"
        element={
          <TrackNavigation pageId="profile">
            <WithPrerequisites>
              <Profile />
            </WithPrerequisites>
          </TrackNavigation>
        }
      />
      <Route
        path="/:maybeDate"
        element={<DateRedirect />}
      />
    </>
  );
};

const TrackNavigation = ({children, pageId}: PropsWithChildren<{pageId: PageId}>) => {
  trackNavigateToPage(pageId, isFirstPageOfSession);
  if (isFirstPageOfSession) isFirstPageOfSession = false;
  return children;
};

const WithPrerequisites = ({children, noLoader}: PropsWithChildren<{noLoader?: boolean}>) => {
  const meetingLoadingStatus = useAppSelector(getMeetingPreferencesLoadingStatus);
  const loadingSettings = useAppSelector(getIsLoadingSettings) ?? true;
  const enabledFeaturesLoadingStatus = useAppSelector(getEnabledFeaturesLoadingStatus);

  const {HybridMeetingsCalendarView} = useFeatureToggle();

  const loadedAllPrerequisites = useMemo(() => {
    if (enabledFeaturesLoadingStatus === 'Loaded' && !loadingSettings) {
      if (HybridMeetingsCalendarView) {
        return meetingLoadingStatus === 'Loaded';
      } else {
        return true;
      }
    }

    return false;
  }, [HybridMeetingsCalendarView, enabledFeaturesLoadingStatus, loadingSettings, meetingLoadingStatus]);

  return loadedAllPrerequisites ? (
    children
  ) : noLoader ? null : (
    <Canvas centerContent>
      <Loader />
    </Canvas>
  );
};

/*
  This component serves to smoothen the transition to the new home page. 
  Previously, URLs of the format /yyyy-MM-dd would show a calendar day.
  With the introduction of the AppHomePage feature flag, those pages have
  been moved to /calendar/yyyy-MM-dd.
  
  This redirect looks at any url of /some-string that has not been claimed
  by a different route. If the some-string is a valid date format, it redirects
  to /calendar/some-string. If not, it 404s.
*/
const DateRedirect = () => {
  const {maybeDate} = useParams();

  if (!maybeDate) {
    throw new Error('Invalid use of DateRedirect route; should only be called with maybeDate parameter.');
  }

  const date = new Date(maybeDate);
  if (isNaN(date.getTime())) {
    return <Navigate to="/fourohfour" />;
  }

  return (
    <Navigate
      to={`/calendar/${format(date, 'yyyy-MM-dd')}`}
      replace={true}
    />
  );
};
