import {BREAKPOINTS} from '@constants';
import {Button, Chip} from '@molecules';
import styled from 'styled-components';

export type StyledChipProps = {
  $hasSolidBackground: boolean;
  $useHalfWidth?: boolean;
};

export type StyledButtonProps = {
  $hasSolidBackground: boolean;
};

export const StyledChip = styled(Chip)<StyledChipProps>`
  background: ${({theme, $hasSolidBackground}) =>
    $hasSolidBackground ? theme.button['primary'].color : theme.button['tertiary'].background};

  max-width: ${({$useHalfWidth}) => ($useHalfWidth ? 'calc(50% - 4px)' : '100%')};

  ${BREAKPOINTS.small`
    max-width: 100%;
  `};
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  min-height: 40px;
  flex-shrink: 0;
  background: ${({theme}) => theme.button['primary'].color};
  box-shadow: ${({$hasSolidBackground, theme}) =>
    $hasSolidBackground ? `0px 2px 5px ${theme.button['tertiary'].background}` : undefined};
  @media (hover) {
    &:hover:not(:disabled) {
      background: ${({theme}) => theme.button['primary'].color};
    }
  }
`;
