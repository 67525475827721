import {ButtonProps} from './types';

import {LoaderWrapper, StyledButton} from './styles';

import {Loader, Icon} from '@atoms';
import {pxToRem} from '@utils';

export const Button = ({
  as,
  button = 'primary',
  children,
  className,
  disabled,
  iconRight,
  loading,
  size,
  style,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      as={as ? 'a' : 'button'}
      className={className}
      $button={button}
      disabled={loading || disabled}
      $loading={loading}
      $size={size}
      style={style}
      {...props}>
      {children}
      {iconRight && (
        <Icon
          color="currentColor"
          icon={iconRight}
          size={pxToRem(15)}
          weight="regular"
        />
      )}
      {loading && (
        <LoaderWrapper>
          <Loader
            icon="loader"
            size={pxToRem(20)}
          />
        </LoaderWrapper>
      )}
    </StyledButton>
  );
};
