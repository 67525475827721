import {Card} from '@atoms';
import styled from 'styled-components';

export const StyledQuotaCard = styled(Card).attrs(({...attrs}) => ({
  ...attrs,
  card: 'safeMauve',
  shadow: false,
}))`
  border-radius: 8px;
  padding: 16px;

  & > ul {
    list-style-position: inside;
    padding-left: 0;
  }
`;
