import {useAppSelector, useFeatureToggle, useModal} from '@hooks';
import {getIsMeetingsCalendarEnabled, getIsOccupancyEnabled} from '@lib/store';
import {MeetingRoomScheduleData} from '@organisms';
import {ModalPage} from '@organisms';
import {FlexCol, H2, P} from '@quarks';
import {useTranslation} from 'react-i18next';

export const HaNAvailabilityStatusMoreInfo = () => {
  const {closeModal, removePages} = useModal();
  const {t} = useTranslation();
  const isOccupancyEnabled = useAppSelector(getIsOccupancyEnabled);
  const isMeetingCalendarEnabled = useAppSelector(getIsMeetingsCalendarEnabled);
  const {HybridMeetingsCalendarView} = useFeatureToggle();

  return (
    <ModalPage
      onClose={closeModal}
      onBack={() => removePages(1)}>
      <FlexCol gap={32}>
        {isOccupancyEnabled && (
          <>
            <H2>{t('hereAndNow:SensorInformation')}</H2>
            <FlexCol gap={8}>
              <MeetingRoomScheduleData
                data-testid="SensorInformationSensorIconAvailable"
                status="available"
                title={t('hereAndNow:RoomSeemsToBeAvailableTitle')}
                icon="broadcast"
              />
              <P>{t('hereAndNow:EmptySensorDescription')}</P>
            </FlexCol>
            <FlexCol gap={8}>
              <MeetingRoomScheduleData
                data-testid="SensorInformationSensorIconUnavailable"
                status="unavailable"
                title={t('hereAndNow:RoomSeemsToBeInUseTitle')}
                icon="broadcast"
              />
              <P>{t('hereAndNow:SensorInUseDescription')}</P>
            </FlexCol>
            <FlexCol gap={8}>
              <MeetingRoomScheduleData
                data-testid="SensorInformationSensorIconUnknown"
                status="unknown"
                title={t('hereAndNow:RoomLiveDataUnavailableTitle')}
                icon="broadcast"
              />
              <P>{t('hereAndNow:NoLiveDataDescription')}</P>
            </FlexCol>
          </>
        )}
        {isMeetingCalendarEnabled && HybridMeetingsCalendarView && (
          <>
            <H2>{t('hereAndNow:CalendarInformation')}</H2>
            <FlexCol gap={8}>
              <MeetingRoomScheduleData
                data-testid="SensorInformationScheduleIconAvailable"
                status="available"
                title={t('NotBooked')}
                icon="calendar"
              />
              <P>{t('hereAndNow:NotBookedDescription')}</P>
            </FlexCol>
            <FlexCol gap={8}>
              <MeetingRoomScheduleData
                data-testid="SensorInformationScheduleIconUnavailable"
                status="unavailable"
                title={t('Booked')}
                icon="calendar"
              />
              <P>{t('hereAndNow:BookedDescription')}</P>
            </FlexCol>
            <FlexCol gap={8}>
              <MeetingRoomScheduleData
                data-testid="SensorInformationScheduleIconAvailableInXMinutes"
                status="pending"
                title={t('hereAndNow:AvailableInXMinutes')}
                icon="calendar"
              />
              <P>{t('hereAndNow:AvailableDescription')}</P>
            </FlexCol>
            <FlexCol gap={8}>
              <MeetingRoomScheduleData
                data-testid="SensorInformationScheduleIconNextBookingX"
                status="pending"
                title={t('hereAndNow:NextBookingX')}
                icon="calendar"
              />
              <P>{t('hereAndNow:NextBookingDescription')}</P>
            </FlexCol>
          </>
        )}
      </FlexCol>
    </ModalPage>
  );
};
