import {useCallback, useEffect, useMemo, useState} from 'react';
import {CreateTimeSlotSuggestionsListProps} from './types';
import {useTranslation} from 'react-i18next';

import {
  getAllMeetingSuggestionsWithOrganizerAttendees,
  getMeetingDurations,
  getMeetingSuggestionLoadingStatus,
  loadMeetingTimeSlotSuggestions,
  MeetingSuggestion,
} from '@lib/store';

import {addDays, max, parseISO, startOfDay} from 'date-fns';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';

import {DEFAULT_NEW_EVENT_DURATION} from '@constants';

import {FlexCol, FlexRow, H2, P, Span} from '@quarks';
import {Duration, Loader} from '@atoms';
import {Button, ModalCell, ModalCellButton, Option, Select, SwiperHorizontal} from '@molecules';
import {
  CreateEventLoadMoreSuggestionsButton,
  CreateEventSelectedTimeSlot,
  CreateEventTimeSlotSuggestionCell,
} from '@organisms';
import {ManualTimeSlotSelectionCard} from '../ManualTimeSlotSelectionCard';

export const CreateEventTimeSlotSuggestionsList = ({
  date,
  attendees,
  endDateTime,
  startDateTime,
  onClose,
  onSelectTimeSlot,
}: CreateTimeSlotSuggestionsListProps) => {
  const dateAsDate = useMemo(() => parseISO(date), [date]);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {addPages} = useModal();

  // * Redux states
  const isTimeSuggestionLoading = useAppSelector(getMeetingSuggestionLoadingStatus) === 'Loading';
  const newEventDurations = useAppSelector((state) => getMeetingDurations(state, 'newEvent'));

  // * Local states
  const [selectedSuggestion, setSelectedSuggestion] = useState<MeetingSuggestion | undefined>();
  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(true);
  const [duration, setDuration] = useState(DEFAULT_NEW_EVENT_DURATION);
  const [selectedSlotId, setSelectedSlotId] = useState('');
  const [areAdditionalSuggestionsDisplayed, setAreAdditionalSuggestionsDisplayed] = useState(false);

  const timeSuggestions = useAppSelector(getAllMeetingSuggestionsWithOrganizerAttendees);

  const setNewDuration = (newDuration: number) => {
    setDuration(newDuration);
    setAreAdditionalSuggestionsDisplayed(false);
  };

  const loadTimeSuggestions = useCallback(
    async (startDate: Date, endDate: Date) => {
      dispatch(
        loadMeetingTimeSlotSuggestions({
          startDateTime: startDate.toISOString(),
          endDateTime: endDate.toISOString(),
          durationInMinutes: duration,
          attendees: attendees.map((attendee) => attendee.email),
        }),
      );
    },
    [attendees, dispatch, duration],
  );

  const loadNextTwoWeekOfSuggestions = () => {
    const startDate = max([dateAsDate, new Date()]);
    const endDate = addDays(startDate, 14);

    loadTimeSuggestions(startDate, endDate);
    setAreAdditionalSuggestionsDisplayed(true);
  };

  useEffect(() => {
    const loadTimeSuggestionsForToday = () => {
      const startDate = max([dateAsDate, new Date()]);
      const endDate = startOfDay(addDays(startDate, 1));

      loadTimeSuggestions(startDate, endDate);
    };

    if (shouldShowSuggestions) {
      loadTimeSuggestionsForToday();
    }
  }, [dateAsDate, loadTimeSuggestions, shouldShowSuggestions]);

  const handleOnClick = () => {
    return addPages([
      <ManualTimeSlotSelectionCard
        onSave={(startDateTimeValue, endDateTimeValue, attendeeAvailabilities) => {
          onSelectTimeSlot(startDateTimeValue, endDateTimeValue);
          setSelectedSuggestion({
            id: '',
            timeSlotStart: startDateTimeValue,
            timeSlotEnd: endDateTimeValue,
            attendeeAvailabilities: attendeeAvailabilities,
          });
          setShouldShowSuggestions(false);
        }}
        onClose={onClose}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        date={date}
        attendees={attendees}
      />,
    ]);
  };

  return (
    <>
      <FlexCol gap={1}>
        <ModalCell>
          <H2>{t('meeting:DateAndTime')}</H2>
        </ModalCell>
        <ModalCellButton
          data-testid="organisms-createEvent-CreateEventTimeSlotSuggestionsList_time-slot-button"
          onClick={handleOnClick}>
          {selectedSuggestion ? (
            <CreateEventSelectedTimeSlot
              attendees={attendees}
              endDateTime={endDateTime}
              startDateTime={startDateTime}
            />
          ) : (
            <Span>{t('meeting:CreateMeetingSelectDateAndTimeLabel')}</Span>
          )}
        </ModalCellButton>
        {shouldShowSuggestions && (
          <>
            <ModalCell>
              <FlexRow
                alignItems="center"
                justifyContent="space-between"
                gap={24}>
                <P>{t('meeting:CreateMeetingSuggestionsTitle')}</P>
                <Select
                  value={duration}
                  setValue={(newDuration) => setNewDuration(newDuration)}>
                  {newEventDurations.map((minute) => (
                    <Option
                      key={minute}
                      value={minute}>
                      <Duration
                        key={minute}
                        number={minute}
                        timeUnit="minutes"
                      />
                    </Option>
                  ))}
                </Select>
              </FlexRow>
            </ModalCell>
            <ModalCell>
              {isTimeSuggestionLoading ? (
                <FlexRow
                  alignItems="center"
                  justifyContent="center">
                  <Loader />
                </FlexRow>
              ) : (
                <SwiperHorizontal>
                  {timeSuggestions.map((item, index) => (
                    <CreateEventTimeSlotSuggestionCell
                      attendees={attendees}
                      key={item.id}
                      date={date}
                      onClose={onClose}
                      id={index.toString()}
                      onClick={(value) => {
                        setSelectedSuggestion(value);
                        setShouldShowSuggestions(false);
                        onSelectTimeSlot(value.timeSlotStart, value.timeSlotEnd);
                      }}
                      selectedSlotId={selectedSlotId}
                      setSelectedSlotId={setSelectedSlotId}
                      suggestion={item}
                      suggestionsLength={timeSuggestions.length}
                    />
                  ))}
                  {!areAdditionalSuggestionsDisplayed && (
                    <CreateEventLoadMoreSuggestionsButton
                      index={timeSuggestions.length}
                      onClick={loadNextTwoWeekOfSuggestions}
                    />
                  )}
                </SwiperHorizontal>
              )}
            </ModalCell>
          </>
        )}
        {!shouldShowSuggestions && (
          <ModalCell>
            <Button
              onClick={() => {
                setShouldShowSuggestions(true);
                setAreAdditionalSuggestionsDisplayed(false);
              }}
              button="text">
              {t('meeting:CreateMeetingShowSuggestionsLink')}
            </Button>
          </ModalCell>
        )}
      </FlexCol>
    </>
  );
};
