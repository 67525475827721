import {CreateEventLoadMoreSuggestionsButtonProps} from './types';

import {useTranslation} from 'react-i18next';
import {useSwiper} from 'swiper/react';

import {pxToRem} from '@utils';
import {FlexCol, P} from '@quarks';
import {StyledIcon, Wrapper} from './styles';

export const CreateEventLoadMoreSuggestionsButton = ({onClick, index}: CreateEventLoadMoreSuggestionsButtonProps) => {
  const {t} = useTranslation();
  const swiper = useSwiper();

  const additionalSuggestionsButtonMessage =
    index === 0
      ? t('meeting:CreateMeetingSearchForAlternativesMessage')
      : t('meeting:CreateMeetingFindMoreSuggestionsMessage');

  return (
    <Wrapper
      as="button"
      card={index === 0 ? 'red' : 'beige200'}
      clickable={true}
      onClick={onClick}
      onFocus={() => swiper.slideTo(index)}
      data-testid="organisms-createEvent-CreateEventLoadMoreSuggestionsButton">
      <FlexCol>
        <P fontWeight="bold">{t('meeting:CreateMeetingSearchForAlternativesTitle')}</P>
        <P>{additionalSuggestionsButtonMessage}</P>
      </FlexCol>
      <StyledIcon
        color="currentColor"
        icon="caretRight"
        size={pxToRem(24)}
      />
    </Wrapper>
  );
};
