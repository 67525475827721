import {useTranslation} from 'react-i18next';
import {H2} from '@quarks';
import {CardProps} from '@atoms';
import {CellWrapper, ProfileCard} from '../styles';
import {ExportsCell} from '@organisms';

export const ExportsCard = ({className, style, ...props}: CardProps) => {
  const {t} = useTranslation();

  return (
    <ProfileCard
      as="section"
      {...props}>
      <H2>{t('profile:ExportsSectionTitle')}</H2>
      <CellWrapper>
        <ExportsCell />
      </CellWrapper>
    </ProfileCard>
  );
};
