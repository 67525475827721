import styled from 'styled-components';

export const StyledToggle = styled.input`
  -webkit-appearance: none;
  align-items: center;
  appearance: none;
  background: ${({theme}) => theme.input.toggle.background};
  block-size: 1rem;
  border-radius: 9999px;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: grid;
  flex-shrink: 0;
  grid: [track] 1fr / [track] 1fr;
  inline-size: calc(34 / 16 * 1rem);
  padding: calc(2 / 16 * 1rem) calc(3 / 16 * 1rem);
  transition: background var(--speed-normal);

  &::before {
    background: ${({theme}) => theme.input.toggle.handle};
    block-size: 1rem;
    border-radius: 9999px;
    content: '';
    grid-area: track;
    inline-size: 1rem;
    transition: transform var(--speed-fast);
  }

  &:checked {
    background: ${({theme}) => theme.input.toggle.checked};
    &::before {
      transform: translate3d(calc(calc(34 / 16 * 1rem) - 100%), 0, 0);
    }
  }

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.brand};
    outline-offset: 2px;
  }

  &[disabled] {
    cursor: not-allowed;
    background: ${({theme}) => theme.disabled.background};
  }
`;
