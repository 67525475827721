import {ChangeEvent, HTMLAttributes, MouseEventHandler, useId} from 'react';
import './RenderModeToggle.scss';
import {useGlobalMapTypePreference} from '../useGlobalMapTypePreference';
import {useFeatureToggle} from '@hooks';

export const RenderModeToggle = ({
  position,
  padding = 0,
  mapTypeNamespace = '',
}: {
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  padding?: 0 | 8 | 16 | 20;
  mapTypeNamespace?: string;
}) => {
  const id2d = useId();
  const id3d = useId();

  const [type, setMapType] = useGlobalMapTypePreference(mapTypeNamespace);
  const {ThreeDMaps: allow3dMap} = useFeatureToggle();
  const testId = mapTypeNamespace ? `render-mode-toggle-button-${mapTypeNamespace}` : `render-mode-toggle-button`;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case '2d':
        setMapType('2d');
        break;
      case '3d':
        setMapType('3d');
        break;
      default:
        console.warn('Unsupported map type found in UI');
    }

    e.stopPropagation();
  };

  const handleToggle: MouseEventHandler<HTMLButtonElement> = (e) => {
    setMapType(type === '2d' ? '3d' : '2d');
    e.stopPropagation();
  };

  const style: HTMLAttributes<HTMLFieldSetElement>['style'] = position
    ? {
        position: 'absolute',
        top: position.includes('top') ? `${padding}px` : '',
        left: position.includes('left') ? `${padding}px` : '',
        right: position.includes('right') ? `${padding}px` : '',
        bottom: position.includes('bottom') ? `${padding}px` : '',
      }
    : {};

  if (!allow3dMap) return <></>;

  return (
    <fieldset
      className="RenderModeToggle"
      style={style}>
      <legend>view mode</legend>
      <input
        value="2d"
        id={id2d}
        type="radio"
        checked={type === '2d'}
        onChange={handleChange}
      />
      <label htmlFor={id2d}>2d</label>
      <input
        value="3d"
        id={id3d}
        type="radio"
        checked={type === '3d'}
        onChange={handleChange}
      />
      <label htmlFor={id3d}>3d</label>

      <button
        data-testid={testId}
        className="Toggle-switch"
        onClick={handleToggle}
        aria-label="Toggle 3d/2d">
        <div className="Cube">
          {Array.from({length: 6}).map((_, i) => (
            <div
              className="Cube-face"
              key={`face_${i}`}></div>
          ))}
        </div>
      </button>
    </fieldset>
  );
};
