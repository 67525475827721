import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

export const CalendarWeekOverview = styled.div<{$showCalendar: boolean}>`
  grid-area: calendar;
  height: calc(100vh - 60px);
  overflow: hidden auto;

  ${BREAKPOINTS.medium`
    display: none;
  `}

  ${BREAKPOINTS.small`
    display: ${({$showCalendar}) => ($showCalendar ? 'block' : 'none')};
    grid-area: fullbleed;
    overflow: unset;
    height: unset;
  `}
`;
