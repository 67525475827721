import React, {KeyboardEvent, useMemo} from 'react';
import {FlexRow} from '@quarks';
import {StyledButton, StyledChip} from './styles';
import {FilterSliceKeyType, useFilters} from '@lib/utils';
import {useTranslation} from 'react-i18next';
import {NewFilterCard} from '@organisms';
import {useModal} from '@hooks';
import {StyledSwiperHorizontal} from 'src/components/organisms/calendar/CalendarHorizontalWeekOverview/styles';

type ChipFilterProps = {
  buttonLabel?: string;
  testIdPrefix?: string;
  filterSlice: FilterSliceKeyType;
  areFilterTransparent?: boolean;
  swiperFilterReset?: () => void;
  goBackFromFilterModal?: () => void;
  closeFilterModal?: () => void;
  dontUseSwiper?: boolean;
  showSearchBarInFilterScreen?: boolean;
  searchBarInFilterScreenOptions?: {
    placeholderText?: string;
    searchByProperties?: string[];
  };
};

export const ChipFilterNew = ({
  showSearchBarInFilterScreen,
  searchBarInFilterScreenOptions,
  areFilterTransparent = false,
  dontUseSwiper,
  filterSlice,
  buttonLabel,
  testIdPrefix = '',
  swiperFilterReset,
  closeFilterModal,
  goBackFromFilterModal,
}: ChipFilterProps) => {
  const {selectedFilters, config, removeFilter} = useFilters(filterSlice);
  const {addPages, openModal} = useModal();
  const {t} = useTranslation();

  const buttonLabelTranslation = buttonLabel ? buttonLabel : t('workplace:Filter');

  const {placeholderText, searchByProperties} = searchBarInFilterScreenOptions ?? {};
  function handleClick() {
    addPages([
      <NewFilterCard
        filterSlice={filterSlice}
        onBackCallback={goBackFromFilterModal}
        onCloseCallback={closeFilterModal}
        showSearchBar={showSearchBarInFilterScreen}
        searchBarPlaceholderText={placeholderText}
        searchByProperties={searchByProperties}
      />,
    ]);
    openModal();
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  const chips = useMemo(() => {
    function handleClearFilters(sectionId: string, valToClear: string) {
      removeFilter(sectionId, valToClear);
      swiperFilterReset?.();
    }

    return Object.values(config).flatMap((filter) => {
      // No chips if filter has been removed due to feature flag or if it's hidden
      if (filter === undefined) return [];
      if (filter.hiddenSection === true) return [];

      const selected = new Set(selectedFilters[filter.sectionId] ?? []);

      return filter.data
        .filter((option) => selected.has(option.id))
        .map((option) => ({
          id: option.id,
          label: option.label,
          onDelete:
            filter.type === 'singleMandatory' ? undefined : () => handleClearFilters(filter.sectionId, option.id),
        }));
    });
  }, [selectedFilters, swiperFilterReset, config, removeFilter]);

  const Wrapper = dontUseSwiper ? React.Fragment : StyledSwiperHorizontal;

  return (
    <FlexRow
      sm={{flexWrap: 'nowrap'}}
      flexWrap="wrap"
      justifyContent="flex-start"
      width="100%"
      gap={8}>
      <Wrapper>
        <StyledButton
          $hasSolidBackground={!areFilterTransparent}
          button={'secondary'}
          iconRight="filter"
          data-testid={testIdPrefix ? `${testIdPrefix}_chip-filter_button` : null}
          onKeyDown={handleKeyDown}
          onClick={() => handleClick()}>
          {buttonLabelTranslation}
        </StyledButton>
        {chips &&
          chips.map((chip) => (
            <StyledChip
              $useHalfWidth={dontUseSwiper}
              $hasSolidBackground={!areFilterTransparent}
              chip="tertiary"
              key={`chip-filter_${chip.id}`}
              data-testid={testIdPrefix ? `${testIdPrefix}_chip-filter_chip` : null}
              {...(chip.onDelete && {onDelete: chip.onDelete})}
              square={true}>
              {chip.label}
            </StyledChip>
          ))}
      </Wrapper>
    </FlexRow>
  );
};
