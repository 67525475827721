import {config, useTransition} from 'react-spring';

import {HaNAttendeesProps} from './types';

import {AttendeesWrapper, StyledBody} from './styles';
import {useTranslation} from 'react-i18next';
import {FlexCol} from '@quarks';
import {AddInviteesCardContent, ModalPageBottom, HaNModalHeader} from '@organisms';
import {useDestructiveDialog, useScrollParentForChild} from '@hooks';
import {MeetingInviteeSearchResult} from '@lib/store';
import {useState, useEffect, useCallback} from 'react';

/**
 * Displayed in modal.
 * Allows user to modify details to room booking.
 */

export const HaNAttendees = ({
  onBack,
  onClose,
  page,
  invitees: attendees,
  onSave,
  hasUnsavedChanges,
}: HaNAttendeesProps) => {
  const {t} = useTranslation();
  const {destructiveDialog} = useDestructiveDialog();
  const transition = useTransition(page === 'addInvitees', {
    from: {transform: 'translate3d(100%, 0, 0)'},
    enter: {transform: 'translate3d(0%, 0, 0)'},
    leave: {transform: 'translate3d(100%, 0, 0)'},
    config: {...config.stiff, clamp: true},
  });

  const [selectedInvitee, setSelectedInvitee] = useState<number | undefined>(undefined);
  const [invitees, setInvitees] = useState<MeetingInviteeSearchResult[]>([]);

  const [scrollRef, childWrapperRef] = useScrollParentForChild(selectedInvitee);

  useEffect(() => {
    setInvitees(attendees);
  }, [attendees]);

  const handleClose = useCallback(async () => {
    if (!hasUnsavedChanges) {
      onClose();
    } else {
      const confirmation = await destructiveDialog(
        t('meeting:DiscardEventChangesTitle'),
        t('meeting:DiscardEventChangesMessage'),
        'warning',
        t('meeting:DiscardEventChangesConfirm'),
      );
      if (confirmation) onClose();
    }
  }, [destructiveDialog, t, hasUnsavedChanges, onClose]);

  const handleOnBack = () => {
    onBack();
    // Timeout is needed because without it rerender will be visible
    // even before we close this page.
    setTimeout(() => {
      setInvitees(attendees);
    }, 500);
  };

  return transition(
    (style, item) =>
      item && (
        <AttendeesWrapper style={style}>
          <HaNModalHeader
            onBack={handleOnBack}
            onClose={handleClose}
            title={t('screen:Invitees')}
          />
          <StyledBody ref={scrollRef}>
            <AddInviteesCardContent
              childWrapperRef={childWrapperRef}
              invitees={invitees}
              setInvitees={setInvitees}
              setSelectedInvitee={setSelectedInvitee}
              selectedInvitee={selectedInvitee}
            />
          </StyledBody>
          <FlexCol paddingBottom={16}>
            <ModalPageBottom
              buttonLabel={t('Save')}
              onClick={() => onSave(invitees)}
            />
          </FlexCol>
        </AttendeesWrapper>
      ),
  );
};
