import {useEffect} from 'react';
import {CalendarParkingTileProps} from './types';

import {
  getIsUserAllowedToReserveParking,
  getLicensePlates,
  getParkingReservationByDate,
  getWorkdayByDate,
  loadParkingCount,
  loadParkingLotsByBuilding,
  withAsyncThunkErrorHandling,
  getParkingSettings,
} from '@lib/store';
import {useAppDispatch, useAppSelector, useFeatureToggle, useModal} from '@hooks';
import {parseISO} from 'date-fns';

import {SquareButton} from '@molecules';
import {CalendarParkingTileBookingInfo, ParkingSelectionCard} from '@organisms';
import {LicensePlatesModalPage} from '../../profile/licensePlates/LicensePlatesModalPage/LicensePlatesModalPage';

export const CalendarParkingTile = ({date}: CalendarParkingTileProps) => {
  const dispatch = useAppDispatch();
  const {Parking: isFeatureEnabled} = useFeatureToggle();
  const {openModal, setModalPages} = useModal();

  const licensePlates = useAppSelector(getLicensePlates);

  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, date));
  const parkingSettings = useAppSelector(getParkingSettings);

  const {nodeId: buildingId} = useAppSelector((state) => getWorkdayByDate(state, date)) || {};
  const isUserAllowedToReserveParking = useAppSelector((state) =>
    getIsUserAllowedToReserveParking(state, parseISO(date)),
  );

  useEffect(() => {
    if (buildingId) {
      dispatch(
        withAsyncThunkErrorHandling(() =>
          loadParkingLotsByBuilding({
            buildingId,
          }),
        ),
      );
      dispatch(withAsyncThunkErrorHandling(() => loadParkingCount({selectedDate: date})));
    }
  }, [dispatch, buildingId, date]);

  if (!isFeatureEnabled) return <></>;

  const isLicensePlateMandatory = parkingSettings?.licensePlateIsRequired ?? false;
  const isDisabled = !isUserAllowedToReserveParking && !parkingReservation;

  const shouldOpenLicensePlateModal = isLicensePlateMandatory && !licensePlates?.length;

  const handleOnClick = () => {
    if (isDisabled) return;

    if (shouldOpenLicensePlateModal) {
      setModalPages([<LicensePlatesModalPage date={date} />]);
    } else {
      setModalPages([<ParkingSelectionCard date={date} />]);
    }

    openModal();
  };

  return (
    <SquareButton
      iconLeft="car"
      iconRight="caretRight"
      squareButton="energizingYellow"
      onClick={handleOnClick}
      disabled={isDisabled}>
      <CalendarParkingTileBookingInfo date={date} />
    </SquareButton>
  );
};
