import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {
  getBuildingByFloorId,
  getNodeInformationByNodeIdInEditMode,
  getSelectedSpaceNodeId,
  selectLocation,
} from '@lib/store';
import {AvatarStack, IconButton, ModalCellItem, ModalCellItemIcon} from '@molecules';
import {AreaDetailsModalPage} from '@organisms';
import {FlexCol} from '@quarks';
import {parseISO} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {StyledText} from './styles';
import {WorkspaceSelectionCellProps} from './types';

export const WorkspaceSelectionCell = ({workspace, date, inviteeCount = 0}: WorkspaceSelectionCellProps) => {
  const {removePages} = useModal();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {
    floorId,
    connectedUsers,
    hasBookableDesks,
    title,
    areaId,
    nodeAvailability,
    subtitle,
    nodeId: spaceNodeId,
  } = workspace;

  // Note: the undefined / empty string path feels unsafe, but shouldn't be?
  const buildingId = useAppSelector((state) => getBuildingByFloorId(state, floorId))?.id ?? '';

  const selectedSpaceNodeId = useAppSelector(getSelectedSpaceNodeId);
  const {isFull, isClosed, hasInsufficientSpaceForGroupBooking} = useAppSelector((state) =>
    getNodeInformationByNodeIdInEditMode(state, {nodeId: spaceNodeId, date: parseISO(date), inviteeCount}),
  );

  const isNodeUnavailable = nodeAvailability === 'Unavailable';
  const {addPages} = useModal();

  const subtitleString = () => {
    if (workspace.workspaceBookingPolicyType === 'NonBookable') {
      return subtitle;
    }
    if (isClosed) {
      return subtitle ? `${subtitle}, ${t('Closed').toLowerCase()}` : t('Closed');
    }
    if (isFull) {
      return subtitle ? `${subtitle}, ${t('Full').toLowerCase()}` : t('Full');
    }
    if (hasInsufficientSpaceForGroupBooking) {
      return subtitle
        ? `${subtitle}, ${t('workplace:NotEnoughAvailableDesks').toLowerCase()}`
        : t('workplace:NotEnoughAvailableDesks');
    }
    const selectAvailableDesk = t('hereAndNow:AreaBooking');
    if (!subtitle) {
      return selectAvailableDesk;
    }
    if (hasBookableDesks) {
      return `${subtitle}, ${t('hereAndNow:DeskBooking')}`;
    }
    return `${subtitle}, ${selectAvailableDesk}`;
  };

  const handleOnClick = () => {
    if (isNodeUnavailable) {
      return;
    }

    dispatch(
      selectLocation({
        buildingId,
        floorId,
        areaId: areaId ?? '',
      }),
    );
    removePages(1);
  };

  return (
    <ModalCellItem
      disabled={isNodeUnavailable}
      alignItems="flex-start"
      left={
        <ModalCellItemIcon
          selected={selectedSpaceNodeId === spaceNodeId}
          disabled={isNodeUnavailable}
          icon={'desk'}
        />
      }
      middle={
        <FlexCol
          gap={8}
          paddingTop={8}>
          <StyledText
            data-testid="organisms-bookWorkspace-WorkspaceSelectionCell_title"
            $disabled={isNodeUnavailable}
            fontWeight="bold">
            {title}
          </StyledText>
          <StyledText $disabled={isNodeUnavailable}>{subtitleString()}</StyledText>
          <AvatarStack
            date={date}
            disabled={isNodeUnavailable}
            users={connectedUsers}
            tooltipId="WorkspaceSelectionCell"
            overlap={true}
          />
        </FlexCol>
      }
      right={
        <IconButton
          size="xsmall"
          onClick={(e) => {
            e.stopPropagation();
            addPages([<AreaDetailsModalPage {...{workspace, date, inviteeCount}} />]);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              addPages([<AreaDetailsModalPage {...{workspace, date, inviteeCount}} />]);
            }
          }}
          icon="info"
          weight="regular"
          iconButton="tertiary"
          aria-label={t('workplace:CheckRoomInfo')}
        />
      }
      onClick={handleOnClick}
    />
  );
};
