import {ConnectionSuggestionCardProps} from './types';
import {
  loadConnectionSuggestions,
  sendConnection,
  sendConnectionSuggestionFeedback,
  withAsyncThunkErrorHandling,
} from '@lib/store';

import {useAppDispatch} from '@hooks';
import {useTranslation} from 'react-i18next';
import {Div, FlexCol, FlexRow, P} from '@quarks';
import {Avatar, Button, IconButton} from '@molecules';
import {SuggestionCard, SuggestionName} from './styles';

export const ConnectionSuggestionCard = ({connectionSuggestion, feedbackData}: ConnectionSuggestionCardProps) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {connectedUser, numberOfCommonFriends} = connectionSuggestion;

  const handleConnect = async () => {
    dispatch(
      withAsyncThunkErrorHandling(() =>
        sendConnectionSuggestionFeedback({
          ...feedbackData,
          userIdSuggestedConnection: connectionSuggestion.connectedUser.id,
          approved: true,
        }),
      ),
    );
    await dispatch(withAsyncThunkErrorHandling(() => sendConnection(connectedUser)));
    dispatch(loadConnectionSuggestions());
  };

  const handleIgnore = async () => {
    await dispatch(
      withAsyncThunkErrorHandling(() =>
        sendConnectionSuggestionFeedback({
          ...feedbackData,
          userIdSuggestedConnection: connectionSuggestion.connectedUser.id,
          approved: false,
        }),
      ),
    );
    dispatch(loadConnectionSuggestions());
  };

  return (
    <SuggestionCard>
      <FlexCol gap={16}>
        <FlexRow gap={16}>
          <Div flexShrink={0}>
            <Avatar
              size="xlarge"
              user={connectedUser}
            />
          </Div>
          <FlexCol
            gap={8}
            justifyContent="flex-start"
            overflow="hidden">
            <SuggestionName>{connectedUser.name}</SuggestionName>
            <P fontSize="sm">{t('connection:MutualConnection', {count: numberOfCommonFriends})}</P>
          </FlexCol>
          <Div flexShrink={0}>
            <IconButton
              aria-label={t('connection:IgnoreConnection')}
              iconButton="tertiary light"
              icon="close"
              onClick={handleIgnore}
              size="xsmall"
            />
          </Div>
        </FlexRow>
        <Button
          button="primary light"
          onClick={handleConnect}>
          {t('connection:Connect')}
        </Button>
      </FlexCol>
    </SuggestionCard>
  );
};
