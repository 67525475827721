import {getAccessToken} from '@lib/store';
import {useAppSelector} from './useAppSelector';
import {useImage} from './useImage';

/**
 * @param imageHash string
 * @returns URL to profile picture as string
 */

export const useProfilePic = (imageHash: string | undefined, size = 64) => {
  const token = useAppSelector(getAccessToken) || '';
  const endpoint = imageHash ? `shifts/profilepictures/${imageHash}?size=${size}` : null;
  const avatarImage = useImage(token, endpoint, '2.0');

  return avatarImage;
};
