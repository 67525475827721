import {TwoColumnCellProps} from './types';
import {Left, Right, StyledTwoColumnCell} from './styles';

export const TwoColumnCell = ({
  className,
  left,
  alignItems,
  padding = 'large',
  right,
  separator = true,
  style,
  ...props
}: TwoColumnCellProps) => {
  return (
    <StyledTwoColumnCell
      $padding={padding}
      $alignItems={alignItems}
      $separator={separator}
      className={className}
      style={style}
      {...props}>
      <Left>{left}</Left>
      <Right>{right}</Right>
    </StyledTwoColumnCell>
  );
};
