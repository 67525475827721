import {ICalendarService} from '@lib/infrastructure';

const redirect_source = 'meet_consent';

const makeRedirectUri = (source: string, result: string) => {
  const {origin, pathname} = window.location;
  const params = new URLSearchParams({redirect_source: source, result});

  return encodeURIComponent(`${origin}${pathname}?${params.toString()}`);
};

export const calendarService = (): ICalendarService => {
  const getSuccessRedirectUrl = () => makeRedirectUri(redirect_source, 'success');
  const getFailureRedirectUrl = () => makeRedirectUri(redirect_source, 'reject');
  const getCancellationRedirectUrl = () => makeRedirectUri(redirect_source, 'failure');

  return {
    requestConsent: (consentUrl: string) => {
      document.location = consentUrl;
    },
    getCancellationRedirectUrl: getCancellationRedirectUrl,
    getFailureRedirectUrl: getFailureRedirectUrl,
    getSuccessRedirectUrl: getSuccessRedirectUrl,
  };
};
