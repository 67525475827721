import {HaNSearchBar} from '../HaNSearchBar';
import {IconButton} from '@molecules';
import {FlexRow} from '@quarks';
import {useTranslation} from 'react-i18next';
import {ExploreState} from 'src/components/pages/HereAndNow/useExploreState';

type Props = {
  exploreState: ExploreState;
  showBackButton: boolean;
  onBack: () => void;
};

export const HaNExploreSearchBar = ({exploreState, onBack, showBackButton}: Props) => {
  const {t} = useTranslation();

  return (
    <FlexRow
      gap={16}
      style={{padding: '16px 20px'}}
      flex={'1'}>
      {showBackButton ? (
        <IconButton
          icon="arrowLeft"
          weight="regular"
          color="black"
          style={{border: 'none'}}
          aria-label={t('GoToPreviousTab')}
          onClick={onBack}
          iconButton={'secondary'}
        />
      ) : null}
      <HaNSearchBar
        size="full"
        exploreState={exploreState}
      />
    </FlexRow>
  );
};
