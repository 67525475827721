import styled, {keyframes} from 'styled-components';
import {Icon} from '@atoms';

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled(Icon).attrs(({color, icon, theme, ...attrs}) => ({
  ...attrs,
  icon: 'loader',
  color: color || theme.text.color.body,
}))`
  animation: ${loaderAnimation} 1s steps(8) infinite;
  transform-origin: center;
`;
