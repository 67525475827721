import {BadgeProps} from './types';

import {StyledBadge, StyledIcon} from './styles';
import {pxToRem} from '@utils';

export const Badge = ({
  badge = 'success',
  border = 'none',
  className,
  icon = undefined,
  top,
  bottom,
  left,
  right,
  size = 'medium',
  style,
  ...props
}: BadgeProps) => {
  return (
    <StyledBadge
      $badge={badge}
      className={className}
      $border={border}
      $top={top}
      $bottom={bottom}
      $left={left}
      $right={right}
      $size={size}
      style={style}
      {...props}>
      {icon && (
        <StyledIcon
          icon={icon}
          size={
            size === 'small'
              ? pxToRem(6)
              : size === 'large'
              ? pxToRem(10)
              : size === 'medium'
              ? pxToRem(8)
              : pxToRem(14)
          }
          weight="regular"
        />
      )}
    </StyledBadge>
  );
};
