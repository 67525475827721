import {getIsWorkspacesOnHereAndNowEnabled, useAppSelector} from '@lib/store';
import {ChipFilterNew} from '@molecules';
import {useTabByPathname} from '../helpers';

type Props = {
  swiperFilterReset: () => void;
  areFilterTransparent?: boolean;
};

export const HaNFilters = ({swiperFilterReset, areFilterTransparent}: Props) => {
  const tab = useTabByPathname();

  const isWorkspacesOnHereAndNowEnabled = useAppSelector(getIsWorkspacesOnHereAndNowEnabled);

  return tab === 'rooms' || (!isWorkspacesOnHereAndNowEnabled && !tab) ? (
    <ChipFilterNew
      filterSlice="HereAndNow_Rooms"
      testIdPrefix="HaNRoomFilters"
      swiperFilterReset={swiperFilterReset}
      areFilterTransparent={areFilterTransparent}
    />
  ) : tab === 'workspaces' ? (
    <ChipFilterNew
      filterSlice="HereAndNow_Workspaces"
      testIdPrefix="HaNWorkSpaceFilters"
      swiperFilterReset={swiperFilterReset}
      areFilterTransparent={areFilterTransparent}
    />
  ) : tab === 'explore' ? (
    <ChipFilterNew
      filterSlice="HereAndNow_Explore"
      testIdPrefix="HaNExploreFilters"
      swiperFilterReset={swiperFilterReset}
      areFilterTransparent={areFilterTransparent}
    />
  ) : (
    <></>
  );
};
