import styled from 'styled-components';
import {baseStyle} from '../styles';
import {pxToRem} from '@utils';

export const P = styled.p`
  ${baseStyle}
  font-size: ${({fontSize, theme}) => (fontSize ? theme.text.size[fontSize] : pxToRem(15))};
  font-weight: ${({fontWeight}) => (fontWeight === 'bold' ? 600 : 400)};
  font: ${({font}) => font};
`;
