import {cn} from '@utils';

export const ExpandMapButton = ({onClick, className}: {onClick: () => void; className: string}) => {
  return (
    <button
      aria-label="Expand map"
      onClick={onClick}
      className={cn(
        'rounded-md bg-white w-[40px] h-[40px] flex items-center justify-center shadow-mapiq-small hover:shadow-mapiq-base',
        className,
      )}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.6641 3.75H16.4141M16.4141 3.75V7.5M16.4141 3.75L12.0391 8.125M7.66406 16.25H3.91406M3.91406 16.25V12.5M3.91406 16.25L8.28906 11.875"
          stroke="#1A1A1A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};
