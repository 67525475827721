import {differenceInMinutes, differenceInSeconds} from 'date-fns';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';

export const useRefreshDataIfStale = (isLoading: boolean, maxAgeSeconds: number) => {
  const lastRefreshTriggeredRef = useRef<Date | null>(null);
  const {t} = useTranslation();
  const lastRefreshAgeMinutes =
    lastRefreshTriggeredRef.current === null ? 0 : differenceInMinutes(new Date(), lastRefreshTriggeredRef.current);

  // TODO: translate
  const lastRefreshLabel = isLoading
    ? `${t('Updating')}...`
    : lastRefreshAgeMinutes === 0
    ? t('UpdatedNow')
    : t('UpdatedMinutesAgo', {minutes: lastRefreshAgeMinutes});

  const refreshDataIfStaleAndNotLoading = (update: Function) => {
    if (isLoading) return;

    // If data goes stale, trigger a reload and store the last reload trigger timestamp
    const dataAge =
      lastRefreshTriggeredRef.current === null
        ? Infinity
        : differenceInSeconds(new Date(), lastRefreshTriggeredRef.current);
    const dataWentStale = dataAge > maxAgeSeconds;

    if (dataWentStale) {
      update();
      lastRefreshTriggeredRef.current = new Date();
    }
  };

  return {
    refreshIfStale: refreshDataIfStaleAndNotLoading,
    refreshLabel: lastRefreshLabel,
  };
};
