import {SelectInviteesButtonProps} from './types';
import {StyledParagraph} from './styles';

import {ModalCellButton, AvatarStack} from '@molecules';

export const SelectInviteesButton = ({onClick, invitees, date, placeholder, ...props}: SelectInviteesButtonProps) => {
  return (
    <ModalCellButton
      onClick={onClick}
      aria-label={placeholder}
      {...props}>
      {invitees.length ? (
        <AvatarStack
          data-testid="organisms-SelectInvteesButton_avatar-stack"
          date={date}
          users={invitees}
          tooltipId="SelectInviteesButton"
          overlap={true}
          max={5}
        />
      ) : (
        <StyledParagraph>{placeholder}</StyledParagraph>
      )}
    </ModalCellButton>
  );
};
