import {AccountInfo} from '@azure/msal-browser';
import {AppUser} from '@lib/store';

type Claims = AccountInfo['idTokenClaims'];

export function login(claims: Claims, user: AppUser) {
  try {
    window.Intercom('update', {
      name: claims?.name,
      email: typeof claims?.email !== 'string' || undefined ? undefined : claims?.email,
      created_at: claims?.iat,
      user_id: user.id,
      mapiq_object_id: claims?.oid,
      mapiq_external_id: typeof claims?.external_id === 'string' ? claims?.external_id : undefined,
      mapiq_roles: claims?.roles ? claims.roles.join(',') : '',
      'Mapiq Workspace Location': JSON.stringify(user.effectiveLocationProfile),
      'Mapiq Workspace Quota': JSON.stringify(user.effectiveAccessProfile),
      'Mapiq Parking Location': JSON.stringify(user.parkingLocationProfile),
      'Mapiq Parking Quota': JSON.stringify(user.parkingQuotaProfile),
      company: {
        // https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#section-company-object
        company_id: typeof claims?.subscription_id === 'string' ? claims?.subscription_id : undefined,
        // By keeping this empty, it allow us to manually rename the company in Intercom itself
        // without it being overwritten during the next app open of an admin of that company/subscription
        name: '',
      },
    });
  } catch (e) {
    console.error('Failed to update Intercom with user info', e);
  }
}

export function logout() {
  // https://www.intercom.com/help/en/articles/16845-how-do-i-end-a-session
  window.Intercom('shutdown');
}
