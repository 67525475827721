import styled from 'styled-components';
import {FlexRow} from '@quarks';

const rgba = (color: string, opacity: number) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

type Props = {$type: 'warning' | 'error'};

export const StyledFlexRow = styled(FlexRow)<Props>`
  border-radius: 8px;
  padding: 18px 16px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  width: 100%;
  opacity: 0;
  background-color: ${({theme, $type}) => rgba(theme.validation.card[$type].background, 0.15)};
  animation: fade 450ms forwards;

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const StyledIconWrapper = styled.div<Props>`
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 4px;

  &::before {
    content: '';
    background-color: ${({theme, $type}) => theme.validation.card[$type].iconBackground};
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 999px;
    z-index: -1;
  }
`;
