import {pickUsingStringAsIndex} from '@lib/utils';
import {pxToRem, truncateString} from '@utils';
import styled from 'styled-components';
import {StyledChipProps} from './types';
import {Div} from '@quarks';

export const StyledChip = styled(Div)<StyledChipProps>`
  align-items: center;
  background: ${({$chip, $email, theme}) =>
    $chip === 'colored' && $email
      ? pickUsingStringAsIndex($email, theme.avatar.colors)
      : $chip === 'colored'
      ? theme.avatar.unknown
      : theme.button[$chip].background};
  border-radius: ${({$square}) => ($square ? '6px' : '9999px')};
  border-radius: 6px;
  border: 2px solid ${({$selected, theme}) => ($selected ? theme.input.hover.border : 'transparent')};
  color: ${({theme}) => theme.text.color.body};
  cursor: ${({onClick}) => (onClick ? 'pointer' : 'default')};
  display: flex;
  font-weight: 600;
  gap: 0 8px;
  justify-content: space-between;
  max-width: 100%;
  padding: 8px 16px;
  transition: filter var(--speed-fast);

  @media (hover) {
    &:hover {
      background: ${({$chip, $email, onClick, theme}) =>
        onClick &&
        ($chip === 'colored' && $email
          ? pickUsingStringAsIndex($email, theme.avatar.hover)
          : $chip === 'colored'
          ? theme.avatar.unknown
          : theme.button[$chip].hover.background)};
    }
  }

  &:focus-visible {
    outline: none;
    border: 2px solid ${({theme}) => theme.brand};
  }
`;

export const ChipLabel = styled.span`
  ${truncateString}
  flex: 1;
`;

export const ChipDeleteButton = styled.button`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  background: none;
  border: none;
  border-radius: 9999px;
  color: ${({theme}) => theme.text.color.body};

  @media (hover) {
    &:hover {
      color: ${({theme}) => theme.text.color.subtitle};
    }
  }
`;
