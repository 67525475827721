import styled from 'styled-components';

import {truncateString} from '@utils';

import {FlexCol} from '@quarks';

export const StyledBuildingWrapper = styled(FlexCol)`
  & > h2 {
    ${truncateString};
  }

  &:not(:first-of-type) {
    padding-top: 16px;
  }
`;
