import {CalendarConnectionsBuildingCellProps} from './types';
import {StyledBuildingWrapper} from './styles';

import {isToday, parseISO} from 'date-fns';

import {H2} from '@quarks';
import {UserCell} from '@molecules';

export const CalendarConnectionsBuildingCell = ({building, date}: CalendarConnectionsBuildingCellProps) => {
  return (
    <StyledBuildingWrapper>
      <H2>{building.buildingName}</H2>
      {building.data.map((connection) => (
        <UserCell
          key={connection.connectionUser.id}
          shouldShowCheckIn={isToday(parseISO(date))}
          subtitle={connection.workspaceReservationSpaceNodeName}
          user={connection.connectionUser}
          separator={false}
        />
      ))}
    </StyledBuildingWrapper>
  );
};
