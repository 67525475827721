import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  grid-area: body / content;
  padding-bottom: 16px;

  ${BREAKPOINTS.small`
    padding-top: 16px;
  `}
`;
