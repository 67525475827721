import {ISecureStorage} from '@lib/infrastructure';

export const secureStorage = (): ISecureStorage => ({
  getItem: (key) => {
    return Promise.resolve(localStorage.getItem(key));
  },
  setItem: (key, value) => {
    localStorage.setItem(key, value);
    return Promise.resolve();
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
    return Promise.resolve();
  },
  clear: () => {
    localStorage.clear();
    return Promise.resolve();
  },
});
