import styled from 'styled-components';
import {Avatar} from '@molecules';
import {StyledAvatarProps, StyledAvatarStackProps} from './types';
import {Div, P} from '@quarks';
import {truncateString} from '@utils';

export const StyledAvatarStack = styled.div<StyledAvatarStackProps>`
  display: grid;
  gap: ${({$overlap}) => ($overlap ? '0' : '0 8px')};
  grid-template-columns: repeat(
    ${({$columns, $length, $max, $span}) => ($length > $max ? $columns + $span : $length * $span + 1)},
    1fr
  );
`;

export const StyledAvatar = styled(Avatar)<StyledAvatarProps>`
  border: 2px solid
    ${({$count, $length, $max, theme}) => ($length > $max && $count === 0 ? 'transparent' : theme.background.white)};
  /* Formatting issues with the grid-area requires silencing Prettier */
  /* prettier-ignore */
  grid-area: 1 / ${({$avatarsLength, $columns, $count, $length, $max, $span}) =>
    $length > $max
      ? $columns + $span - ($count * $span + $span)
      : $avatarsLength * $span + 1 - ($count * $span + $span)} / 1 / span ${({$overlap, $span}) =>
    $overlap ? $span + 1 : $span};

  isolation: isolate;
`;

export const StyledDiv = styled(Div)`
  max-height: 300px;
  overflow-x: hidden;
  padding: 16px;
`;

export const TooltipName = styled(P)`
  ${truncateString}
`;
