import fallbackBg from '@images/customer_banner_fallback.webp';
import styled from 'styled-components';

type CustomerBannerProps = {
  bannerSrc?: string;
  title: string;
  logoSrc?: string;
};
export const CustomerBanner = ({bannerSrc = fallbackBg, title, logoSrc}: CustomerBannerProps) => {
  // had to use --homeLayoutWidth css var here to get the width of the banner
  // homeLayoutWidth is calculated in parent HomeLayoutWrapper
  return (
    <section
      className="w-full relative h-[200px] 3xl:max-w-[var(--homeLayoutWidth)] 3xl:mx-auto"
      style={{background: `url(${bannerSrc}) center center/cover`}}>
      <img
        src={bannerSrc}
        alt="Banner background"
        className="h-full w-full absolute inset-0 object-cover"
      />
      <div className="absolute inset-0 h-full w-full bg-gradient-to-b from-transparent to-[#00000057] bg-opacity-10 "></div>
      <div className="max-w-[var(--homeLayoutWidth)]   w-full h-full mx-auto lg:pb-10 flex justify-center items-center lg:justify-start lg:items-end 3xl:justify-center">
        <div className="flex flex-col items-center  gap-6 lg:flex-row  lg:items-end">
          {logoSrc && (
            <div className=" overflow-hidden rounded-md border-2 border-white bg-white drop-shadow-md">
              <img
                src={logoSrc}
                className="h-[5rem] w-[5rem] rounded-md object-cover "
              />
            </div>
          )}
          <ClampedHeading className="pb-2 text-white  drop-shadow-[1px_1px_3px_rgba(0,0,0,0.5)] font-display font-medium">
            {title}
          </ClampedHeading>
        </div>
      </div>
    </section>
  );
};

const ClampedHeading = styled('h1')`
  font-size: clamp(1rem, 10vw, 42px);
`;
