import {Icon, IconNames} from '@atoms';
import {RoomEquipment} from '@lib/store';
import {FlexCol, FlexRow, H2, P} from '@quarks';
import {pxToRem} from '@utils';
import {useTranslation} from 'react-i18next';
import {MeetingRoomEquipmentProps} from './types';

export const MeetingRoomEquipment = ({equipment}: MeetingRoomEquipmentProps) => {
  const {t} = useTranslation();

  const equipmentMap: Map<RoomEquipment, IconNames> = new Map([
    [RoomEquipment.Audio, 'speaker'],
    [RoomEquipment.Flipboard, 'flipboard'],
    [RoomEquipment.InteractiveWhiteboard, 'whiteboardInteractive'],
    [RoomEquipment.PresentationScreen, 'presentationScreen'],
    [RoomEquipment.Projector, 'projector'],
    [RoomEquipment.Smartboard, 'whiteboardInteractive'],
    [RoomEquipment.Speakerphone, 'speakerphone'],
    [RoomEquipment.Webcam, 'webcam'],
    [RoomEquipment.Whiteboard, 'whiteboard'],
  ]);

  return (
    <>
      {!!equipment.length && (
        <FlexCol gap={16}>
          <H2 as="h3">{t('meeting:EquipmentTitle')}</H2>
          {equipment.map((item, index) => (
            <FlexRow
              key={index}
              justifyContent="flex-start"
              gap={8}
              alignItems="center">
              <Icon
                weight="thin"
                size={pxToRem(24)}
                icon={equipmentMap.get(item)!}
              />
              <P>{t(`meetingEquipment:${item}`)}</P>
            </FlexRow>
          ))}
        </FlexCol>
      )}
    </>
  );
};
