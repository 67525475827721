import {Illustration} from '@atoms';
import {useAppDispatch, useAppSelector} from '@hooks';
import {getCalendarIntegrationType, redirectForCalendarConsent, withAsyncThunkErrorHandling} from '@lib/store';
import {Button} from '@molecules';
import {FlexCol, P, Span} from '@quarks';
import {pxToRem} from '@utils';
import {useTranslation} from 'react-i18next';

type HaNCalendarSyncProps = {
  displayIllustration?: boolean;
};

export const HaNCalendarSync = ({displayIllustration = true}: HaNCalendarSyncProps) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const calendarIntegrationType = useAppSelector(getCalendarIntegrationType);

  const handleButtonClick = () => {
    dispatch(withAsyncThunkErrorHandling(() => redirectForCalendarConsent()));
  };

  return (
    <FlexCol
      alignItems="center"
      data-testid="organisms-hereAndNow-calendarSync-wrapper">
      <Span sm={{display: displayIllustration ? 'block' : 'none'}}>
        <Illustration
          illustration="calendar"
          size="160px"
        />
      </Span>
      <FlexCol
        alignItems={displayIllustration ? 'center' : 'flex-start'}
        gap={16}
        width="295px">
        <P
          textAlign={displayIllustration ? 'center' : 'left'}
          maxWidth={pxToRem(300)}
          fontWeight="bold"
          color="currentColor">
          {t('hereAndNow:NotConnectedCalendarTitle')}
        </P>
        <P
          textAlign={displayIllustration ? 'center' : 'left'}
          maxWidth={pxToRem(300)}>
          {t('hereAndNow:NotConnectedCalendarMessage')}
        </P>
        <Button
          onClick={handleButtonClick}
          data-testid="organisms-calendar-CalendarEventsList_connect-calendar-button-fs"
          button={'tertiary'}>
          {t('meeting:CalendarBannerButton', {calendarIntegrationType})}
        </Button>
      </FlexCol>
    </FlexCol>
  );
};
