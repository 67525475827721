import {RoomState} from '@lib/infrastructure';
import {
  MeetingRoom,
  getFirstAvailableTimeslot,
  getFirstBookingWhithinInterval,
  getFirstBookingAfterAboutToEndPeriod,
  getLastAboutToEndBooking,
  getMeetingRoomSuggestionDataById,
  getAppLanguage,
  formatDuration,
} from '@lib/store';
import {formatDate, stripSecondsAndMilliseconds} from '@lib/utils';
import {differenceInMinutes, endOfDay, intervalToDuration, parseISO} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from './useAppSelector';

export type ScheduleStatus = {
  scheduleStatus: 'available' | 'unavailable' | 'pending';
  scheduleTitle: string;
  scheduleSubtitle?: string;
};

export const useRoomScheduleStatus = (
  {id: roomId, suggestionData: suggestionDataId = '', buildingId}: MeetingRoom,
  startDateTime: string,
  endDateTime: string,
): ScheduleStatus | undefined => {
  const appLanguage = useAppSelector(getAppLanguage);
  const {t} = useTranslation();
  const {state: roomState, markedAsBookedLocally} =
    useAppSelector((state) => getMeetingRoomSuggestionDataById(state, buildingId, suggestionDataId)) ?? {};

  const parsedStart = parseISO(startDateTime);

  const parsedEnd = parseISO(endDateTime);
  const meetingDuration = intervalToDuration({start: parsedStart, end: parsedEnd});

  // next booking at {time}
  const nextBooking = useAppSelector((rootState) =>
    getFirstBookingWhithinInterval(rootState, buildingId, roomId, {minStartDateString: startDateTime}),
  );
  const formattedNextBookingStart = nextBooking?.start ? formatDate(nextBooking?.start, 'HH:mm', appLanguage) : '';
  const nextBookingAtText = nextBooking && t('hereAndNow:NextBookingStartsAt', {time: formattedNextBookingStart});
  // next booking today at {time}
  const nextBookingToday = useAppSelector((rootState) =>
    getFirstBookingWhithinInterval(rootState, buildingId, roomId, {
      minStartDateString: startDateTime,
      maxStartDateString: endOfDay(parsedStart).toISOString(),
    }),
  );
  const formattedNextBookingTodayStart = nextBookingToday?.start
    ? formatDate(nextBookingToday?.start, 'HH:mm', appLanguage)
    : '';
  const nextBookingTodayAtText = nextBookingToday
    ? t('hereAndNow:NextBookingStartsAt', {time: formattedNextBookingTodayStart})
    : t('hereAndNow:NoMoreBookingsForToday');
  // booked until {time}
  const {start: startOfNextFreeTimeslot} =
    useAppSelector((rootState) =>
      getFirstAvailableTimeslot(rootState, buildingId, roomId, startDateTime, meetingDuration),
    ) ?? {};
  const formattedStartOfNextFreeTimeslot = startOfNextFreeTimeslot
    ? formatDate(startOfNextFreeTimeslot, 'HH:mm', appLanguage)
    : '';
  const bookedUntilText =
    startOfNextFreeTimeslot && t('hereAndNow:BookedUntil', {time: formattedStartOfNextFreeTimeslot});
  // Available for {duration}
  const duration = nextBooking?.start
    ? intervalToDuration({
        start: stripSecondsAndMilliseconds(parsedStart),
        end: nextBooking?.start,
      })
    : undefined;
  const formattedDuration = duration ? formatDuration(t, duration, true) : '';
  const availableForText =
    formattedDuration && t('hereAndNow:RoomAvailableForTitle', {countWithMinutesSuffix: formattedDuration});
  // available in {duration}
  const lastAboutToEndBooking = useAppSelector((rootState) =>
    getLastAboutToEndBooking(rootState, buildingId, roomId, startDateTime),
  );
  const endingMeetingOffset =
    lastAboutToEndBooking && differenceInMinutes(lastAboutToEndBooking.end, parsedStart, {roundingMethod: 'floor'});
  const availableInText = t('hereAndNow:RoomWillBeAvailableSoonTitle', {count: endingMeetingOffset});
  // next meeting after about to end period at {time}
  const firstMeetingAfterAboutToEnd = useAppSelector((rootState) =>
    getFirstBookingAfterAboutToEndPeriod(rootState, buildingId, roomId, startDateTime),
  );
  const firstMeetingAfterAboutToEndFormattedTime = firstMeetingAfterAboutToEnd?.start
    ? formatDate(firstMeetingAfterAboutToEnd?.start, 'HH:mm', appLanguage)
    : '';
  const firstMeetingAfterAboutToEndAtText = firstMeetingAfterAboutToEnd
    ? t('hereAndNow:NextBookingStartsAt', {time: firstMeetingAfterAboutToEndFormattedTime})
    : t('hereAndNow:NoMoreBookingsForToday');

  if (markedAsBookedLocally) {
    return {
      scheduleStatus: 'unavailable',
      scheduleTitle: t('Booked'),
    };
  }

  switch (roomState) {
    case RoomState.NotBookedAndUnknownSensor:
    case RoomState.NotBookedAndFree:
    case RoomState.NotBookedAndInUse:
    case RoomState.NotBooked:
      return {
        scheduleStatus: 'available',
        scheduleTitle: t('NotBooked'),
        scheduleSubtitle: nextBookingTodayAtText,
      };
    case RoomState.BookedAndUnknownSensor:
    case RoomState.BookedAndInUse:
    case RoomState.BookedAndFree:
    case RoomState.Booked:
      return {
        scheduleStatus: 'unavailable',
        scheduleTitle: t('Booked'),
        scheduleSubtitle: bookedUntilText,
      };
    case RoomState.BookingAboutToStartAndUnknownSensor:
    case RoomState.BookingAboutToStartAndInUse:
    case RoomState.BookingAboutToStartAndFree:
    case RoomState.BookingAboutToStart:
      return {
        scheduleStatus: 'pending',
        scheduleTitle: availableForText,
        scheduleSubtitle: nextBookingAtText,
      };
    case RoomState.BookingAboutToEndAndUnknownSensor:
    case RoomState.BookingAboutToEndAndInUse:
    case RoomState.BookingAboutToEndAndFree:
    case RoomState.BookingAboutToEnd:
      return {
        scheduleStatus: 'pending',
        scheduleTitle: availableInText,
        scheduleSubtitle: firstMeetingAfterAboutToEndAtText,
      };
    default:
      return;
  }
};
