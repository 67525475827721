import {pickUsingStringAsIndex} from '@lib/utils';
import {pxToRem} from '@utils';
import styled from 'styled-components';
import {StyledAvatarProps} from './types';
import {avatarSizeToFontSize, avatarSizeToPx} from './utils';

export const StyledAvatar = styled.div<StyledAvatarProps>`
  background: ${({$imageURL, $initials, $email, theme}) =>
    $imageURL
      ? `scroll center/contain no-repeat url('${$imageURL}')`
      : $initials && $email
      ? pickUsingStringAsIndex($email, theme.avatar.colors)
      : theme.avatar.unknown};
  border: ${({$border, theme}) => $border || `2px solid ${theme.avatar.border}`};
  border-radius: 9999px;
  color: ${({theme}) => theme.text.color.body};
  display: grid;
  font-size: ${({$size}) => pxToRem(avatarSizeToFontSize($size))};
  font-weight: 600;
  height: ${({$size}) => pxToRem(avatarSizeToPx($size))};
  line-height: 1;
  place-items: center;
  position: relative;
  width: ${({$size}) => pxToRem(avatarSizeToPx($size))};

  ${({$disabled, theme, $size}) =>
    $disabled &&
    `&::before {
    content: '';
    background: ${theme.background.white};
    top: -2px;
    left: -2px;
    position: absolute;
    border-radius: 9999px;
    opacity: 0.7;
    height: calc(${pxToRem(avatarSizeToPx($size))} + .1rem);
    width: ${pxToRem(avatarSizeToPx($size))};
  }`}
`;
