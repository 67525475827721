import {createPortal} from 'react-dom';
import {useTransition} from 'react-spring';
import {useTranslation} from 'react-i18next';

import {useDialog} from '@hooks';

import {ModalBackground} from '@organisms';
import {Icon} from '@atoms';
import {Button} from '@molecules';
import {ModalCenterWrap} from '@templates';

import FocusTrap from 'focus-trap-react';
import {DialogTypes} from '@contexts';
import {H3} from '@quarks';
import {
  ButtonWrapper,
  ContentWrapper,
  IconAndContentWrapper,
  IconWrapper,
  StyledCard,
  StyledDialogLayout,
} from './styles';

const dialogRoot = document.getElementById('dialog-portal')!;

export const Dialog = () => {
  const {onConfirm, onCancel, state} = useDialog();
  const {buttonLabel, icon, isDialogOpen, message, title, type} = state;
  const transition = useTransition(isDialogOpen, {
    from: {transform: 'translate3d(0, 100px, 0)', opacity: 0},
    enter: {transform: 'translate3d(0, 0px, 0)', opacity: 1},
    leave: {transform: 'translate3d(0, 100px, 0)', opacity: 0},
  });
  const {t} = useTranslation();

  return createPortal(
    <>
      <ModalBackground
        clickable={false}
        open={isDialogOpen}
      />
      {transition(
        (style, item) =>
          item && (
            <ModalCenterWrap
              sm={{alignItems: 'center', justifyContent: 'center'}}
              data-testid="organisms-dialog">
              <FocusTrap>
                <StyledDialogLayout
                  role="alertdialog"
                  style={style}>
                  <StyledCard>
                    <IconAndContentWrapper>
                      <IconWrapper $destructable={type === DialogTypes.destroy}>
                        {!!icon && (
                          <Icon
                            icon={icon}
                            color="white"
                          />
                        )}
                      </IconWrapper>
                      <ContentWrapper>
                        <H3 as="h1">{title}</H3>
                        <p>{message}</p>
                      </ContentWrapper>
                    </IconAndContentWrapper>
                    <ButtonWrapper>
                      {(type === DialogTypes.confirm || type === DialogTypes.destroy) && (
                        <Button
                          button="tertiary"
                          onClick={onCancel}>
                          {t('Cancel')}
                        </Button>
                      )}
                      {(type === DialogTypes.alert || type === DialogTypes.confirm) && (
                        <Button
                          button="primary"
                          onClick={onConfirm}>
                          {buttonLabel || t('Confirm')}
                        </Button>
                      )}
                      {type === DialogTypes.destroy && (
                        <Button
                          button="primary destructive"
                          data-testid="organisms-dialog-destructive-button"
                          onClick={onConfirm}>
                          {buttonLabel || t('Delete')}
                        </Button>
                      )}
                    </ButtonWrapper>
                  </StyledCard>
                </StyledDialogLayout>
              </FocusTrap>
            </ModalCenterWrap>
          ),
      )}
    </>,
    dialogRoot,
  );
};
