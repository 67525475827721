import {MeetingDetailsCardOrganizerProps} from './types';
import {useTranslation} from 'react-i18next';

import {TwoColumnCell} from '@atoms';
import {Avatar} from '@molecules';

export const MeetingDetailsCardOrganizer = ({
  currentUserResponseStatus,
  organizer,
}: MeetingDetailsCardOrganizerProps) => {
  const {t} = useTranslation('meeting');

  if (currentUserResponseStatus === 'Organizer') {
    return <></>;
  }

  return (
    <TwoColumnCell
      left={<Avatar user={{name: organizer.displayName, ...organizer}} />}
      padding="none"
      right={<p>{t('HasInvitedYou', {user: organizer.displayName || organizer.email})}</p>}
      separator={false}
    />
  );
};
