import {ILocalStorage} from '@lib/infrastructure';

export const webLocalStorage = (): ILocalStorage => ({
  getItem: (key) => {
    return Promise.resolve(localStorage.getItem(key));
  },
  setItem: (key, value) => {
    localStorage.setItem(key, value);
    return Promise.resolve();
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
    return Promise.resolve();
  },
  multiRemove: (keys) => {
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
    return Promise.resolve();
  },
  clear: () => {
    localStorage.clear();
    return Promise.resolve();
  },
});
