import {useEffect, useRef} from 'react';
import {TextareaProps} from './types';
import {GrowWrap, StyledTextarea} from './styles';

export const Textarea = ({value, className, ...props}: TextareaProps) => {
  const grower = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (grower.current) {
      grower.current.dataset.replicatedValue = value as string;
    }
  }, [value]);

  return (
    <GrowWrap
      ref={grower}
      className={className}>
      <StyledTextarea
        rows={1}
        cols={40}
        value={value}
        {...props}
      />
    </GrowWrap>
  );
};
