import {useRoomImage} from '@hooks';

import {MeetingRoomImageProps} from './types';
import {StyledImage} from './styles';

export const MeetingRoomImage = ({displayName, email, roomPictureHash, buildingId}: MeetingRoomImageProps) => {
  const imageURL = useRoomImage(email || displayName, roomPictureHash, buildingId, 500);

  return imageURL ? (
    <StyledImage
      alt={displayName}
      width="360"
      height="140"
      src={imageURL}
    />
  ) : null;
};
