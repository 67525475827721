import {StyledClearButton, StyledSearchInput} from './styles';
import {SearchProps} from 'src/components/molecules/Input/types';
import {FlexRow} from '@quarks';
import {Icon} from '@atoms';
import {ExploreState} from 'src/components/pages/HereAndNow/useExploreState';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

type Props = Omit<SearchProps, 'onChange' | 'type'> & {
  exploreState: ExploreState;
  delay?: number;
};

export const HaNSearchBar = ({exploreState, delay = 200, ...props}: Props) => {
  const {rawQuery, setRawQuery, setQuery} = exploreState;
  const {t} = useTranslation();

  useEffect(
    function debounceQuery() {
      const timerId = setTimeout(() => {
        setQuery(rawQuery);
      }, delay);

      return () => {
        clearTimeout(timerId);
      };
    },
    [rawQuery, setQuery, delay],
  );

  return (
    <FlexRow
      alignItems="center"
      flex="1"
      position="relative">
      <StyledSearchInput
        {...props}
        type="search"
        placeholder={t('hereAndNow:ResourceSearchPlaceholder')}
        iconWeight="bold"
        value={rawQuery}
        onChange={(e) => setRawQuery(e.target.value)}
      />
      <StyledClearButton
        $show={!!rawQuery.length}
        onClick={() => setRawQuery('')}>
        <Icon
          size={'12px'}
          icon={'close'}
        />
      </StyledClearButton>
    </FlexRow>
  );
};
