import {getProfileConfig, getSettings} from '@lib/store';
import {useTranslation} from 'react-i18next';

import {useAppSelector} from '@hooks';
import {ProfileCell} from '../styles';
import {Link, P} from '@quarks';

export const QuestionsCell = () => {
  const {isSupportSectionShown} = useAppSelector(getProfileConfig);
  const {contactEmailAddress} = useAppSelector(getSettings);
  const {t} = useTranslation('profile');

  if (!isSupportSectionShown) {
    return <></>;
  }

  return (
    <ProfileCell>
      <P>{t('Questions')}</P>
      <Link to={`mailto:${contactEmailAddress}`}>{contactEmailAddress}</Link>
    </ProfileCell>
  );
};
