import {LabelProps} from './types';
import styled from 'styled-components';
import {truncateString} from '@utils';
import {Div, P} from '@quarks';
import {LabelType} from 'mapiq-atoms';

export const StyledLabel = styled(Div)<{label: LabelType}>`
  background: ${({label, theme}) => theme.label[label].background};
  border-radius: 6px;
  color: ${({label, theme}) => theme.label[label].color};
  overflow: hidden;
  padding: 8px 16px;
`;

export const StyledP = styled(P)`
  ${truncateString}
  font-weight: 600;
`;

export const Label = ({label, children, ...props}: LabelProps) => {
  return (
    <StyledLabel label={label}>
      <StyledP {...props}>{children}</StyledP>
    </StyledLabel>
  );
};
