import {truncateString} from '@utils';
import styled from 'styled-components';

export const ModalPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  padding: 0 0 16px;
`;

export const Top = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.divider.grey};
  display: flex;
  gap: 0 8px;
  justify-content: space-between;
  padding: 16px var(--padding-y);
`;

export const TopMiddle = styled.div`
  align-self: center;
  display: flex;
  flex: 1;
  font-weight: 600;
  justify-content: center;
  overflow: hidden;

  & > h1 {
    ${truncateString}
  }
`;

export const TopRightLeft = styled.div`
  min-width: 2rem;
`;

export const Body = styled.div`
  flex: 1;
  overflow: hidden auto;
  padding: var(--modal-padding-y) var(--modal-padding-x);
  position: relative;
`;
