import {MeetingDetailsCardRSVPProps} from './types';

import {TwoColumnCell} from '@atoms';
import {RSVP} from '@molecules';

export const MeetingDetailsCardRSVP = ({currentUserResponseStatus}: MeetingDetailsCardRSVPProps) => {
  if (!currentUserResponseStatus) {
    return <></>;
  }

  return (
    <TwoColumnCell
      left={
        <RSVP
          asIcon={currentUserResponseStatus === 'Organizer'}
          rsvp={currentUserResponseStatus}
        />
      }
      padding="none"
      right={<p>{currentUserResponseStatus}</p>}
      separator={false}
    />
  );
};
