import {FlexRow} from '@quarks';
import {StyledSwiperHorizontal, StyledContainer, StyledExploreCellContainer} from './styles';
import {forwardRef} from 'react';
import {EmptyListPlaceholder, SwiperRefType} from '@molecules';
import {useTranslation} from 'react-i18next';
import {Loader} from '@atoms';
import {ExploreState} from 'src/components/pages/HereAndNow/useExploreState';
import {ExploreCell} from './ExploreCell';

/**
 * Lists (horizontally) available rooms in Here And Now
 */

export const HaNExploreListHorizontal = forwardRef<SwiperRefType, {exploreState: ExploreState}>(
  ({exploreState}, ref) => {
    const {t} = useTranslation();
    const {resourceData, highlightedResources} = exploreState;

    const resourcesAreLoading = resourceData?.status === 'loading';

    const handleSlideChange = () => {
      // const visibleRoom = rooms[swiper.activeIndex];
      // onSlideChange(visibleRoom);
    };

    return (
      <FlexRow
        columnGap={8}
        overflow="hidden"
        zIndex={2}
        justifyContent="center">
        {resourcesAreLoading ? (
          <StyledContainer justifyContent="center">
            <Loader />
          </StyledContainer>
        ) : highlightedResources.length ? (
          <StyledSwiperHorizontal
            freeMode={false}
            onSlideChange={handleSlideChange}
            ref={ref}
            centeredSlides={true}
            spaceBetween={16}>
            {highlightedResources.map((resource, index) => (
              <StyledExploreCellContainer key={resource.id}>
                <ExploreCell
                  exploreState={exploreState}
                  resource={highlightedResources[index]}
                />
              </StyledExploreCellContainer>
            ))}
          </StyledSwiperHorizontal>
        ) : (
          <EmptyListPlaceholder text={t('hereAndNow:NoSearchResults')} />
        )}
      </FlexRow>
    );
  },
);
