import {useTranslation} from 'react-i18next';

import {FlexCol, P, Subtitle} from '@quarks';
import {ExtrasSelectionCellProps} from './types';
import {ModalCellItem, ModalCellItemIcon} from '@molecules';

export const ExtrasSelectionCell = ({description, name, onClick, iconLeft, iconRight}: ExtrasSelectionCellProps) => {
  const {t} = useTranslation();

  return (
    <ModalCellItem
      onClick={onClick}
      left={
        <ModalCellItemIcon
          backgroundColor={null}
          icon={iconLeft}
        />
      }
      middle={
        <FlexCol>
          <P>{t(`extras:${name}`)}</P>
          <Subtitle>{description}</Subtitle>
        </FlexCol>
      }
      right={
        <ModalCellItemIcon
          backgroundColor={null}
          icon={iconRight}
        />
      }></ModalCellItem>
  );
};
