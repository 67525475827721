import {ModalCellItemIcon} from '@molecules';
import {FlexRow, P} from '@quarks';
import {truncateString} from '@utils';
import styled from 'styled-components';

export const Wrapper = styled(FlexRow)`
  background: ${({theme}) => theme.card.transparent.background};
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  border-radius: 8px;
  padding: 6px;
  cursor: pointer;
  border: 2px solid transparent;
  width: 100%;

  @media (hover) {
    &:hover {
      background: ${({theme}) => theme.card.transparent.hover.background};
    }
  }

  &:focus-visible {
    border: ${({theme}) => `2px solid ${theme.brand}`};
    outline: none;
  }
`;

export const BackgroundWrapper = styled(FlexRow)``;

export const StyledModalCellItemIcon = styled(ModalCellItemIcon)`
  flex: none;
  align-self: flex-start;
  width: calc(3rem + 4px);
  height: calc(3rem + 4px);
`;

export const StyledTruncatedText = styled(P)`
  ${truncateString}
`;
