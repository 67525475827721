import {KeyboardEvent} from 'react';
import {ThreeColumnCell} from '@atoms';
import {FlexCol} from '@quarks';
import {pxToRem} from '@utils';
import {ModalCellItemProps} from './types';
import {StyledAnimatedButton} from './styles';

export const ModalCellItem = ({
  left,
  ariaLabel,
  middle,
  right,
  disabled,
  alignItems,
  onClick,
  ...rest
}: ModalCellItemProps) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      onClick && onClick();
    }
  };

  return (
    <StyledAnimatedButton
      role="button"
      aria-label={ariaLabel}
      onKeyDown={(e) => handleKeyDown(e)}
      tabIndex={0}
      $disabled={disabled}
      onClick={onClick}
      {...rest}>
      <ThreeColumnCell
        alignItems={alignItems}
        separator={false}
        padding="small"
        left={left}
        middle={middle}
        right={
          <FlexCol
            height={pxToRem(48)}
            justifyContent="center"
            alignItems="center">
            {right}
          </FlexCol>
        }
      />
    </StyledAnimatedButton>
  );
};
