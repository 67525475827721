import {ConnectionUserDto, WorkspaceReservationDto} from '@lib/infrastructure';
import {BigMapModal} from '../../BigMapModal';
import {useTheme} from 'styled-components';
import {useProfilePic} from '@hooks';
import {useFacilityMarkers} from '../../hereAndNow/useFacilityMarkers';
import {DeskMarkerData} from '@map/mapiq-map/markers/NodeMarkerData';
import {pickUsingStringAsIndex} from '@lib/utils';
import {Avatar, MapView} from '@molecules';

export const ConnectionOnMapModal = ({
  user,
  workspace,
}: {
  user: ConnectionUserDto;
  workspace: WorkspaceReservationDto;
}) => {
  return (
    <BigMapModal
      title={workspace.buildingName}
      main={
        <ConnectionMap
          user={user}
          workspace={workspace}
        />
      }
      footer={
        <ConnectionMapFooter
          user={user}
          workspace={workspace}
        />
      }
    />
  );
};

const ConnectionMap = ({user, workspace}: {user: ConnectionUserDto; workspace: WorkspaceReservationDto}) => {
  const theme = useTheme();
  const imageData = useProfilePic(user.imageHash, 40);
  const facilityMarkers = useFacilityMarkers(workspace.buildingId, workspace.floorId);

  const personMarker: DeskMarkerData = {
    type: 'desk',
    nodeId: workspace.nodeId,
    available: false,
    connections: [
      {
        color: pickUsingStringAsIndex(user.email, theme.avatar.colors),
        initials: user.initials,
        picture: imageData,
      },
    ],
  };

  return (
    <MapView
      buildingId={workspace.buildingId}
      floorId={workspace.floorId}
      disablePointerEvents={false}
      fullView={true}
      highlights={[personMarker, ...facilityMarkers]}
      buildingNodeTypeStates={new Map([['facility', 'dimmed']])}
      buildingNodeStates={new Map([[workspace.nodeId, 'selected']])}
    />
  );
};

const ConnectionMapFooter = ({user, workspace}: {user: ConnectionUserDto; workspace: WorkspaceReservationDto}) => {
  return (
    <div className="flex w-full p-6 pb-2 gap-4">
      <Avatar
        user={user}
        size="xlarge"
      />

      <div>
        <p>
          <strong>{user.name}</strong>
        </p>
        <p>{`${workspace.floorName}, ${workspace.areaName}`}</p>
      </div>
    </div>
  );
};
