import styled from 'styled-components';
import {animated} from 'react-spring';
import {FlexCol} from '@quarks';
import {BREAKPOINTS} from '@constants';

export const RoomInformationWrapper = styled(animated.div)`
  background: ${({theme}) => theme.background.white};
  display: flex;
  height: var(--modal-height);
  flex-direction: column;
  width: inherit;
`;

export const StyledBody = styled(FlexCol)`
  flex: 1;
  padding: 24px 20px;
  justify-content: flex-start;
  overflow: auto;
  gap: 32px;
`;

export const StyledMapWrapper = styled(FlexCol)`
  // width of the modal minus 2 * paddings on the side
  ${BREAKPOINTS.small`
    width: calc(100vw - 40px);
    display: inline;
  `}
`;
