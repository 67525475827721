import styled from 'styled-components';

export const MeetingLinkWrapper = styled.div<{$noUrl: boolean}>`
  border-bottom: ${({$noUrl, theme}) => ($noUrl ? 'none' : `1px solid ${theme.divider.grey}`)};
  border-top: 1px solid ${({theme}) => theme.divider.grey};
  padding: ${({$noUrl}) => ($noUrl ? '0' : '6px 0')};
`;

export const MeetingLink = styled.a`
  border-radius: 4px;
  border: 2px solid transparent;
  color: inherit;
  cursor: pointer;
  padding: 4px 0;
  display: block;
  transition: background var(--speed-fast), border var(--speed-fast);

  & > div > div:nth-child(3) > svg {
    transition: transform var(--speed-fast);
  }

  @media (hover) {
    &:hover {
      background: ${({theme}) => theme.card.opaque.hover.background};
      color: inherit !important;
      text-decoration: none !important;

      & > div > div:nth-child(3) > svg {
        transform: translate3d(4px, 0, 0);
      }
    }
  }

  &:focus-visible {
    border: 2px solid ${({theme}) => theme.brand};
    color: inherit !important;
    text-decoration: none !important;

    & > div > div:nth-child(3) > svg {
      transform: translate3d(4px, 0, 0);
    }
  }
`;
