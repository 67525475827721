import {ExtraName} from '@lib/infrastructure';
import {Extra} from '@lib/store';
import {IconNames} from 'mapiq-atoms';
import {ExtrasSelectionCellProps} from 'src/components/organisms/extras/ExtrasSelectionCell/types';

const getExtraIcon = (extraName: ExtraName): IconNames => {
  switch (extraName) {
    case ExtraName.BookCoworkingSpace:
      return 'desk';
    case ExtraName.BookLocker:
      return 'lockOpen';
    case ExtraName.BookLunch:
      return 'lunch';
    case ExtraName.RequestMedicalService:
      return 'medical';
    case ExtraName.RegisterVisitor:
      return 'visitors';
    case ExtraName.InviteVisitors:
      return 'visitors';
    case ExtraName.ReportProblem:
      return 'reportProblem';
    case ExtraName.RequestCatering:
      return 'lunch';
    case ExtraName.RequestEquipment:
      return 'presentationScreen';
    case ExtraName.RequestSeatingArrangement:
      return 'chair';
    case ExtraName.ControlBlinds:
      return 'blinds';
    case ExtraName.ManageEmergencyContact:
      return 'contacts';
    case ExtraName.ManageLicensePlate:
      return 'car';
    default:
      return 'support';
  }
};

export const getExtraItem = (extra: Extra): ExtrasSelectionCellProps => ({
  id: extra.id,
  iconLeft: getExtraIcon(extra.name),
  iconRight: 'openInBrowser',
  name: extra.name,
  description: extra.description,
  onClick: () => window.open(extra.url, '_blank'),
  url: extra.url,
});
