import styled, {css} from 'styled-components';
import {TextareaProps} from './types';

const Styling = css`
  border: none;
  color: ${({theme}) => theme.text.color.body};
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;

  line-height: 1.2;
  min-height: 10px;
  outline: none;
  overflow-y: clip;
  padding: 8px 0;
  width: 100%;
  word-wrap: anywhere;
`;

export const GrowWrap = styled.div`
  display: grid;
  overflow: hidden;
  width: 100%;

  &:after {
    ${Styling}

    content: attr(data-replicated-value) ' ';
    text-align: left;
    text-transform: none;
    visibility: hidden;
    white-space: pre-wrap;
  }
`;

export const StyledTextarea = styled.textarea<TextareaProps>`
  ${Styling}

  div > & {
    background: inherit;
    resize: none;
  }

  &::placeholder {
    color: ${({theme}) => theme.input.placeholder};
  }
`;
