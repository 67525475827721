import {useModal} from '@hooks';
import {Button} from '@molecules';
import {PropsWithChildren} from 'react';
import emptyStateImgUrl from '../../../../assets/images/empty-states/connections.svg';
import {AddConnectionsCard} from '../../connections';
import {trackEvent} from '@lib/infrastructure';
import {useTranslation} from 'react-i18next';

const EmptyState = ({title, subTitle, children}: PropsWithChildren<{title?: string; subTitle: string}>) => {
  const {t} = useTranslation();
  return (
    <div className="bg-beige-200 w-full p-8 rounded-lg flex flex-col gap-2 items-center text-center">
      <img
        src={emptyStateImgUrl}
        alt={t('SpeechBubbles')}
      />
      {title && (
        <p>
          <strong>{title}</strong>
        </p>
      )}
      <p className="mb-2 text-mapiq-black-500">{subTitle}</p>
      {children}
    </div>
  );
};
export const NoConnections = () => {
  const {addPages, openModal} = useModal();
  const {t} = useTranslation();
  const handleOpenAddConnections = () => {
    addPages([<AddConnectionsCard />]);
    trackEvent('Home_ConnectionsOverview__AddConnection');
    openModal();
  };

  return (
    <EmptyState
      title={t('connection:SeeWhereCoworkers')}
      subTitle={t('connection:ConnectWithCoworkers')}>
      <Button
        aria-label={t('AddAConnection')}
        button="tertiary"
        iconRight="plus"
        onClick={handleOpenAddConnections}>
        {t('connection:Connect')}
      </Button>
    </EmptyState>
  );
};
export const NoOneThere = () => {
  const {t} = useTranslation();
  return <EmptyState subTitle={t('connection:NoConnectionsHere')} />;
};
