import {Card} from '@atoms';
import {ModalLayout} from '@templates';
import styled from 'styled-components';

export const StyledModalLayout = styled(ModalLayout)`
  --modal-padding-x: var(--padding-x);
  --modal-padding-y: 16px;
  background: none;
  border: none;
  padding: 0;
`;

export const StyledCard = styled(Card)`
  align-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 32px 0;
  max-width: 100%;
  overflow: hidden auto;
  position: relative;
`;
