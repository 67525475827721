import {pxToRem, truncateString} from '@utils';
import styled from 'styled-components';

export const StyledSubMenu = styled.div<{
  $borderWidth: string;
  $borderOffset: string;
}>`
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    background: ${({theme}) => theme.text.color.body};
    bottom: -3px;
    left: 0;
    transform: ${({$borderOffset}) => `translate3d(${$borderOffset}, 0, 0)`};
    width: ${({$borderWidth}) => $borderWidth};
    height: 3px;
    transition: transform var(--speed-fast), width var(--speed-fast);
  }
`;

export const StyledSubMenuItem = styled.button<{$active: boolean}>`
  border: none;
  background: none;
  color: ${({$active, theme}) => ($active ? theme.text.color.body : theme.text.color.subtitle)};
  cursor: pointer;
  font-size: ${pxToRem(18)};
  font-weight: 600;
  padding: 0;
  transition: color 500ms;
  ${truncateString}

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid ${({theme}) => theme.brand};
  }

  @media (hover) {
    &:hover {
      color: ${({theme}) => theme.text.color.body};
    }
  }
`;
