import styled from 'styled-components';
import {pxToRem} from '@utils';
import {BREAKPOINTS} from '@constants';

export const StyledNotificationCard = styled.div`
  display: grid;
  gap: 32px;
  padding-top: ${pxToRem(32)};

  ${BREAKPOINTS.small`
  padding: 0 16px;
  `}
`;
