import {HaNRoomCellProps} from './types';
import {useTranslation} from 'react-i18next';

import {pickUsingStringAsIndex} from '@lib/utils';
import {useRoomImage, useScheduleAndSensorStatuses} from '@hooks';

import {useTheme} from 'styled-components';
import {StyledModalCellItemIcon, StyledTruncatedText, Wrapper} from './styles';
import {FlexCol, FlexRow, P} from '@quarks';
import {HaNScheduleDataIcon} from '@organisms';

/**
 * Room cell for list of rooms on Here And Now.
 * Adapts to small screen size and is used in both `RoomsList` and `RoomsListHorizontal`.
 */

export const HaNRoomCell = ({index, room, onClick, startDateTime, endDateTime}: HaNRoomCellProps) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {displayName, capacity, floorName, roomPictureHash, buildingId, email} = room;
  const color = pickUsingStringAsIndex(email, theme.avatar.colors);
  const imageURL = useRoomImage(
    email || displayName,
    roomPictureHash,
    buildingId,
    Math.round(48 * window.devicePixelRatio * 4),
  );
  const {scheduleStatus, sensorStatus, sublineText} = useScheduleAndSensorStatuses(room, startDateTime, endDateTime);

  return (
    <Wrapper
      as="button"
      tabIndex={1}
      onClick={() => onClick(room)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onClick(room);
      }}>
      <FlexRow
        gap={8}
        alignItems="flex-start"
        maxWidth="250px"
        sm={{maxWidth: 225}}>
        <StyledModalCellItemIcon
          imageURL={imageURL}
          backgroundColor={color}
          icon="meetingRoom"
        />
        <FlexCol
          justifyContent="center"
          overflow="hidden"
          paddingTop={2}>
          <StyledTruncatedText fontWeight="bold">{displayName}</StyledTruncatedText>
          <P>
            {t('meeting:MeetingRoomBasicInfo', {
              count: capacity,
              floorName: floorName,
            })}
          </P>
          {sublineText && <StyledTruncatedText color={theme.text.color.error}>{sublineText}</StyledTruncatedText>}
        </FlexCol>
      </FlexRow>
      <FlexRow gap={8}>
        {sensorStatus && (
          <HaNScheduleDataIcon
            data-testid={`HNSensorDataIcon-${index}`}
            status={sensorStatus}
            icon="broadcast"
          />
        )}
        {scheduleStatus && (
          <HaNScheduleDataIcon
            data-testid={`HNScheduleDataIcon-${index}`}
            status={scheduleStatus}
            icon="calendar"
          />
        )}
      </FlexRow>
    </Wrapper>
  );
};
