import {pxToRem} from '@utils';
import styled from 'styled-components';

export const StyledImage = styled.img`
  aspect-ratio: 360 / 140;
  border-radius: 8px;
  height: ${pxToRem(140)};
  object-fit: cover;
  overflow: hidden;
  width: auto;
`;
