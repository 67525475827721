import {HaNScheduleDataIcon} from '@organisms';
import {FlexCol, FlexRow, P, Subtitle} from '@quarks';
import {MeetingRoomScheduleDataProps} from './types';

export const MeetingRoomScheduleData = ({status, subtitle, title, icon, ...props}: MeetingRoomScheduleDataProps) => {
  return (
    <FlexRow
      justifyContent="flex-start"
      alignItems="center"
      gap={16}>
      <HaNScheduleDataIcon
        data-testid={`${props['data-testid']}`}
        icon={icon}
        {...(status ? {status: status} : {status: 'none'})}
      />
      <FlexCol>
        <P fontWeight="bold">{title}</P>
        <Subtitle>{subtitle}</Subtitle>
      </FlexCol>
    </FlexRow>
  );
};
