import {useModal} from '@hooks';
import {HaNAttendees, HaNBookingDetails, HaNRoomInformation, HaNSensorInformation} from '@organisms';
import {MeetingInviteeSearchResult, MeetingRoom} from '@lib/store';

import {DEFAULT_NEW_EVENT_DURATION as DURATION} from '@constants';
import {useState} from 'react';
import {HaNRoomModalPage} from '../../hereAndNow/types';

type Props = {room: MeetingRoom; startDateTime: string; endDateTime: string};

export const AvailableRoomInformationModal = ({room, startDateTime, endDateTime}: Props) => {
  const {closeModal} = useModal();
  const [page, setPage] = useState<HaNRoomModalPage>('roomInformation');
  const [prevPage, setPrevPage] = useState<HaNRoomModalPage>('roomInformation');
  const [invitees, setInvitees] = useState<MeetingInviteeSearchResult[]>([]);

  return (
    <>
      <HaNRoomInformation
        room={room}
        onClose={closeModal}
        page={page}
        setPage={setPage}
        endDateTime={endDateTime}
        startDateTime={startDateTime}
        duration={DURATION}
        showMapTile="always"
      />
      <HaNSensorInformation
        onBack={() => {
          setPrevPage('sensorInformation');
          setPage('roomInformation');
        }}
        onClose={closeModal}
        page={page}
      />
      <HaNBookingDetails
        onBack={() => {
          setPrevPage('bookingDetails');
          setPage('roomInformation');
        }}
        onClose={closeModal}
        page={page}
        resetAttendees={() => setInvitees([])}
        room={room}
        duration={DURATION}
        startDateTime={startDateTime}
        setPage={setPage}
        prevPage={prevPage}
        attendees={invitees}
      />
      <HaNAttendees
        onBack={() => {
          setPrevPage('addInvitees');
          setPage('bookingDetails');
        }}
        onClose={closeModal}
        page={page}
        invitees={invitees}
        onSave={(value) => {
          setInvitees(value);
          setPrevPage('addInvitees');
          setPage('bookingDetails');
        }}
        hasUnsavedChanges={false}
      />
    </>
  );
};
