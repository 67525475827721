import {MapView} from '@molecules';
import {HereAndNowWorkspacesMapProps} from './types';
import {useAppSelector, useUserProfilePics} from '@hooks';
import {
  ConnectionUser,
  getConnectedUserEntities,
  getNodeInformationPerAreaByBuildingId,
  getWorkspaceOccupancyForBuildingAndFloorId,
  getWorkspaceReservationsForConnectionsByDate,
} from '@lib/store';
import {useMemo} from 'react';
import {toMapViewUser} from '@utils';
import {AreaEventTarget} from '../../../../../../../submodules/map/mapiq-map/EventTarget';
import {createSensorHighlightsForAreas, createState, mergeState} from '@lib/utils';
import {AreaMarkerData, MarkerData} from '../../../../../../../submodules/map/mapiq-map/markers/NodeMarkerData';
import {startOfDay} from 'date-fns';
import {useFacilityMarkers} from '../useFacilityMarkers';
import {useTheme} from 'styled-components';

export const HaNWorkspacesMap = ({
  // TODO: why is floorId nullable?
  floorId,
  viewportRestrictions,
  buildingId,
  onAreaClick,
  onOutsideClick,
  selectedArea,
  hoverArea,
  workspaces,
}: HereAndNowWorkspacesMapProps) => {
  const areaBookingStates = useAppSelector((state) =>
    getNodeInformationPerAreaByBuildingId(state, startOfDay(new Date()).toISOString(), buildingId),
  );

  // Sensor highlights might not be available if (a) FF is turned off, or (b) there are just not any sensors
  const sensorData = useAppSelector((state) => getWorkspaceOccupancyForBuildingAndFloorId(state, buildingId, floorId));

  // TODO: shouldn't this happen one layer up?
  const workspacesOnFloor = useMemo(() => workspaces.filter((a) => a.floorId === floorId), [floorId, workspaces]);

  const params = useMemo(
    () => ({
      date: new Date().toISOString(),
      buildingId,
    }),
    [buildingId],
  );

  // TODO: is there not a better way to get this from the store?!
  const connectionReservations = useAppSelector((state) => getWorkspaceReservationsForConnectionsByDate(state, params));
  const connections = useAppSelector(getConnectedUserEntities);
  const connectionProfileImages = useUserProfilePics(
    Object.values(connections)
      .filter((c): c is ConnectionUser => c !== undefined)
      .map((c) => ({
        userId: c.id,
        imageHash: c.imageHash ?? '',
      })),
  );

  const theme = useTheme();

  const sensorHighlights = useMemo(() => {
    if (sensorData?.status !== 'loaded') return [];

    const deskReservations = connectionReservations
      .filter((f) => f.floorId === floorId && f.deskId)
      .flatMap((f) => {
        const con = connections[f.userId];

        if (!con) return [];

        return {
          deskId: f.deskId,
          connection: toMapViewUser(
            {
              email: con.email,
              initials: con.initials,
              imageUrl: connectionProfileImages[con.id],
            },
            theme,
          ),
        };
      });

    return createSensorHighlightsForAreas(workspacesOnFloor, sensorData.states, deskReservations);
  }, [sensorData, workspacesOnFloor, connectionReservations, theme, connections, floorId, connectionProfileImages]);

  // Area highlights are used if no sensors are found on the floor
  const capacityMarkersOnFloor: AreaMarkerData[] = useMemo(
    () =>
      workspacesOnFloor.map((area) => {
        const areaInfo = (areaBookingStates ?? {})[area.id];

        return {
          type: 'area',
          nodeId: area.id,
          available: areaInfo ? areaInfo.capacity - areaInfo.occupation : area.capacity || area.adHocCapacity,
          connections: [],
        };
      }),
    [areaBookingStates, workspacesOnFloor],
  );

  // When the sensor data is loaded but there's nothing with a state, we fall back to just regular area markers.
  const doNotShowSensorData =
    sensorData?.status === 'failed' ||
    (sensorData?.status === 'loaded' && sensorData?.states.length === 0) ||
    !sensorData;

  const facilityMarkers = useFacilityMarkers(buildingId, floorId);

  const highlights = useMemo(() => {
    const base: MarkerData[] = doNotShowSensorData ? capacityMarkersOnFloor : sensorHighlights;
    return base.concat(facilityMarkers);
  }, [doNotShowSensorData, capacityMarkersOnFloor, sensorHighlights, facilityMarkers]);

  const areaStates = useMemo(() => {
    const highlightState = createState(
      workspacesOnFloor.map((a) => a.id),
      'highlighted',
    );

    const selectionState = createState(selectedArea ? [selectedArea.id] : [], 'selected');

    return mergeState(highlightState, selectionState);
  }, [workspacesOnFloor, selectedArea]);

  const handleClick = (e: AreaEventTarget) => {
    if (e.areaId) {
      onAreaClick(e);
    } else {
      onOutsideClick();
    }
  };

  const elementsToKeepInView = useMemo(() => (selectedArea ? [selectedArea.id] : undefined), [selectedArea]);

  return (
    <MapView
      borderRadius={0}
      floorId={floorId}
      buildingId={buildingId}
      highlights={highlights}
      fullView={true}
      elementsToKeepInView={elementsToKeepInView}
      disablePointerEvents={false}
      buildingNodeStates={areaStates}
      buildingNodeTypeStates={new Map([['facility', 'dimmed']])}
      viewportRestrictions={viewportRestrictions}
      onClick={(e) => handleClick(e as AreaEventTarget)}
      spotlightId={hoverArea?.floorId === floorId ? hoverArea.id : null}
      mapTypeNamespace="HereAndNow_workspaces"
    />
  );
};
