import {ThreeColumnCellProps} from './types';
import {Left, Middle, Right, StyledThreeColumnCell} from './styles';

export const ThreeColumnCell = ({
  className,
  left,
  middle,
  alignItems,
  padding = 'large',
  right,
  truncate = true,
  separator = true,
  style,
  ...props
}: ThreeColumnCellProps) => (
  <StyledThreeColumnCell
    $padding={padding}
    $alignItems={alignItems}
    $separator={separator}
    $truncate={truncate}
    className={className}
    style={style}
    {...props}>
    <Left>{left}</Left>
    <Middle $truncate={truncate}>{middle}</Middle>
    <Right>{right}</Right>
  </StyledThreeColumnCell>
);
