import {Input} from '@molecules';
import {InputHTMLAttributes} from 'react';
import styled from 'styled-components';

export const StyledSearchInput = styled(Input)`
  background: ${({theme}) => theme.button.tertiary.background};
  border-bottom: none;
  border-radius: 6px;
  padding: 6px 0px 6px 8px;
  appearance: none;

  height: 100%;

  svg {
    color: ${({theme}) => theme.icon.inactive};
  }
  :hover {
    border-bottom: none;
  }
  :focus-within {
    border: ${({theme}) => `solid 2px ${theme.brand}`};
    svg {
      color: ${({theme}) => theme.icon.active};
    }
  }

  [type='search']::-webkit-search-decoration,
  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-results-button,
  [type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`;

export const StyledClearButton = styled.button<{$show: boolean}>`
  border: none;
  outline: none;
  background: ${({theme}) => theme.button.tertiary.background};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 20px;
  right: 12px;
  transition: opacity 200ms ease-in-out;
  opacity: ${({$show}) => Number($show)};
  pointer-events: ${({$show}) => ($show ? 'all' : 'none')};
`;
