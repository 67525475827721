import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

export const FooterLayout = styled.div`
  display: none;

  ${BREAKPOINTS.small`
    position: sticky;
    bottom: 0;
    left: 0;
    display: grid;

    height: calc(env(safe-area-inset-bottom, 0) + 64px);
    padding-bottom: env(safe-area-inset-bottom, 0);
    
    width: 100%;
    grid-area: footer / fullbleed;
    border-top: 1px solid ${({theme}) => theme.divider.grey};
    box-shadow: ${({theme}) => theme.boxShadow.secondary};
  `}
`;
