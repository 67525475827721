import {useTranslation} from 'react-i18next';
import {useAppSelector, useFeatureToggle} from '@hooks';
import {getExtrasBySpecificType} from '@lib/store';
import {H2} from '@quarks';
import {CardProps} from '@atoms';
import {CellWrapper, ProfileCard} from '../styles';
import {ExtrasCell} from '../ExtrasCell';

export const ExtrasCard = ({className, style, ...props}: CardProps) => {
  const extras = useAppSelector((state) => getExtrasBySpecificType(state, 'UserProfile'));
  const {t} = useTranslation();
  const {ExtrasFeature: isExtrasFeatureEnabled} = useFeatureToggle();

  if (!isExtrasFeatureEnabled || !extras.length) return <></>;

  return (
    <ProfileCard
      as="section"
      {...props}>
      <H2>{t('extras:Extras')}</H2>
      <CellWrapper>
        <ExtrasCell />
      </CellWrapper>
    </ProfileCard>
  );
};
