import {useAppDispatch, useAppSelector} from '@hooks';
import {
  NotificationCategory,
  NotificationCategoryType,
  getNotificationPreferences,
  updateNotificationPreference,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useTranslation} from 'react-i18next';
import {StyledToggleCell} from './styles';
import {trackEvent} from '@lib/infrastructure';

export const NotificationSettingsItem = ({
  category,
  type,
}: {
  category: NotificationCategory;
  type: NotificationCategoryType;
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const notificationPreferences = useAppSelector(getNotificationPreferences);
  const enabled = notificationPreferences[category][type];

  const handleToggle = () => {
    const next = {
      category,
      type,
      enabled: !enabled,
    };

    dispatch(withAsyncThunkErrorHandling(() => updateNotificationPreference(next)));

    const trackEventAction = next.enabled ? 'EnableSetting' : `DisableSetting`;
    trackEvent(`Profile_NotificationSettings__${trackEventAction}`, {
      category,
      type,
    });
  };

  return (
    <StyledToggleCell
      label={t('profile:NotificationSettings.toggleLabel', {context: type})}
      name={`${category}${type}`}
      checked={enabled}
      onToggle={handleToggle}
    />
  );
};
