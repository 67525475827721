import {ClickableCellWrapper, IconButton} from '@molecules';
import {ModalPage} from '@organisms';
import styled from 'styled-components';

export const StyledModalPage = styled(ModalPage)`
  --modal-padding-x: 0;
  --modal-padding-y: 0;
  --padding-x: 24px;
  --padding-y: 24px;
  background: ${({theme}) => theme.background.white};

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid ${({theme}) => theme.brand};
  }
`;

export const CardWrapperEmpty = styled.div`
  display: grid;
  gap: 20px;
  justify-items: center;
  padding: 70px 40px;
  text-align: center;
`;

export const StyledIconButton = styled(IconButton)`
  align-self: end;
`;

export const StyledClickableCellWrapper = styled(ClickableCellWrapper)`
  border-bottom: 1px solid ${({theme}) => theme.divider.grey};
  flex: 1;
`;
