export const COLOR_PALETTE = {
  black: {
    a100: 'rgba(0, 0, 0, 1)',
    a90: 'rgba(0, 0, 0, .9)',
    a80: 'rgba(0, 0, 0, .8)',
    a70: 'rgba(0, 0, 0, .7)',
    a60: 'rgba(0, 0, 0, .6)',
    a50: 'rgba(0, 0, 0, .5)',
    a40: 'rgba(0, 0, 0, .4)',
    a30: 'rgba(0, 0, 0, .3)',
    a20: 'rgba(0, 0, 0, .2)',
    a15: 'rgba(0, 0, 0, .15)',
    a10: 'rgba(0, 0, 0, .1)',
    a06: 'rgba(0, 0, 0, .06)',
    a03: 'rgba(0, 0, 0, .03)',
  },
  white: {
    a100: 'rgba(255, 255, 255, 1)',
    a90: 'rgba(255, 255, 255, .9)',
    a80: 'rgba(255, 255, 255, .8)',
    a70: 'rgba(255, 255, 255, .7)',
    a60: 'rgba(255, 255, 255, .6)',
    a50: 'rgba(255, 255, 255, .5)',
    a40: 'rgba(255, 255, 255, .4)',
    a30: 'rgba(255, 255, 255, .3)',
    a20: 'rgba(255, 255, 255, .2)',
    a15: 'rgba(255, 255, 255, .15)',
    a10: 'rgba(255, 255, 255, .1)',
  },
  transparent: {
    default: 'transparent',
  },
  focusingBrown: {
    900: '#231c14',
    800: '#463829',
    700: '#68553d',
    600: '#8b7152',
    500: '#ae8d66',
    400: '#bea485',
    300: '#cebba3',
    200: '#dfd1c2',
    100: '#efe8e0',
    50: '#fbf9f8',
  },
  collaborativeBlue: {
    900: '#06102c',
    800: '#0c1f58',
    700: '#122f83',
    600: '#183eaf',
    500: '#1e4edb',
    400: '#4b71e2',
    300: '#7895e9',
    200: '#a5b8f1',
    100: '#d2dcf8',
    50: '#f5f7fd',
  },
  inspiringOrange: {
    900: '#250c06',
    800: '#4a190b',
    700: '#702511',
    600: '#953216',
    500: '#ba3e1c',
    400: '#c86549',
    300: '#d68b77',
    200: '#e3b2a4',
    100: '#f1d8d2',
    50: '#fbf3f1',
  },
  creativePink: {
    900: '#301d28',
    800: '#603b4f',
    700: '#915877',
    600: '#c1769e',
    500: '#f193c6',
    400: '#f4a9d1',
    300: '#f7bedd',
    200: '#f9d4e8',
    100: '#fce9f4',
    50: '#fff6fc',
  },
  connectingGreen: {
    900: '#011611',
    800: '#012d22',
    700: '#024334',
    600: '#025a45',
    500: '#037056',
    400: '#358d78',
    300: '#68a99a',
    200: '#9ac6bb',
    100: '#cde2dd',
    50: '#e7f1ef',
  },
  energizingYellow: {
    900: '#312a0d',
    800: '#62541a',
    700: '#937f27',
    600: '#c4a934',
    550: '#E7C73D',
    500: '#f5d341',
    400: '#f7dc67',
    300: '#f9e58d',
    200: '#fbedb3',
    100: '#fdf6d9',
    50: '#fffdf6',
  },
  safeMauve: {
    900: '#120f15',
    800: '#241e2a',
    700: '#372e3f',
    600: '#493d54',
    500: '#5b4c69',
    400: '#7c7087',
    300: '#9d94a5',
    200: '#bdb7c3',
    100: '#dedbe1',
    50: '#f1f0f2',
  },
  beige: {
    900: '#312e2b',
    800: '#615c56',
    700: '#928a82',
    600: '#c2b8ad',
    500: '#f3e6d8',
    400: '#f5ebe0',
    300: '#f8f0e8',
    200: '#faf5ef',
    100: '#fdfaf7',
    50: '#fefcfb',
  },
  mapiqBlack: {
    900: '#121212',
    800: '#1a1a1a',
    700: '#393939',
    600: '#575757',
    500: '#767676',
    400: '#949494',
    300: '#b3b3b3',
    200: '#d2d2d2',
    150: '#e8e8e8',
    100: '#f0f0f0',
    75: '#f4f4f4',
    50: '#f9f9f9',
    a15: 'rgba(26, 26, 26, .15)',
    a06: 'rgba(26, 26, 26, .06)',
    a03: 'rgba(26, 26, 26, .03)',
  },
  functional: {
    green: {
      default: '#128E64',
      hover: '#209A6F',
    },
    orange: {
      default: '#E88427',
      hover: '#F6902E',
    },
    red: {
      default: '#D92911',
      hover: '#E7351D',
    },
  },
};
