import {useTranslation} from 'react-i18next';

import {getMeetingPreferencesLoadingStatus, getMeetingPreferencesWorkingHours} from '@lib/store';

import {useAppSelector, useModal} from '@hooks';
import {ProfileCell} from '../styles';
import {Button} from '@molecules';
import {P} from '@quarks';
import {constructWorkingHoursLabel} from '@lib/utils/src/util/workingHourHelpers';
import {WorkingHoursModal} from '../WorkingHoursModal';
import {Loader} from '@atoms';

export const WorkingHoursCell = () => {
  const {t} = useTranslation();
  const {addPages, openModal} = useModal();

  const workingHoursLoading = useAppSelector(getMeetingPreferencesLoadingStatus);
  const workingHoursData = useAppSelector(getMeetingPreferencesWorkingHours);
  const workingHoursLabel = constructWorkingHoursLabel(workingHoursData);

  const handleEditWorkingHours = () => {
    addPages([<WorkingHoursModal />]);
    openModal();
  };

  return (
    <ProfileCell>
      <P>{t('screen:WorkingHours')}</P>

      {workingHoursLoading !== 'Loaded' ? (
        <Loader size="1rem" />
      ) : (
        <Button
          onClick={handleEditWorkingHours}
          button="tertiary"
          iconRight="caretRight">
          {workingHoursLabel}
        </Button>
      )}
    </ProfileCell>
  );
};
