import {getProfileConfig, getSettings} from '@lib/store';
import {useTranslation} from 'react-i18next';

import {useAppSelector} from '@hooks';
import {ProfileCell} from '../styles';
import {Link, P} from '@quarks';

export const MoreInformationCell = () => {
  const {isMoreInfoSectionShown} = useAppSelector(getProfileConfig);
  const {companyWebsite} = useAppSelector(getSettings);
  const {t} = useTranslation('profile');

  if (!isMoreInfoSectionShown) {
    return <></>;
  }

  return (
    <ProfileCell>
      <P>{t('MoreInformation')}</P>
      <Link to={companyWebsite}>{companyWebsite}</Link>
    </ProfileCell>
  );
};
