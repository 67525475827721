import {useTranslation} from 'react-i18next';
import {useModal} from '@hooks';
import {ModalPage, ExtrasSelectionCell} from '@organisms';
import {ExtrasSelectionCardProps} from './types';
import {ExtrasTypes} from '@lib/store';
import {StyledExtraItemWrapper, StyledFlexCol} from './styles';

export const ExtrasSelectionCard = ({extrasType, extrasItems}: ExtrasSelectionCardProps) => {
  const {t} = useTranslation('screen');
  const {closeModal, removePages} = useModal();

  return (
    <ModalPage
      onClose={closeModal}
      title={t('Extras')}
      onBack={extrasType === ExtrasTypes.HYBRID_MEETINGS ? () => removePages(1) : undefined}>
      <StyledFlexCol>
        {extrasItems.map((extra) => (
          <StyledExtraItemWrapper key={extra.id}>
            <ExtrasSelectionCell {...extra} />
          </StyledExtraItemWrapper>
        ))}
      </StyledFlexCol>
    </ModalPage>
  );
};
