import {MeetingDetailsCardMessageProps} from './types';
import {useTranslation} from 'react-i18next';

import {useModal} from '@hooks';

import {Button} from '@molecules';
import {ReadMoreCard} from '@organisms';

import {Message} from './styles';

export const MeetingDetailsCardMessage = ({messageAsHTML, messagePreview}: MeetingDetailsCardMessageProps) => {
  const {addPages} = useModal();
  const {t} = useTranslation();

  const teamsSectionRegexp = /(\.|_){5,}/;
  const message =
    messagePreview &&
    messagePreview
      .split(teamsSectionRegexp)[0]
      .replaceAll(/(\r\n|\n|\r)/gm, ' ')
      .trim();
  const readMore = message && t('meeting:ReadMore');

  if (!message) {
    return <></>;
  }

  return (
    <div>
      <Message data-testid="organisms-eventDetails-MeetingDetailsCardMessage_description">{message}</Message>
      <Button
        button="text"
        data-testid="organisms-eventDetails-MeetingDetailsCardMessage_description-read-more"
        onClick={() => addPages([<ReadMoreCard messageAsHTML={messageAsHTML} />])}>
        {readMore}
      </Button>
    </div>
  );
};
