import {createContext, useEffect, useState} from 'react';
import {ThemeProviderProps, ThemeContextProps} from './types';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import {darkTheme, defaultTheme, GlobalStyle} from '@styles';
import {} from '@styles';

const themes = {
  light: 'light',
  dark: 'dark',
};

const initTheme = {
  theme: themes.light,
  toggleTheme: () => void 0,
};

export const ThemeContext = createContext<ThemeContextProps>(initTheme);

export const ThemeProvider = ({children}: ThemeProviderProps) => {
  const [theme, setTheme] = useState(themes.light);
  const [styledTheme, setStyledTheme] = useState(defaultTheme);

  useEffect(() => {
    const mpqTheme = window.localStorage.getItem('mpqTheme');

    if (mpqTheme === themes.dark) {
      document.documentElement.dataset.theme = themes.dark;
      setTheme(themes.dark);
      setStyledTheme(darkTheme);
      return;
    }

    if (!mpqTheme || mpqTheme === themes.light) {
      document.documentElement.dataset.theme = themes.light;
      setTheme(themes.light);
      setStyledTheme(defaultTheme);
      return;
    }
  }, []);

  const toggleTheme = () => {
    if (theme === themes.dark) {
      setTheme(themes.light);
      setStyledTheme(defaultTheme);
      window.localStorage.setItem('mpqTheme', themes.light);
      document.documentElement.dataset.theme = themes.light;
    }

    if (theme === themes.light) {
      setTheme(themes.dark);
      setStyledTheme(darkTheme);
      window.localStorage.setItem('mpqTheme', themes.dark);
      document.documentElement.dataset.theme = themes.dark;
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}>
      <StyledThemeProvider theme={styledTheme}>
        <GlobalStyle />
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};
