import styled from 'styled-components';
import {FlexCol} from '@quarks';
import {StyledMapViewProps} from './types';
import {BREAKPOINTS, COLOR_PALETTE} from '@constants';

export const StyledMapView = styled(FlexCol)<StyledMapViewProps>`
  position: relative;
  height: ${({$fullView}) => ($fullView ? '100%' : '300px')};
  width: 100%;
  border-radius: ${({$borderRadius}) => $borderRadius && `${$borderRadius}px`};
  overflow: hidden;

  * {
    pointer-events: ${({$disablePointerEvents}) => $disablePointerEvents && 'none !important'};
  }

  background: ${({$hasError, theme}) => ($hasError ? theme.card.red.background : 'transparent')};

  ${BREAKPOINTS.small`
    height: ${({$fullView}) => ($fullView ? '100%' : '200px')}
  `}
`;

export const StyledMapWrapper = styled.div`
  height: 100%;
  width: 100%;
  align-self: stretch;

  [data-spotlighted] {
    &[data-type='deskIslandLayer'] {
      [data-type='deskLayer'] > g {
        filter: contrast(85%);
      }
    }

    &[data-type='floorLayer'],
    &[data-type='deskLayer'] > g {
      filter: contrast(85%);
    }

    &[data-type='meetingRoomLayer'] > g {
      filter: contrast(90%);
    }

    &[data-type='facilityLayer'],
    &[data-type='areaLayer'] {
      filter: contrast(90%);
    }
  }

  [data-state='selected'] {
    --map-stroke-color: ${COLOR_PALETTE.collaborativeBlue[500]};
    --map-fill-color: ${COLOR_PALETTE.collaborativeBlue[100]};

    &[data-type='facilityLayer'],
    &[data-type='meetingRoomLayer'],
    &[data-type='deskLayer'],
    &[data-type='areaLayer'] {
      > g:first-child {
        stroke: var(--map-stroke-color);
        fill: var(--map-fill-color);
      }
    }

    &[data-type='floorLayer'] > [data-type='floorOutline'] {
      stroke: var(--map-stroke-color);
      fill: var(--map-fill-color);
    }

    &[data-type='deskIslandLayer'] {
      [data-type='deskLayer'] > g {
        stroke: var(--map-stroke-color);
        fill: var(--map-fill-color);
      }
    }
  }
`;
