import {FlexCol, P} from '@quarks';
import {StyledGroupItem, StyledFacilityIcon, GroupItemIconWrapper} from './styles';
import {Icon} from '@atoms';
import {ResourceGroup} from '@lib/store/src/data/buildingResources';

type Props = ResourceGroup & {
  onClick: () => void;
};

export const GroupItem = ({type, displayName, iconUrl, onClick}: Props) => {
  return (
    <StyledGroupItem onClick={onClick}>
      <FlexCol
        padding={16}
        justifyContent="center"
        alignItems="center"
        flex="1"
        gap={8}
        data-testid={`HaNEpxloreList-GroupItem-${displayName}`}>
        <GroupItemIconWrapper>
          {type === 'facility' ? (
            <StyledFacilityIcon $imageURL={iconUrl} />
          ) : (
            <Icon
              size={'24px'}
              icon={type === 'area' ? 'desk' : 'meetingRoom'}
            />
          )}
        </GroupItemIconWrapper>

        <P
          fontWeight="bold"
          textAlign="center">
          {displayName}
        </P>
      </FlexCol>
    </StyledGroupItem>
  );
};
