import {MeetingRoomDetailsCardBottom} from '@organisms';
import {StyledBottomContainer} from './styles';
import {HaNCardBottomProps} from './types';
import {getIsMeetingsCalendarActive} from '@lib/store';
import {useAppSelector} from '@hooks';

export const HaNCardBottom = ({room, startDateTime, duration, onBookNowCallback, setPage}: HaNCardBottomProps) => {
  const isMeetingsCalendarActive = useAppSelector(getIsMeetingsCalendarActive);
  const isRoomBookable = !!room?.email && isMeetingsCalendarActive;

  return isRoomBookable ? (
    <StyledBottomContainer>
      <MeetingRoomDetailsCardBottom
        room={room}
        startDateTime={startDateTime}
        duration={duration}
        onBookingDetailsPress={() => setPage('bookingDetails')}
        onBookNowCallback={onBookNowCallback}
      />
    </StyledBottomContainer>
  ) : (
    <></>
  );
};
