import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {useTranslation} from 'react-i18next';
import {ModalCell} from '@molecules';
import {DeskSelectionModalPage, MapDetailsModalPage, MapPreviewWithSuggestionsCard} from '@organisms';
import {
  MeetingInviteeSearchResult,
  getAreaById,
  getFloorHasMap,
  getDesksByAreaId,
  getSelectedNodes,
  clearDeskSuggestions,
  loadDeskSuggestionsAndData,
  getWorkspaceReservationByDate,
  getSuggestedDesks,
  selectDesks,
  clearSuggestionError,
} from '@lib/store';
import {useEffect, useState} from 'react';
import {parseISO} from 'date-fns';

export function WorkspaceOnMapSection({invitees, date}: {invitees: MeetingInviteeSearchResult[]; date: string}) {
  const {addPages} = useModal();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const {buildingId, floorId, areaId, deskIds} = useAppSelector(getSelectedNodes);

  const currentWorkspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, parseISO(date)));
  const reservationId = currentWorkspaceReservation?.id;

  // All desks in the area with their names and whether they're enabled (static)
  const floorHasMap = useAppSelector(getFloorHasMap(floorId));
  const areaNode = useAppSelector((state) => getAreaById(state, areaId));
  const deskBookingRequired = Boolean(areaNode?.hasBookableDesks);
  const areaDesks = useAppSelector((state) => getDesksByAreaId(state, areaId));
  const groupSize = invitees.length + 1;

  // Trick the component to not update when reservationId is dynamically loaded
  // Note: this means you can never have this component in a piece of UI that
  //       should survive switching between create & edit mode!
  const [initialReservationId] = useState(reservationId);

  useEffect(
    function autoLoadDeskSuggestions() {
      dispatch(clearDeskSuggestions());
      dispatch(clearSuggestionError());

      // We need an area before we can suggest desks
      if (!areaId) return;

      // Only fetch suggestions for areas with desk booking
      if (!deskBookingRequired) return;

      const params = {
        areaId,
        date,
        // Note: feature flag must be enabled for groupSize to work
        deskCount: groupSize,
        reservationId: initialReservationId,
      };

      dispatch(loadDeskSuggestionsAndData(params));
    },
    [areaId, deskBookingRequired, date, groupSize, initialReservationId, dispatch],
  );

  const desksForGroup = useAppSelector((state) => getSuggestedDesks(state, groupSize));
  useEffect(
    function selectBestSuggestions() {
      dispatch(selectDesks(desksForGroup?.map((d) => d.id) ?? []));
    },
    [desksForGroup, dispatch],
  );

  // Desk names are shown when users are tapped
  const deskNames = (deskIds ?? []).flatMap((id) => {
    const desk = areaDesks.find((d) => d.id === id);

    return desk ? [desk.name] : [];
  });

  if (!buildingId || !floorId || !floorHasMap) return <></>;

  return (
    <ModalCell>
      <MapPreviewWithSuggestionsCard
        buildingId={buildingId}
        floorId={floorId}
        areaId={areaId}
        deskIds={deskIds}
        groupSize={groupSize}
        deskNames={deskNames}
        buttonLabel={deskBookingRequired ? t('Change') : t('View')}
        onClick={() => {
          addPages([
            deskBookingRequired ? (
              <DeskSelectionModalPage
                date={date}
                invitees={invitees}
              />
            ) : (
              <MapDetailsModalPage
                date={date}
                areaId={areaId}
                buildingId={buildingId}
                floorId={floorId}
                groupSize={groupSize}
              />
            ),
          ]);
        }}
      />
    </ModalCell>
  );
}
