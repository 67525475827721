import {config, useTransition} from 'react-spring';
import {HaNWorkspaceModalPage} from '../HaNWorkspaceModal';
import {OccupancyInformationWrapper, StyledRow} from './styles';
import {HaNModalHeader, HaNWorkspaceIcon} from '@organisms';
import {StyledContainer} from '../HaNSensorInformation/styles';
import {useTranslation} from 'react-i18next';
import {FlexCol, H1, H2, P} from '@quarks';

type Props = {
  page: HaNWorkspaceModalPage;
  onClose: () => void;
  onBack: () => void;
};

export const HaNOccupancyInformation = ({page, onClose, onBack}: Props) => {
  const {t} = useTranslation();

  const transition = useTransition(page === 'availabilityInformation', {
    from: {transform: 'translate3d(100%, 0, 0)'},
    enter: {transform: 'translate3d(0%, 0, 0)'},
    leave: {transform: 'translate3d(100%, 0, 0)'},
    config: {...config.stiff, clamp: true},
  });

  return transition(
    (style, item) =>
      item && (
        <OccupancyInformationWrapper style={style}>
          <HaNModalHeader
            onBack={onBack}
            onClose={onClose}
            title={t('workplace:Area')}
          />
          <StyledContainer>
            <FlexCol gap={24}>
              <H1>{t('AvailabilityInformation')}</H1>
              <H2 as="h3">{t('hereAndNow:SensorData')}</H2>
              <FlexCol gap={8}>
                <StyledRow gap={8}>
                  <HaNWorkspaceIcon iconType="quiet" />
                  <P fontWeight="bold"> {t('ItSeemsQuiet')}</P>
                </StyledRow>
                <P>{t('ItSeemsQuietDescription')}</P>
              </FlexCol>
              <FlexCol gap={8}>
                <StyledRow gap={8}>
                  <HaNWorkspaceIcon iconType="lively" />
                  <P fontWeight="bold"> {t('ItSeemsLively')}</P>
                </StyledRow>
                <P>{t('ItSeemsLivelyDescription')}</P>
              </FlexCol>
              <FlexCol gap={8}>
                <StyledRow gap={8}>
                  <HaNWorkspaceIcon iconType="busy" />
                  <P fontWeight="bold"> {t('ItSeemsBusy')}</P>
                </StyledRow>
                <P>{t('ItSeemsBusyDescription')}</P>
              </FlexCol>
              <FlexCol gap={8}>
                <StyledRow gap={8}>
                  <HaNWorkspaceIcon iconType="full" />
                  <P fontWeight="bold"> {t('ItSeemsFull')}</P>
                </StyledRow>

                <P>{t('ItSeemsFullDescription')}</P>
              </FlexCol>
              <FlexCol gap={8}>
                <StyledRow gap={8}>
                  <HaNWorkspaceIcon iconType="unknown" />
                  <P fontWeight="bold"> {t('NoSensorData')}</P>
                </StyledRow>
                <P>{t('NoSensorDataDescription')}</P>
              </FlexCol>
              <FlexCol gap={8}>
                <StyledRow gap={8}>
                  <HaNWorkspaceIcon iconType={null} />
                  <P fontWeight="bold"> {t('NoSensorInstalled')}</P>
                </StyledRow>
                <P>{t('NoSensorInstalledDescriptionWorkspace')}</P>
              </FlexCol>
              <H2 as="h3">{t('hereAndNow:BookingData')}</H2>
              <FlexCol gap={8}>
                <StyledRow gap={8}>
                  <HaNWorkspaceIcon iconType={'NonBookable'} />
                  <P fontWeight="bold">{t('hereAndNow:AdhocUsageOnly')}</P>
                </StyledRow>
                <P>{t('hereAndNow:WorkspaceInfoAdHocDetail')}</P>
              </FlexCol>
              <FlexCol gap={8}>
                <StyledRow gap={8}>
                  <HaNWorkspaceIcon iconType={'Booking'} />
                  <P fontWeight="bold">{t('hereAndNow:WorkspaceBookings')}</P>
                </StyledRow>
                <P>{t('hereAndNow:WorkspaceBookingsDescription')}</P>
              </FlexCol>
            </FlexCol>
          </StyledContainer>
        </OccupancyInformationWrapper>
      ),
  );
};
