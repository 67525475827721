import {useAppDispatch, useAppSelector, useBreakPoint, useIsPageActive, useModal, useRefreshDataIfStale} from '@hooks';
import {
  AreaWithSensorInfo,
  getAreasWithSensorData,
  getFloorById,
  getWorkspaceOccupancyLoadingStatusForBuildingId,
  loadWorkspaceOccupancy,
  withAsyncThunkErrorHandling,
  getWorkspaceReservationsForConnectionsByDate,
} from '@lib/store';
import {pickUsingStringAsIndex} from '@lib/utils';
import bg_03 from '@images/rooms/03.webp';
import bg_09 from '@images/rooms/09.webp';
import bg_13 from '@images/rooms/13.webp';
import bg_14 from '@images/rooms/14.webp';
import bg_15 from '@images/rooms/15.webp';
import bg_18 from '@images/rooms/18.webp';
import bg_19 from '@images/rooms/19.webp';
import {AvailableWorkspacesModal, HaNWorkspaceIcon, Tile} from '@organisms';
import {format} from 'date-fns';
import {ReactNode, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {cn} from '@utils';
import {getWorkspacePicks} from '@lib/store/src/util';
import {StyledSwiperHorizontal} from '../../hereAndNow/HaNRoomListHorizontal/styles';
import {useNavigate} from 'react-router-dom';
import {trackEvent} from '@lib/infrastructure';

const REFRESH_INTERVAL_SECONDS = 5 * 60;

export const AvailableWorkspacesTile = ({time, buildingId}: {time: Date; buildingId: string}) => {
  // Note that in this component { time } is used for two things:
  //  - determining which workday is relevant, which helps decide which building to show workspaces for
  //  - triggering re-renders so we can check if our data went stale
  // For the actual stale-checks, we don't use time but use in-line calls to new Date(),
  // this ensures our time-traveling features will not impact our data-loading

  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const dateKey = format(time, 'yyyy-MM-dd');
  const breakPoint = useBreakPoint();
  const navigate = useNavigate();
  const areasWithSensorData = useAppSelector((state) => getAreasWithSensorData(state, buildingId, t));
  const params = useMemo(() => ({date: dateKey, buildingId}), [buildingId, dateKey]);
  const connectionsGroupedByWorkspaceWorkingAtDate = useAppSelector((state) =>
    getWorkspaceReservationsForConnectionsByDate(state, params),
  );

  const areasWithSensorDataAndConnections = useMemo(() => {
    return areasWithSensorData.map((area) => {
      const numberOfConnections =
        connectionsGroupedByWorkspaceWorkingAtDate.find((wkReservation) => wkReservation.areaId === area.id)?.members
          .length ?? 0;
      return {...area, numberOfConnections};
    });
  }, [areasWithSensorData, connectionsGroupedByWorkspaceWorkingAtDate]);

  let loadingState = useAppSelector((state) =>
    getWorkspaceOccupancyLoadingStatusForBuildingId(state, buildingId ?? ''),
  );

  const availableWorkspacesLoading = loadingState === 'loading';

  const pageActive = useIsPageActive();
  const {refreshIfStale, refreshLabel} = useRefreshDataIfStale(availableWorkspacesLoading, REFRESH_INTERVAL_SECONDS);

  function loadWorkspaceAvailability() {
    // List time as a dependency: even though we won't use it, its updates will dictate
    // the interval at which we check whether data went stale
    time;

    // Nothing to load if we don't have a building
    if (!buildingId) return;

    // Let's not refresh if this page is not being looked at by a user

    if (!pageActive) return;

    refreshIfStale(() => {
      dispatch(withAsyncThunkErrorHandling(() => loadWorkspaceOccupancy(buildingId)));
    });
  }

  /* Cannot be moved outside of useEffect because it will complain about faulty setState */
  useEffect(loadWorkspaceAvailability, [buildingId, time, dispatch, refreshIfStale, pageActive]);

  const workspacePicks = getWorkspacePicks(areasWithSensorDataAndConnections);
  const RowContainer =
    breakPoint === 'small'
      ? ({children}: {children: ReactNode[]}) => (
          <StyledSwiperHorizontal spaceBetween={16}>{children}</StyledSwiperHorizontal>
        )
      : ({children}: {children: ReactNode[]}) => <div className="grid grid-cols-6 w-full gap-8"> {children} </div>;

  const NoWorkspacesTile = ({numberOfWorkspaces}: {numberOfWorkspaces: number}) => (
    <div className="bg-collaborative-blue-50 rounded-md flex flex-col justify-center items-center w-full aspect-[255/150] h-44 col-span-3">
      <p>{numberOfWorkspaces === 0 ? t('NoWorkspacesAvailable') : t('NoOtherWorkspacesAvailable')}</p>
    </div>
  );

  return (
    <Tile
      title={t('FindAWorkspace')}
      titleNote={refreshLabel}
      viewAllAction={() => {
        trackEvent('Home_AvailableWorkspacesTile__ViewAll');
        navigate('/now/workspaces');
      }}>
      {loadingState === 'failed' ? (
        <div>{t('error:FailedToLoadWorkspaceAvailability')}</div>
      ) : workspacePicks.length > 0 ? (
        <RowContainer>
          {workspacePicks.map((r) => (
            <WorkspaceItem
              key={r.id}
              workspace={r}
              className={cn('flex flex-col gap-4', {
                'col-span-2': workspacePicks.length === 3,
                'col-span-3': workspacePicks.length === 2 || workspacePicks.length === 1,
              })}
            />
          ))}
          {workspacePicks.length === 1 ? <NoWorkspacesTile numberOfWorkspaces={workspacePicks.length} /> : null}
        </RowContainer>
      ) : loadingState === 'loaded' ? (
        <NoWorkspacesTile numberOfWorkspaces={workspacePicks.length} />
      ) : loadingState === 'loading' ? (
        <div className="grid grid-cols-3 gap-8 w-full">
          <WorkspaceItemSkeleton />
          <WorkspaceItemSkeleton />
          <WorkspaceItemSkeleton />
        </div>
      ) : (
        <div className="bg-collaborative-blue-50 w-full rounded-md flex flex-col justify-center items-center h-64">
          <p>{t('NoWorkspacesAvailable')}</p>
        </div>
      )}
    </Tile>
  );
};

const WorkspaceItem = ({workspace, className}: {workspace: AreaWithSensorInfo; className: string}) => {
  const {t} = useTranslation();
  const bp = useBreakPoint();
  const {openModal, addPages} = useModal();

  const imageUrl = pickUsingStringAsIndex(workspace.id, [bg_03, bg_09, bg_13, bg_14, bg_15, bg_18, bg_19]);
  const capacity = workspace.capacity;

  const capacityLabel = t('meeting:MeetingRoomSeat', {count: capacity});
  const floorLabel = useAppSelector((state) => getFloorById(state, workspace.floorId))?.name ?? '';

  function handleWorkspaceClick() {
    trackEvent('Home_AvailableWorkspacesTile__ViewDetails', {workspaceName: workspace.name});
    addPages([<AvailableWorkspacesModal workspaceWithSensorData={workspace} />]);
    openModal();
  }

  return (
    <button
      data-testid="HomePage-available-workspace"
      className={cn('outline-offset-8 rounded-lg outline-gray-400', className)}
      onClick={handleWorkspaceClick}>
      <img
        alt={`Placeholder image for workspace`}
        src={imageUrl}
        className={cn(`cursor-pointer object-cover rounded-md w-full aspect-[255/150] h-[176px]`)}
      />
      <div
        className={cn(`flex gap-2 justify-between flex-row items-center`, {
          'flex-col-reverse items-start': bp === 'medium',
        })}>
        <div>
          <strong>{workspace.name}</strong>
          <p>
            {capacityLabel}, {floorLabel}
          </p>
        </div>
        {workspace.sensorInformation && (
          <div className="flex-none">
            <HaNWorkspaceIcon iconType={workspace.sensorInformation.status} />
          </div>
        )}
      </div>
    </button>
  );
};

const WorkspaceItemSkeleton = () => {
  return (
    <div className="w-full flex-grow flex flex-col gap-4">
      <div className={`animate-pulse duration-1500 rounded-md bg-slate-100 w-full aspect-[255/150]`}></div>
      <div className="flex flex-col gap-2">
        <div className="animate-pulse duration-1500 delay-500 rounded-md bg-slate-100 w-full h-4"></div>
        <div className="animate-pulse duration-1500 delay-500 rounded-md bg-slate-100 w-full h-4"></div>
      </div>
    </div>
  );
};
