import styled from 'styled-components';
import {animated} from 'react-spring';
import {FlexCol} from '@quarks';

export const AttendeesWrapper = styled(animated.div)`
  background: ${({theme}) => theme.background.white};
  display: flex;
  flex-direction: column;
  height: var(--modal-height);
  overflow-x: hidden;
`;

export const StyledBody = styled(FlexCol)`
  flex: 1;
  padding: 24px 20px;
  justify-content: flex-start;
  overflow: auto;
  gap: 32;
`;
