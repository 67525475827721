import {FlexRow, FlexCol, P} from '@quarks';
import {StyledModalCellItemIcon, StyledTruncatedText} from '../HaNRoomCell/styles';
import {useRoomImage} from '@hooks';
import {ExploreState} from 'src/components/pages/HereAndNow/useExploreState';
import {Resource} from '@lib/store/src/data/buildingResources';
import {Wrapper} from './styles';

type Props = {
  resource: Resource;
  exploreState: ExploreState;
};

export const ExploreCell = ({resource, exploreState}: Props) => {
  const {selectedResource, setSelectedResource, setSpotlightedResource} = exploreState;
  const {buildingId, data, type, name} = resource;

  const roomHash = type === 'room' && data?.roomPictureHash ? data.roomPictureHash : undefined;
  const imageURL = useRoomImage(name, roomHash, buildingId, Math.round(48 * window.devicePixelRatio * 4), true);

  const handleOnClick = () => {
    setSelectedResource(resource);
  };
  return (
    <Wrapper
      as="button"
      tabIndex={1}
      onClick={handleOnClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleOnClick();
      }}>
      <FlexRow gap={8}>
        <StyledModalCellItemIcon
          icon={resource.type === 'area' ? 'desk' : 'meetingRoom'}
          iconUrl={resource.type === 'facility' ? resource.data?.facilityType.lightIconUrl : undefined}
          imageURL={resource.type === 'room' ? imageURL : undefined}
        />
        <FlexCol
          justifyContent="center"
          gap={2}>
          <StyledTruncatedText fontWeight="bold">{resource.name}</StyledTruncatedText>
          <P>{resource.floorName}</P>
        </FlexCol>
      </FlexRow>
    </Wrapper>
  );
};
