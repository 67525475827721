import {useEffect} from 'react';

import {
  AttendeeAvailability,
  getAttendeeAvailabilityLoadingStatus,
  getCurrentAttendeeAvailabilities,
  isMeetingDateTimeRangeValid,
  loadAvailabilityOfInviteesSuggestions,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppSelector} from './useAppSelector';
import {useAppDispatch} from './useAppDispatch';

import {parseISO} from 'date-fns';

type Props = {
  startDate: string;
  endDate: string;
  attendeeEmails: string[];
};

type ReturnedProps = {
  attendeeAvailabilities: AttendeeAvailability[];
  loading: boolean;
  error: boolean;
};

export const useLoadAvailableInvitees = ({startDate, endDate, attendeeEmails}: Props): ReturnedProps => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getAttendeeAvailabilityLoadingStatus) === 'Loading';
  const error = useAppSelector(getAttendeeAvailabilityLoadingStatus) === 'Failed';
  const isDateRangeValid = isMeetingDateTimeRangeValid(parseISO(startDate), parseISO(endDate));

  const attendeeAvailabilities = useAppSelector((state) =>
    getCurrentAttendeeAvailabilities(state, {
      isDateRangeValid,
      date: parseISO(startDate),
    }),
  );

  useEffect(() => {
    const loadAvailabilities = async () => {
      if (!isDateRangeValid) return;

      await dispatch(
        withAsyncThunkErrorHandling(() =>
          loadAvailabilityOfInviteesSuggestions({
            startDateTime: parseISO(startDate),
            endDateTime: parseISO(endDate),
            attendeeEmails,
          }),
        ),
      );
    };

    loadAvailabilities();
  }, [attendeeEmails, dispatch, endDate, isDateRangeValid, startDate]);

  return {attendeeAvailabilities, loading, error};
};
