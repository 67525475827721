import {Card} from '@atoms';
import {truncateStringAfterLines} from '@utils';
import styled from 'styled-components';

export const SuggestionCard = styled(Card).attrs(({...attrs}) => ({
  ...attrs,
  card: 'connectingGreen',
}))`
  max-width: 20rem;
  padding: 1rem;
`;

export const SuggestionName = styled.p`
  ${truncateStringAfterLines(2)}
  font-weight: bold;
`;
