import {getAccessToken} from '@lib/store';
import {useAppSelector} from './useAppSelector';
import {useImage} from './useImage';
import bg_03 from '@images/rooms/03.webp';
import bg_09 from '@images/rooms/09.webp';
import bg_13 from '@images/rooms/13.webp';
import bg_14 from '@images/rooms/14.webp';
import bg_15 from '@images/rooms/15.webp';
import bg_18 from '@images/rooms/18.webp';
import bg_19 from '@images/rooms/19.webp';
import {pickUsingStringAsIndex} from '@lib/utils';

/**
 * @param imageHash String
 * @returns URL to profile picture as string
 */

export const useRoomImage = (
  email: string,
  imageHash: string | undefined,
  buildingId: string,
  imagePixelWidth: number,
  useFallBackImage: boolean = true,
) => {
  const token = useAppSelector(getAccessToken) || '';
  const endpoint =
    imageHash && buildingId ? `buildings/${buildingId}/pictures?hash=${imageHash}&width=${imagePixelWidth}` : null;

  const roomImage = useImage(token, endpoint);

  if (!useFallBackImage && !endpoint) return undefined;

  if (useFallBackImage && !endpoint) {
    return pickUsingStringAsIndex(email, [bg_03, bg_09, bg_13, bg_14, bg_15, bg_18, bg_19]);
  }

  return roomImage;
};
