import {Button} from '@molecules';
import {Icon} from '@atoms';
import {FlexRow} from '@quarks';
import styled from 'styled-components';
import {pxToRem} from '@utils';
import {StyledToastContainerProps} from './types';

export const StyledToastContainer = styled(FlexRow).attrs<StyledToastContainerProps>(({theme, $type}) => ({
  background:
    $type === 'success'
      ? theme.toast.success.background
      : $type === 'warning'
      ? theme.toast.warning.background
      : theme.toast.error.background,
  color:
    $type === 'success'
      ? theme.toast.success.color
      : $type === 'warning'
      ? theme.toast.warning.color
      : theme.toast.error.color,
  gap: 8,
  padding: 16,
  borderRadius: 8,
}))<StyledToastContainerProps>`
  align-items: center;
  justify-content: space-around;
  max-width: fit-content;
`;

export const StyledToastIcon = styled(Icon).attrs(({theme, icon}) => ({
  icon,
  color: theme.icon.duotone.color,
  size: pxToRem(24),
}))``;

export const StyledToastButton = styled(Button).attrs(({theme, ...attrs}) => ({
  ...attrs,
  button: 'text',
}))`
  color: ${({theme}) => theme.button.primary.color};
`;
