import {MeetingInviteeSearchResult, MeetingRoom} from '@lib/store';
import {useState, useEffect} from 'react';
import {HaNModalProps} from '../HaNModalContainer/types';
import {HaNRoomModalPage} from '../types';
import {HaNAttendees, HaNBookingDetails, HaNRoomInformation, HaNSensorInformation} from '@organisms';

type HaNRoomModalProps = Omit<HaNModalProps, 'entity' | 'show' | 'modalToShow' | 'children'> & {
  room: MeetingRoom;
};

export const HaNRoomModal = ({
  onClose,
  room,
  endDateTime,
  startDateTime,
  onBookNowCallback,
  setWarnBeforeOpeningNewRoom,
  duration,
}: HaNRoomModalProps) => {
  const [invitees, setInvitees] = useState<MeetingInviteeSearchResult[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [page, setPage] = useState<HaNRoomModalPage>('roomInformation');
  const [prevPage, setPrevPage] = useState<HaNRoomModalPage>('roomInformation');

  useEffect(() => {
    setWarnBeforeOpeningNewRoom(hasUnsavedChanges);

    return () => setWarnBeforeOpeningNewRoom(false);
  }, [hasUnsavedChanges, setWarnBeforeOpeningNewRoom]);

  const handleCloseModal = () => {
    setInvitees([]);
    onClose();
  };

  return (
    <>
      <HaNRoomInformation
        room={room}
        onClose={onClose}
        page={page}
        setPage={setPage}
        endDateTime={endDateTime}
        startDateTime={startDateTime}
        duration={duration}
        onBookNowCallback={() => {
          onClose();
          onBookNowCallback();
        }}
      />
      <HaNSensorInformation
        onBack={() => {
          setPrevPage('sensorInformation');
          setPage('roomInformation');
        }}
        onClose={handleCloseModal}
        page={page}
      />
      <HaNBookingDetails
        onBack={() => {
          setPrevPage('bookingDetails');
          setPage('roomInformation');
        }}
        onClose={handleCloseModal}
        page={page}
        resetAttendees={() => setInvitees([])}
        room={room}
        duration={duration}
        startDateTime={startDateTime}
        setPage={setPage}
        prevPage={prevPage}
        attendees={invitees}
        setHasUnsavedChanges={setHasUnsavedChanges}
        onBookSuccessCallback={onBookNowCallback}
      />
      <HaNAttendees
        onBack={() => {
          setPrevPage('addInvitees');
          setPage('bookingDetails');
        }}
        onClose={handleCloseModal}
        page={page}
        invitees={invitees}
        onSave={(value) => {
          setInvitees(value);
          setPrevPage('addInvitees');
          setPage('bookingDetails');
        }}
        hasUnsavedChanges={hasUnsavedChanges}
      />
    </>
  );
};
