import {WorkspaceReservation} from '@lib/store';
import {MapView} from '@molecules';
import {useFacilityMarkers} from '../../hereAndNow/useFacilityMarkers';
import {FacilityMarkerData} from '@map/mapiq-map/markers/NodeMarkerData';

type Props = {
  workspaceReservation: WorkspaceReservation;
  interactive?: boolean;
};

export const WorkspaceMapView = ({workspaceReservation, interactive = false}: Props) => {
  const facilityMarkers = useFacilityMarkers(
    workspaceReservation.buildingId ?? null,
    workspaceReservation.floorId ?? null,
  );
  const mapMarker: FacilityMarkerData = {
    type: 'facility',
    subType: 'Area',
    nodeId: workspaceReservation.nodeId,
  };

  return (
    <MapView
      buildingId={workspaceReservation.buildingId}
      floorId={workspaceReservation.floorId}
      highlights={[...facilityMarkers, mapMarker]}
      buildingNodeStates={new Map([[mapMarker.nodeId, 'selected']])}
      buildingNodeTypeStates={new Map([['facility', 'dimmed']])}
      fullView={true}
      disablePointerEvents={interactive ? false : true}
    />
  );
};
