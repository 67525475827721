import {pxToRem} from '@utils';
import styled from 'styled-components';
import {StyledBadgeProps} from './types';
import {Icon} from '@atoms';

export const StyledBadge = styled.div<StyledBadgeProps>`
  align-items: center;
  background: ${({$badge, theme}) => theme.validation[$badge]};
  border-radius: 9999px;
  border: ${({$border}) => $border};
  bottom: ${({$bottom}) => $bottom};
  display: flex;
  height: ${({$size}) =>
    $size === 'small' ? pxToRem(8) : $size === 'large' ? pxToRem(16) : $size === 'medium' ? pxToRem(10) : pxToRem(18)};
  justify-content: center;
  left: ${({$left}) => $left};
  position: ${({$bottom, $left, $right, $top}) => ($bottom || $left || $right || $top) && 'absolute'};
  right: ${({$right}) => $right};
  top: ${({$top}) => $top};
  width: ${({$size}) =>
    $size === 'small' ? pxToRem(8) : $size === 'large' ? pxToRem(16) : $size === 'medium' ? pxToRem(10) : pxToRem(18)};
`;

export const StyledIcon = styled(Icon).attrs((attrs) => ({
  ...attrs,
  color: attrs.theme.validation.iconColor,
}))``;
