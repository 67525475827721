import {SquareButtonProps} from './types';
import {StyledSquareButton} from './styles';

import {pxToRem} from '@utils';

import {Span} from '@quarks';
import {Icon, Loader} from '@atoms';

export const SquareButton = ({
  as,
  children,
  className,
  disabled,
  iconLeft,
  iconRight,
  left,
  loading,
  right,
  style,
  squareButton,
  alignItems,
  ...props
}: SquareButtonProps) => {
  return (
    <StyledSquareButton
      as={as ? 'div' : 'button'}
      className={className}
      disabled={disabled}
      style={style}
      $squareButton={squareButton}
      $alignItems={alignItems}
      {...props}>
      {loading ? (
        <Loader
          size={pxToRem(24)}
          color="currentColor"
        />
      ) : iconLeft && !left ? (
        <Icon
          color="currentColor"
          icon={iconLeft}
          size={pxToRem(24)}
          weight="light"
        />
      ) : (
        left
      )}
      <Span
        flex="auto"
        fontWeight="bold">
        {children}
      </Span>
      {iconRight && !right ? (
        <Icon
          color="currentColor"
          icon={iconRight}
          size={pxToRem(24)}
          weight="light"
        />
      ) : (
        right
      )}
    </StyledSquareButton>
  );
};
