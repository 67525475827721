import {CardProps} from './types';
import styled from 'styled-components';
import {baseStyle, Div} from '@quarks';

export const Card = styled(Div).attrs(({shadow, card, clickable, ...attrs}: CardProps) => ({
  as: clickable ? 'button' : 'div',
  card: card || 'white',
  clickable,
  shadow: shadow !== undefined ? shadow : true,
  ...attrs,
}))`
  ${baseStyle}
  background: ${({card, theme}) => theme.card[card].background};
  border: ${({clickable, card, theme}) => clickable && `2px solid ${theme.card[card].border}`};
  border-radius: 10px;
  box-shadow: ${({shadow, theme}) => shadow && theme.boxShadow.secondary};
  color: ${({card, theme}) => theme.card[card].color};

  @media (hover) {
    &:hover:not(:disabled) {
      background: ${({card, clickable, theme}) => clickable && theme.card[card].hover.background};
      border: ${({card, clickable, theme}) => clickable && `2px solid ${theme.card[card].hover.background}`};
    }
  }
`;
