import {useAppSelector} from '@hooks';
import {getConnectedUsersByDate, getWorkspaceReservationByDate} from '@lib/store';
import {UserCell} from '@molecules';
import {Div, P} from '@quarks';
import {parseISO} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {BookingDetailsConnectionsProps} from './types';

export const BookingDetailsConnections = ({date, location}: BookingDetailsConnectionsProps) => {
  const {t} = useTranslation();

  const connections = useAppSelector((state) => getConnectedUsersByDate(state, {date, nodeId: location.nodeId}));
  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, parseISO(date)));

  const getFilteredConnections = () => {
    if (!workspaceReservation?.groupReservationId) {
      return connections;
    }
    const membersWorkspaceReservationIds = workspaceReservation.members.map((member) => member.userId);
    const connectionsWithoutInvitees = connections.filter(
      (connection) => !membersWorkspaceReservationIds.includes(connection.id),
    );
    return connectionsWithoutInvitees;
  };

  return (
    <>
      {getFilteredConnections().length > 0 ? (
        <Div>
          <P
            fontSize="sm"
            fontWeight="bold">
            {location.isUserLocation ? t('connection:YourLocation') : location.name}
          </P>
          {getFilteredConnections().map((connection) => (
            <UserCell
              separator={false}
              padding="small"
              key={connection.connectionId}
              user={connection}
            />
          ))}
        </Div>
      ) : null}
    </>
  );
};
