import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import {
  enableCalendarAfterRedirection,
  getAllCalendarDays,
  getMeetingPreferencesLoadingStatus,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppDispatch, useAppSelector} from '@hooks';

export const useDetectRedirect = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const meetingPreferencesLoadingStatus = useAppSelector(getMeetingPreferencesLoadingStatus);
  const days = useAppSelector((state) => getAllCalendarDays(state));

  useEffect(() => {
    const redirectSource = searchParams.get('redirect_source');
    const result = searchParams.get('result');

    /**
     * * Logic for redirect flow for calendar consent
     */
    if (redirectSource === 'meet_consent' && meetingPreferencesLoadingStatus === 'Loaded') {
      if (result === 'success') {
        const calendarConsentSuccessful = async () => {
          await dispatch(withAsyncThunkErrorHandling(() => enableCalendarAfterRedirection()));
          setSearchParams();
        };

        calendarConsentSuccessful();
      } else {
        setSearchParams();
      }
    }
  }, [days, dispatch, meetingPreferencesLoadingStatus, searchParams, setSearchParams]);
};
