import {Navigation} from '@organisms';

import {useShowNavigation, useTheme} from '@hooks';
import {HeaderLayout} from '@templates';
import {useTranslation} from 'react-i18next';
import {StyledNavLink} from './styles';
import {MapiqLogo} from '@atoms';

export const Header = () => {
  const showNavigation = useShowNavigation();
  const {t} = useTranslation();
  const {theme} = useTheme();

  return (
    <HeaderLayout>
      <StyledNavLink
        to="/"
        aria-label={t('GoHome')}>
        <MapiqLogo
          data-testid="organisms-header-logo"
          theme={theme === 'light' ? 'dark' : 'light'}
          type="full"
          width="105px"
        />
      </StyledNavLink>
      {showNavigation && <Navigation footer={false} />}
    </HeaderLayout>
  );
};
