import {KeyboardEvent} from 'react';
import {ClickableCellWrapperProps} from './types';

import {StyledClickableCellWrapper} from './styles';

export const ClickableCellWrapper = ({children, ...props}: ClickableCellWrapperProps) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.click();
    }
  };

  return (
    <StyledClickableCellWrapper
      role="button"
      onKeyDown={handleKeyDown}
      tabIndex={0}
      {...props}>
      {children}
    </StyledClickableCellWrapper>
  );
};
