import styled from 'styled-components';
import {DuotonePathProps, PathProps, StyledIconProps} from './types';

const regularIconWeights = {
  regular: '2px',
  light: '1.5px',
  bold: '2.5px',
  thin: '1px',
};

const filledIconWeights = {
  regular: '1px',
  light: '.5px',
  bold: '1.5px',
  thin: '0px',
};

const duotoneIconWeights = {
  regular: '2px',
  light: '1.5px',
  bold: '2.5px',
  thin: '1px',
};

export const StyledIcon = styled.svg<StyledIconProps>`
  fill: none;
  height: ${({$size}) => $size || '3rem'};
  stroke-linecap: round;
  stroke-linejoin: initial;
  width: ${({$size}) => $size || '3rem'};
`;

export const Path = styled.path<PathProps>`
  fill: ${({$duotone, theme}) => $duotone && theme.icon.duotone.color};
  stroke: ${({$color, theme}) => $color || theme.text.color.body};
  stroke-width: ${({$weight}) => regularIconWeights[$weight || 'thin']};
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const FilledPath = styled.path<PathProps>`
  fill: ${({$color, theme}) => $color || theme.text.color.body};
  stroke: ${({$color, theme}) => $color || theme.text.color.body};
  stroke-width: ${({$weight}) => filledIconWeights[$weight || 'thin']};
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const DuotonePath = styled.path<DuotonePathProps>`
  fill: ${({$duotone, theme}) => $duotone && theme.icon.duotone.color};
  stroke: ${({$color}) => $color || 'none'};
  stroke-width: ${({$weight}) => duotoneIconWeights[$weight || 'thin']};
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const Rect = styled.rect<{$color?: string}>`
  fill: ${({$color}) => $color || 'none'};
`;

export const StyledImg = styled.img<StyledIconProps>`
  height: ${({$size}) => $size || '3rem'};
  width: ${({$size}) => $size || '3rem'};
`;
