import {useTranslation} from 'react-i18next';

import {P} from '@quarks';
import {TwoColumnCell} from '@atoms';
import {useAppSelector, useAppDispatch, useModal} from '@hooks';
import {getNotificationCategoryData, loadNotificationCategories} from '@lib/store';
import {pxToRem} from '@utils';
import {ProfileCell} from '../styles';
import {NotificationSettingsModalPage} from '@organisms';
import {StyledClickableCellWrapper, StyledIcon} from './styles';
import {useEffect} from 'react';

export const EmailNotificationCell = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {setModalPages, openModal} = useModal();

  const {status} = useAppSelector(getNotificationCategoryData);

  useEffect(() => {
    if (status === 'NotLoaded') dispatch(loadNotificationCategories());
  }, [dispatch, status]);

  const openSettings = () => {
    setModalPages([<NotificationSettingsModalPage />]);
    openModal();
  };

  return (
    <ProfileCell>
      <StyledClickableCellWrapper
        data-testid="profilepage_notification-settings-modalpage-button"
        onClick={openSettings}>
        <TwoColumnCell
          padding="small"
          left={<P>{t('profile:Notifications')}</P>}
          right={
            <StyledIcon
              icon="caretRight"
              size={pxToRem(24)}
            />
          }
        />
      </StyledClickableCellWrapper>
    </ProfileCell>
  );
};
