import {FacilityResource, Resource} from '@lib/store/src/data/buildingResources';
import {ExploreState} from 'src/components/pages/HereAndNow/useExploreState';
import {Wrapper, StyledModalCellItemIcon} from './styles';
import {FlexCol, FlexRow, P} from '@quarks';
import {StyledTruncatedText} from '../HaNWorkspaceCell/styles';
import {useRoomImage} from '@hooks';

type Props = {
  resource: Resource;
  exploreState: ExploreState;
  onClick: (facility: FacilityResource) => void;
};

export const HaNExploreListItem = ({resource, exploreState, onClick}: Props) => {
  const {selectedResource, setSelectedResource, setSpotlightedResource} = exploreState;
  const {buildingId, data, type, name} = resource;

  const roomHash = type === 'room' && data?.roomPictureHash ? data.roomPictureHash : undefined;
  const imageURL = useRoomImage(name, roomHash, buildingId, Math.round(48 * window.devicePixelRatio * 4), false);

  const handleClick = () => {
    setSelectedResource(selectedResource === resource ? null : resource);
    if (selectedResource !== resource && resource.type === 'facility') {
      onClick(resource);
    }
  };

  return (
    <Wrapper
      $selected={selectedResource?.id === resource.id}
      key={resource.id}
      tabIndex={1}
      onClick={() => handleClick()}
      onKeyDown={(e) => {
        if (e.key === 'Enter') () => handleClick();
      }}
      onMouseEnter={() => setSpotlightedResource(resource)}
      onMouseLeave={() => setSpotlightedResource(null)}>
      <FlexRow gap={8}>
        <StyledModalCellItemIcon
          icon={resource.type === 'area' ? 'desk' : 'meetingRoom'}
          iconUrl={resource.type === 'facility' ? resource.data?.facilityType.lightIconUrl : undefined}
          imageURL={resource.type === 'room' ? imageURL : undefined}
        />
        <FlexCol
          justifyContent="center"
          gap={2}>
          <StyledTruncatedText fontWeight="bold">{resource.name}</StyledTruncatedText>
          <P>{resource.floorName}</P>
        </FlexCol>
      </FlexRow>
    </Wrapper>
  );
};
