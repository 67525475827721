import {pxToRem} from '@utils';
import styled from 'styled-components';
import {baseStyle} from '../styles';

export const H4 = styled.h4`
  ${baseStyle}

  color: ${({color, theme}) => color || theme.text.color.heading};
  font-size: ${pxToRem(15)};
  font-weight: ${({theme}) => theme.text.weight.bold};
  line-height: ${pxToRem(20)};
`;
