import {generateRandomId} from '@utils';

export const illustrations = {
  calendar: () => {
    const randomId = generateRandomId();
    return (
      <>
        <path
          d="M10.8696 22.3659V88.5732C10.8696 91.5688 8.43478 94.0001 5.43478 94.0001H94.5652C97.5652 94.0001 100 91.5688 100 88.5732V22.3659H10.8696Z"
          fill={`url(#calendar-${randomId}-1)`}
        />
        <path
          d="M89.1304 88.5734V85.3173H0V88.5734C0 91.569 2.43478 94.0002 5.43478 94.0002H94.5652C91.5652 94.0002 89.1304 91.569 89.1304 88.5734Z"
          fill={`url(#calendar-${randomId}-2)`}
        />
        <path
          d="M10.8696 5V20.1951H100V5H10.8696ZM46.7391 14.7683C45.5391 14.7683 44.5652 13.7958 44.5652 12.5976C44.5652 11.3993 45.5391 10.4268 46.7391 10.4268C47.9391 10.4268 48.913 11.3993 48.913 12.5976C48.913 13.7958 47.9391 14.7683 46.7391 14.7683ZM55.4348 14.7683C54.2348 14.7683 53.2609 13.7958 53.2609 12.5976C53.2609 11.3993 54.2348 10.4268 55.4348 10.4268C56.6348 10.4268 57.6087 11.3993 57.6087 12.5976C57.6087 13.7958 56.6348 14.7683 55.4348 14.7683ZM64.1304 14.7683C62.9304 14.7683 61.9565 13.7958 61.9565 12.5976C61.9565 11.3993 62.9304 10.4268 64.1304 10.4268C65.3304 10.4268 66.3043 11.3993 66.3043 12.5976C66.3043 13.7958 65.3304 14.7683 64.1304 14.7683Z"
          fill={`url(#calendar-${randomId}-3)`}
        />
        <g opacity="0.7">
          <path
            d="M82.6086 37.9951H28.2608C28.0217 37.9951 27.826 37.7997 27.826 37.561C27.826 37.3222 28.0217 37.1268 28.2608 37.1268H82.6086C82.8478 37.1268 83.0434 37.3222 83.0434 37.561C83.0434 37.7997 82.8478 37.9951 82.6086 37.9951Z"
            fill="#037056"
          />
        </g>
        <g opacity="0.7">
          <path
            d="M82.6086 48.8487H28.2608C28.0217 48.8487 27.826 48.6534 27.826 48.4146C27.826 48.1758 28.0217 47.9805 28.2608 47.9805H82.6086C82.8478 47.9805 83.0434 48.1758 83.0434 48.4146C83.0434 48.6534 82.8478 48.8487 82.6086 48.8487Z"
            fill="#037056"
          />
        </g>
        <g opacity="0.7">
          <path
            d="M82.6086 59.7024H28.2608C28.0217 59.7024 27.826 59.507 27.826 59.2682C27.826 59.0295 28.0217 58.8341 28.2608 58.8341H82.6086C82.8478 58.8341 83.0434 59.0295 83.0434 59.2682C83.0434 59.507 82.8478 59.7024 82.6086 59.7024Z"
            fill="#037056"
          />
        </g>
        <g opacity="0.7">
          <path
            d="M82.6086 70.556H28.2608C28.0217 70.556 27.826 70.3606 27.826 70.1219C27.826 69.8831 28.0217 69.6877 28.2608 69.6877H82.6086C82.8478 69.6877 83.0434 69.8831 83.0434 70.1219C83.0434 70.3606 82.8478 70.556 82.6086 70.556Z"
            fill="#037056"
          />
        </g>
        <g opacity="0.7">
          <path
            d="M71.7391 70.5561C71.5 70.5561 71.3043 70.3607 71.3043 70.1219V37.561C71.3043 37.3222 71.5 37.1268 71.7391 37.1268C71.9782 37.1268 72.1739 37.3222 72.1739 37.561V70.1219C72.1739 70.3607 71.9782 70.5561 71.7391 70.5561Z"
            fill="#037056"
          />
        </g>
        <g opacity="0.7">
          <path
            d="M82.6086 70.5561C82.3695 70.5561 82.1739 70.3607 82.1739 70.1219V37.561C82.1739 37.3222 82.3695 37.1268 82.6086 37.1268C82.8478 37.1268 83.0434 37.3222 83.0434 37.561V70.1219C83.0434 70.3607 82.8478 70.5561 82.6086 70.5561Z"
            fill="#037056"
          />
        </g>
        <g opacity="0.7">
          <path
            d="M60.8695 70.5561C60.6304 70.5561 60.4347 70.3607 60.4347 70.1219V37.561C60.4347 37.3222 60.6304 37.1268 60.8695 37.1268C61.1086 37.1268 61.3043 37.3222 61.3043 37.561V70.1219C61.3043 70.3607 61.1086 70.5561 60.8695 70.5561Z"
            fill="#037056"
          />
        </g>
        <g opacity="0.7">
          <path
            d="M49.9999 70.5561C49.7608 70.5561 49.5652 70.3607 49.5652 70.1219V37.561C49.5652 37.3222 49.7608 37.1268 49.9999 37.1268C50.2391 37.1268 50.4347 37.3222 50.4347 37.561V70.1219C50.4347 70.3607 50.2391 70.5561 49.9999 70.5561Z"
            fill="#037056"
          />
        </g>
        <g opacity="0.7">
          <path
            d="M39.1304 70.5561C38.8912 70.5561 38.6956 70.3607 38.6956 70.1219V37.561C38.6956 37.3222 38.8912 37.1268 39.1304 37.1268C39.3695 37.1268 39.5652 37.3222 39.5652 37.561V70.1219C39.5652 70.3607 39.3695 70.5561 39.1304 70.5561Z"
            fill="#037056"
          />
        </g>
        <g opacity="0.7">
          <path
            d="M28.2608 70.5561C28.0217 70.5561 27.826 70.3607 27.826 70.1219V37.561C27.826 37.3222 28.0217 37.1268 28.2608 37.1268C28.4999 37.1268 28.6956 37.3222 28.6956 37.561V70.1219C28.6956 70.3607 28.4999 70.5561 28.2608 70.5561Z"
            fill="#037056"
          />
        </g>
        <defs>
          <linearGradient
            id={`calendar-${randomId}-1`}
            x1="16.8111"
            y1="87.5722"
            x2="101.818"
            y2="13.3623"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.0001"
              stopColor="#037056"
            />
            <stop
              offset="0.208333"
              stopColor="#A6C8BB"
              stopOpacity="0.867813"
            />
            <stop
              offset="0.296875"
              stopColor="#CDE2DD"
            />
            <stop
              offset="0.453125"
              stopColor="#E7F1EF"
            />
            <stop
              offset="0.645833"
              stopColor="#CDE2DD"
            />
            <stop
              offset="0.729167"
              stopColor="#B9D2C7"
              stopOpacity="0.865553"
            />
            <stop
              offset="0.973958"
              stopColor="#037056"
            />
          </linearGradient>
          <linearGradient
            id={`calendar-${randomId}-2`}
            x1="47.4667"
            y1="98.9444"
            x2="47.4667"
            y2="85.1"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`calendar-${randomId}-3`}
            x1="81.0889"
            y1="5"
            x2="10.8765"
            y2="25.7624"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.53125"
              stopColor="#C2D7CC"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
        </defs>
      </>
    );
  },
  car: () => {
    const randomId = generateRandomId();
    return (
      <>
        <path
          d="M0 55.4902C0 60.8088 3.19119 60.8088 3.19119 60.8088L8.34114 60.8932C8.34114 60.8932 9.37207 60.8932 19.2127 60.8932C28.1162 60.8932 29.4892 60.8932 29.4892 60.8932L67.9803 60.8088C67.9803 60.8088 64.9063 60.8088 78.4911 60.8088C92.0759 60.8088 90.8529 60.8088 90.8529 60.8088H98.7254C98.7254 60.8088 100 60.9166 100 55.8088C100 50.701 97.5539 50.8088 97.5539 50.8088C97.5539 50.8088 100.211 40.5979 74.1471 40.5979L61.8088 30.8088C61.8088 30.8088 64.2549 29 59.5736 29H11.1715C11.1715 29 7.98032 29.956 11.1715 31.1275L2.76476 41.1275V50.0637C2.76476 50.0637 0 49.6186 0 55.4902ZM90.2484 44.0422C90.2484 44.0422 95.9794 45.7104 96.3683 50.94C96.3683 50.94 89.5783 52.552 90.2484 44.0422ZM55.8529 40.7057L50.8529 31.2352H59.8969L70.4311 40.7057H55.8575H55.8529ZM14.5736 40.7057L18.299 31.2352H47.8725L52.6617 40.7057H14.5736ZM5.42643 40.7057L12.9803 31.2352H15.9606L11.7057 40.7057H5.42643Z"
          fill={`url(#car-${randomId}-1)`}
        />
        <path
          d="M11.7151 40.7151H5.38895L13.1209 31.1087H15.9325L11.7151 40.7151Z"
          fill={`url(#car-${randomId}-2)`}
        />
        <path
          d="M59.9813 31.1087H50.8435L55.7638 40.7151H70.5249L59.9813 31.1087Z"
          fill={`url(#car-${randomId}-3)`}
        />
        <path
          d="M52.7179 40.7151H14.5267L18.2756 31.1087H47.7976L52.7179 40.7151Z"
          fill={`url(#car-${randomId}-4)`}
        />
        <ellipse
          cx="18.9784"
          cy="59.2249"
          rx="11.0122"
          ry="11.0122"
          fill="white"
        />
        <ellipse
          cx="79.8969"
          cy="59.2249"
          rx="11.0122"
          ry="11.0122"
          fill="white"
        />
        <circle
          cx="18.9785"
          cy="59.2249"
          r="9.60637"
          fill={`url(#car-${randomId}-5)`}
        />
        <circle
          cx="18.9745"
          cy="59.2395"
          r="6.31631"
          transform="rotate(105 18.9745 59.2395)"
          fill={`url(#car-${randomId}-6)`}
        />
        <circle
          cx="79.8969"
          cy="59.2249"
          r="9.60637"
          fill={`url(#car-${randomId}-7)`}
        />
        <circle
          cx="79.893"
          cy="59.2395"
          r="6.31631"
          transform="rotate(105 79.893 59.2395)"
          fill={`url(#car-${randomId}-8)`}
        />
        <defs>
          <linearGradient
            id={`car-${randomId}-1`}
            x1="76.7241"
            y1="61.8695"
            x2="77.3891"
            y2="27.2502"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#BA3E1C" />
            <stop
              offset="0.263019"
              stopColor="#E3B2A4"
            />
            <stop
              offset="0.5"
              stopColor="#FBF3F1"
            />
            <stop
              offset="0.661458"
              stopColor="#D68B77"
            />
            <stop
              offset="0.958333"
              stopColor="#BA3E1C"
            />
          </linearGradient>
          <linearGradient
            id={`car-${randomId}-2`}
            x1="16.8697"
            y1="40.7151"
            x2="14.0502"
            y2="30.6099"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#F1D8D2"
            />
            <stop
              offset="0.619792"
              stopColor="#E3B2A4"
            />
            <stop
              offset="1"
              stopColor="#BA3E1C"
            />
          </linearGradient>
          <linearGradient
            id={`car-${randomId}-3`}
            x1="70.5249"
            y1="40.7151"
            x2="65.9512"
            y2="26.0169"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#F1D8D2"
            />
            <stop
              offset="0.619792"
              stopColor="#E3B2A4"
            />
            <stop
              offset="1"
              stopColor="#BA3E1C"
            />
          </linearGradient>
          <linearGradient
            id={`car-${randomId}-4`}
            x1="51.3121"
            y1="40.7151"
            x2="49.6033"
            y2="26.3632"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#F1D8D2"
            />
            <stop
              offset="0.619792"
              stopColor="#E3B2A4"
            />
            <stop
              offset="1"
              stopColor="#BA3E1C"
            />
          </linearGradient>
          <radialGradient
            id={`car-${randomId}-5`}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(19.2128 58.9906) rotate(92.7263) scale(9.85183)">
            <stop
              offset="0.473958"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.59375"
              stopColor="#F1D8D2"
            />
            <stop
              offset="0.723958"
              stopColor="#E3B2A4"
            />
            <stop
              offset="0.796875"
              stopColor="#D68B77"
            />
            <stop
              offset="1"
              stopColor="#BA3E1C"
            />
          </radialGradient>
          <radialGradient
            id={`car-${randomId}-6`}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(19.1286 59.0855) rotate(92.776) scale(6.75769)">
            <stop
              offset="0.473958"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.671875"
              stopColor="#F1D8D2"
            />
            <stop
              offset="0.723958"
              stopColor="#F1D8D2"
            />
            <stop
              offset="0.796875"
              stopColor="#E3B2A4"
            />
            <stop
              offset="1"
              stopColor="#BA3E1C"
            />
          </radialGradient>
          <radialGradient
            id={`car-${randomId}-7`}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(80.1312 58.9906) rotate(92.7263) scale(9.85183)">
            <stop
              offset="0.473958"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.59375"
              stopColor="#F1D8D2"
            />
            <stop
              offset="0.723958"
              stopColor="#E3B2A4"
            />
            <stop
              offset="0.796875"
              stopColor="#D68B77"
            />
            <stop
              offset="1"
              stopColor="#BA3E1C"
            />
          </radialGradient>
          <radialGradient
            id={`car-${randomId}-8`}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(80.0471 59.0855) rotate(92.776) scale(6.75769)">
            <stop
              offset="0.473958"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.671875"
              stopColor="#F1D8D2"
            />
            <stop
              offset="0.723958"
              stopColor="#F1D8D2"
            />
            <stop
              offset="0.796875"
              stopColor="#E3B2A4"
            />
            <stop
              offset="1"
              stopColor="#BA3E1C"
            />
          </radialGradient>
        </defs>
      </>
    );
  },
  connections: () => {
    const randomId = generateRandomId();
    return (
      <>
        <path
          d="M0 80.372H16.6667L29.1667 90.8537V80.372H39.5833V40.5415H0V80.372Z"
          fill={`url(#connections-${randomId}-1)`}
        />
        <path
          d="M83.3333 48.9268L91.6667 53.1195V56.264C91.6667 57.9998 90.2667 59.4085 88.5417 59.4085H71.875C70.15 59.4085 68.75 57.9998 68.75 56.264V53.1195L77.0833 48.9268H83.3333Z"
          fill={`url(#connections-${randomId}-2)`}
        />
        <path
          d="M80.2083 51.0232C77.3333 51.0232 75 48.6753 75 45.7823V42.6378C75 40.3234 76.8667 38.4451 79.1667 38.4451H81.25C83.55 38.4451 85.4167 40.3234 85.4167 42.6378V45.7823C85.4167 48.6753 83.0833 51.0232 80.2083 51.0232Z"
          fill={`url(#connections-${randomId}-3)`}
        />
        <path
          d="M22.9167 59.4086L31.25 63.6012V66.7458C31.25 68.4815 29.85 69.8903 28.125 69.8903H11.4583C9.73333 69.8903 8.33333 68.4815 8.33333 66.7458V63.6012L16.6667 59.4086H22.9167Z"
          fill={`url(#connections-${randomId}-4)`}
        />
        <path
          d="M19.7917 61.5049C16.9167 61.5049 14.5833 59.157 14.5833 56.2641V53.1195C14.5833 50.8052 16.45 48.9269 18.75 48.9269H20.8333C23.1333 48.9269 25 50.8052 25 53.1195V56.2641C25 59.157 22.6667 61.5049 19.7917 61.5049Z"
          fill={`url(#connections-${randomId}-5)`}
        />
        <path
          d="M100.256 69.5828H83.5894L71.0894 80.0645V69.5828H60.6728V29.7523H100.256V69.5828Z"
          fill={`url(#connections-${randomId}-6)`}
        />
        <path
          d="M77.3395 48.6194L69.0061 52.8121V55.9566C69.0061 57.6923 70.4061 59.1011 72.1311 59.1011H88.7978C90.5228 59.1011 91.9228 57.6923 91.9228 55.9566V52.8121L83.5895 48.6194H77.3395Z"
          fill={`url(#connections-${randomId}-7)`}
        />
        <path
          d="M80.4645 50.7157C83.3395 50.7157 85.6728 48.3678 85.6728 45.4749V42.3303C85.6728 40.016 83.8061 38.1377 81.5061 38.1377H79.4228C77.1228 38.1377 75.2561 40.016 75.2561 42.3303V45.4749C75.2561 48.3678 77.5895 50.7157 80.4645 50.7157Z"
          fill={`url(#connections-${randomId}-8)`}
        />
        <path
          d="M26.5443 46.8305H43.211H51.1927H55.711H66.1277V7H26.5443V46.8305Z"
          fill={`url(#connections-${randomId}-9)`}
          fillOpacity="0.6"
        />
        <path
          d="M49.461 25.8671L57.7943 30.0598V33.2043C57.7943 34.94 56.3943 36.3488 54.6693 36.3488H38.0027C36.2777 36.3488 34.8777 34.94 34.8777 33.2043V30.0598L43.211 25.8671H49.461Z"
          fill={`url(#connections-${randomId}-10)`}
        />
        <path
          d="M46.336 27.9634C43.461 27.9634 41.1277 25.6155 41.1277 22.7226V19.5781C41.1277 17.2637 42.9943 15.3854 45.2943 15.3854H47.3777C49.6777 15.3854 51.5443 17.2637 51.5443 19.5781V22.7226C51.5443 25.6155 49.211 27.9634 46.336 27.9634Z"
          fill={`url(#connections-${randomId}-11)`}
        />
        <defs>
          <linearGradient
            id={`connections-${randomId}-1`}
            x1="15.6422"
            y1="88.529"
            x2="2.64482"
            y2="42.9461"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F193C6" />
            <stop
              offset="0.291667"
              stopColor="#F7D0DF"
            />
            <stop
              offset="0.510417"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.645833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.854167"
              stopColor="#F7D0DF"
            />
            <stop
              offset="1"
              stopColor="#F193C6"
            />
          </linearGradient>
          <linearGradient
            id={`connections-${randomId}-2`}
            x1="77.4458"
            y1="46.4196"
            x2="82.7973"
            y2="62.7179"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F193C6" />
            <stop
              offset="0.697917"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`connections-${randomId}-3`}
            x1="69.7917"
            y1="35.091"
            x2="86.9442"
            y2="49.7269"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F193C6" />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`connections-${randomId}-4`}
            x1="20.8563"
            y1="55.8225"
            x2="12.3852"
            y2="72.4438"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="0.723958"
              stopColor="#F193C6"
            />
          </linearGradient>
          <linearGradient
            id={`connections-${randomId}-5`}
            x1="25.5963"
            y1="46.3425"
            x2="15.7331"
            y2="61.0975"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="1"
              stopColor="#F193C6"
            />
          </linearGradient>
          <linearGradient
            id={`connections-${randomId}-6`}
            x1="84.6139"
            y1="77.7399"
            x2="97.6113"
            y2="32.1569"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F193C6" />
            <stop
              offset="0.291667"
              stopColor="#F7D0DF"
            />
            <stop
              offset="0.510417"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.645833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.854167"
              stopColor="#F7D0DF"
            />
            <stop
              offset="1"
              stopColor="#F193C6"
            />
          </linearGradient>
          <linearGradient
            id={`connections-${randomId}-7`}
            x1="79.3998"
            y1="45.0333"
            x2="87.8709"
            y2="61.6546"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="0.723958"
              stopColor="#F193C6"
            />
          </linearGradient>
          <linearGradient
            id={`connections-${randomId}-8`}
            x1="74.6598"
            y1="35.5533"
            x2="84.523"
            y2="50.3083"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="1"
              stopColor="#F193C6"
            />
          </linearGradient>
          <linearGradient
            id={`connections-${randomId}-9`}
            x1="42.1865"
            y1="54.9876"
            x2="29.1892"
            y2="9.40462"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F193C6" />
            <stop
              offset="0.291667"
              stopColor="#F7D0DF"
            />
            <stop
              offset="0.510417"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.645833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.854167"
              stopColor="#F7D0DF"
            />
            <stop
              offset="1"
              stopColor="#F193C6"
            />
          </linearGradient>
          <linearGradient
            id={`connections-${randomId}-10`}
            x1="47.4006"
            y1="22.281"
            x2="38.9295"
            y2="38.9023"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="0.723958"
              stopColor="#F193C6"
            />
          </linearGradient>
          <linearGradient
            id={`connections-${randomId}-11`}
            x1="52.1407"
            y1="12.801"
            x2="42.2774"
            y2="27.556"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="1"
              stopColor="#F193C6"
            />
          </linearGradient>
        </defs>
      </>
    );
  },
  error: () => {
    const randomId = generateRandomId();
    return (
      <>
        <path
          d="M18.2799 57.1699C26.1665 57.1699 32.5598 50.771 32.5598 42.8774C32.5598 34.9839 26.1665 28.585 18.2799 28.585C10.3933 28.585 4 34.9839 4 42.8774C4 50.771 10.3933 57.1699 18.2799 57.1699Z"
          fill={`url(#error-${randomId}-1)`}
        />
        <path
          d="M76.3516 19.0566C75.3463 19.0566 74.3715 19.1557 73.408 19.3082C70.2131 8.16005 59.9583 0 47.7918 0C33.0701 0 21.1359 11.9447 21.1359 26.6793C21.1359 27.4263 21.174 28.1619 21.2349 28.8937C20.2829 28.6917 19.2928 28.585 18.2799 28.585C10.3936 28.585 4 34.9842 4 42.8774C4 50.7707 10.3936 57.1699 18.2799 57.1699H76.3516C86.8654 57.1699 95.3915 48.6364 95.3915 38.1133C95.3915 27.5902 86.8654 19.0566 76.3516 19.0566Z"
          fill={`url(#error-${randomId}-2)`}
        />
        <path
          d="M47.7918 53.3586C62.5134 53.3586 74.4476 41.4139 74.4476 26.6793C74.4476 11.9447 62.5134 0 47.7918 0C33.0701 0 21.1359 11.9447 21.1359 26.6793C21.1359 41.4139 33.0701 53.3586 47.7918 53.3586Z"
          fill={`url(#error-${randomId}-3)`}
        />
        <path
          d="M76.3516 57.1699C86.867 57.1699 95.3915 48.638 95.3915 38.1133C95.3915 27.5886 86.867 19.0566 76.3516 19.0566C65.8362 19.0566 57.3117 27.5886 57.3117 38.1133C57.3117 48.638 65.8362 57.1699 76.3516 57.1699Z"
          fill={`url(#error-${randomId}-4)`}
        />
        <path
          d="M39.4167 83.3333C37.6917 83.3333 36.2917 81.2728 36.2917 78.7281V58.3333H42.5417V78.7281C42.5417 81.2728 41.1417 83.3333 39.4167 83.3333Z"
          fill={`url(#error-${randomId}-5)`}
        />
        <path
          d="M50 96.875C48.344 96.875 47 93.6984 47 89.7752V58.3333H53V89.7752C53 93.6984 51.656 96.875 50 96.875Z"
          fill={`url(#error-${randomId}-6)`}
        />
        <path
          d="M60.25 78.125C58.525 78.125 57.125 76.4937 57.125 74.4792V58.3333H63.375V74.4792C63.375 76.4937 61.975 78.125 60.25 78.125Z"
          fill={`url(#error-${randomId}-7)`}
        />
        <ellipse
          cx="39.4167"
          cy="81.25"
          rx="5.20833"
          ry="5.20833"
          fill={`url(#error-${randomId}-8)`}
          fillOpacity="0.6"
        />
        <ellipse
          cx="49.8333"
          cy="94.7917"
          rx="5.20833"
          ry="5.20833"
          fill={`url(#error-${randomId}-9)`}
          fillOpacity="0.6"
        />
        <ellipse
          cx="60.25"
          cy="73.9583"
          rx="5.20833"
          ry="5.20833"
          fill={`url(#error-${randomId}-10)`}
          fillOpacity="0.6"
        />
        <defs>
          <linearGradient
            id={`error-${randomId}-1`}
            x1="24.0757"
            y1="40.9146"
            x2="-65.3671"
            y2="71.1669"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#BA3E1C" />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`error-${randomId}-2`}
            x1="49.6958"
            y1="60.4363"
            x2="49.5556"
            y2="31.6667"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#5B4C69" />
            <stop
              offset="0.260417"
              stopColor="#9A8F9E"
            />
            <stop
              offset="0.520833"
              stopColor="#CDC5C9"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`error-${randomId}-3`}
            x1="70.0869"
            y1="10.0727"
            x2="31.8054"
            y2="46.6955"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#5B4C69" />
            <stop
              offset="0.270833"
              stopColor="#9C92A0"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`error-${randomId}-4`}
            x1="94.9001"
            y1="34.6403"
            x2="59.5513"
            y2="41.6458"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#5B4C69" />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`error-${randomId}-5`}
            x1="39.4167"
            y1="58.3333"
            x2="50.0921"
            y2="77.3361"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#5B4C69" />
            <stop
              offset="0.291667"
              stopColor="#BCB3BB"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`error-${randomId}-6`}
            x1="50"
            y1="58.3333"
            x2="69.1691"
            y2="79.5812"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#5B4C69" />
            <stop
              offset="0.291667"
              stopColor="#BCB3BB"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`error-${randomId}-7`}
            x1="60.25"
            y1="58.3333"
            x2="67.5987"
            y2="74.8567"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#5B4C69" />
            <stop
              offset="0.291667"
              stopColor="#BCB3BB"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <radialGradient
            id={`error-${randomId}-8`}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(42.1341 83.9674) rotate(-138.013) scale(9.13969)">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="1"
              stopColor="#5B4C69"
            />
          </radialGradient>
          <radialGradient
            id={`error-${randomId}-9`}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(52.5507 97.5091) rotate(-138.013) scale(9.13969)">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="1"
              stopColor="#5B4C69"
            />
          </radialGradient>
          <radialGradient
            id={`error-${randomId}-10`}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(62.9674 76.6757) rotate(-138.013) scale(9.13969)">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="1"
              stopColor="#5B4C69"
            />
          </radialGradient>
        </defs>
      </>
    );
  },
  floors: () => {
    const randomId = generateRandomId();
    return (
      <>
        <g clipPath={`floors-${randomId}-clip-0`}>
          <mask
            id={`floors-${randomId}-mask-0`}
            style={{
              maskType: 'luminance',
            }}
            maskUnits="userSpaceOnUse"
            x="34"
            y="50"
            width="32"
            height="50">
            <path
              d="M50 59.375L34.375 50V100H65.625V50L50 59.375Z"
              fill="white"
            />
          </mask>
          <g mask={`url(#floors-${randomId}-mask-0)`}>
            <path
              d="M39.5833 93.75C39.5149 93.75 39.4472 93.7366 39.384 93.7104C39.3208 93.6843 39.2633 93.6459 39.215 93.5975C39.1666 93.5492 39.1282 93.4917 39.1021 93.4285C39.0759 93.3653 39.0625 93.2976 39.0625 93.2292V14.5833C39.0625 14.4452 39.1174 14.3127 39.215 14.215C39.3127 14.1174 39.4452 14.0625 39.5833 14.0625C39.7215 14.0625 39.8539 14.1174 39.9516 14.215C40.0493 14.3127 40.1042 14.4452 40.1042 14.5833V93.2292C40.1042 93.2976 40.0908 93.3653 40.0646 93.4285C40.0384 93.4917 40.0001 93.5492 39.9517 93.5975C39.9033 93.6459 39.8459 93.6843 39.7827 93.7104C39.7195 93.7366 39.6517 93.75 39.5833 93.75Z"
              fill={`url(#floors-${randomId}-0)`}
            />
            <path
              d="M60.4166 93.75C60.3482 93.75 60.2805 93.7366 60.2173 93.7104C60.1541 93.6843 60.0966 93.6459 60.0483 93.5975C59.9999 93.5492 59.9615 93.4917 59.9354 93.4285C59.9092 93.3653 59.8958 93.2976 59.8958 93.2292V14.5833C59.8958 14.4452 59.9507 14.3127 60.0484 14.215C60.146 14.1174 60.2785 14.0625 60.4166 14.0625C60.5548 14.0625 60.6873 14.1174 60.7849 14.215C60.8826 14.3127 60.9375 14.4452 60.9375 14.5833V93.2292C60.9375 93.2976 60.9241 93.3653 60.8979 93.4285C60.8718 93.4917 60.8334 93.5492 60.785 93.5975C60.7366 93.6459 60.6792 93.6843 60.616 93.7104C60.5528 93.7366 60.4851 93.75 60.4166 93.75Z"
              fill={`url(#floors-${randomId}-1)`}
            />
            <path
              d="M50 100C49.9316 100 49.8639 99.9866 49.8007 99.9604C49.7375 99.9343 49.68 99.8959 49.6316 99.8475C49.5833 99.7992 49.5449 99.7417 49.5188 99.6785C49.4926 99.6153 49.4791 99.5476 49.4792 99.4792V20.8333C49.4792 20.6952 49.5341 20.5627 49.6317 20.465C49.7294 20.3674 49.8619 20.3125 50 20.3125C50.1382 20.3125 50.2706 20.3674 50.3683 20.465C50.466 20.5627 50.5209 20.6952 50.5209 20.8333V99.4792C50.5209 99.5476 50.5074 99.6153 50.4813 99.6785C50.4551 99.7417 50.4168 99.7992 50.3684 99.8475C50.32 99.8959 50.2626 99.9343 50.1994 99.9604C50.1362 99.9866 50.0684 100 50 100Z"
              fill={`url(#floors-${randomId}-2)`}
            />
          </g>
          <path
            d="M50 59.375L8.77187 34.8958L0 40.1042L50 69.7917L100 40.1042L91.2281 34.8958L50 59.375Z"
            fill={`url(#floors-${randomId}-3)`}
          />
          <path
            d="M50 69.7917L8.77187 45.3125L0 50.5208L50 80.2083L100 50.5208L91.2281 45.3125L50 69.7917Z"
            fill={`url(#floors-${randomId}-4)`}
          />
          <path
            d="M91.2281 45.3125L82.4562 40.1042L50 59.375L17.5437 40.1042L8.77185 45.3125L50 69.7917L91.2281 45.3125Z"
            fill={`url(#floors-${randomId}-5)`}
          />
          <path
            d="M50 80.2083L8.77187 55.7292L0 60.9375L50 90.625L100 60.9375L91.2281 55.7292L50 80.2083Z"
            fill={`url(#floors-${randomId}-6)`}
          />
          <path
            d="M91.2281 55.7292L82.4562 50.5208L50 69.7917L17.5437 50.5208L8.77185 55.7292L50 80.2083L91.2281 55.7292Z"
            fill={`url(#floors-${randomId}-7)`}
          />
          <path
            d="M50 69.7917L82.4563 50.5208L73.6844 45.3125L50 59.375L26.3156 45.3125L17.5438 50.5208L50 69.7917Z"
            fill={`url(#floors-${randomId}-8)`}
          />
          <path
            d="M60.4167 14.0625C60.4851 14.0625 60.5528 14.0759 60.616 14.1021C60.6792 14.1282 60.7367 14.1666 60.785 14.215C60.8334 14.2633 60.8718 14.3208 60.8979 14.384C60.9241 14.4472 60.9375 14.5149 60.9375 14.5833V52.8809L100 29.6875L50 0L0 29.6875L39.0625 52.8809V14.5833C39.0625 14.4452 39.1174 14.3127 39.215 14.215C39.3127 14.1174 39.4452 14.0625 39.5833 14.0625C39.7215 14.0625 39.8539 14.1174 39.9516 14.215C40.0493 14.3127 40.1042 14.4452 40.1042 14.5833V53.4994L49.4792 59.0658V20.8333C49.4792 20.6952 49.534 20.5627 49.6317 20.465C49.7294 20.3674 49.8619 20.3125 50 20.3125C50.1381 20.3125 50.2706 20.3674 50.3683 20.465C50.466 20.5627 50.5208 20.6952 50.5208 20.8333V59.0658L59.8958 53.4994V14.5833C59.8958 14.5149 59.9092 14.4472 59.9354 14.384C59.9616 14.3208 59.9999 14.2633 60.0483 14.215C60.0967 14.1666 60.1541 14.1282 60.2173 14.1021C60.2805 14.0759 60.3483 14.0625 60.4167 14.0625Z"
            fill={`url(#floors-${randomId}-9)`}
          />
          <path
            d="M50 10.4167L40.1042 16.2923V53.4994L49.4792 59.0658V20.8333C49.4792 20.6952 49.5341 20.5627 49.6317 20.465C49.7294 20.3674 49.8619 20.3125 50 20.3125C50.1382 20.3125 50.2706 20.3674 50.3683 20.465C50.466 20.5627 50.5209 20.6952 50.5209 20.8333V59.0658L59.8959 53.4994V16.2923L50 10.4167Z"
            fill={`url(#floors-${randomId}-10)`}
          />
          <path
            d="M91.2281 34.8958L60.9375 16.9104V52.8812L91.2281 34.8958Z"
            fill={`url(#floors-${randomId}-11)`}
          />
          <path
            d="M8.77185 34.8958L39.0625 52.8812V16.9104L8.77185 34.8958Z"
            fill={`url(#floors-${randomId}-12)`}
          />
          <path
            d="M60.4167 14.0625C60.4851 14.0625 60.5528 14.0759 60.616 14.1021C60.6792 14.1282 60.7367 14.1666 60.785 14.215C60.8334 14.2633 60.8718 14.3208 60.8979 14.384C60.9241 14.4472 60.9375 14.5149 60.9375 14.5833V52.8809L100 29.6875L50 0L0 29.6875L39.0625 52.8809V14.5833C39.0625 14.4452 39.1174 14.3127 39.215 14.215C39.3127 14.1174 39.4452 14.0625 39.5833 14.0625C39.7215 14.0625 39.8539 14.1174 39.9516 14.215C40.0493 14.3127 40.1042 14.4452 40.1042 14.5833V53.4994L49.4792 59.0658V20.8333C49.4792 20.6952 49.534 20.5627 49.6317 20.465C49.7294 20.3674 49.8619 20.3125 50 20.3125C50.1381 20.3125 50.2706 20.3674 50.3683 20.465C50.466 20.5627 50.5208 20.6952 50.5208 20.8333V59.0658L59.8958 53.4994V14.5833C59.8958 14.5149 59.9092 14.4472 59.9354 14.384C59.9616 14.3208 59.9999 14.2633 60.0483 14.215C60.0967 14.1666 60.1541 14.1282 60.2173 14.1021C60.2805 14.0759 60.3483 14.0625 60.4167 14.0625Z"
            fill={`url(#floors-${randomId}-13)`}
          />
        </g>
        <defs>
          <linearGradient
            id={`floors-${randomId}-0`}
            x1="39.5833"
            y1="93.75"
            x2="39.5833"
            y2="14.0625"
            gradientUnits="userSpaceOnUse">
            <stop
              stopColor="#1E4EDB"
              stopOpacity="0.05"
            />
            <stop
              offset="0.08815"
              stopColor="#1E4EDB"
              stopOpacity="0.0852"
            />
            <stop
              offset="0.23201"
              stopColor="#1E4EDB"
              stopOpacity="0.18085"
            />
            <stop
              offset="0.4137"
              stopColor="#1E4EDB"
              stopOpacity="0.33682"
            />
            <stop
              offset="0.62613"
              stopColor="#1E4EDB"
              stopOpacity="0.55328"
            />
            <stop
              offset="0.8616"
              stopColor="#1E4EDB"
              stopOpacity="0.82613"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-1`}
            x1="60.4166"
            y1="-3.49246e-07"
            x2="60.4166"
            y2="-3.49246e-07"
            gradientUnits="userSpaceOnUse">
            <stop
              stopColor="#1E4EDB"
              stopOpacity="0.05"
            />
            <stop
              offset="0.08815"
              stopColor="#1E4EDB"
              stopOpacity="0.0852"
            />
            <stop
              offset="0.23201"
              stopColor="#1E4EDB"
              stopOpacity="0.18085"
            />
            <stop
              offset="0.4137"
              stopColor="#1E4EDB"
              stopOpacity="0.33682"
            />
            <stop
              offset="0.62613"
              stopColor="#1E4EDB"
              stopOpacity="0.55328"
            />
            <stop
              offset="0.8616"
              stopColor="#1E4EDB"
              stopOpacity="0.82613"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-2`}
            x1="50"
            y1="100"
            x2="50"
            y2="20.3125"
            gradientUnits="userSpaceOnUse">
            <stop
              stopColor="#1E4EDB"
              stopOpacity="0.05"
            />
            <stop
              offset="0.08815"
              stopColor="#1E4EDB"
              stopOpacity="0.0852"
            />
            <stop
              offset="0.23201"
              stopColor="#1E4EDB"
              stopOpacity="0.18085"
            />
            <stop
              offset="0.4137"
              stopColor="#1E4EDB"
              stopOpacity="0.33682"
            />
            <stop
              offset="0.62613"
              stopColor="#1E4EDB"
              stopOpacity="0.55328"
            />
            <stop
              offset="0.8616"
              stopColor="#1E4EDB"
              stopOpacity="0.82613"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-3`}
            x1="50"
            y1="37.3019"
            x2="50"
            y2="98.2328"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.05321"
              stopColor="#1E4EDB"
              stopOpacity="0.88086"
            />
            <stop
              offset="0.15491"
              stopColor="#1E4EDB"
              stopOpacity="0.67671"
            />
            <stop
              offset="0.2611"
              stopColor="#1E4EDB"
              stopOpacity="0.49556"
            />
            <stop
              offset="0.36952"
              stopColor="#1E4EDB"
              stopOpacity="0.34294"
            />
            <stop
              offset="0.4809"
              stopColor="#1E4EDB"
              stopOpacity="0.21845"
            />
            <stop
              offset="0.596"
              stopColor="#1E4EDB"
              stopOpacity="0.12211"
            />
            <stop
              offset="0.71653"
              stopColor="#1E4EDB"
              stopOpacity="0.05367"
            />
            <stop
              offset="0.84592"
              stopColor="#1E4EDB"
              stopOpacity="0.01305"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-4`}
            x1="50"
            y1="47.3862"
            x2="50"
            y2="104.595"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.10793"
              stopColor="#1E4EDB"
              stopOpacity="0.8019"
            />
            <stop
              offset="0.23768"
              stopColor="#1E4EDB"
              stopOpacity="0.59178"
            />
            <stop
              offset="0.36907"
              stopColor="#1E4EDB"
              stopOpacity="0.41053"
            />
            <stop
              offset="0.49934"
              stopColor="#1E4EDB"
              stopOpacity="0.26253"
            />
            <stop
              offset="0.62831"
              stopColor="#1E4EDB"
              stopOpacity="0.14761"
            />
            <stop
              offset="0.75557"
              stopColor="#1E4EDB"
              stopOpacity="0.06565"
            />
            <stop
              offset="0.8804"
              stopColor="#1E4EDB"
              stopOpacity="0.01649"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-5`}
            x1="50"
            y1="33.2375"
            x2="50"
            y2="101.698"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.00738"
              stopColor="#1E4EDB"
              stopOpacity="0.96381"
            />
            <stop
              offset="0.04768"
              stopColor="#1E4EDB"
              stopOpacity="0.78417"
            />
            <stop
              offset="0.09309"
              stopColor="#1E4EDB"
              stopOpacity="0.61488"
            />
            <stop
              offset="0.1419"
              stopColor="#1E4EDB"
              stopOpacity="0.46688"
            />
            <stop
              offset="0.19502"
              stopColor="#1E4EDB"
              stopOpacity="0.33967"
            />
            <stop
              offset="0.25374"
              stopColor="#1E4EDB"
              stopOpacity="0.23299"
            />
            <stop
              offset="0.32001"
              stopColor="#1E4EDB"
              stopOpacity="0.14667"
            />
            <stop
              offset="0.39742"
              stopColor="#1E4EDB"
              stopOpacity="0.08036"
            />
            <stop
              offset="0.49311"
              stopColor="#1E4EDB"
              stopOpacity="0.03399"
            />
            <stop
              offset="0.62736"
              stopColor="#1E4EDB"
              stopOpacity="0.01"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-6`}
            x1="50"
            y1="58.2758"
            x2="50"
            y2="117.325"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.14337"
              stopColor="#1E4EDB"
              stopOpacity="0.8132"
            />
            <stop
              offset="0.38023"
              stopColor="#1E4EDB"
              stopOpacity="0.5277"
            />
            <stop
              offset="0.59295"
              stopColor="#1E4EDB"
              stopOpacity="0.3009"
            />
            <stop
              offset="0.77356"
              stopColor="#1E4EDB"
              stopOpacity="0.13744"
            />
            <stop
              offset="0.91507"
              stopColor="#1E4EDB"
              stopOpacity="0.03707"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-7`}
            x1="50"
            y1="39.3341"
            x2="50"
            y2="107.795"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.04976"
              stopColor="#1E4EDB"
              stopOpacity="0.88961"
            />
            <stop
              offset="0.15304"
              stopColor="#1E4EDB"
              stopOpacity="0.6834"
            />
            <stop
              offset="0.26063"
              stopColor="#1E4EDB"
              stopOpacity="0.50058"
            />
            <stop
              offset="0.37033"
              stopColor="#1E4EDB"
              stopOpacity="0.34643"
            />
            <stop
              offset="0.48272"
              stopColor="#1E4EDB"
              stopOpacity="0.22077"
            />
            <stop
              offset="0.59863"
              stopColor="#1E4EDB"
              stopOpacity="0.12344"
            />
            <stop
              offset="0.71947"
              stopColor="#1E4EDB"
              stopOpacity="0.05431"
            />
            <stop
              offset="0.84852"
              stopColor="#1E4EDB"
              stopOpacity="0.01323"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-8`}
            x1="1.31175e-05"
            y1="26.4507"
            x2="50"
            y2="94.9113"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.00738"
              stopColor="#1E4EDB"
              stopOpacity="0.96381"
            />
            <stop
              offset="0.04768"
              stopColor="#1E4EDB"
              stopOpacity="0.78417"
            />
            <stop
              offset="0.09309"
              stopColor="#1E4EDB"
              stopOpacity="0.61488"
            />
            <stop
              offset="0.1419"
              stopColor="#1E4EDB"
              stopOpacity="0.46688"
            />
            <stop
              offset="0.19502"
              stopColor="#1E4EDB"
              stopOpacity="0.33967"
            />
            <stop
              offset="0.25374"
              stopColor="#1E4EDB"
              stopOpacity="0.23299"
            />
            <stop
              offset="0.32001"
              stopColor="#1E4EDB"
              stopOpacity="0.14667"
            />
            <stop
              offset="0.39742"
              stopColor="#1E4EDB"
              stopOpacity="0.08036"
            />
            <stop
              offset="0.49311"
              stopColor="#1E4EDB"
              stopOpacity="0.03399"
            />
            <stop
              offset="0.62736"
              stopColor="#1E4EDB"
              stopOpacity="0.01"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-9`}
            x1="61.8671"
            y1="8.44708"
            x2="30.1186"
            y2="65.2722"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.00738"
              stopColor="#1E4EDB"
              stopOpacity="0.96381"
            />
            <stop
              offset="0.04768"
              stopColor="#1E4EDB"
              stopOpacity="0.78417"
            />
            <stop
              offset="0.09309"
              stopColor="#1E4EDB"
              stopOpacity="0.61488"
            />
            <stop
              offset="0.1419"
              stopColor="#1E4EDB"
              stopOpacity="0.46688"
            />
            <stop
              offset="0.19502"
              stopColor="#1E4EDB"
              stopOpacity="0.33967"
            />
            <stop
              offset="0.25374"
              stopColor="#1E4EDB"
              stopOpacity="0.23299"
            />
            <stop
              offset="0.32001"
              stopColor="#1E4EDB"
              stopOpacity="0.14667"
            />
            <stop
              offset="0.39742"
              stopColor="#1E4EDB"
              stopOpacity="0.08036"
            />
            <stop
              offset="0.49311"
              stopColor="#1E4EDB"
              stopOpacity="0.03399"
            />
            <stop
              offset="0.62736"
              stopColor="#1E4EDB"
              stopOpacity="0.01"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-10`}
            x1="2.52813e-05"
            y1="12.0335"
            x2="50"
            y2="96.0267"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.00738"
              stopColor="#1E4EDB"
              stopOpacity="0.96381"
            />
            <stop
              offset="0.04768"
              stopColor="#1E4EDB"
              stopOpacity="0.78417"
            />
            <stop
              offset="0.09309"
              stopColor="#1E4EDB"
              stopOpacity="0.61488"
            />
            <stop
              offset="0.1419"
              stopColor="#1E4EDB"
              stopOpacity="0.46688"
            />
            <stop
              offset="0.19502"
              stopColor="#1E4EDB"
              stopOpacity="0.33967"
            />
            <stop
              offset="0.25374"
              stopColor="#1E4EDB"
              stopOpacity="0.23299"
            />
            <stop
              offset="0.32001"
              stopColor="#1E4EDB"
              stopOpacity="0.14667"
            />
            <stop
              offset="0.39742"
              stopColor="#1E4EDB"
              stopOpacity="0.08036"
            />
            <stop
              offset="0.49311"
              stopColor="#1E4EDB"
              stopOpacity="0.03399"
            />
            <stop
              offset="0.62736"
              stopColor="#1E4EDB"
              stopOpacity="0.01"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-11`}
            x1="76.0829"
            y1="12.0335"
            x2="76.0829"
            y2="96.0267"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.00738"
              stopColor="#1E4EDB"
              stopOpacity="0.96381"
            />
            <stop
              offset="0.04768"
              stopColor="#1E4EDB"
              stopOpacity="0.78417"
            />
            <stop
              offset="0.09309"
              stopColor="#1E4EDB"
              stopOpacity="0.61488"
            />
            <stop
              offset="0.1419"
              stopColor="#1E4EDB"
              stopOpacity="0.46688"
            />
            <stop
              offset="0.19502"
              stopColor="#1E4EDB"
              stopOpacity="0.33967"
            />
            <stop
              offset="0.25374"
              stopColor="#1E4EDB"
              stopOpacity="0.23299"
            />
            <stop
              offset="0.32001"
              stopColor="#1E4EDB"
              stopOpacity="0.14667"
            />
            <stop
              offset="0.39742"
              stopColor="#1E4EDB"
              stopOpacity="0.08036"
            />
            <stop
              offset="0.49311"
              stopColor="#1E4EDB"
              stopOpacity="0.03399"
            />
            <stop
              offset="0.62736"
              stopColor="#1E4EDB"
              stopOpacity="0.01"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-12`}
            x1="23.9171"
            y1="12.0335"
            x2="23.9171"
            y2="96.0267"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.00738"
              stopColor="#1E4EDB"
              stopOpacity="0.96381"
            />
            <stop
              offset="0.04768"
              stopColor="#1E4EDB"
              stopOpacity="0.78417"
            />
            <stop
              offset="0.09309"
              stopColor="#1E4EDB"
              stopOpacity="0.61488"
            />
            <stop
              offset="0.1419"
              stopColor="#1E4EDB"
              stopOpacity="0.46688"
            />
            <stop
              offset="0.19502"
              stopColor="#1E4EDB"
              stopOpacity="0.33967"
            />
            <stop
              offset="0.25374"
              stopColor="#1E4EDB"
              stopOpacity="0.23299"
            />
            <stop
              offset="0.32001"
              stopColor="#1E4EDB"
              stopOpacity="0.14667"
            />
            <stop
              offset="0.39742"
              stopColor="#1E4EDB"
              stopOpacity="0.08036"
            />
            <stop
              offset="0.49311"
              stopColor="#1E4EDB"
              stopOpacity="0.03399"
            />
            <stop
              offset="0.62736"
              stopColor="#1E4EDB"
              stopOpacity="0.01"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`floors-${randomId}-13`}
            x1="38.7734"
            y1="8.50051"
            x2="66.1946"
            y2="60.2501"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.03118"
              stopColor="#1E4EDB"
              stopOpacity="0.90087"
            />
            <stop
              offset="0.09721"
              stopColor="#1E4EDB"
              stopOpacity="0.71387"
            />
            <stop
              offset="0.16887"
              stopColor="#1E4EDB"
              stopOpacity="0.54354"
            />
            <stop
              offset="0.2446"
              stopColor="#1E4EDB"
              stopOpacity="0.39671"
            />
            <stop
              offset="0.32537"
              stopColor="#1E4EDB"
              stopOpacity="0.27328"
            />
            <stop
              offset="0.41268"
              stopColor="#1E4EDB"
              stopOpacity="0.17307"
            />
            <stop
              offset="0.50924"
              stopColor="#1E4EDB"
              stopOpacity="0.09575"
            />
            <stop
              offset="0.61984"
              stopColor="#1E4EDB"
              stopOpacity="0.04127"
            />
            <stop
              offset="0.75679"
              stopColor="#1E4EDB"
              stopOpacity="0.01"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
              stopOpacity="0"
            />
          </linearGradient>
          <clipPath id={`floors-${randomId}-clip-0`}>
            <rect
              width="100"
              height="100"
              fill="white"
            />
          </clipPath>
        </defs>
      </>
    );
  },
  map: () => {
    const randomId = generateRandomId();
    return (
      <>
        <path
          d="M-3 8.73707V92.0571L32.0403 83.32V0L-3 8.73707Z"
          fill={`url(#map-${randomId}-0)`}
        />
        <path
          d="M67.1199 16.6799V99.9999L32.0399 83.32V0L67.1199 16.6799Z"
          fill={`url(#map-${randomId}-1)`}
        />
        <path
          d="M102.2 91.0643V7.74428L67.1202 16.6799V100L102.2 91.0643Z"
          fill={`url(#map-${randomId}-2)`}
        />
        <defs>
          <linearGradient
            id={`map-${randomId}-0`}
            x1="-11.3333"
            y1="61.1111"
            x2="47.6032"
            y2="26.9078"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`map-${randomId}-1`}
            x1="20.3333"
            y1="31.1111"
            x2="84.3896"
            y2="70.1151"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`map-${randomId}-2`}
            x1="56.4446"
            y1="71.111"
            x2="117.762"
            y2="34.7308"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FAF5EF" />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
        </defs>
      </>
    );
  },
  noResults: () => {
    const randomId = generateRandomId();
    return (
      <>
        <path
          d="M61.7318 55.3121L52.3813 64.6592L87.7347 99.9999L97.0852 90.6528L61.7318 55.3121Z"
          fill={`url(#noResults-${randomId}-1)`}
        />
        <path
          d="M36.6853 73.3705C56.946 73.3705 73.3706 56.946 73.3706 36.6853C73.3706 16.4246 56.946 0 36.6853 0C16.4245 0 0 16.4246 0 36.6853C0 56.946 16.4245 73.3705 36.6853 73.3705Z"
          fill={`url(#noResults-${randomId}-2)`}
        />
        <path
          d="M41.4383 13.4572L39.6948 18.2836C48.6267 19.6242 55.4933 27.2391 55.6542 36.5164L60.6432 35.2562C59.919 24.3701 51.9259 15.4682 41.4383 13.4304V13.4572Z"
          fill={`url(#noResults-${randomId}-3)`}
        />
        <defs>
          <linearGradient
            id={`noResults-${randomId}-1`}
            x1="52"
            y1="59"
            x2="96.6665"
            y2="100"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <radialGradient
            id={`noResults-${randomId}-2`}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(36.6853 36.6853) rotate(90) scale(36.6853 36.6853)">
            <stop stopColor="white" />
            <stop
              offset="0.0625"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.713542"
              stopColor="#DBDDEC"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </radialGradient>
          <linearGradient
            id={`noResults-${randomId}-3`}
            x1="37.5"
            y1="13"
            x2="65"
            y2="53"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
        </defs>
      </>
    );
  },
  notifications: () => {
    const randomId = generateRandomId();
    return (
      <>
        <path
          d="M87.5 83.3333H20.8334C18.5322 83.3333 16.6667 85.1988 16.6667 87.5C16.6667 89.8012 18.5322 91.6667 20.8334 91.6667H87.5C89.8012 91.6667 91.6667 89.8012 91.6667 87.5C91.6667 85.1988 89.8012 83.3333 87.5 83.3333Z"
          fill={`url(#notifications-${randomId}-0)`}
        />
        <path
          d="M87.5 83.3333H20.8334C18.5322 83.3333 16.6667 85.1988 16.6667 87.5C16.6667 89.8012 18.5322 91.6667 20.8334 91.6667H87.5C89.8012 91.6667 91.6667 89.8012 91.6667 87.5C91.6667 85.1988 89.8012 83.3333 87.5 83.3333Z"
          fill={`url(#notifications-${randomId}-1)`}
        />
        <path
          d="M87.5 83.3333H20.8334C18.5322 83.3333 16.6667 85.1988 16.6667 87.5C16.6667 89.8012 18.5322 91.6667 20.8334 91.6667H87.5C89.8012 91.6667 91.6667 89.8012 91.6667 87.5C91.6667 85.1988 89.8012 83.3333 87.5 83.3333Z"
          fill={`url(#notifications-${randomId}-2)`}
        />
        <path
          d="M63.5417 91.6667C63.5417 96.8443 59.3444 100 54.1667 100C48.989 100 44.7917 96.8443 44.7917 91.6667H63.5417Z"
          fill={`url(#notifications-${randomId}-3)`}
        />
        <path
          d="M63.5417 91.6667C63.5417 96.8443 59.3444 100 54.1667 100C48.989 100 44.7917 96.8443 44.7917 91.6667H63.5417Z"
          fill={`url(#notifications-${randomId}-4)`}
        />
        <path
          d="M63.5417 91.6667C63.5417 96.8443 59.3444 100 54.1667 100C48.989 100 44.7917 96.8443 44.7917 91.6667H63.5417Z"
          fill={`url(#notifications-${randomId}-5)`}
        />
        <path
          d="M20.8333 70.8333C31.1887 70.8333 39.5833 62.4387 39.5833 52.0833C39.5833 41.728 31.1887 33.3333 20.8333 33.3333C10.478 33.3333 2.08331 41.728 2.08331 52.0833C2.08331 62.4387 10.478 70.8333 20.8333 70.8333Z"
          fill={`url(#notifications-${randomId}-6)`}
        />
        <path
          d="M54.1666 4.16667C46.0465 4.1681 38.2062 7.13364 32.1177 12.5065C26.0293 17.8793 22.1115 25.2899 21.1001 33.3468C26.0488 33.4156 30.7695 35.4388 34.2322 38.9751C37.6948 42.5113 39.6184 47.2735 39.5832 52.2226C39.548 57.1717 37.5568 61.906 34.0442 65.3925C30.5315 68.8791 25.7825 70.835 20.8333 70.8333V83.3333H87.5V37.5C87.5 28.6595 83.9881 20.181 77.7369 13.9298C71.4857 7.67856 63.0072 4.16667 54.1666 4.16667Z"
          fill={`url(#notifications-${randomId}-7)`}
        />
        <path
          d="M54.1666 4.16667C46.0465 4.1681 38.2062 7.13364 32.1177 12.5065C26.0293 17.8793 22.1115 25.2899 21.1001 33.3468C26.0488 33.4156 30.7695 35.4388 34.2322 38.9751C37.6948 42.5113 39.6184 47.2735 39.5832 52.2226C39.548 57.1717 37.5568 61.906 34.0442 65.3925C30.5315 68.8791 25.7825 70.835 20.8333 70.8333V83.3333H87.5V37.5C87.5 28.6595 83.9881 20.181 77.7369 13.9298C71.4857 7.67856 63.0072 4.16667 54.1666 4.16667Z"
          fill={`url(#notifications-${randomId}-8)`}
        />
        <path
          d="M54.1666 4.16667C46.0465 4.1681 38.2062 7.13364 32.1177 12.5065C26.0293 17.8793 22.1115 25.2899 21.1001 33.3468C26.0488 33.4156 30.7695 35.4388 34.2322 38.9751C37.6948 42.5113 39.6184 47.2735 39.5832 52.2226C39.548 57.1717 37.5568 61.906 34.0442 65.3925C30.5315 68.8791 25.7825 70.835 20.8333 70.8333V83.3333H87.5V37.5C87.5 28.6595 83.9881 20.181 77.7369 13.9298C71.4857 7.67856 63.0072 4.16667 54.1666 4.16667Z"
          fill={`url(#notifications-${randomId}-9)`}
        />
        <path
          d="M54.1666 4.16667C46.0465 4.1681 38.2062 7.13364 32.1177 12.5065C26.0293 17.8793 22.1115 25.2899 21.1001 33.3468C26.0488 33.4156 30.7695 35.4388 34.2322 38.9751C37.6948 42.5113 39.6184 47.2735 39.5832 52.2226C39.548 57.1717 37.5568 61.906 34.0442 65.3925C30.5315 68.8791 25.7825 70.835 20.8333 70.8333V83.3333H87.5V37.5C87.5 28.6595 83.9881 20.181 77.7369 13.9298C71.4857 7.67856 63.0072 4.16667 54.1666 4.16667Z"
          fill={`url(#notifications-${randomId}-10)`}
        />
        <path
          d="M20.8333 44.7917V59.375"
          stroke="#037056"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.125 52.0833H13.5417"
          stroke="#037056"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M59.3099 4.5627C59.1588 3.30486 58.552 2.146 57.6042 1.3053C56.6565 0.464604 55.4335 0.000350952 54.1667 0.000350952C52.8998 0.000350952 51.6768 0.464604 50.7291 1.3053C49.7813 2.146 49.1745 3.30486 49.0234 4.5627C52.4319 4.03466 55.9014 4.03466 59.3099 4.5627Z"
          fill={`url(#notifications-${randomId}-11)`}
        />
        <path
          d="M54.1666 10.4167C55.548 10.4167 56.8727 9.86793 57.8495 8.89118C58.8262 7.91443 59.375 6.58967 59.375 5.20833C59.3669 4.99196 59.3452 4.77632 59.3099 4.5627C55.9014 4.03466 52.4319 4.03466 49.0234 4.5627C48.9881 4.77632 48.9663 4.99196 48.9583 5.20833C48.9583 6.58967 49.507 7.91443 50.4838 8.89118C51.4606 9.86793 52.7853 10.4167 54.1666 10.4167Z"
          fill={`url(#notifications-${randomId}-12)`}
        />
        <defs>
          <linearGradient
            id={`notifications-${randomId}-0`}
            x1="26.0273"
            y1="92.8274"
            x2="89.2175"
            y2="80.8642"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.046"
              stopColor="#037056"
              stopOpacity="0.89884"
            />
            <stop
              offset="0.15085"
              stopColor="#037056"
              stopOpacity="0.69052"
            />
            <stop
              offset="0.25989"
              stopColor="#037056"
              stopOpacity="0.50583"
            />
            <stop
              offset="0.37088"
              stopColor="#037056"
              stopOpacity="0.35012"
            />
            <stop
              offset="0.48434"
              stopColor="#037056"
              stopOpacity="0.22316"
            />
            <stop
              offset="0.60104"
              stopColor="#037056"
              stopOpacity="0.12481"
            />
            <stop
              offset="0.72227"
              stopColor="#037056"
              stopOpacity="0.05496"
            />
            <stop
              offset="0.851"
              stopColor="#037056"
              stopOpacity="0.01341"
            />
            <stop
              offset="1"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`notifications-${randomId}-1`}
            x1="54.1667"
            y1="92.3249"
            x2="54.1667"
            y2="83.0015"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.03073"
              stopColor="#037056"
              stopOpacity="0.92832"
            />
            <stop
              offset="0.14295"
              stopColor="#037056"
              stopOpacity="0.68593"
            />
            <stop
              offset="0.25427"
              stopColor="#037056"
              stopOpacity="0.47664"
            />
            <stop
              offset="0.36194"
              stopColor="#037056"
              stopOpacity="0.30555"
            />
            <stop
              offset="0.46524"
              stopColor="#037056"
              stopOpacity="0.17248"
            />
            <stop
              offset="0.56297"
              stopColor="#037056"
              stopOpacity="0.07732"
            />
            <stop
              offset="0.65297"
              stopColor="#037056"
              stopOpacity="0.01985"
            />
            <stop
              offset="0.72793"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`notifications-${randomId}-2`}
            x1="105.509"
            y1="85.709"
            x2="65.9111"
            y2="87.0903"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.03478"
              stopColor="#037056"
              stopOpacity="0.92832"
            />
            <stop
              offset="0.16179"
              stopColor="#037056"
              stopOpacity="0.68593"
            />
            <stop
              offset="0.28779"
              stopColor="#037056"
              stopOpacity="0.47664"
            />
            <stop
              offset="0.40964"
              stopColor="#037056"
              stopOpacity="0.30555"
            />
            <stop
              offset="0.52655"
              stopColor="#037056"
              stopOpacity="0.17248"
            />
            <stop
              offset="0.63717"
              stopColor="#037056"
              stopOpacity="0.07732"
            />
            <stop
              offset="0.73903"
              stopColor="#037056"
              stopOpacity="0.01985"
            />
            <stop
              offset="0.82387"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`notifications-${randomId}-3`}
            x1="47.4601"
            y1="96.7196"
            x2="69.2542"
            y2="90.0792"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.01063"
              stopColor="#037056"
              stopOpacity="0.96993"
            />
            <stop
              offset="0.08792"
              stopColor="#037056"
              stopOpacity="0.76878"
            />
            <stop
              offset="0.17066"
              stopColor="#037056"
              stopOpacity="0.58587"
            />
            <stop
              offset="0.25682"
              stopColor="#037056"
              stopOpacity="0.42823"
            />
            <stop
              offset="0.34728"
              stopColor="#037056"
              stopOpacity="0.29554"
            />
            <stop
              offset="0.4433"
              stopColor="#037056"
              stopOpacity="0.18757"
            />
            <stop
              offset="0.5468"
              stopColor="#037056"
              stopOpacity="0.10421"
            />
            <stop
              offset="0.66151"
              stopColor="#037056"
              stopOpacity="0.04527"
            />
            <stop
              offset="0.7962"
              stopColor="#037056"
              stopOpacity="0.01062"
            />
            <stop
              offset="1"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`notifications-${randomId}-4`}
            x1="53.0902"
            y1="90.5244"
            x2="58.6394"
            y2="101.621"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.00826"
              stopColor="#037056"
              stopOpacity="0.96993"
            />
            <stop
              offset="0.06835"
              stopColor="#037056"
              stopOpacity="0.76878"
            />
            <stop
              offset="0.13267"
              stopColor="#037056"
              stopOpacity="0.58587"
            />
            <stop
              offset="0.19965"
              stopColor="#037056"
              stopOpacity="0.42823"
            />
            <stop
              offset="0.26998"
              stopColor="#037056"
              stopOpacity="0.29554"
            />
            <stop
              offset="0.34462"
              stopColor="#037056"
              stopOpacity="0.18757"
            />
            <stop
              offset="0.42508"
              stopColor="#037056"
              stopOpacity="0.10421"
            />
            <stop
              offset="0.51426"
              stopColor="#037056"
              stopOpacity="0.04527"
            />
            <stop
              offset="0.61897"
              stopColor="#037056"
              stopOpacity="0.01062"
            />
            <stop
              offset="0.7774"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`notifications-${randomId}-5`}
            x1="60.9363"
            y1="87.7657"
            x2="49.8934"
            y2="97.1983"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.00826"
              stopColor="#037056"
              stopOpacity="0.96993"
            />
            <stop
              offset="0.06835"
              stopColor="#037056"
              stopOpacity="0.76878"
            />
            <stop
              offset="0.13267"
              stopColor="#037056"
              stopOpacity="0.58587"
            />
            <stop
              offset="0.19965"
              stopColor="#037056"
              stopOpacity="0.42823"
            />
            <stop
              offset="0.26998"
              stopColor="#037056"
              stopOpacity="0.29554"
            />
            <stop
              offset="0.34462"
              stopColor="#037056"
              stopOpacity="0.18757"
            />
            <stop
              offset="0.42508"
              stopColor="#037056"
              stopOpacity="0.10421"
            />
            <stop
              offset="0.51426"
              stopColor="#037056"
              stopOpacity="0.04527"
            />
            <stop
              offset="0.61897"
              stopColor="#037056"
              stopOpacity="0.01062"
            />
            <stop
              offset="0.7774"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <radialGradient
            id={`notifications-${randomId}-6`}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(28.0419 45.795) scale(27.2115)">
            <stop
              stopColor="#037056"
              stopOpacity="0"
            />
            <stop
              offset="0.21249"
              stopColor="#037056"
              stopOpacity="0.01033"
            />
            <stop
              offset="0.34781"
              stopColor="#037056"
              stopOpacity="0.04424"
            />
            <stop
              offset="0.46158"
              stopColor="#037056"
              stopOpacity="0.10202"
            />
            <stop
              offset="0.56347"
              stopColor="#037056"
              stopOpacity="0.18385"
            />
            <stop
              offset="0.65744"
              stopColor="#037056"
              stopOpacity="0.28989"
            />
            <stop
              offset="0.74556"
              stopColor="#037056"
              stopOpacity="0.42025"
            />
            <stop
              offset="0.82912"
              stopColor="#037056"
              stopOpacity="0.57506"
            />
            <stop
              offset="0.90906"
              stopColor="#037056"
              stopOpacity="0.75464"
            />
            <stop
              offset="0.9835"
              stopColor="#037056"
              stopOpacity="0.95207"
            />
            <stop
              offset="1"
              stopColor="#037056"
            />
          </radialGradient>
          <linearGradient
            id={`notifications-${randomId}-7`}
            x1="24.4845"
            y1="38.0179"
            x2="62.8374"
            y2="51.8139"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.03632"
              stopColor="#037056"
              stopOpacity="0.8793"
            />
            <stop
              offset="0.09872"
              stopColor="#037056"
              stopOpacity="0.69669"
            />
            <stop
              offset="0.1668"
              stopColor="#037056"
              stopOpacity="0.53019"
            />
            <stop
              offset="0.239"
              stopColor="#037056"
              stopOpacity="0.38687"
            />
            <stop
              offset="0.31649"
              stopColor="#037056"
              stopOpacity="0.26631"
            />
            <stop
              offset="0.40081"
              stopColor="#037056"
              stopOpacity="0.16848"
            />
            <stop
              offset="0.49476"
              stopColor="#037056"
              stopOpacity="0.09311"
            />
            <stop
              offset="0.60364"
              stopColor="#037056"
              stopOpacity="0.04004"
            />
            <stop
              offset="0.74108"
              stopColor="#037056"
              stopOpacity="0.01"
            />
            <stop
              offset="1"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`notifications-${randomId}-8`}
            x1="107.324"
            y1="43.75"
            x2="74.3325"
            y2="43.75"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.10006"
              stopColor="#037056"
              stopOpacity="0.81861"
            />
            <stop
              offset="0.2331"
              stopColor="#037056"
              stopOpacity="0.60419"
            />
            <stop
              offset="0.36739"
              stopColor="#037056"
              stopOpacity="0.41923"
            />
            <stop
              offset="0.50001"
              stopColor="#037056"
              stopOpacity="0.26821"
            />
            <stop
              offset="0.63069"
              stopColor="#037056"
              stopOpacity="0.1509"
            />
            <stop
              offset="0.75881"
              stopColor="#037056"
              stopOpacity="0.0672"
            />
            <stop
              offset="0.88329"
              stopColor="#037056"
              stopOpacity="0.01694"
            />
            <stop
              offset="1"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`notifications-${randomId}-9`}
            x1="20.837"
            y1="20.7192"
            x2="69.61"
            y2="68.5719"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.00738"
              stopColor="#037056"
              stopOpacity="0.96381"
            />
            <stop
              offset="0.04768"
              stopColor="#037056"
              stopOpacity="0.78417"
            />
            <stop
              offset="0.09309"
              stopColor="#037056"
              stopOpacity="0.61488"
            />
            <stop
              offset="0.1419"
              stopColor="#037056"
              stopOpacity="0.46688"
            />
            <stop
              offset="0.19502"
              stopColor="#037056"
              stopOpacity="0.33967"
            />
            <stop
              offset="0.25374"
              stopColor="#037056"
              stopOpacity="0.23299"
            />
            <stop
              offset="0.32001"
              stopColor="#037056"
              stopOpacity="0.14667"
            />
            <stop
              offset="0.39742"
              stopColor="#037056"
              stopOpacity="0.08036"
            />
            <stop
              offset="0.49311"
              stopColor="#037056"
              stopOpacity="0.03399"
            />
            <stop
              offset="0.62736"
              stopColor="#037056"
              stopOpacity="0.01"
            />
            <stop
              offset="1"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`notifications-${randomId}-10`}
            x1="28.7065"
            y1="54.0321"
            x2="80.6536"
            y2="40.6803"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.10441"
              stopColor="#037056"
              stopOpacity="0.80944"
            />
            <stop
              offset="0.23566"
              stopColor="#037056"
              stopOpacity="0.59737"
            />
            <stop
              offset="0.36836"
              stopColor="#037056"
              stopOpacity="0.41444"
            />
            <stop
              offset="0.49974"
              stopColor="#037056"
              stopOpacity="0.26504"
            />
            <stop
              offset="0.62943"
              stopColor="#037056"
              stopOpacity="0.1491"
            />
            <stop
              offset="0.75712"
              stopColor="#037056"
              stopOpacity="0.06633"
            />
            <stop
              offset="0.88174"
              stopColor="#037056"
              stopOpacity="0.01669"
            />
            <stop
              offset="1"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`notifications-${randomId}-11`}
            x1="51.9241"
            y1="6.3626"
            x2="59.0921"
            y2="-1.62709"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.07996"
              stopColor="#037056"
              stopOpacity="0.80272"
            />
            <stop
              offset="0.16776"
              stopColor="#037056"
              stopOpacity="0.61618"
            />
            <stop
              offset="0.26083"
              stopColor="#037056"
              stopOpacity="0.45071"
            />
            <stop
              offset="0.35759"
              stopColor="#037056"
              stopOpacity="0.31134"
            />
            <stop
              offset="0.45908"
              stopColor="#037056"
              stopOpacity="0.19784"
            />
            <stop
              offset="0.56681"
              stopColor="#037056"
              stopOpacity="0.11015"
            />
            <stop
              offset="0.68373"
              stopColor="#037056"
              stopOpacity="0.04806"
            />
            <stop
              offset="0.81657"
              stopColor="#037056"
              stopOpacity="0.01143"
            />
            <stop
              offset="1"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
          <linearGradient
            id={`notifications-${randomId}-12`}
            x1="50.9742"
            y1="0.0613243"
            x2="55.2271"
            y2="7.48796"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#037056" />
            <stop
              offset="0.03632"
              stopColor="#037056"
              stopOpacity="0.8793"
            />
            <stop
              offset="0.09872"
              stopColor="#037056"
              stopOpacity="0.69669"
            />
            <stop
              offset="0.1668"
              stopColor="#037056"
              stopOpacity="0.53019"
            />
            <stop
              offset="0.239"
              stopColor="#037056"
              stopOpacity="0.38687"
            />
            <stop
              offset="0.31649"
              stopColor="#037056"
              stopOpacity="0.26631"
            />
            <stop
              offset="0.40081"
              stopColor="#037056"
              stopOpacity="0.16848"
            />
            <stop
              offset="0.49476"
              stopColor="#037056"
              stopOpacity="0.09311"
            />
            <stop
              offset="0.60364"
              stopColor="#037056"
              stopOpacity="0.04004"
            />
            <stop
              offset="0.74108"
              stopColor="#037056"
              stopOpacity="0.01"
            />
            <stop
              offset="1"
              stopColor="#037056"
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
      </>
    );
  },
  office: () => {
    const randomId = generateRandomId();
    return (
      <>
        <path
          d="M21 2H79V100H21V2Z"
          fill={`url(#office-${randomId}-0)`}
        />
        <rect
          x="28"
          y="11"
          width="12"
          height="21"
          fill={`url(#office-${randomId}-1)`}
        />
        <path
          d="M28.5 31.5V11.5H39.5V31.5H28.5Z"
          stroke="url(#paint2_linear_848_13030)"
          fill={`url(#office-${randomId}-2)`}
        />
        <rect
          x="28"
          y="36"
          width="12"
          height="21"
          fill={`url(#office-${randomId}-3)`}
        />
        <path
          d="M28.5 56.5V36.5H39.5V56.5H28.5Z"
          stroke="url(#paint4_linear_848_13030)"
          fill={`url(#office-${randomId}-4)`}
        />
        <rect
          x="28"
          y="61"
          width="12"
          height="21"
          fill={`url(#office-${randomId}-5)`}
        />
        <path
          d="M28.5 81.5V61.5H39.5V81.5H28.5Z"
          stroke="url(#paint6_linear_848_13030)"
          fill={`url(#office-${randomId}-6)`}
        />
        <rect
          x="44"
          y="11"
          width="12"
          height="21"
          fill={`url(#office-${randomId}-7)`}
        />
        <path
          d="M44.5 31.5V11.5H55.5V31.5H44.5Z"
          stroke="url(#paint8_linear_848_13030)"
          fill={`url(#office-${randomId}-8)`}
        />
        <rect
          x="44"
          y="36"
          width="12"
          height="21"
          fill={`url(#office-${randomId}-9)`}
        />
        <path
          d="M44.5 56.5V36.5H55.5V56.5H44.5Z"
          stroke="url(#paint10_linear_848_13030)"
          fill={`url(#office-${randomId}-10)`}
        />
        <rect
          x="44"
          y="61"
          width="12"
          height="21"
          fill={`url(#office-${randomId}-11)`}
        />
        <path
          d="M44.5 81.5V61.5H55.5V81.5H44.5Z"
          stroke="url(#paint12_linear_848_13030)"
          fill={`url(#office-${randomId}-12)`}
        />
        <rect
          x="60"
          y="11"
          width="12"
          height="21"
          fill={`url(#office-${randomId}-13)`}
        />
        <path
          d="M60.5 31.5V11.5H71.5V31.5H60.5Z"
          stroke="url(#paint14_linear_848_13030)"
          fill={`url(#office-${randomId}-14)`}
        />
        <rect
          x="60"
          y="36"
          width="12"
          height="21"
          fill={`url(#office-${randomId}-15)`}
        />
        <path
          d="M60.5 56.5V36.5H71.5V56.5H60.5Z"
          stroke="url(#paint16_linear_848_13030)"
          fill={`url(#office-${randomId}-16)`}
        />
        <rect
          x="60"
          y="61"
          width="12"
          height="21"
          fill={`url(#office-${randomId}-17)`}
        />
        <path
          d="M60.5 81.5V61.5H71.5V81.5H60.5Z"
          stroke="url(#paint18_linear_848_13030)"
          fill={`url(#office-${randomId}-18)`}
        />
        <rect
          x="21"
          width="58"
          height="2"
          fill={`url(#office-${randomId}-19)`}
        />
        <rect
          x="19"
          y="99"
          width="62"
          height="1"
          fill="#183EAF"
        />
        <defs>
          <linearGradient
            id={`office-${randomId}-0`}
            x1="65.5"
            y1="103"
            x2="76.2188"
            y2="-2.32509"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.263019"
              stopColor="#B8C3E9"
              stopOpacity="0.719474"
            />
            <stop
              offset="0.5"
              stopColor="#D3D7EC"
              stopOpacity="0.671579"
            />
            <stop
              offset="0.661458"
              stopColor="#ACBAE8"
              stopOpacity="0.742488"
            />
            <stop
              offset="0.958333"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-1`}
            x1="40"
            y1="32"
            x2="28.0214"
            y2="11.8087"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#E4E4ED"
            />
            <stop
              offset="0.619792"
              stopColor="#DEE0ED"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-2`}
            x1="28.5"
            y1="12"
            x2="25.7721"
            y2="38.5762"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.515625"
              stopColor="#5679E0"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-3`}
            x1="40"
            y1="57"
            x2="28.0214"
            y2="36.8087"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#E4E4ED"
            />
            <stop
              offset="0.619792"
              stopColor="#DEE0ED"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-4`}
            x1="28.5"
            y1="37"
            x2="25.7721"
            y2="63.5762"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.515625"
              stopColor="#5679E0"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-5`}
            x1="40"
            y1="82"
            x2="28.0214"
            y2="61.8087"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#E4E4ED"
            />
            <stop
              offset="0.619792"
              stopColor="#DEE0ED"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-6`}
            x1="28.5"
            y1="62"
            x2="25.7721"
            y2="88.5762"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.515625"
              stopColor="#5679E0"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-7`}
            x1="56"
            y1="32"
            x2="44.0214"
            y2="11.8087"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#E4E4ED"
            />
            <stop
              offset="0.619792"
              stopColor="#DEE0ED"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-8`}
            x1="44.5"
            y1="12"
            x2="41.7721"
            y2="38.5762"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.515625"
              stopColor="#5679E0"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-9`}
            x1="56"
            y1="57"
            x2="44.0214"
            y2="36.8087"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#E4E4ED"
            />
            <stop
              offset="0.619792"
              stopColor="#DEE0ED"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-10`}
            x1="44.5"
            y1="37"
            x2="41.7721"
            y2="63.5762"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.515625"
              stopColor="#5679E0"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-11`}
            x1="56"
            y1="82"
            x2="44.0214"
            y2="61.8087"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#E4E4ED"
            />
            <stop
              offset="0.619792"
              stopColor="#DEE0ED"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-12`}
            x1="44.5"
            y1="62"
            x2="41.7721"
            y2="88.5762"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.515625"
              stopColor="#5679E0"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-13`}
            x1="72"
            y1="32"
            x2="60.0214"
            y2="11.8087"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#E4E4ED"
            />
            <stop
              offset="0.619792"
              stopColor="#DEE0ED"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-14`}
            x1="60.5"
            y1="12"
            x2="57.7721"
            y2="38.5762"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.515625"
              stopColor="#5679E0"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-15`}
            x1="72"
            y1="57"
            x2="60.0214"
            y2="36.8087"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#E4E4ED"
            />
            <stop
              offset="0.619792"
              stopColor="#DEE0ED"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-16`}
            x1="60.5"
            y1="37"
            x2="57.7721"
            y2="63.5762"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.515625"
              stopColor="#5679E0"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-17`}
            x1="72"
            y1="82"
            x2="60.0214"
            y2="61.8087"
            gradientUnits="userSpaceOnUse">
            <stop
              offset="0.270833"
              stopColor="#FAF5EF"
            />
            <stop
              offset="0.453125"
              stopColor="#E4E4ED"
            />
            <stop
              offset="0.619792"
              stopColor="#DEE0ED"
            />
            <stop
              offset="1"
              stopColor="#1E4EDB"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-18`}
            x1="60.5"
            y1="62"
            x2="57.7721"
            y2="88.5762"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E4EDB" />
            <stop
              offset="0.515625"
              stopColor="#5679E0"
            />
            <stop
              offset="1"
              stopColor="#FAF5EF"
            />
          </linearGradient>
          <linearGradient
            id={`office-${randomId}-19`}
            x1="50"
            y1="0"
            x2="50"
            y2="2"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#D2DCF8" />
            <stop
              offset="1"
              stopColor="#D2DCF8"
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
      </>
    );
  },
} as const;
