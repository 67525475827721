import {Grid, H4} from '@quarks';
import {truncateString} from '@utils';
import styled from 'styled-components';

export const Wrapper = styled(Grid)`
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.divider.grey};
  column-gap: 8px;
  grid-template-columns: 2rem 1fr 2rem;
  padding: 24px 20px;
  width: 100%;
`;

export const Title = styled(H4).attrs((attrs) => ({
  ...attrs,
  as: 'h1',
}))`
  ${truncateString}
  text-align: center;
`;
