import {AttendeeDetailsProps} from './types';
import {useTranslation} from 'react-i18next';

import {useModal} from '@hooks';
import {ModalPage} from '@organisms';
import {AttendeeDetailsCell} from '@organisms';
import {Div, FlexCol, H1} from '@quarks';

export const AttendeeDetailsCard = ({building}: AttendeeDetailsProps) => {
  const {closeModal, removePages} = useModal();
  const {t} = useTranslation();

  return (
    <ModalPage
      onBack={() => removePages(1)}
      onClose={closeModal}
      title={t('Invitees')}>
      <FlexCol
        as="section"
        gap={16}>
        <H1 as="h2">{building.locationDescription}</H1>
        <Div>
          {building.attendees.map((attendee) => (
            <AttendeeDetailsCell
              attendee={attendee}
              key={attendee.email}
            />
          ))}
        </Div>
      </FlexCol>
    </ModalPage>
  );
};
