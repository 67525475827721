import {FocusEvent, useRef, useState} from 'react';
import {OnboardingBuildingsCardProps} from './types';

import {savePreferredBuilding, withAsyncThunkErrorHandling} from '@lib/store';

import {useTranslation} from 'react-i18next';

import {animated, config, useTransition} from 'react-spring';
import {useAppDispatch} from '@hooks';
import {Button, Input} from '@molecules';

import {scrollParentForChild} from '@utils';
import {FlexCol, H1, P} from '@quarks';
import {BuildingCell, CardBody, CardBottom, CardTop, LabelText, StyledCard, StyledLoader} from '../styles';

export const OnboardingBuildingsCard = ({buildings, page}: OnboardingBuildingsCardProps) => {
  const [selectedBuilding, setSelectedBuilding] = useState<string>('');
  const {t} = useTranslation();
  const parentRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const transition = useTransition(page === 1, {
    from: {transform: 'translate3d(100vw, 0, 0)'},
    enter: {transform: 'translate3d(0vw, 0, 0)'},
    leave: {transform: 'translate3d(-100vw, 0, 0)'},
    config: {clamp: true, ...config.stiff},
  });

  const saveSelection = () => {
    if (selectedBuilding) {
      dispatch(withAsyncThunkErrorHandling(() => savePreferredBuilding(selectedBuilding)));
    }
  };

  const handleOnFocus = (e: FocusEvent<HTMLLabelElement, Element>) => {
    if (parentRef.current) scrollParentForChild(e.target, parentRef.current);
  };

  return transition(
    (style, item) =>
      item && (
        <animated.div style={style}>
          <StyledCard>
            <CardTop>
              <FlexCol gap={16}>
                <H1 id="office-legend">{t('profile:WhichOfficeToSetAsDefault')}</H1>
                <P>{t('profile:WhichOfficeToSetAsDefaultDescription')}</P>
              </FlexCol>
            </CardTop>
            <CardBody
              aria-labelledby="office-legend"
              ref={parentRef}
              role="radiogroup"
              tabIndex={0}>
              {buildings.length > 0 ? (
                buildings.map((building) => (
                  <BuildingCell
                    key={building.id}
                    onFocus={(e) => handleOnFocus(e)}>
                    <Input
                      type="radio"
                      name="building"
                      value={building.id}
                      onChange={() => setSelectedBuilding(building.id)}
                    />
                    <LabelText>{building.name}</LabelText>
                  </BuildingCell>
                ))
              ) : (
                <StyledLoader />
              )}
            </CardBody>
            <CardBottom>
              <Button
                button="primary"
                disabled={!selectedBuilding}
                onClick={saveSelection}
                size="full">
                {t('Continue')}
              </Button>
            </CardBottom>
          </StyledCard>
        </animated.div>
      ),
  );
};
