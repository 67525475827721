import {BREAKPOINTS} from '@constants';
import {ModalBackgroundLayout} from '@templates';
import styled from 'styled-components';

export const StyledModalBackgroundLayout = styled(ModalBackgroundLayout)<{
  $cursor: boolean;
  $hideOnSmallScreen: boolean;
}>`
  background: ${({theme}) => theme.background.overlay};
  cursor: ${({$cursor}) => ($cursor ? 'pointer' : 'default')};

  ${BREAKPOINTS.small`
    display: ${({$hideOnSmallScreen}) => ($hideOnSmallScreen ? 'none' : 'block')};
  `}
`;
