import {Skeleton} from '@molecules';

export const ExtrasLoader = () => {
  const placeholderCount = 3;

  return (
    <div className="gap-4 flex flex-col w-full">
      {Array.from({length: placeholderCount}, (_, i) => (
        <div
          key={`placeholder_${i}`}
          className="flex gap-4 h-full w-full items-center">
          <Skeleton className="size-10 flex-none rounded-md bg-mapiq-black-100" />
          <Skeleton className="h-4 w-full bg-mapiq-black-100" />
        </div>
      ))}
    </div>
  );
};
