import {ReactComponent as LogoIcon} from '@svgs/logo_icon.svg';
import {ReactComponent as LogoFull} from '@svgs/logo_full.svg';
import {ReactComponent as LogoWordmark} from '@svgs/logo_wordmark.svg';
import styled from 'styled-components';

type StyledProps = {
  $type: boolean;
  $width: string;
};

export const StyledFull = styled(LogoFull)<StyledProps>`
  fill: ${({$type, theme}) => ($type === 'light' ? theme.mapiqLogo.light : theme.mapiqLogo.dark)};

  aspect-ratio: 1318 / 302;
  flex: none;
  width: ${({$width}) => $width};
  height: auto;
`;
export const StyledIcon = styled(LogoIcon)<StyledProps>`
  fill: ${({$type, theme}) => ($type === 'light' ? theme.mapiqLogo.light : theme.mapiqLogo.dark)};

  aspect-ratio: 525 / 427;
  flex: none;
  width: ${({$width}) => $width};
  height: auto;
`;
export const StyledWordMark = styled(LogoWordmark)<StyledProps>`
  fill: ${({$type, theme}) => ($type === 'light' ? theme.mapiqLogo.light : theme.mapiqLogo.dark)};

  aspect-ratio: 1146 / 373;
  flex: none;
  width: ${({$width}) => $width};
  height: auto;
`;
