import {ReadMoreCardProps} from './types';
import {useTranslation} from 'react-i18next';

import {useModal} from '@hooks';

import {ModalPage} from '@organisms';

export const ReadMoreCard = ({messageAsHTML}: ReadMoreCardProps) => {
  const {closeModal, removePages} = useModal();
  const {t} = useTranslation();

  return (
    <ModalPage
      onBack={() => removePages(1)}
      onClose={closeModal}
      title={t('screen:MeetingDetailsOverview')}>
      {messageAsHTML && (
        <div
          data-testid="organisms-eventDetails-ReadMoreCard_content"
          dangerouslySetInnerHTML={{__html: messageAsHTML}}
        />
      )}
    </ModalPage>
  );
};
