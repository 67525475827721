import styled from 'styled-components';
import {BREAKPOINTS} from '@constants';
import {PageHeader} from '@molecules';
import {Card} from '@atoms';
import {FlexRow} from '@quarks';

export const CalendarPageHeader = styled(PageHeader)`
  display: none;

  ${BREAKPOINTS.small`
    display: grid;
  `}
`;

export const CalendarHorizontalWeekOverviewWrapper = styled(FlexRow)`
  background: ${({theme}) => theme.card.white.background};
  border-bottom: 1px solid ${({theme}) => theme.divider.grey};
  grid-area: horizontal-calendar;
  overflow: hidden;
  display: none;

  ${BREAKPOINTS.xLarge`
    display: none;
  `}

  ${BREAKPOINTS.large`
    display: none;
  `}
  
  ${BREAKPOINTS.medium`
    display: flex;
  `}
`;

export const OverviewWrapper = styled(Card).attrs((attrs) => ({
  ...attrs,
  card: 'white',
}))`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  grid-area: overview;

  ${BREAKPOINTS.large`
    border-radius: 0;
    box-shadow: none;
  `}
`;
