import styled from 'styled-components';
export const StyledInviteeCellButton = styled.button`
  width: 100%;
  background: ${({theme}) => theme.card.transparent.background};
  padding: 8px 4px;
  border-radius: 4px;
  border: 2px solid transparent;

  @media (hover) {
    &:hover {
      background: ${({theme}) => theme.card.transparent.hover.background};
    }
  }

  &:focus-visible {
    outline: none;
    border: 2px solid ${({theme}) => theme.brand};
  }
`;
