import {getIsSigningIn, signInRedirect, withAsyncThunkErrorHandling} from '@lib/store';

import {useTranslation} from 'react-i18next';

import {useAppDispatch, useAppSelector, useTheme} from '@hooks';

import {Button} from '@molecules';
import {FlexCol, Link, P} from '@quarks';
import {StyledCard, StyledH1} from './styles';
import {MapiqLogo} from '@atoms';

export const LoginCard = () => {
  const dispatch = useAppDispatch();
  const isSigningIn = useAppSelector(getIsSigningIn);
  const logIn = () => {
    dispatch(withAsyncThunkErrorHandling(() => signInRedirect()));
  };
  const {theme} = useTheme();

  // Translations
  const {t} = useTranslation(['translation', 'auth', 'workplace']);
  const textSignIn = isSigningIn ? t('auth:SigningYouIn') : t('auth:SignIn');
  const textOnBoardingTitle = t('workplace:OnboardingTitle');
  const textOnBoardingMessage = t('workplace:OnboardingMessage');
  const textLearnMore = t('workplace:FindOutMore');

  return (
    <StyledCard>
      <MapiqLogo
        width="123px"
        theme={theme === 'light' ? 'dark' : 'light'}
        type="full"
      />
      <FlexCol
        alignItems="center"
        rowGap={8}
        sm={{rowGap: '16px'}}>
        <StyledH1>{textOnBoardingTitle}</StyledH1>
        <P>{textOnBoardingMessage}</P>
      </FlexCol>
      <FlexCol rowGap={16}>
        <Button
          data-testid="organisms-loginCard-loginButton"
          button="primary"
          loading={isSigningIn}
          size="full"
          onClick={logIn}>
          {textSignIn}
        </Button>
        <Link
          textAlign="center"
          to="https://support.mapiq.com/support/home"
          sm={{
            display: 'none',
          }}>
          {textLearnMore}
        </Link>
      </FlexCol>
    </StyledCard>
  );
};
