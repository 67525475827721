import {P} from '@quarks';
import styled from 'styled-components';
import {StyledTextProps} from './types';
import {ModalPage} from '../../ModalPage';

export const StyledText = styled(P).attrs<StyledTextProps>(({theme, $disabled, ...attrs}) => ({
  ...attrs,
  color: $disabled ? theme.button.text.disabled.color : theme.text.color.body,
}))<StyledTextProps>``;

export const StyledModalPage = styled(ModalPage)`
  --modal-padding-x: 0;
  --modal-padding-y: 0;
  --padding-x: 24px;
  --padding-y: 24px;
  background: ${({theme}) => theme.background.white};

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid ${({theme}) => theme.brand};
  }
`;
