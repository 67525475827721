import {FlexRow} from '@quarks';
import {HaNRoomListHorizontalProps} from './types';
import {HaNCalendarSync, HaNRoomCell} from '@organisms';
import {StyledSwiperHorizontal, StyledRoomCellContainer, StyledContainer} from './styles';
import Swiper from 'swiper';
import {forwardRef} from 'react';
import {EmptyListPlaceholder, SwiperRefType} from '@molecules';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '@hooks';
import {getMeetingRoomSuggestionByBuildingIdLoadingStatus} from '@lib/store';
import {Loader} from '@atoms';

/**
 * Lists (horizontally) available rooms in Here And Now
 */

export const HaNRoomListHorizontal = forwardRef<SwiperRefType, HaNRoomListHorizontalProps>(
  (
    {
      buildingId,
      rooms,
      onClick,
      startDateTime,
      endDateTime,
      onSlideChange,
      showCalendarConsentMessageNoOccupancy,
    }: HaNRoomListHorizontalProps,
    ref,
  ) => {
    const {t} = useTranslation();

    const meetingRoomLoadingStatus = useAppSelector((state) =>
      getMeetingRoomSuggestionByBuildingIdLoadingStatus(state, buildingId),
    );
    const loadingMeetingRooms = meetingRoomLoadingStatus === 'loading' || meetingRoomLoadingStatus === undefined;

    const handleSlideChange = (swiper: Swiper) => {
      const visibleRoom = rooms[swiper.activeIndex];
      onSlideChange(visibleRoom);
    };

    return (
      <FlexRow
        columnGap={8}
        overflow="hidden"
        zIndex={2}
        justifyContent="center">
        {loadingMeetingRooms ? (
          <StyledContainer justifyContent="center">
            <Loader />
          </StyledContainer>
        ) : showCalendarConsentMessageNoOccupancy ? (
          <StyledContainer>
            <HaNCalendarSync displayIllustration={false} />
          </StyledContainer>
        ) : rooms.length ? (
          <StyledSwiperHorizontal
            freeMode={false}
            onSlideChange={handleSlideChange}
            ref={ref}
            centeredSlides={true}
            spaceBetween={16}>
            {rooms.map((room, index) => (
              <StyledRoomCellContainer key={room.id}>
                <HaNRoomCell
                  onClick={onClick}
                  index={index}
                  key={room.id}
                  room={room}
                  startDateTime={startDateTime}
                  endDateTime={endDateTime}
                />
              </StyledRoomCellContainer>
            ))}
          </StyledSwiperHorizontal>
        ) : (
          <EmptyListPlaceholder text={t('hereAndNow:NoRoomsAvailable')} />
        )}
      </FlexRow>
    );
  },
);
