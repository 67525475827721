import {ChipProps} from './types';
import {useTranslation} from 'react-i18next';

import {ChipDeleteButton, ChipLabel, StyledChip} from './styles';
import {Icon} from '@atoms';
import {pxToRem} from '@utils';

export const Chip = ({
  children,
  chip,
  className,
  email,
  onClick,
  onDelete,
  selected = false,
  square = false,
  style,
  ...props
}: ChipProps) => {
  const {t} = useTranslation();

  return (
    <StyledChip
      $chip={chip}
      $email={email}
      $selected={selected}
      $square={square}
      className={className}
      onClick={onClick}
      style={style}
      {...props}>
      <ChipLabel>{children}</ChipLabel>
      {!!onDelete && (
        <ChipDeleteButton
          aria-label={t('Delete')}
          data-testid="chip-delete"
          onClick={onDelete}>
          <Icon
            color="currentColor"
            icon={'close'}
            size={pxToRem(20)}
          />
        </ChipDeleteButton>
      )}
    </StyledChip>
  );
};
