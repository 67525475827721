import {Card} from '@atoms';
import {IconButton} from '@molecules';
import {pxToRem} from '@utils';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  align-items: stretch;
  justify-content: space-between;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  grid-area: 1 / 1;
  height: ${pxToRem(90)};
  min-width: ${pxToRem(270)};
  padding: 12px 16px;

  &:focus-visible {
    outline: none;
    border: solid 2px ${({theme}) => theme.brand};
  }
`;

export const Info = styled(IconButton)`
  align-self: flex-end;
`;

export const Input = styled.input`
  appearance: none;
  grid-area: 1 / 1;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  &:focus-visible {
    & + label {
      outline: none;
      border: solid 2px ${({theme}) => theme.brand};
    }
  }
`;
