import {getPrivacyPolicy} from '@lib/store';
import {useTranslation} from 'react-i18next';

import {useAppSelector} from '@hooks';
import {ProfileCell} from '../styles';
import {Link} from '@quarks';

export const PrivacyPolicyCell = () => {
  const {t} = useTranslation('profile');
  const privacyPolicyLink = useAppSelector(getPrivacyPolicy);

  return (
    <ProfileCell>
      <Link
        data-testid="organisms-privacyPolicyCell-link"
        to={privacyPolicyLink}>
        {t('PrivacyPolicy')}
      </Link>
    </ProfileCell>
  );
};
