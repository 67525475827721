import {Navigation} from '@organisms';

import {useShowNavigation} from '@hooks';
import {StyledFooterLayout} from './styles';

export const Footer = () => {
  const showNavigation = useShowNavigation();

  if (!showNavigation) {
    return <></>;
  }

  return (
    <StyledFooterLayout>
      <Navigation footer />
    </StyledFooterLayout>
  );
};
