import {DateProps} from './types';

import {useFormatDate} from '@hooks';

/**
 * @param format
 * Date 2022-09-09
 *
 * yyy 22
 * yyyy 2022
 * M  9
 * MM 09
 * MMM Sep
 * MMMM September
 * d 	9
 * dd 	09
 *
 * Time 09:09
 *
 * H 9
 * HH 09
 * m 9
 * mm 09
 * s 9
 * ss 09
 *
 * Weekday
 *
 * EEE Fri
 * EEEE Friday
 * EEEEE F
 * EEEEEE Fr
 *
 * @returns a date string
 */

export const Date = ({date, format}: DateProps) => {
  return <>{useFormatDate(date, format)}</>;
};
