import {Card, Loader} from '@atoms';
import {BREAKPOINTS} from '@constants';
import {truncateString} from '@utils';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  background: white;
  box-shadow: ${({theme}) => theme.boxShadow.secondary};
  display: flex;
  flex-direction: column;
  gap: 0px;
  max-height: calc(var(--100vh) - 60px - 48px);

  ${BREAKPOINTS.small`
    border-radius: 0px;
    height: var(--100vh);
    max-height: unset;
  `}
`;

export const CardTop = styled.div`
  padding: var(--padding-y) var(--padding-x) calc(var(--padding-y) / 2);
`;

export const CardBottom = styled.div`
  padding: calc(var(--padding-x) / 2) var(--padding-x);
`;

export const CardBody = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.divider.grey};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  overflow: hidden auto;
  padding: 0 var(--padding-x);
  /* Needed for scroll to work properly */
  position: relative;

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid ${({theme}) => theme.brand};
  }
`;

export const WelcomeCard = styled(CardBody)`
  gap: var(--gap-y);
  justify-content: flex-start;

  ${BREAKPOINTS.small`
    gap: var(--gap-y);
    justify-content: space-evenly;
  `}
`;

export const QuotaWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

export const BuildingCell = styled.label`
  cursor: pointer;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr;
  padding: 20px 0;
`;

export const LabelText = styled.span`
  ${truncateString}
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
