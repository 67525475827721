import styled from 'styled-components';
import {FlexCol} from '@quarks';

export const NoMapContainer = styled(FlexCol)`
  align-items: center;
  background-color: ${({theme}) => theme.background.sand};
  gap: 24px;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
