import {EventTimeSlotProps} from './types';
import {useTranslation} from 'react-i18next';

import {Badge} from '@molecules';
import {Div, FlexRow, Grid, P, Span, Subtitle} from '@quarks';
import {pxToRem} from '@utils';
import {Date, Icon} from '@atoms';

export const EventTimeSlot = ({
  attendeesOnSite,
  availableAttendees,
  endDateTime,
  startDateTime,
  totalAttendees,
}: EventTimeSlotProps) => {
  const {t} = useTranslation();

  return (
    <FlexRow
      gap={16}
      data-testid="molecules-EventTimeSlot_cell">
      <Div alignSelf="center">
        <P
          fontWeight="bold"
          data-testid="molecules-EventTimeSlot_cell-start">
          <Date
            date={startDateTime}
            format="HH:mm"
          />
        </P>
        <P data-testid="molecules-EventTimeSlot_cell-end">
          <Date
            date={endDateTime}
            format="HH:mm"
          />
        </P>
      </Div>
      <Div
        width="1px"
        background="currentColor"
      />
      <Grid gridTemplateRows="1fr 1fr 1fr">
        <Span
          fontWeight="bold"
          data-testid="molecules-EventTimeSlot_cell-day">
          <Date
            date={startDateTime}
            format="EEEE dd MMMM"
          />
        </Span>
        <FlexRow
          alignItems="center"
          justifyContent="flex-start"
          gap={4}>
          <Div
            position="relative"
            filter="unset"
            width={pxToRem(20)}>
            <Badge
              size="xlarge"
              icon="check"
              badge={availableAttendees === totalAttendees ? 'success' : 'warning'}
            />
          </Div>
          <Subtitle
            as="span"
            fontSize="sm">
            {t('meeting:CreateMeetingAttendeeAvailability', {available: availableAttendees, count: totalAttendees})}
          </Subtitle>
        </FlexRow>
        <FlexRow
          alignItems="center"
          justifyContent="flex-start"
          gap={4}>
          <Icon
            icon="office"
            color="currentColor"
            size={pxToRem(20)}
          />
          <Subtitle
            as="span"
            fontSize="sm">
            {t('meeting:CreateMeetingAttendeeOnSite', {onSite: attendeesOnSite, count: totalAttendees})}
          </Subtitle>
        </FlexRow>
      </Grid>
    </FlexRow>
  );
};
