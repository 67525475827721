import {Div} from '@quarks';
import styled from 'styled-components';
import {WorkspaceIcon} from './HaNWorkspaceIcon';

export const StyledContainer = styled(Div)<{
  $iconType: WorkspaceIcon;
}>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({$iconType}) => ($iconType === 'desk' || $iconType === 'area' ? '8px' : '12px')};
  border: ${({$iconType, theme}) =>
    $iconType === 'unknown' ? `1px solid ${theme.border.color.sensorStatusUnknwon}` : null};
  background: ${({$iconType, theme}) =>
    $iconType === 'full'
      ? theme.occupancy.red
      : $iconType === 'busy'
      ? theme.occupancy.orange
      : $iconType === 'lively'
      ? theme.occupancy.yellow
      : $iconType === 'quiet'
      ? theme.occupancy.green
      : $iconType === 'desk' || $iconType === 'area'
      ? theme.card.beige500.background
      : $iconType === 'unknown'
      ? theme.occupancy.sensorAway
      : 'none'};
`;
