import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {AppLanguage} from '@lib/utils';

import {changeAppLanguage, getAppLanguage, getAppLanguages} from '@lib/store';

import {Option} from '@molecules';
import {useAppDispatch, useAppSelector} from '@hooks';
import {ProfileCell, ProfileSelect} from '../styles';
import {P} from '@quarks';

export const LanguageCell = () => {
  const dispatch = useAppDispatch();
  const appLanguages = useAppSelector(getAppLanguages);
  const appLanguage = useAppSelector(getAppLanguage);

  const [selectedLanguage, setSelectedLanguage] = useState<AppLanguage>(appLanguage);
  const {t} = useTranslation();

  const handleSelectLanguage = (language: AppLanguage) => {
    setSelectedLanguage(language);
    dispatch(changeAppLanguage(language));
  };

  return (
    <ProfileCell>
      <P>{t('screen:LanguageSelection')}</P>
      <ProfileSelect
        value={selectedLanguage}
        /*@ts-expect-error*/
        setValue={handleSelectLanguage}>
        {appLanguages.map((language) => (
          <Option
            key={language}
            value={language}>
            {t(`language:${language}`)}
          </Option>
        ))}
      </ProfileSelect>
    </ProfileCell>
  );
};
