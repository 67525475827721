import {AddConnectionsCardHintProps} from './types';
import {useTranslation} from 'react-i18next';
import {animated, config, useTransition} from 'react-spring';

import {isEmailValid} from '@lib/utils';
import {P} from '@quarks';

export const AddConnectionsCardHint = ({debouncedSearchTerm, isSearching, length}: AddConnectionsCardHintProps) => {
  const {t} = useTranslation();
  const show: boolean =
    debouncedSearchTerm.length === 0 ||
    (!isSearching && length === 0) ||
    debouncedSearchTerm.length === 1 ||
    (debouncedSearchTerm.length >= 2 && isEmailValid(debouncedSearchTerm));
  const transition = useTransition(show, {
    initial: {opacity: 1, transform: 'translate3d(0, 0px, 0) scale(1)', transformOrigin: 'top center'},
    from: {opacity: 0, transform: 'translate3d(0, 10px, 0) scale(1)', transformOrigin: 'top center'},
    enter: {opacity: 1, transform: 'translate3d(0, 0px, 0) scale(1)', transformOrigin: 'top center'},
    leave: {opacity: 0, transform: 'translate3d(0, 10px, 0) scale(1)', transformOrigin: 'top center'},
    config: config.stiff,
  });

  return transition(
    (style, item) =>
      item && (
        <animated.div style={style}>
          {debouncedSearchTerm.length === 0 ? (
            <p>{t('connection:AddConnectionMessage')}</p>
          ) : !isSearching && length === 0 ? (
            <>
              <P fontWeight="bold">{t('connection:NoUsersFound')}</P>
              <P>
                {debouncedSearchTerm.length === 1
                  ? t('connection:TypeMoreCharsToFindColleagues')
                  : debouncedSearchTerm.length >= 2 && isEmailValid(debouncedSearchTerm)
                  ? t('connection:CouldntFindColleagues')
                  : t('connection:TryUsingEmailAddressToFindColleagues')}
              </P>
            </>
          ) : null}
        </animated.div>
      ),
  );
};
