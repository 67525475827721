import {Dispatch, ReactNode} from 'react';
import {IconNames} from '@atoms';

export type DialogProviderProps = {
  children: ReactNode;
};

export enum DialogActions {
  clear = 'CLEAR_DIALOG',
  hide = 'HIDE_DIALOG',
  show = 'SHOW_DIALOG',
}

export enum DialogTypes {
  confirm = 'CONFIRM',
  alert = 'ALERT',
  destroy = 'DESTROY',
}

export type State = {
  buttonLabel?: string;
  icon?: IconNames;
  isDialogOpen: boolean;
  message: string;
  title: string;
  type: DialogTypes;
};

export type Action =
  | {type: DialogActions.show; payload: State}
  | {type: DialogActions.hide; payload?: State}
  | {type: DialogActions.clear; payload?: State};

export type Reducer<S, A> = (prevState: S, action: A) => S;

export type DialogContextProps = {
  state: State;
  dispatch: Dispatch<Action>;
};
