import styled from 'styled-components';
import {BREAKPOINTS} from '@constants';
import {Div, P} from '@quarks';

export const RoomListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({theme}) => theme.divider.grey};
  overflow: hidden auto;
  flex: auto;

  ${BREAKPOINTS.small`
    width: 100vw;
  `}
`;

export const Subtitle = styled(P)`
  font-weight: 600;
  padding: 16px 20px 0;
`;

export const FilterWrapper = styled(Div)`
  display: none;
  padding: 16px 20px 16px 20px;

  ${BREAKPOINTS.small`
    display: block;
  `}
`;
