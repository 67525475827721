import {Icon} from '@atoms';
import {Div, FlexRow} from '@quarks';

import styled from 'styled-components';

export const Cell = styled(Div)`
  background: ${({theme}) => theme.card.white.background};
  padding: calc(var(--padding-y) / 2) calc(var(--padding-x) / 2);
`;

export const CellButton = styled(FlexRow)`
  background: ${({theme}) => theme.card.transparent.background};
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 0 2px transparent;
  color: inherit;
  cursor: pointer;
  padding: 8px calc(var(--padding-x) / 2);
  transition: background var(--speed-fast), border var(--speed-fast);

  @media (hover) {
    &:hover {
      background: ${({theme}) => theme.card.transparent.hover.background};
      color: inherit;
      text-decoration: none;
      box-shadow: 0 0 0 2px transparent;
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${({theme}) => theme.brand};
    color: inherit;
    outline: none;
    text-decoration: none;
  }
`;

export const Caret = styled(Icon)`
  transition: transform var(--speed-fast);

  @media (hover) {
    ${CellButton}:hover & {
      transform: translate3d(4px, 0, 0);
    }
  }

  ${CellButton}:focus-visible & {
    transform: translate3d(4px, 0, 0);
  }
`;
