import {FlexRow} from '@quarks';
import {animated} from 'react-spring';
import styled from 'styled-components';

export const OccupancyInformationWrapper = styled(animated.div)`
  background: ${({theme}) => theme.background.white};
  display: flex;
  height: var(--modal-height);
  flex-direction: column;
`;

export const StyledRow = styled(FlexRow)`
  justify-content: flex-start;
  align-items: center;
`;
