import styled from 'styled-components';
import {getRandomItemInArray} from '@lib/utils';

import {BREAKPOINTS} from '@constants';

import bg_001 from '@images/bg/001_noon/01.webp';
import bg_002 from '@images/bg/001_noon/02.webp';
import bg_003 from '@images/bg/001_noon/03.webp';
import bg_004 from '@images/bg/001_noon/04.webp';
import bg_005 from '@images/bg/001_noon/05.webp';
import bg_006 from '@images/bg/001_noon/06.webp';
import bg_007 from '@images/bg/001_noon/07.webp';
import bg_008 from '@images/bg/001_noon/08.webp';
import bg_009 from '@images/bg/001_noon/09.webp';
import bg_010 from '@images/bg/001_noon/10.webp';
import bg_011 from '@images/bg/001_noon/11.webp';
import bg_012 from '@images/bg/001_noon/12.webp';
import bg_013 from '@images/bg/001_noon/13.webp';
import bg_014 from '@images/bg/001_noon/14.webp';
import bg_015 from '@images/bg/001_noon/15.webp';
import bg_016 from '@images/bg/001_noon/16.webp';
import bg_017 from '@images/bg/001_noon/17.webp';
import bg_018 from '@images/bg/001_noon/18.webp';
import bg_019 from '@images/bg/001_noon/19.webp';
import bg_020 from '@images/bg/001_noon/20.webp';
import bg_021 from '@images/bg/001_noon/21.webp';
import bg_022 from '@images/bg/001_noon/22.webp';
import bg_023 from '@images/bg/001_noon/23.webp';
import bg_024 from '@images/bg/001_noon/24.webp';
import bg_025 from '@images/bg/001_noon/25.webp';
import bg_026 from '@images/bg/001_noon/26.webp';
import bg_027 from '@images/bg/001_noon/27.webp';
import bg_028 from '@images/bg/001_noon/28.webp';

import am_09 from '@images/bg/002_am/09.webp';
import am_13 from '@images/bg/002_am/13.webp';
import am_22 from '@images/bg/002_am/22.webp';
import am_25 from '@images/bg/002_am/25.webp';

const bgArray = [
  bg_001,
  bg_002,
  bg_003,
  bg_004,
  bg_005,
  bg_006,
  bg_007,
  bg_008,
  bg_009,
  bg_010,
  bg_011,
  bg_012,
  bg_013,
  bg_014,
  bg_015,
  bg_016,
  bg_017,
  bg_018,
  bg_019,
  bg_020,
  bg_021,
  bg_022,
  bg_023,
  bg_024,
  bg_025,
  bg_026,
  bg_027,
  bg_028,
];

const smBgArray = [am_09, am_13, am_22, am_25];

const getBg = getRandomItemInArray(bgArray);
const getSmBg = getRandomItemInArray(smBgArray);

export const LoginContent = styled.div`
  align-items: center;
  background: ${({theme}) => theme.card.beige500.background} fixed center / cover no-repeat url(${getBg});
  display: flex;
  grid-area: header-start / fullbleed / fullbody-end / fullbleed;
  justify-content: center;
  z-index: 10;

  ${BREAKPOINTS.small`
    align-items: stretch;
    background: fixed center / cover no-repeat url(${getSmBg});
    grid-area: header-start / fullbleed / footer-end / fullbleed;
    justify-content: center;
  `}
`;
