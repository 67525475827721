import {forceSignInRedirect, getIsUserFeedbackShown, getUserFeedbackMessage, setIsSigningOut} from '@lib/store';
import {useAppSelector} from './useAppSelector';
import {useEffect} from 'react';
import {toast} from 'react-hot-toast';
import {useAppDispatch} from './useAppDispatch';
import {useTranslation} from 'react-i18next';
import {useDialog} from './useDialog';
import {ErrorMessage} from '@infrastructure';

export function useShowErrorToUser() {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {alertDialog} = useDialog();

  const showFeedback = useAppSelector(getIsUserFeedbackShown);
  const feedbackMessage = useAppSelector(getUserFeedbackMessage);

  useEffect(
    function showErrorToUser() {
      async function promptSignIn() {
        await alertDialog(t('auth:SignIn'), t('error:AuthorizationError'), 'lock');
        dispatch(setIsSigningOut(true));
        dispatch(forceSignInRedirect());
      }

      if (showFeedback) {
        // In the case of validation errors, the message is actually just a string
        const msgKey = feedbackMessage as ErrorMessage;

        switch (msgKey) {
          // Authorization errors are blocking
          case 'error:AuthorizationError':
            promptSignIn();
            break;
          case 'error:ConnectionError':
          case 'error:GenericError':
            toast.error(t(msgKey));
            break;
          default:
            toast.error(feedbackMessage);
        }
      }
    },
    [showFeedback, feedbackMessage, t, alertDialog, dispatch],
  );
}
