import {MeetingDetailsCardDateTimeProps} from './types';
import {useTranslation} from 'react-i18next';

import {format, parseISO} from 'date-fns';
import {Duration} from '@atoms';
import {Div, P} from '@quarks';

export const MeetingDetailsCardDateTime = ({endDateTime, isAllDay, startDateTime}: MeetingDetailsCardDateTimeProps) => {
  const {t} = useTranslation();

  return (
    <Div>
      <P
        fontWeight="bold"
        data-testid="organisms-eventDetails-MeetingDetailsCardDateTime_time">
        {isAllDay
          ? t('meeting:AllDay')
          : `${format(parseISO(startDateTime), 'EEEE dd MMM HH:mm')} ${t('ToTagForTimeInterval')} ${format(
              parseISO(endDateTime),
              'HH:mm',
            )}`}
        {!isAllDay && (
          <>
            {` (`}
            <Duration
              startDate={startDateTime}
              endDate={endDateTime}
            />
            {`)`}
          </>
        )}
      </P>
    </Div>
  );
};
