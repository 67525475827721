import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  getAreaById,
  getAreaSensorInfo,
  getIsOccupancyEnabled,
  getSensorLastUpdatedText,
  getWorkspaceOccupancyForArea,
} from '@lib/store';
import {useAppSelector} from './useAppSelector';

export const useAreaSensorInfo = (areaId: string) => {
  const {t} = useTranslation();
  const isOccupancyEnabled = useAppSelector(getIsOccupancyEnabled);
  const area = useAppSelector((state) => getAreaById(state, areaId));
  const sensorData = useAppSelector((state) => getWorkspaceOccupancyForArea(state, area?.buildingId ?? '', areaId));

  return useMemo(() => {
    const areaSensorInformation = getAreaSensorInfo(t, area, sensorData);
    const updateTimeLabelText = getSensorLastUpdatedText(t, sensorData?.stateTimestamp);
    if (!isOccupancyEnabled || !areaSensorInformation) return undefined;

    return {
      areaSensorInfo: areaSensorInformation,
      updateTimeLabelText,
    };
  }, [area, isOccupancyEnabled, sensorData, t]);
};
