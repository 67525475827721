import {BREAKPOINTS} from '@constants';
import {animated} from 'react-spring';
import styled from 'styled-components';

export const SubPageWrapper = styled.div`
  height: 100%;
  max-width: calc(100vw - 32px);
  overflow: hidden;

  ${BREAKPOINTS.small`
    max-width: 100vw;
  `}
`;

export const Slider = styled(animated.div)<{
  $width: string;
}>`
  display: flex;
  height: 100%;
  width: ${({$width}) => $width || '100%'};
`;

export const Page = styled.div<{
  $pageWidth: string;
}>`
  height: 100%;
  overflow: hidden auto;
  width: ${({$pageWidth}) => $pageWidth || '100%'};

  &[aria-hidden='true'] {
    & > * {
      display: none !important;
    }
  }
`;
