import {AvailabilityDetailsCardProps} from './types';
import {useTranslation} from 'react-i18next';

import {
  getUserDirectoryObjectId,
  getGroupedAndSortedAvailabilities,
  AttendeeAvailability,
  getWorkdayByDate,
} from '@lib/store';
import {parseISO} from 'date-fns';

import {useAppSelector, useModal} from '@hooks';

import {ThreeColumnCell} from '@atoms';
import {Avatar, RSVP} from '@molecules';
import {ModalPage} from '@organisms';
import {FlexCol, H2} from '@quarks';

export const AvailabilityDetailsCard = ({
  date,
  onClose,
  timeslotId,
  source,
  isDateRangeValid,
}: AvailabilityDetailsCardProps) => {
  const {removePages} = useModal();
  const dateAsDate = parseISO(date);
  const {t} = useTranslation();
  const workday = useAppSelector((state) => getWorkdayByDate(state, date));
  const loggedInUserDirectoryObjectId = useAppSelector(getUserDirectoryObjectId);

  const groupedAttendeeAvailabilities = useAppSelector((state) =>
    getGroupedAndSortedAvailabilities(state, {
      loggedInUserWorkspaceReservationBuildingId: workday?.nodeId,
      loggedInUserWorkspaceReservationBuildingName: workday?.nodeName,
      isDateRangeValid,
      date: dateAsDate,
      source: source,
      selectedTimeSuggestionId: timeslotId,
    }),
  );

  const availabilityGrouped = Object.entries(groupedAttendeeAvailabilities).map(([groupKey, data]) => {
    const title = data?.[0]?.shiftBuildingName ?? t(`meeting:CreateMeetingAttendeeDetails${groupKey}` as any);
    return {title, data};
  });

  const displayName = (item: AttendeeAvailability) =>
    item.directoryObjectId === loggedInUserDirectoryObjectId ? t('You') : item.displayName || item.email;

  return (
    <ModalPage
      onBack={() => removePages(1)}
      onClose={onClose}
      title={t('screen:InviteesAvailability')}>
      <FlexCol
        as="section"
        gap={16}>
        {availabilityGrouped.map(({title, data}) => (
          <div key={title}>
            <H2>{title}</H2>
            {data.map((invitee) => (
              <ThreeColumnCell
                key={invitee.email}
                left={<Avatar user={{...invitee, name: invitee.displayName || invitee.email}} />}
                middle={
                  <p data-testid="oraganisms-createEvent-AvailabilityDetailsCard_invitee-name">
                    {displayName(invitee)}
                  </p>
                }
                padding="medium"
                right={
                  <RSVP
                    rsvp={
                      invitee.availability === 'Available'
                        ? 'Accepted'
                        : invitee.availability === 'Unavailable'
                        ? 'Declined'
                        : 'Tentative'
                    }
                  />
                }
                separator={false}
              />
            ))}
          </div>
        ))}
      </FlexCol>
    </ModalPage>
  );
};
