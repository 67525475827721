import {OnboardingWelcomeCardProps} from './types';

import {getSettings, getUserFirstName} from '@lib/store';

import {useTranslation} from 'react-i18next';

import {useAppSelector} from '@hooks';
import {animated, config, useTransition} from 'react-spring';

import {Button} from '@molecules';
import {MoreInformationCell, QuestionsCell, QuotaCard} from '@organisms';
import {FlexCol, H1, H2, P} from '@quarks';
import {CardBottom, CardTop, QuotaWrapper, StyledCard, WelcomeCard} from '../styles';

export const OnboardingWelcomeCard = ({handleContinue, page}: OnboardingWelcomeCardProps) => {
  const userFirstName = useAppSelector(getUserFirstName);
  const {officeInstructions} = useAppSelector(getSettings);
  const {t} = useTranslation();
  const transition = useTransition(page === 0, {
    from: {transform: 'translate3d(100vw, 0, 0)'},
    enter: {transform: 'translate3d(0vw, 0, 0)'},
    leave: {transform: 'translate3d(-100vw, 0, 0)'},
    config: {clamp: true, ...config.stiff},
  });

  return transition(
    (style, item) =>
      item && (
        <animated.div style={style}>
          <StyledCard>
            <CardTop>
              <H1>{t('profile:HelloMessage', {name: userFirstName})}</H1>
            </CardTop>
            <WelcomeCard>
              <P>{officeInstructions}</P>
              <QuotaWrapper>
                <H2>{t('profile:YourQuota')}</H2>
                <QuotaCard />
              </QuotaWrapper>
              <FlexCol>
                <QuestionsCell />
                <hr />
                <MoreInformationCell />
              </FlexCol>
            </WelcomeCard>
            <CardBottom>
              <Button
                button="primary"
                onClick={handleContinue}
                size="full">
                {t('Continue')}
              </Button>
            </CardBottom>
          </StyledCard>
        </animated.div>
      ),
  );
};
