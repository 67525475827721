import styled from 'styled-components';
import {Swiper, SwiperSlide} from 'swiper/react';

export const StyledSwiper = styled(Swiper)`
  &.swiper {
    margin-left: 0;
    z-index: 0;
  }

  &.swiper > div.swiper-wrapper {
    justify-content: flex-start;
    margin: 0;
    padding: 10px 0 20px;
    width: max-content;
    z-index: 0;
  }

  &.swiper > div.swiper-scrollbar {
    background: transparent;
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: grid;
  height: auto;
  width: auto;
  z-index: 0;
`;
