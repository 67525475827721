import {Card} from '@atoms';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: var(--gap-y);
  padding: var(--padding-y) var(--padding-x);
  padding: 0;
  gap: 32px 0;
  box-shadow: none;
`;
