import {MeetingDetailsCardMeetingLinkProps} from './types';
import {useTranslation} from 'react-i18next';

import {pxToRem} from '@utils';
import {Icon, ThreeColumnCell} from '@atoms';
import {MeetingLink, MeetingLinkWrapper} from './styles';

export const MeetingDetailsCardMeetingLink = ({onlineMeetingUrl}: MeetingDetailsCardMeetingLinkProps) => {
  const {t} = useTranslation();

  return (
    <MeetingLinkWrapper $noUrl={!onlineMeetingUrl}>
      {onlineMeetingUrl && (
        <MeetingLink
          href={onlineMeetingUrl}
          rel="noopener noreferrer"
          data-testid="organisms-eventDetails-MeetingDetailsCardMeetingLink_join-meeting-link"
          target="_blank">
          <ThreeColumnCell
            left={
              <Icon
                icon="videocamera"
                size={pxToRem(24)}
              />
            }
            middle={<p>{t('meeting:JoinOnlineMeeting')}</p>}
            padding="small"
            right={
              <Icon
                icon="caretRight"
                size={pxToRem(24)}
              />
            }
            separator={false}
          />
        </MeetingLink>
      )}
    </MeetingLinkWrapper>
  );
};
