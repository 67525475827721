import {useTranslation} from 'react-i18next';
import {TimeSlotSuggestionProps} from './types';

import {countAttendeeAvailabilitiesOnSite, countAvailableAttendees, getAppLanguage} from '@lib/store';
import {formatDate} from '@lib/utils';
import {parseISO} from 'date-fns';

import {useAppSelector, useModal} from '@hooks';
import {EventTimeSlot} from '@molecules';
import {AvailabilityDetailsCard, CreateEventSuggestionItem} from '@organisms';

export const CreateEventTimeSlotSuggestionCell = ({
  suggestion,
  suggestionsLength,
  setSelectedSlotId,
  selectedSlotId,
  id,
  date,
  onClose,
  onClick,
}: TimeSlotSuggestionProps) => {
  const {addPages} = useModal();
  const {t} = useTranslation();
  const {timeSlotStart, attendeeAvailabilities, timeSlotEnd} = suggestion;
  const appLanguage = useAppSelector(getAppLanguage);
  const availableAttendees = countAvailableAttendees(attendeeAvailabilities);
  const attendeesOnSiteCount = countAttendeeAvailabilitiesOnSite(attendeeAvailabilities);

  const startDateTime = formatDate(parseISO(timeSlotStart), 'HH:mm', appLanguage);
  const endDateTime = formatDate(parseISO(timeSlotEnd), 'HH:mm', appLanguage);
  const eventDate = formatDate(parseISO(timeSlotStart), 'EEEE d MMMM', appLanguage);

  const totalAttendees = attendeeAvailabilities.length;

  return (
    <CreateEventSuggestionItem
      ariaLabel={t('meeting:CreateMeetingTimeSlotSuggestionAria', {
        startTime: startDateTime,
        endTime: endDateTime,
        date: eventDate,
        availableNumber: availableAttendees,
        total: totalAttendees,
        onSiteNumber: attendeesOnSiteCount,
      })}
      id={id}
      type="timeSlots"
      suggestionsLength={suggestionsLength}
      infoAriaLabel={t('meeting:CreateMeetingTimeSlotSuggestionAriaLabel')}
      item={suggestion}
      selectedId={selectedSlotId}
      setSelectedId={setSelectedSlotId}
      onClick={() => onClick(suggestion)}
      onInfoClick={() => {
        addPages([
          <AvailabilityDetailsCard
            date={date}
            onClose={onClose}
            timeslotId={id}
            source="suggestion"
            isDateRangeValid={true}
          />,
        ]);
      }}>
      <EventTimeSlot
        attendeesOnSite={attendeesOnSiteCount}
        availableAttendees={availableAttendees}
        endDateTime={timeSlotEnd}
        startDateTime={timeSlotStart}
        totalAttendees={totalAttendees}
      />
    </CreateEventSuggestionItem>
  );
};
