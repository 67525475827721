import {FeatureName, FeatureToggle, useOverridableFeatureFlags} from '@lib/store';

// For debug purposes, store reference to search params at initial load
const localOverrides = createLocalOverrides(document.location.search);

export const useFeatureToggle = () => useOverridableFeatureFlags(localOverrides);

function createLocalOverrides(query: string) {
  const params = new URLSearchParams(query);
  const overrides = new Map<FeatureName, boolean>();

  for (const featureName in FeatureToggle) {
    const override = params.get(featureName);

    if (override === '1') overrides.set(featureName as FeatureName, true);
    if (override === '0') overrides.set(featureName as FeatureName, false);
  }

  return overrides;
}
