import {useTranslation} from 'react-i18next';
import {MeetingDetailsCardLocationCellProps} from './types';

import {
  MeetingRoom,
  getMeetingRoomsGroupedByBuilding,
  getMeetingUpdateLoadingStatus,
  getWorkdayByDate,
  updateMeeting,
  withAsyncThunkErrorHandling,
  getMeetingRoomsByMeetingId,
} from '@lib/store';
import {pxToRem} from '@utils';

import {useAppDispatch, useAppSelector, useDestructiveDialog, useModal} from '@hooks';
import {AttendeeDetailsCard, MeetingRoomDetailsCard} from '@organisms';
import {Subtitle} from '@quarks';
import toast from 'react-hot-toast';
import {
  BuildingButton,
  BuildingButtonAvatarStack,
  BuildingButtonIcon,
  BuildingButtonTitle,
  BuildingWrapper,
  MeetingRoomButton,
  MeetingRoomButtonIcon,
  MeetingRoomButtonInformationWrapper,
  MeetingRoomButtonWrapper,
  Name,
  StyledMeetingRoomButtonWrapper,
} from './styles';
import {Icon} from '@atoms';

export const MeetingDetailsCardLocationCell = ({
  building,
  currentUser,
  date,
  meetingId,
}: MeetingDetailsCardLocationCellProps) => {
  const {addPages, closeModal} = useModal();
  const {destructiveDialog} = useDestructiveDialog();
  const meetingRooms = useAppSelector((state) => getMeetingRoomsGroupedByBuilding(state, meetingId));
  const workday = useAppSelector((state) => getWorkdayByDate(state, date));
  const {t} = useTranslation('meeting');
  const isUpdatingEvent = useAppSelector(getMeetingUpdateLoadingStatus) === 'Loading';
  const dispatch = useAppDispatch();
  const allMeetingRoomsForMeeting = useAppSelector((state) => getMeetingRoomsByMeetingId(state, meetingId));

  const {id: currentBuildingId} = building;
  const workdayBuildingId = workday?.nodeId;

  const hasAttendees = !!building.attendees.length;

  const handleRemoveRoom = async (value: MeetingRoom) => {
    const result = await destructiveDialog(t('DeleteRoomBookingTitle'), t('DeleteRoomBookingMessage'), 'warning');

    if (!result) return;

    const {success} = await dispatch(
      withAsyncThunkErrorHandling(() =>
        updateMeeting({
          origin: 'DeleteRoomFromMeetingDetail',
          meetingId: meetingId,
          systemTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          rooms: allMeetingRoomsForMeeting.filter((room) => room.email !== value.email).map((room) => room.email),
        }),
      ),
    );
    if (success) {
      toast.success(t('DeleteRoomBookingConfirmation', {roomName: value.displayName}));
    }
  };

  return (
    <BuildingWrapper data-testid="organisms-eventDetails-MeetingDetailsCardLocationCell">
      <BuildingButton
        $hasMeetingRooms={!!currentBuildingId && !!meetingRooms[currentBuildingId]}
        as="button"
        card={workdayBuildingId === currentBuildingId ? 'energizingYellow' : 'beige200'}
        clickable={true}
        disabled={!hasAttendees}
        onClick={() => {
          if (hasAttendees) addPages([<AttendeeDetailsCard building={building} />]);
        }}>
        <BuildingButtonIcon
          color="currentColor"
          icon={
            building.locationType === 'Remote'
              ? 'remote'
              : building.locationType === 'Unknown'
              ? 'locationUnknown'
              : 'office'
          }
          size={pxToRem(24)}
        />
        <BuildingButtonTitle>
          {building.locationDescription} {hasAttendees && `(${building.attendees.length})`}
        </BuildingButtonTitle>
        {hasAttendees && (
          <BuildingButtonAvatarStack
            date={date}
            overlap={false}
            tooltipId="MeetingDetailsCardLocationCell"
            users={building.attendees.map((attendee) => ({
              ...attendee,
              name: attendee.displayName,
              id: attendee.directoryObjectId,
              email: attendee.email,
              imageHash: attendee.imageHash,
              initials: attendee.initials,
              inviteResponse: attendee.responseStatus,
            }))}
          />
        )}
      </BuildingButton>
      {currentBuildingId &&
        !!meetingRooms[currentBuildingId] &&
        Object.values(meetingRooms[currentBuildingId]).map((meetingRoom) => (
          <StyledMeetingRoomButtonWrapper
            $sameBuilding={workdayBuildingId === currentBuildingId}
            position="relative"
            key={meetingRoom.email}>
            <MeetingRoomButton
              as="button"
              card={workdayBuildingId === currentBuildingId ? 'energizingYellow' : 'beige200'}
              clickable={true}
              disabled={isUpdatingEvent}
              onClick={() =>
                addPages([
                  <MeetingRoomDetailsCard
                    room={meetingRoom}
                    onClose={closeModal}
                  />,
                ])
              }>
              <MeetingRoomButtonInformationWrapper>
                <Name
                  data-testid={`organisms-eventDetails-MeetingDetailsCardLocaionCell_meetingRoom-${meetingRoom.displayName}`}>
                  {meetingRoom.displayName}
                </Name>
                <Subtitle fontSize="sm">{t('MeetingRoomSeat', {count: meetingRoom.capacity})}</Subtitle>
              </MeetingRoomButtonInformationWrapper>
            </MeetingRoomButton>
            {currentUser?.responseStatus === 'Organizer' && (
              <MeetingRoomButtonIcon>
                {currentUser?.responseStatus === 'Organizer' ? (
                  <MeetingRoomButtonWrapper
                    aria-label={t('DeleteRoomBookingTitle')}
                    data-testid={`organisms-eventDetails-MeetingDetailsCardLocationCell_removeRoom-button-${meetingRoom.displayName}`}
                    disabled={isUpdatingEvent}
                    onClick={() => handleRemoveRoom(meetingRoom)}>
                    <Icon
                      color="currentColor"
                      icon="trash"
                      size={pxToRem(24)}
                    />
                  </MeetingRoomButtonWrapper>
                ) : (
                  <Icon
                    color="currentColor"
                    icon="caretRight"
                    size={pxToRem(24)}
                  />
                )}
              </MeetingRoomButtonIcon>
            )}
          </StyledMeetingRoomButtonWrapper>
        ))}
    </BuildingWrapper>
  );
};
