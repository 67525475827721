import {useMemo} from 'react';
import {CreateEventSelectedTimeSlotProps} from './types';

import {countAttendeeAvailabilitiesOnSite, countAvailableAttendees} from '@lib/store';

import {useLoadAvailableInvitees} from '@hooks';

import {FlexRow} from '@quarks';
import {Loader} from '@atoms';
import {EventTimeSlot} from '@molecules';

export const CreateEventSelectedTimeSlot = ({
  attendees,
  endDateTime,
  startDateTime,
}: CreateEventSelectedTimeSlotProps) => {
  const attendeeEmails = useMemo(() => {
    return attendees.map((attendee) => attendee.email);
  }, [attendees]);

  const {attendeeAvailabilities, loading: isLoadingAttendeeAvailabilities} = useLoadAvailableInvitees({
    startDate: startDateTime,
    endDate: endDateTime,
    attendeeEmails,
  });

  const availableAttendees = countAvailableAttendees(attendeeAvailabilities);
  const attendeesOnSiteCount = countAttendeeAvailabilitiesOnSite(attendeeAvailabilities);

  const totalAttendees = attendeeAvailabilities.length;

  return isLoadingAttendeeAvailabilities ? (
    <FlexRow
      alignItems="center"
      justifyContent="center"
      width="100%">
      <Loader />
    </FlexRow>
  ) : (
    <EventTimeSlot
      attendeesOnSite={attendeesOnSiteCount}
      availableAttendees={availableAttendees}
      endDateTime={endDateTime}
      startDateTime={startDateTime}
      totalAttendees={totalAttendees}
    />
  );
};
