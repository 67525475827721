import {MeetingDetailsCardLocationsListProps} from './types';

import {getDefaultBuildingId, getMeetingRoomsGroupedByBuilding} from '@lib/store';

import {sortAttendeesByLocation} from '@utils';
import {useAppSelector} from '@hooks';
import {MeetingDetailsCardLocationCell} from '@organisms';
import {Grid} from '@quarks';

export const MeetingDetailsCardLocationsList = ({
  attendees,
  currentUser,
  date,
  meetingId,
}: MeetingDetailsCardLocationsListProps) => {
  const defaultBuildingId = useAppSelector(getDefaultBuildingId);
  const meetingRoomsByBuilding = useAppSelector((state) => getMeetingRoomsGroupedByBuilding(state, meetingId));
  const attendeesGroupedByLocation = sortAttendeesByLocation(
    attendees,
    currentUser,
    defaultBuildingId,
    meetingRoomsByBuilding,
    date,
  );

  return (
    <Grid rowGap={16}>
      {attendeesGroupedByLocation.map((building) => (
        <MeetingDetailsCardLocationCell
          key={building.id}
          building={building}
          currentUser={currentUser}
          date={date}
          meetingId={meetingId}
        />
      ))}
    </Grid>
  );
};
