import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {ParkingSelectionCellProps} from './types';

import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {
  changeParkingSpot,
  getParkingReservationByDate,
  ParkingReservation,
  reserveParkingSpot,
  withAsyncThunkErrorHandling,
} from '@lib/store';

import {FlexCol, P} from '@quarks';
import {CapacityIndicator, ModalCellItem, ModalCellItemIcon} from '@molecules';
import {toast} from 'react-hot-toast';

export const ParkingSelectionCell = ({date, parkingNode, loading, setLoading}: ParkingSelectionCellProps) => {
  const {t} = useTranslation();
  const {closeModal} = useModal();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, date));
  const {parkingZoneId, isClosed, occupation, capacity, title, subtitle, nodeAvailability, nodeId} = parkingNode;

  const isUnavailable = nodeAvailability === 'Unavailable';
  const textColor = isUnavailable ? theme.button.text.disabled.color : theme.text.color.body;
  const isCurrentlyReservedNode = nodeId === parkingReservation?.nodeId;

  const getSubtitle = () => {
    if (isUnavailable) {
      return subtitle;
    }
    const anyAvailableSpotText = t('parking:AnyAvailableSpot');

    return parkingZoneId ? `${subtitle}, ${anyAvailableSpotText.toLowerCase()}` : anyAvailableSpotText;
  };

  const handleOnClick = async () => {
    if (isUnavailable || isCurrentlyReservedNode) return;

    const args = {
      id: parkingReservation?.id,
      nodeId,
      date,
    } as ParkingReservation;

    setLoading(true);
    const {success} = await dispatch(
      withAsyncThunkErrorHandling(() => (parkingReservation ? changeParkingSpot(args) : reserveParkingSpot(args))),
    );

    if (success) {
      toast.success(t('parking:ParkingReservedMessage'));
    }
    setLoading(false);

    closeModal();
  };

  return (
    <ModalCellItem
      alignItems="flex-start"
      onClick={handleOnClick}
      disabled={isUnavailable || loading}
      left={
        <ModalCellItemIcon
          selected={isCurrentlyReservedNode}
          disabled={isUnavailable}
          icon={parkingZoneId ? 'area' : 'parking'}
        />
      }
      middle={
        <FlexCol gap={8}>
          <P
            color={textColor}
            fontWeight="bold">
            {title}
          </P>
          {(!isUnavailable || parkingZoneId) && <P color={textColor}>{getSubtitle()}</P>}
          {isUnavailable ? (
            <P color={textColor}>{isClosed ? t('parking:ParkingLotClosed') : t('Full')}</P>
          ) : (
            <CapacityIndicator
              occupation={occupation}
              capacity={capacity}
            />
          )}
        </FlexCol>
      }
    />
  );
};
