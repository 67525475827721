import {ReactDatePickerCustomHeaderProps} from 'react-datepicker';

import {formatISO} from 'date-fns';

import {Div, FlexRow, P} from '@quarks';
import {Date} from '@atoms';
import {IconButton} from '@molecules';

export const DatePickerHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <FlexRow
      alignItems="center"
      padding={8}>
      <Div width="1.25rem">
        {!prevMonthButtonDisabled && (
          <IconButton
            aria-label="Prev month"
            icon="caretLeft"
            iconButton="tertiary"
            size="xsmall"
            onClick={decreaseMonth}
          />
        )}
      </Div>
      <P fontWeight="bold">
        <Date
          date={formatISO(date)}
          format="MMMM yyyy"
        />
      </P>
      <Div width="1.25rem">
        {!nextMonthButtonDisabled && (
          <IconButton
            aria-label="Next month"
            icon="caretRight"
            iconButton="tertiary"
            size="xsmall"
            onClick={increaseMonth}
          />
        )}
      </Div>
    </FlexRow>
  );
};
