import {FlexRow} from '@quarks';
import {StyledFlexRow} from './styles';
import {ChipFilterNew} from '@molecules';
import {useTranslation} from 'react-i18next';

export const CalendarOverviewConnectionFilter = () => {
  const {t} = useTranslation();

  return (
    <StyledFlexRow>
      <FlexRow
        sm={{flexWrap: 'nowrap'}}
        flexWrap="wrap"
        justifyContent="flex-start"
        width="100%"
        gap={8}>
        <ChipFilterNew
          filterSlice="CalendarOverview_Connections"
          areFilterTransparent
          dontUseSwiper
          showSearchBarInFilterScreen
          searchBarInFilterScreenOptions={{
            placeholderText: t('connection:SearchConnectionPlaceholder'),
            searchByProperties: ['email', 'name'],
          }}
        />
      </FlexRow>
    </StyledFlexRow>
  );
};
