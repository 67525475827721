import {useTranslation} from 'react-i18next';

import {Illustration} from '@atoms';
import {Canvas, Content} from '@templates';
import {FlexCol, H1, P} from '@quarks';

export const NotFound = () => {
  const {t} = useTranslation('error');

  return (
    <>
      <Canvas centerContent></Canvas>
      <Content>
        <FlexCol
          alignItems="center"
          justifyContent="center"
          height="100%"
          paddingBottom={64}
          rowGap={32}
          sm={{padding: '0 16px 64px'}}>
          <Illustration
            illustration="error"
            size="100px"
          />
          <FlexCol
            alignItems="center"
            rowGap={16}>
            <H1>{t('404Title')}</H1>
            <P>{t('404Message')}</P>
          </FlexCol>
        </FlexCol>
      </Content>
    </>
  );
};
