import {createContext, ReactNode, useEffect, useRef, useState} from 'react';
import {SidePanelProviderProps, SidePanelContextProps, EventData} from './types';
import {trackNavigateToPage} from '@lib/infrastructure';

const initSidePanel = {
  closeSidePanel: () => void 0,
  sidePanelComponent: null,
  openSidePanel: () => void 0,
  setSidePanelContent: () => void 0,
  isSidePanelOpen: false,
  toggleSidePanel: () => void 0,
};

export const SidePanelContext = createContext<SidePanelContextProps>(initSidePanel);

export const SidePanelProvider = ({children}: SidePanelProviderProps) => {
  const sidePanelEventDataRef = useRef<Partial<EventData>>({});
  const [sidePanelComponent, setSidePanelComponent] = useState<ReactNode>(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState<boolean>(false);

  const openSidePanel = (options?: {silent?: boolean}) => {
    setIsSidePanelOpen(true);
    const eventdata = sidePanelEventDataRef.current;

    if (!options?.silent && eventdata.pageId) {
      trackNavigateToPage(eventdata.pageId, false);
    }
  };

  const closeSidePanel = (options?: {silent?: boolean}) => {
    setIsSidePanelOpen(false);

    // clear data
    sidePanelEventDataRef.current = {};
  };

  const setSidePanelContent = (node: ReactNode, options?: {eventData: Partial<EventData>}) => {
    setSidePanelComponent(node);

    sidePanelEventDataRef.current = options?.eventData ?? {};
  };

  const toggleSidePanel = () => setIsSidePanelOpen(!isSidePanelOpen);

  useEffect(() => {
    if (isSidePanelOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isSidePanelOpen]);

  return (
    <SidePanelContext.Provider
      value={{
        closeSidePanel,
        sidePanelComponent,
        openSidePanel,
        setSidePanelContent,
        isSidePanelOpen,
        toggleSidePanel,
      }}>
      {children}
    </SidePanelContext.Provider>
  );
};
