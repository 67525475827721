import {useRef, useEffect} from 'react';

export const useScrollOutside = (callback: () => void) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('scroll', handleClick, true);

    return () => {
      document.removeEventListener('scroll', handleClick, true);
    };
  }, [callback, ref]);

  return ref;
};
