import {useCallback, useEffect, useState} from 'react';

export const useIsPageActive = () => {
  const [isPageVisible, setIsPageVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsPageVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(
    function updateVisibility() {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    },
    [handleVisibilityChange],
  );

  return isPageVisible;
};
