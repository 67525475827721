import {MeetingRoom} from '@lib/store';
import {Icon} from '@atoms';
import {useTranslation} from 'react-i18next';

export const MeetingRoomInfoFooter = ({room}: {room: MeetingRoom}) => {
  const {t} = useTranslation();
  return (
    <div className="flex gap-4 w-full pl-4 pt-4 pr-4">
      <div className="flex justify-center items-center size-12 bg-beige-500* rounded-lg flex-none">
        <Icon
          icon="meetingRoom"
          size={'22px'}
        />
      </div>
      <div className="flex flex-col mr-auto min-w-0">
        <strong> {room.displayName}</strong>
        <p className="truncate">
          {t('meeting:MeetingRoomFloorSeat', {floor: room.floorName, capacity: room.capacity})}
        </p>
      </div>
    </div>
  );
};
