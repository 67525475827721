import {useAppSelector} from './useAppSelector';
import {getAppLanguage} from '@lib/store';
import {formatDate} from '@lib/utils';
import parseISO from 'date-fns/parseISO';
import {DateFormats} from 'mapiq-atoms';

export const useFormatDate = (date: string, format: DateFormats): string => {
  const appLanguage = useAppSelector(getAppLanguage);
  if (date === '') return '';
  return formatDate(parseISO(date), format, appLanguage);
};
