import {FlexRow, P} from '@quarks';
import {useTranslation} from 'react-i18next';
import {StyledProgressIndicator} from './styles';
import {CapacityIndicatorProps} from './types';

export const CapacityIndicator = ({
  capacity,
  disabled,
  occupation,
  displayDescription = true,
}: CapacityIndicatorProps) => {
  const {t} = useTranslation();

  return (
    <FlexRow
      justifyContent="flex-start"
      alignItems="center"
      gap={8}>
      <StyledProgressIndicator
        $width={1 - occupation / capacity}
        $disabled={disabled}
      />
      {displayDescription ? (
        <P fontSize="xs">{occupation === capacity ? t('Full') : `${capacity - occupation}/${capacity}`}</P>
      ) : null}
    </FlexRow>
  );
};
