import {useEffect} from 'react';

import {getConnectionSuggestion, getIsLoadingConnectionSuggestionsList, loadConnectionSuggestions} from '@lib/store';

import {useAppDispatch, useAppSelector} from '@hooks';
import {useTranslation} from 'react-i18next';
import {Div, FlexCol, FlexRow, H2, P} from '@quarks';
import {Loader} from '@atoms';
import {ConnectionSuggestionCard} from '../ConnectionSuggestionCard';
import {StyledSwiperHorizontal} from './styles';

let didMount = false;

export const ConnectionsSuggestionsList = () => {
  const {t} = useTranslation();
  const {suggestions: connectionSuggestions, ...feedbackData} = useAppSelector(getConnectionSuggestion);
  const dispatch = useAppDispatch();
  const isLoadingConnectionsSuggestions = useAppSelector(getIsLoadingConnectionSuggestionsList);

  useEffect(() => {
    if (!didMount) {
      dispatch(loadConnectionSuggestions());
      didMount = true;
    }
  }, [dispatch]);

  return (
    <Div sm={{padding: '0 16px'}}>
      {isLoadingConnectionsSuggestions ? (
        <FlexRow
          alignItems="center"
          justifyContent="center">
          <Loader size="2rem" />
        </FlexRow>
      ) : connectionSuggestions && connectionSuggestions.length > 0 ? (
        <>
          <H2>{t('connection:PeopleYouMayKnow')}</H2>
          <StyledSwiperHorizontal>
            {connectionSuggestions.map((connectionSuggestion) => (
              <ConnectionSuggestionCard
                key={connectionSuggestion.connectedUser.id}
                connectionSuggestion={connectionSuggestion}
                feedbackData={{...feedbackData, suggestionId: feedbackData.id}}
              />
            ))}
          </StyledSwiperHorizontal>
        </>
      ) : (
        <FlexCol gap={8}>
          <H2>{t('connection:PeopleYouMayKnow')}</H2>
          <P>{t('connection:EmptySuggestions')}</P>
        </FlexCol>
      )}
    </Div>
  );
};
