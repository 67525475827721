import {Illustration} from '@atoms';
import {NoMapContainer} from './styles';
import {P} from '@quarks';
import {useTranslation} from 'react-i18next';

export const NoMapPlaceholder = () => {
  const {t} = useTranslation();
  const noMapPresentMessage = t('hereAndNow:NoMapForFloor');

  return (
    <NoMapContainer>
      <Illustration illustration="map" />
      <P fontSize="md">{noMapPresentMessage}</P>
    </NoMapContainer>
  );
};
