import styled from 'styled-components';
import {ModalPage} from '../../ModalPage';
import {FlexCol} from '@quarks';

export const StyledModalPage = styled(ModalPage)`
  --modal-padding-x: 0;
  --modal-padding-y: 0;
  background: ${({theme}) => theme.background.white};

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid ${({theme}) => theme.brand};
  }
`;

export const StyledFlexCol = styled(FlexCol)`
  background: ${({theme}) => theme.divider.grey};
`;
