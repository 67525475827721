import {pxToRem} from '@utils';
import styled from 'styled-components';
import {baseStyle} from '../styles';

export const H1 = styled.h1`
  ${baseStyle}

  color: ${({color, theme}) => color || theme.text.color.heading};
  font-size: ${pxToRem(26)};
  font-weight: 500;
  line-height: ${pxToRem(32)};
  font-family: 'Feature Display', ui-sans-serif, system-ui, -apple-system, sans-serif;
`;
