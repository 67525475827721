import {Loader} from '@atoms';
import {Div} from '@quarks';
import styled from 'styled-components';

export const StyledCard = styled(Div)`
  height: 100%;
  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
`;

export const CenteredLoader = styled(Loader)`
  align-self: center;
`;
