import {useSwiper} from 'swiper/react';

import {CreateEventSuggestionItemProps} from './types';

import {Grid} from '@quarks';

import {Info, Input, StyledCard} from './styles';

export const CreateEventSuggestionItem = <T,>({
  item,
  onClick,
  ariaLabel,
  selectedId,
  children,
  setSelectedId,
  onInfoClick,
  id,
  suggestionsLength,
  infoAriaLabel,
  type,
  ...props
}: CreateEventSuggestionItemProps<T>) => {
  const swiper = useSwiper();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onClick(item);
    }

    if ((event.key === 'ArrowLeft' || event.key === 'ArrowUp') && selectedId === '0') {
      event.preventDefault();
    }

    if ((event.key === 'ArrowRight' || event.key === 'ArrowDown') && selectedId === `${suggestionsLength - 1}`) {
      event.preventDefault();
    }
  };

  return (
    <Grid
      placeItems="center"
      {...props}>
      <Input
        aria-label={ariaLabel}
        type="radio"
        name={type}
        id={id}
        value={selectedId}
        onFocus={() => {
          swiper.slideTo(parseInt(id));
          setSelectedId(id);
        }}
        onChange={() => swiper.slideTo(parseInt(id))}
        onKeyDown={handleKeyPress}
      />
      <StyledCard
        as="label"
        card="mapiqBlack"
        clickable={true}
        aria-label={ariaLabel}
        htmlFor={id}
        onClick={() => {
          onClick(item);
        }}>
        {children}
        <Info
          data-testid={`organisms-createEvent-CreateEventSuggestionItem-${type}_info-icon`}
          icon="info"
          iconButton="secondary"
          size="xsmall"
          onClick={(e) => {
            onInfoClick();
            e.preventDefault();
            e.stopPropagation();
          }}
          tabIndex={selectedId !== id ? -1 : 0}
          aria-label={infoAriaLabel}
          weight="regular"
        />
      </StyledCard>
    </Grid>
  );
};
