import {ModalPageBottomProps} from './types';
import {useTranslation} from 'react-i18next';

import {Button} from '@molecules';
import {ModalPageWrapper} from './styles';

/**
 * @param buttonType - Defaults to 'primary'
 * @param cancelButtonType - Defaults to 'tertiary'
 */

export const ModalPageBottom = ({
  buttonDisabled,
  buttonLabel,
  buttonType = 'primary',
  buttonLoading,
  cancelButtonDisabled,
  cancelButtonLabel,
  cancelButtonType = 'tertiary',
  className,
  destructive,
  onCancel,
  onClick,
  style,
  ...props
}: ModalPageBottomProps) => {
  const {t} = useTranslation('translation');

  const dataTestId = props['data-testid' as keyof typeof props] ?? 'organisms-ModalPageBottom-ModalPageBottom';

  return (
    <ModalPageWrapper
      className={className}
      style={style}>
      {!!onCancel && (
        <Button
          data-testid={`${dataTestId}_cancel-button`}
          loading={buttonLoading}
          button={cancelButtonType}
          disabled={cancelButtonDisabled}
          onClick={onCancel}
          size="full">
          {cancelButtonLabel || t('Cancel')}
        </Button>
      )}
      <Button
        data-testid={`${dataTestId}_confirm-button`}
        button={!!destructive ? 'secondary destructive' : buttonType}
        disabled={buttonDisabled}
        loading={buttonLoading}
        onClick={onClick}
        size="full">
        {buttonLabel || (destructive ? t('Delete') : t('Confirm'))}
      </Button>
    </ModalPageWrapper>
  );
};
