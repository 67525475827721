import {Children, cloneElement, forwardRef, isValidElement, useImperativeHandle, useRef} from 'react';
import {SwiperHorizontalProps} from './types';

import Swiper, {FreeMode, Keyboard, Mousewheel, Scrollbar} from 'swiper';
import 'swiper/scss';
import 'swiper/scss/free-mode';
import 'swiper/scss/keyboard';
import 'swiper/scss/scrollbar';

import {StyledSwiper, StyledSwiperSlide} from './styles';

export type SwiperRefType = {
  update: () => void;
  scrollToSlide: (index: number) => void;
  getCurrentActiveIndex: () => number | undefined;
};

export const SwiperHorizontal = forwardRef<SwiperRefType, SwiperHorizontalProps>(
  ({children, freeMode = true, spaceBetween = 10, ...props}: SwiperHorizontalProps, ref) => {
    const swiperRef = useRef<Swiper | null>(null);

    useImperativeHandle(ref, () => ({
      update: () => {
        if (swiperRef.current) {
          swiperRef.current.update();
        }
      },
      scrollToSlide: (index: number) => {
        if (swiperRef.current) {
          swiperRef.current.slideTo(index);
        }
      },
      getCurrentActiveIndex: () => {
        if (swiperRef.current) {
          return swiperRef.current.activeIndex;
        }
      },
    }));

    return (
      <StyledSwiper
        spaceBetween={spaceBetween}
        onSwiper={(swiper: Swiper) => (swiperRef.current = swiper)}
        direction="horizontal"
        freeMode={freeMode}
        grabCursor={true}
        scrollbar={{
          hide: true,
        }}
        mousewheel={{
          forceToAxis: true,
        }}
        slidesPerView="auto"
        modules={[Scrollbar, FreeMode, Mousewheel, Keyboard]}
        {...props}>
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return <StyledSwiperSlide>{cloneElement(child)}</StyledSwiperSlide>;
          }
        })}
      </StyledSwiper>
    );
  },
);
