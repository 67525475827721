import {OptionProps, SelectValue} from './types';

import {StyledOption} from './styles';

export const Option = <TValue extends SelectValue>({
  activeIndex,
  children,
  className,
  index,
  selectedValue,
  setActiveIndex,
  setOpen,
  setValue,
  style,
  value,
  ...props
}: OptionProps<TValue>) => {
  const handleClick = () => {
    if (index !== undefined && !!setActiveIndex && !!setOpen && !!setValue && !!value) {
      setValue(value);
      setActiveIndex(index);
      setOpen(false);
    }
  };

  const handleMouseMove = () => {
    if (index !== undefined && !!setActiveIndex) {
      setActiveIndex(index);
    }
  };

  return (
    <StyledOption
      $value={value}
      $activeIndex={activeIndex}
      $index={index}
      $selectedValue={selectedValue}
      className={className}
      aria-selected={value === selectedValue}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      role="option"
      style={style}
      {...props}>
      {children}
    </StyledOption>
  );
};
