import {useFeatureToggle} from '@hooks';
import {useAppSelector, getIsOccupancyEnabled} from '@lib/store';
import {useMemo} from 'react';
import {Navigate} from 'react-router-dom';
import {Outlet} from 'react-router-dom';
import {useTabByPathname} from './organisms/hereAndNow/helpers';

export const HaNRedirect = () => {
  const {
    WorkspacesOnHereAndNow: isWorkspacesOnHereAndNowEnabled,
    HybridMeetingsCalendarView: isHybridMeetingsCalendarViewEnabled,
  } = useFeatureToggle();
  const isOccupancyEnabled = useAppSelector(getIsOccupancyEnabled);

  const tab = useTabByPathname();

  const availableTabs = useMemo(
    () =>
      ['rooms', 'workspaces', 'explore'].filter((tabId) => {
        switch (tabId) {
          case 'rooms':
            return isHybridMeetingsCalendarViewEnabled || isOccupancyEnabled;
          case 'workspaces':
            return isWorkspacesOnHereAndNowEnabled;
          case 'explore':
            return true;
        }
      }),
    [isHybridMeetingsCalendarViewEnabled, isOccupancyEnabled, isWorkspacesOnHereAndNowEnabled],
  );

  // Verify tab from url is allowed
  const activeTabId =
    // Only allow current tab if it's available
    tab && availableTabs.includes(tab)
      ? tab
      : // Default to first available tab if not
        availableTabs.at(0);

  // Redirect if needed
  if (!activeTabId) return <Navigate to="/fourohfour" />;
  if (activeTabId !== tab)
    return (
      <Navigate
        to={`/now/${activeTabId}`}
        replace
      />
    );

  return <Outlet />;
};
