import {useMsal} from '@azure/msal-react';
import {
  getIsSigningOut,
  setIsSigningIn,
  setSignInChecked,
  silentSignIn,
  updateUserCredentials,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppDispatch} from './useAppDispatch';
import {useEffect} from 'react';
import {useAppSelector} from './useAppSelector';

export const useLoginRedirectResult = () => {
  const {instance} = useMsal();
  const dispatch = useAppDispatch();
  const isSigningOut = useAppSelector(getIsSigningOut);

  useEffect(
    function processRedirect() {
      let canceled = false;
      if (!isSigningOut) {
        dispatch(setIsSigningIn(true));

        instance
          .handleRedirectPromise()
          .then(async (result) => {
            if (canceled) return;

            if (result) {
              instance.setActiveAccount(result.account);
              await dispatch(
                withAsyncThunkErrorHandling(() => updateUserCredentials({accessToken: result.accessToken})),
              );
            } else {
              const activeAccount = instance.getActiveAccount();

              // If no active account can be resolved by the instance, it means we're not signed in
              if (activeAccount) {
                return dispatch(silentSignIn());
              }
            }
          })
          .finally(() => {
            if (canceled) return;

            dispatch(setSignInChecked(true));
            dispatch(setIsSigningIn(false));
          });
      }

      return () => {
        canceled = true;
      };
    },
    [isSigningOut, instance, dispatch],
  );
};
