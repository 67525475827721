import {pxToRem} from '@utils';
import {createGlobalStyle} from 'styled-components';
import {Reset} from './reset';
import {Variables} from './variables';

export const GlobalStyle = createGlobalStyle`
  ${Reset}
  ${Variables}

  :root {
    --theme-color: ${({theme}) => theme.background.white};
    color-scheme: light;

    &[data-theme='dark'] {
      color-scheme: dark;
    }
  }

  body {
    background: ${({theme}) => theme.background.white};
    color: ${({theme}) => theme.text.color.body};
    font-family: 'Pangram Sans', ui-sans-serif, system-ui, -apple-system, sans-serif;
    font-size: ${pxToRem(15)};
    font-weight: 400;

    &.modal-open {
      overflow: hidden;
    }
  }

  h2,
  h3,
  h4 {
    color: ${({theme}) => theme.text.color.heading};
    font-weight: 600;
  }

  h1 {
    color: ${({theme}) => theme.text.color.heading};
    font-size: ${pxToRem(26)};
    line-height: ${pxToRem(32)};
  }

  h2 {
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(25)};
  }

  h3 {
    font-size: ${pxToRem(17)};
    line-height: ${pxToRem(22)};
  }

  h4 {
    font-size: ${pxToRem(15)};
    line-height: ${pxToRem(20)};
  }

  a {
    color: ${({theme}) => theme.link.default};
    font-weight: ${({theme}) => theme.text.weight.bold};
    text-decoration: none;
    transition: color var(--speed-fast);

    &:focus-visible {
      color: ${({theme}) => theme.link.hover};
      outline: none;
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }

    @media (hover) {
      &:hover {
        color: ${({theme}) => theme.link.hover};
        text-decoration: underline;
        text-decoration-thickness: 2px;
      }
    }
  }

  strong,
  b {
    font-weight: 600;
  }

  *::selection {
    background: ${({theme}) => theme.brand};
    color: #ffffff;
  }

  hr {
    border: 0.5px solid ${({theme}) => theme.divider.sand};
  }

  button {
    color: ${({theme}) => theme.text.color.body};
    cursor: pointer;
    padding: 0;
    text-align: unset;
  }
`;
