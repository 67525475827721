import {useEffect, useState} from 'react';
import {AddInviteesCardProps} from './types';
import {useTranslation} from 'react-i18next';

import {MeetingInviteeSearchResult} from '@lib/store';

import {useModal, useScrollParentForChild} from '@hooks';

import {AddInviteesCardContent, ModalPage, ModalPageBottom} from '@organisms';

export const AddInviteesCard = ({onClose, onSave, invitees: attendees}: AddInviteesCardProps) => {
  const {t} = useTranslation();
  const {removePages} = useModal();

  const [selectedInvitee, setSelectedInvitee] = useState<number | undefined>(undefined);
  const [invitees, setInvitees] = useState<MeetingInviteeSearchResult[]>([]);

  const [scrollRef, childWrapperRef] = useScrollParentForChild(selectedInvitee);

  useEffect(() => {
    setInvitees(attendees);
  }, [attendees]);

  return (
    <ModalPage
      bottom={
        <ModalPageBottom
          data-testid="organisms-createEvent-AddInviteesCard"
          buttonLabel={t('translation:Save')}
          buttonDisabled={false}
          onClick={() => {
            onSave(invitees);
            removePages(1);
          }}
        />
      }
      onBack={() => removePages(1)}
      onClose={onClose}
      ref={scrollRef}
      title={t('screen:Invitees')}>
      <AddInviteesCardContent
        childWrapperRef={childWrapperRef}
        invitees={invitees}
        setInvitees={setInvitees}
        selectedInvitee={selectedInvitee}
        setSelectedInvitee={setSelectedInvitee}
      />
    </ModalPage>
  );
};
