import {useRoomImage} from '@hooks';
import {pickUsingStringAsIndex} from '@lib/utils';
import {useTranslation} from 'react-i18next';
import {FlexCol, FlexRow, H3, P} from '@quarks';

import {EventRoomProps} from './types';
import {Icon} from '@atoms';
import {pxToRem} from '@utils';
import {useTheme} from 'styled-components';

export const EventRoom = ({className, style, room}: EventRoomProps) => {
  const theme = useTheme();
  const {displayName, capacity, floorName, roomPictureHash, buildingId, email} = room;
  const color = pickUsingStringAsIndex(email, theme.avatar.colors);
  const {t} = useTranslation();
  const imageURL = useRoomImage(email || displayName, roomPictureHash, buildingId, 48);

  const background = imageURL ? `scroll center/cover no-repeat url('${imageURL}')` : color;

  return (
    <FlexRow
      data-testid={`molecules-eventRoom_${displayName}`}
      className={className}
      columnGap={16}
      justifyContent="flex-start"
      style={style}>
      <FlexRow
        alignItems="center"
        background={background}
        borderRadius={8}
        justifyContent="center"
        overflow="hidden"
        width={pxToRem(48)}>
        {!imageURL && (
          <Icon
            icon="room"
            duotone
            weight="thin"
            size={pxToRem(35)}
          />
        )}
      </FlexRow>
      <FlexCol>
        <H3
          color="inherit"
          data-testid="molecules-eventRoom_title">
          {displayName}
        </H3>
        <P fontSize="sm">
          {t('meeting:MeetingRoomBasicInfo', {
            count: capacity,
            floorName: floorName,
          })}
        </P>
      </FlexCol>
    </FlexRow>
  );
};
