import {useAppSelector} from '@hooks';
import {FlexCol, FlexRow, P} from '@quarks';
import {getAreaById} from '@lib/store';
import {useTranslation} from 'react-i18next';
import {StyledCard} from './styles';
import {MapPreviewCardProps} from './types';
import {Button, MapView} from '@molecules';
import {anonymousSelectedAreaState} from '@lib/utils';
import {RenderModeToggle} from 'src/components/molecules/MapView/RenderModeToggle/RenderModeToggle';

export const MapPreviewCard = ({
  buildingId,
  floorId,
  areaId,

  groupSize = 1,
  onClick,
  buttonLabel,
}: MapPreviewCardProps) => {
  // Global state
  const {t} = useTranslation();
  const areaNode = useAppSelector((state) => getAreaById(state, areaId));

  const {state, markers} = anonymousSelectedAreaState(areaId, groupSize ?? 1);
  const deskBookingRequired = Boolean(areaNode?.hasBookableDesks);

  const title = deskBookingRequired
    ? t('workplace:Desk', {count: groupSize})
    : t('workplace:AnyAvailableDesk', {count: groupSize});

  return (
    <StyledCard
      shadow={true}
      padding={16}>
      <FlexCol
        gap={32}
        paddingBottom={16}>
        <MapView
          buildingId={buildingId}
          floorId={floorId}
          buildingNodeStates={state}
          highlights={markers}
          disablePointerEvents={true}
          borderRadius={4}
          fullView={false}
          mapTypeNamespace="MapPreview"
        />
        <RenderModeToggle mapTypeNamespace="MapPreview" />
        <FlexRow
          paddingLeft={8}
          alignItems="center"
          justifyContent={'space-between'}
          gap={8}>
          <P>{title}</P>
          {onClick && (
            <Button
              button="text"
              onClick={onClick}>
              {buttonLabel ?? t('View')}
            </Button>
          )}
        </FlexRow>
      </FlexCol>
    </StyledCard>
  );
};
