import {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TransitionGroup} from 'react-transition-group';

import {StyledOutputWrapper} from './styles';

import {useAppDispatch, useModal} from '@hooks';
import {ConnectionUser, searchConnections, withAsyncThunkErrorHandling} from '@lib/store';
import {useDebounce} from '@lib/utils';

import {Div, FlexCol} from '@quarks';
import {Input} from '@molecules';
import {AddConnectionsCardCell, AddConnectionsCardHint, ModalPage} from '@organisms';

export const AddConnectionsCard = () => {
  const {t} = useTranslation();
  const {closeModal} = useModal();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);
  const [isSearching, setIsSearching] = useState(false);
  const [resultsArray, setResultsArray] = useState<ConnectionUser[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleSearch = async () => {
      const result = await dispatch(withAsyncThunkErrorHandling(() => searchConnections(debouncedSearchTerm)));
      const payload = result.result as ConnectionUser[];
      setIsSearching(false);
      setResultsArray(payload);
    };

    if (debouncedSearchTerm.length >= 2) handleSearch();
    if (debouncedSearchTerm.length < 2) setResultsArray([]);
  }, [dispatch, debouncedSearchTerm]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 2) {
      setResultsArray([]);
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    setSearchTerm(e.target.value);
  };

  return (
    <ModalPage
      onClose={closeModal}
      title={t('screen:AddConnection')}>
      <FlexCol gap={16}>
        <Input
          data-testid="organisms-addConnectionsCard-input"
          onChange={handleChange}
          placeholder={t('connection:SearchConnectionPlaceholder')}
          showLoader={isSearching}
          size="full"
          type="search"
          value={searchTerm}
        />

        <StyledOutputWrapper>
          <Div gridArea="hint">
            <AddConnectionsCardHint
              debouncedSearchTerm={debouncedSearchTerm}
              isSearching={isSearching}
              length={resultsArray.length}
            />
          </Div>

          <Div gridArea="result">
            <TransitionGroup component={null}>
              {resultsArray.map((user, i) => (
                <AddConnectionsCardCell
                  key={user.id}
                  user={user}
                  length={resultsArray.length}
                  i={i}
                />
              ))}
            </TransitionGroup>
          </Div>
        </StyledOutputWrapper>
      </FlexCol>
    </ModalPage>
  );
};
