export const AccountRoleIds = {
  GlobalAdmin: 'GlobalMapiqAdministrator',
  SolutionArchitect: 'SolutionArchitect',
  TemplateDeveloper: 'TemplateDeveloper',
  AccountAdmin: 'MapiqSubscriptionManager',
  AccountViewer: 'AccountViewer',
  SubscriptionAdmin: 'SubscriptionAdministrator',
  SubscriptionAdminOwner: 'SubscriptionAdministratorOwner',
  BuildingAdmin: 'BuildingAdministrator',
  ShiftAdmin: 'ShiftAdministrator',
  ShiftAssistant: 'ShiftAssistant',
  ShiftHost: 'ShiftHost',
  AnalyticsViewer: 'AnalyticsViewer',
} as const;
