import {CSSProperties, useRef} from 'react';
import {InviteeCardCellProps} from './types';

import {Transition, TransitionStatus} from 'react-transition-group';

import {TwoColumnCell} from '@atoms';
import {Avatar} from '@molecules';
import {StyledInviteeCellButton} from './styles';
import {useTheme} from 'styled-components';

export const InviteeCardCell = ({user, onClick, i, length, selected, ...props}: InviteeCardCellProps) => {
  const ref = useRef(null);
  const animationTime = 200;
  const trailTime = 10;
  const theme = useTheme();

  const style = (state: TransitionStatus) => {
    return {
      opacity: state === 'entered' ? 1 : 0,
      transition: `opacity ${animationTime}ms ${trailTime * i}ms`,
    } as CSSProperties;
  };

  const buttonStyle = (state: TransitionStatus) => {
    return {
      '--bg-color-add-invitee-button':
        state === 'entered' && selected ? theme.card.opaque.hover.background : 'transparent',
    } as CSSProperties;
  };

  return (
    <Transition
      nodeRef={ref}
      unmountOnExit={true}
      timeout={{
        appear: 0,
        enter: i * trailTime,
        exit: animationTime + length * trailTime,
      }}
      {...props}>
      {(state) => (
        <StyledInviteeCellButton
          disabled={state !== 'entered'}
          onClick={onClick}
          style={buttonStyle(state)}
          ref={ref}>
          <TwoColumnCell
            padding="none"
            left={<Avatar user={user} />}
            right={<p data-testid="molecules-InviteeCardCell_name-or-email">{user.name || user.email}</p>}
            style={style(state)}
          />
        </StyledInviteeCellButton>
      )}
    </Transition>
  );
};
