import {Card, Icon} from '@atoms';
import {pxToRem} from '@utils';
import styled from 'styled-components';

export const Wrapper = styled(Card)`
  align-items: center;
  display: flex;
  justify-content: stretch;
  padding: 11px 15px;
  width: ${pxToRem(270)};

  &:focus-visible {
    border: 1px solid ${({theme}) => theme.brand};
    outline: none;
  }
`;

export const StyledIcon = styled(Icon)`
  align-self: center;
  flex-shrink: 0;
`;
