import {useTranslation} from 'react-i18next';

import {
  disconnectCalendar,
  getHasUserGivenCalendarConsent,
  getIsMeetingsCalendarEnabled,
  getIsUpdatingCalendarConsent,
  redirectForCalendarConsent,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppDispatch, useAppSelector, useFeatureToggle} from '@hooks';

import {Toggle} from '@atoms';
import {ProfileCell} from '../styles';
import {P} from '@quarks';

export const CalendarSyncCell = () => {
  const {t} = useTranslation('profile');
  const {HybridMeetingsCalendarView} = useFeatureToggle();

  const dispatch = useAppDispatch();
  const isMeetingsCalendarEnabled = useAppSelector(getIsMeetingsCalendarEnabled);
  const isCalendarConsentGiven = useAppSelector(getHasUserGivenCalendarConsent);
  const isUpdatingCalendarConsent = useAppSelector(getIsUpdatingCalendarConsent);

  const handleToggle = () => {
    if (isCalendarConsentGiven) {
      dispatch(withAsyncThunkErrorHandling(() => disconnectCalendar()));
    } else {
      dispatch(withAsyncThunkErrorHandling(() => redirectForCalendarConsent()));
    }
  };

  if (!HybridMeetingsCalendarView || !isMeetingsCalendarEnabled) {
    return <></>;
  }

  return (
    <ProfileCell>
      <P id="label-calendarsync">{t('CalendarSynchronizationToggle')}</P>
      <Toggle
        aria-labelledby="label-calendarsync"
        data-testid="organisms-profile-CalendarSyncCell_toggle"
        checked={isCalendarConsentGiven}
        disabled={isUpdatingCalendarConsent}
        onChange={handleToggle}
      />
    </ProfileCell>
  );
};
