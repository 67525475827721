import {useAppSelector} from '@hooks';
import {FlexCol, FlexRow, P} from '@quarks';
import {getAreaById, getIsLoadingDeskSuggestions} from '@lib/store';
import {Loader} from '@atoms';
import {useTranslation, UseTranslationResponse} from 'react-i18next';
import {Namespace} from 'i18next';
import {StyledCard} from './styles';
import {MapPreviewCardProps} from './types';
import {Button, MapView} from '@molecules';
import {anonymousSelectedAreaState, anonymousSelectedDeskState} from '@lib/utils';
import {RenderModeToggle} from 'src/components/molecules/MapView/RenderModeToggle/RenderModeToggle';

export const MapPreviewWithSuggestionsCard = ({
  buildingId,
  floorId,
  areaId,
  deskIds = [],
  deskNames = [],

  groupSize = 1,
  onClick,
  buttonLabel,
}: MapPreviewCardProps) => {
  // Global state
  const {t} = useTranslation();
  const areaNode = useAppSelector((state) => getAreaById(state, areaId));
  const isLoadingDeskSuggestions = useAppSelector(getIsLoadingDeskSuggestions);

  const deskBookingRequired = Boolean(areaNode?.hasBookableDesks);
  const showLoading = isLoadingDeskSuggestions && deskBookingRequired;

  const {state, markers} = deskIds.length
    ? anonymousSelectedDeskState(deskIds)
    : anonymousSelectedAreaState(areaId, groupSize);

  return (
    <StyledCard
      shadow={true}
      padding={16}>
      <FlexCol
        gap={32}
        paddingBottom={16}
        style={{position: 'relative'}}>
        <MapView
          buildingId={buildingId}
          floorId={floorId}
          highlights={markers}
          buildingNodeStates={state}
          disablePointerEvents={true}
          borderRadius={4}
          fullView={false}
          mapTypeNamespace="MapPreview"
        />
        <RenderModeToggle
          mapTypeNamespace="MapPreview"
          position="top-right"
          padding={8}
        />
        <FlexRow
          paddingLeft={8}
          alignItems="center"
          justifyContent={showLoading ? 'flex-start' : 'space-between'}
          gap={8}>
          {showLoading && <Loader size="16px" />}
          <P>{constructDeskLabel(deskBookingRequired, showLoading, groupSize, deskNames, t)}</P>
          {onClick && !showLoading && (
            <Button
              button="text"
              onClick={onClick}>
              {buttonLabel ?? t('View')}
            </Button>
          )}
        </FlexRow>
      </FlexCol>
    </StyledCard>
  );
};

type Translator = UseTranslationResponse<Namespace, undefined>['t'];
function constructDeskLabel(
  deskBookingRequired: boolean,
  isLoading: boolean,
  requiredNumber: number,
  availableDeskNames: string[],
  t: Translator,
) {
  if (!deskBookingRequired) {
    return t(`workplace:UseAnyFreeDesk`);
  }

  if (isLoading) {
    return t('workplace:SearchingFreeDesk', {count: requiredNumber});
  }

  if (availableDeskNames.length < requiredNumber) {
    return requiredNumber > 1 ? t(`workplace:NoAvailableDesks`) : t(`workplace:NotEnoughAvailableDesks`);
  }

  if (requiredNumber === 1) {
    return availableDeskNames[0];
  }

  return t(`workplace:NDesksSelected`, {count: requiredNumber});
}
