import {animated} from '@react-spring/web';
import styled from 'styled-components';
import {BREAKPOINTS} from '@constants';

export const ModalWrapper = styled(animated.div)`
  // Full window height minus the header menu, paddings, and filters
  --modal-height: calc(var(--100vh) - 148px);
  align-items: stretch;
  background: ${({theme}) => theme.background.white};
  border-radius: 8px;
  box-shadow: ${({theme}) => theme.boxShadow.secondary};
  display: grid;
  flex-direction: column;
  height: var(--modal-height);
  justify-content: stretch;
  left: 370px;
  overflow: hidden;
  position: fixed;
  top: 130px;
  width: 400px;

  ${BREAKPOINTS.large`
    --modal-height: calc(var(--100vh) - 60px);
    border-radius: 0px;
    border-right: 1px solid ${({theme}) => theme.divider.grey};
    box-shadow: none;
    left: 0px;
    top: 60px;
    width: 350px;
  `}

  ${BREAKPOINTS.small`
    --modal-height: calc(var(--100vh) - 30px);
    border-radius: 8px 8px 0 0 / 8px 8px 0 0;
    box-shadow: none;
    left: 0;
    top: 30px;
    width: 100vw;
  `}

  & > div {
    grid-area: 1 / 1;
  }
`;
