import isEqual from 'lodash/isEqual';
import {Loader} from '@atoms';
import {P} from '@quarks';
import {useEffect, useRef, useState} from 'react';
import {type MapiqMap} from '../../../../../../submodules/map/mapiq-map/MapiqMap';
import {MapViewHighlight, MapViewProps} from './types';
import {useTranslation} from 'react-i18next';
import {StyledMapView, StyledMapWrapper} from './styles';
import {highlightsAreEqual} from './mapViewHighlightsAreEqual';
import {MapType, getMapDataByFloorId, loadMap} from '@lib/store';
import {useAppDispatch, useAppSelector, useFeatureToggle} from '@hooks';
import {MapState} from '../../../../../../submodules/map/mapiq-map/MapState';
import {useGlobalMapTypePreference} from './useGlobalMapTypePreference';
import {type LocationFeatureCollection} from '../../../../../../submodules/map/types/MpqGeoJSON';

// Note: we might be able to import these types from the module, but we need to be very careful
//       to not accidentally import the whole thing!
type MapFactory2d = (
  container: HTMLElement,
  svgMapData: string | SVGSVGElement,
  viewportRestriction?: {
    paddingTopPixels: number;
    paddingRightPixels: number;
    paddingBottomPixels: number;
    paddingLeftPixels: number;
  } | null,
  disablePointerEvents?: boolean,
) => MapiqMap;
type MapFactory3d = (container: HTMLElement, model: LocationFeatureCollection) => MapiqMap;

const DEBUG = false;
const debugLog = (msg: string) => {
  if (DEBUG) console.log(`MapView: ${msg}`);
};

function assertNotNil<T>(x: T | undefined | null): asserts x is T {
  if (x === undefined) {
    throw new Error('Variable is undefined, but it should not be possible');
  }

  if (x === null) {
    throw new Error('Variable is null, but it should not be possible');
  }
}

/*
  This component is quite complex because it bridges the gap between React's state management 
  and the imperative API of the map widget.
*/
export const MapView = ({
  buildingId,
  floorId,
  highlights = [],
  buildingNodeStates,
  buildingNodeTypeStates,
  onClick = (_t) => {},
  onHover = (_t) => {},
  borderRadius = 4,
  fullView = false,
  disablePointerEvents = false,
  spotlightId = null,
  elementsToKeepInView,
  viewportRestrictions,
  mapTypeNamespace = '',
}: MapViewProps) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const allow3dMap = useFeatureToggle().ThreeDMaps;
  let [type] = useGlobalMapTypePreference(mapTypeNamespace);
  if (!allow3dMap) type = '2d';

  //// REFS
  // The map view uses 3 refs to manage the lifecycle of the map-renderer
  //  1. Container: a reference to the instance's HTML element that the MapView component rendered to the DOM
  //  2. Map: a reference to the renderer instance (note: would it not be easier to put this in state?)
  //  3. OnClick: a callback reference passed to the renderer instance so we can swap out click handlers without
  //              having to dispose and re-attach event handlers (especially disposing is tricky; you need a ref
  //                                                              to what you last attached)
  const containerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<MapiqMap | null>(null);
  const onClickRef = useRef(onClick);
  const onHoverRef = useRef(onHover);

  // Renderer constructors
  const [renderer2d, setRenderer2d] = useState<MapFactory2d | null>(null);
  const [renderer3d, setRenderer3d] = useState<MapFactory3d | null>(null);
  const [activeRenderer, setActiveRenderer] = useState<{type: MapType; floorId: string} | null>(null);
  const rendererInitialized = activeRenderer?.type === type && activeRenderer?.floorId === floorId;

  // Markers should only be added to the map when they actually change. We can't rely on Object.is equality
  // because it's not clear to components higher up the chain that they should memo.
  const lastRenderedMarkers = useRef<MapViewHighlight[] | null>(null);
  const lastRenderedMapStateRef = useRef<MapState | null>(null);

  const mapData = useAppSelector(getMapDataByFloorId(buildingId, floorId, type));

  // Because our map renderer + component is still not 100% stable, we wrap code in a try-catch
  // to ensure we don't break any components outside of this mapView if things go wrong.
  const [hasError, setHasError] = useState(false);

  const isLoading = mapData.status === 'Loading' || mapData.status === 'NotLoaded';
  const errorMessage =
    mapData.status === 'Failed' ? t('error:FailedToLoadMap') : hasError ? t('error:FailedToDisplayMap') : '';

  // We have to wait a frame before we can render our map; it needs its dimensions. This ensures
  // we do not try to add highlights (markers) to the map in between.

  const [elementHasSize, setElementHasSize] = useState(false);

  // Because we swap out the container with a <Loading> and <Error> view in our render function,
  // we have to manage our resize observer
  const mapContainerIsInView = !isLoading && !hasError;
  const mapReadyForState = mapContainerIsInView && elementHasSize && rendererInitialized;

  if (isLoading && rendererInitialized) {
    // This MapView expects a certain state-machine-type behavior from the store:
    // if ever the mapData for a given floor changes _without_ its status going
    // loaded -> loading -> loaded, the map might fail to render correctly
    debugLog(`Loading map with initialized renderer; map might be broken`);
  }

  //// INTERNAL EFFECTS
  // This effect tracks the dimensions of the map container. It uses that information to store
  // whether the container is ready to render and update a map. (0-sized maps are not supported)
  useEffect(
    function checkScreenDimensions() {
      const container = containerRef.current;

      if (mapContainerIsInView && container) {
        // Set current size
        const containerSize = container.getBoundingClientRect();
        setElementHasSize(containerSize.width > 0 && containerSize.height > 0);

        // Ensure size changes are handled during component lifetime
        debugLog(`Watching container for resizes`);
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            const rect = entry.target.getBoundingClientRect();
            setElementHasSize(rect.width > 0 && rect.height > 0);
          }
        });

        resizeObserver.observe(container);

        // Ensure we stop checking size changes when disposed
        return () => {
          resizeObserver.unobserve(container);
        };
      } else {
        debugLog(`No container to measure size of`);
        setElementHasSize(false);
      }
    },
    [mapContainerIsInView],
  );

  // This effect stores the passed onClick handler in a ref to which any initialized map
  // renderer has access. Without it, we would have to dispose and reattach the tap listener
  // on the renderer instance whenever onClick changes instead.
  useEffect(
    function storeLatestClickHandler() {
      onClickRef.current = onClick;
    },
    [onClick],
  );

  useEffect(
    function storeLatestHoverHandler() {
      onHoverRef.current = onHover;
    },
    [onHover],
  );

  // This effect dispatches a load command if the map for the current floor hasn't been loaded before
  useEffect(
    function triggerFloorplanDownload() {
      if (mapData.status === 'NotLoaded') {
        dispatch(loadMap({buildingId, floorId, type}));
      }
    },
    [dispatch, buildingId, floorId, type, mapData.status],
  );

  // This effect loads the required map renderer for the currently requested map type (2d/3d)
  useEffect(
    function downloadMapRenderer() {
      let canceled = false;
      const loadCorrectRenderer =
        type === '3d'
          ? async function load3dMapModule() {
              try {
                const {createMapiqMap3d} = await import('../../../../../../submodules/map/mapiq-map/MapiqMap3d');
                if (!canceled) setRenderer3d(() => createMapiqMap3d);
              } catch (err) {
                if (!canceled) setHasError(true);
              }
            }
          : async function load2dMapModule() {
              try {
                const {createMapiqMap} = await import('../../../../../../submodules/map/mapiq-map/MapiqMap');
                if (!canceled) setRenderer2d(() => createMapiqMap);
              } catch (err) {
                if (!canceled) setHasError(true);
              }
            };

      // Note: canceling this shouldn't make much of a difference since the imports 2nd call will always
      //       return the same result from browser cache.
      loadCorrectRenderer();

      return () => {
        canceled = true;
      };
    },
    [type],
  );

  //// EXTERNAL MAP-RENDERER EFFECTS
  // This effect waits for 3 things:
  //  1. loaded map data,
  //  2. a renderer that supports showing that data, and
  //  3. a container element with a non-zero size
  //
  // Once it has all of those, it initializes the map and communicates that it's done
  // by writing the activeRenderer state. Other effects can use that state to trigger
  // their updates.
  useEffect(
    function initializeRendererForFloor() {
      // 1. Check if map data is loaded
      if (mapData.status !== 'Loaded') {
        debugLog('Postpone createMapiqMap; no map data');
        return;
      }

      // 2. Check if renderer is available
      if (mapData.type === '2d' && !renderer2d) {
        debugLog(`Postpone createMapiqMap; 2d renderer not yet loaded`);
        return;
      } else if (mapData.type === '3d' && !renderer3d) {
        debugLog(`Postpone createMapiqMap; 3d renderer not yet loaded`);
        return;
      }

      // 3. Check if our container has a size
      if (!elementHasSize) {
        debugLog('Postpone createMapiqMap; container does not have valid dimensions');
        return;
      }

      // Extract the container from its ref so we can provide a correct clean up function
      const container = containerRef.current;
      assertNotNil(container);

      // Create the map
      let newMap: MapiqMap;

      try {
        switch (mapData.type) {
          case '2d':
            assertNotNil(renderer2d);
            newMap = renderer2d(container, mapData.svg);
            break;
          case '3d':
            assertNotNil(renderer3d);
            newMap = renderer3d(container, mapData.featureCollection);
            break;
        }

        // Attach a persistant event listener that points to the onClick ref
        newMap.addEventListener('tap', (target) => {
          onClickRef.current(target);
        });

        newMap.addEventListener('hover', (target) => {
          onHoverRef.current(target);
        });

        // Ensure initial fit
        newMap.fitBuildingNodes({nodeIds: [], animated: false});

        // Store a ref to what we just rendered so we
        mapRef.current = newMap;

        debugLog(`Created ${mapData.type} map for floor ${mapData.floorId.slice(0, 6)}...`);

        // This should trigger all state/marker/zoom effects that have been "waiting" for the renderer
        // to initialize
        setActiveRenderer({
          floorId: mapData.floorId,
          type: mapData.type,
        });
      } catch (err) {
        console.error(err);
        setHasError(true);
        return;
      } finally {
        lastRenderedMarkers.current = [];
        lastRenderedMapStateRef.current = {};
      }

      return function disposeMap() {
        assertNotNil(mapRef.current);
        debugLog(`Disposing map ${mapData.floorId.slice(0, 6)}..., ${mapData.type}`);

        // Dispose the instance created in this effect
        newMap.dispose();

        // Clear the HTML container that was used for this instance
        container.innerHTML = '';

        // Clear the ref so instance can be garbage collected
        mapRef.current = null;

        // Clear last rendered state
        lastRenderedMarkers.current = null;
        lastRenderedMapStateRef.current = null;
      };
    },
    [mapData, elementHasSize, renderer2d, renderer3d],
  );

  // We create an effect for every method of the mapiq-map. To minimize the risk of re-applying
  // states that are already rendered, we put custom equality comparers in between
  useEffect(
    function updateState() {
      if (!mapReadyForState) {
        return;
      }

      assertNotNil(mapRef.current);

      const newBuildingNodeStates: MapState = {
        buildingNodeStates,
        buildingNodeTypeStates,
      };

      const statesAreEqual = isEqual(lastRenderedMapStateRef.current, newBuildingNodeStates);

      // Update state if needed
      if (!statesAreEqual) {
        mapRef.current.setState({buildingNodeStates, buildingNodeTypeStates});
        lastRenderedMapStateRef.current = newBuildingNodeStates;
        debugLog(`Set state`);
      } else {
        debugLog(`Skip setState; equal to last rendered state`);
      }
    },
    [mapReadyForState, buildingNodeStates, buildingNodeTypeStates],
  );

  useEffect(
    function fitBuildingNodes() {
      if (!mapReadyForState) {
        return;
      }

      assertNotNil(mapRef.current);

      if (elementsToKeepInView) {
        mapRef.current.fitBuildingNodes({
          nodeIds: elementsToKeepInView,
          abortIfAlreadyInView: true,
          viewportRestriction: viewportRestrictions,
        });
      }
    },
    [mapReadyForState, elementsToKeepInView, viewportRestrictions],
  );

  useEffect(
    function setSpotlight() {
      if (!mapReadyForState) {
        return;
      }

      assertNotNil(mapRef.current);

      debugLog(`Set spotlight`);
      mapRef.current.setSpotlight(spotlightId);
    },
    [mapReadyForState, spotlightId],
  );

  useEffect(
    function updateMarkers() {
      if (!mapReadyForState) {
        return;
      }

      assertNotNil(mapRef.current);

      const highlightsAlreadyDisplayed = highlightsAreEqual(lastRenderedMarkers.current ?? [], highlights);

      if (!highlightsAlreadyDisplayed) {
        // Try-catch because the map-renderer will throw if markers are added for nodes not in
        // the current floorplan
        try {
          debugLog(`Set markers`);
          mapRef.current.setMarkerData(highlights);
          lastRenderedMarkers.current = highlights;
        } catch (err) {
          console.error(err);
          setHasError(true);
        }
      } else {
        debugLog('Skip setMarkersl; equal to last rendered markers');
      }
    },
    [highlights, mapReadyForState],
  );

  return (
    <StyledMapView
      data-testid="molecules-MapView-MapView_map-container"
      justifyContent="center"
      alignItems="center"
      $hasError={errorMessage.length > 0}
      $borderRadius={borderRadius}
      $fullView={fullView}
      $disablePointerEvents={disablePointerEvents}>
      {isLoading ? (
        <Loader />
      ) : errorMessage ? (
        <P>
          <strong>{errorMessage}</strong>
        </P>
      ) : (
        <StyledMapWrapper ref={containerRef} />
      )}
    </StyledMapView>
  );
};
