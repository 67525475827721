import styled from 'styled-components';
import {StyledSquareButtonProps} from './types';

export const StyledSquareButton = styled.button<StyledSquareButtonProps>`
  align-items: ${({$alignItems}) => $alignItems ?? 'center'};
  background: ${({$squareButton, theme}) => theme.card[$squareButton].background};
  border: 2px solid ${({$squareButton, theme}) => theme.card[$squareButton].border};
  color: ${({$squareButton, theme}) => theme.card[$squareButton].color};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 19px 15px;
  width: 100%;
  cursor: pointer;

  &:disabled {
    color: ${({theme}) => theme.disabled.colorHighContrast};

    @media (hover) {
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  @media (hover) {
    &:hover:not(:disabled) {
      background: ${({$squareButton, theme}) => theme.card[$squareButton].hover.background};
      border: 2px solid ${({$squareButton, theme}) => theme.card[$squareButton].hover.border};
    }
  }

  &:focus-visible {
    border: 2px solid ${({theme}) => theme.brand};
    outline: none;
  }

  & > svg {
    flex: none;
  }
`;
