import {
  compareByMeetingResponse,
  compareByTemplateWithStringFallback,
  getLocationForDate,
  MeetingAttendee,
  MeetingAttendeeType,
  MeetingRoom,
} from '@lib/store';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';

export const sortAttendeesByLocation = (
  attendees: MeetingAttendee[],
  currentUser: MeetingAttendee | undefined,
  defaultBuildingId: string,
  rooms: Record<string, MeetingRoom[]>,
  date: string,
) => {
  const attendeesGroupedByLocation = groupBy(attendees, (attendee) => getLocationForDate(attendee, date).locationType);

  const localAttendeesGroupedByLocation = map(
    groupBy(
      attendeesGroupedByLocation.Local,
      (meetingAttendee) => getLocationForDate(meetingAttendee, date).shiftBuildingId,
    ),
    (meetingAttendees, key) => ({
      attendees: meetingAttendees.sort(compareByMeetingResponse),
      buildingId: key,
      id: key,
      locationDescription: getLocationForDate(meetingAttendees[0], date).shiftBuildingName,
      locationType: MeetingAttendeeType.Local,
    }),
  );

  const generalAttendeesGroupedByLocation = map(
    pickBy(attendeesGroupedByLocation, (_item, key) => key !== 'Local'),

    (meetingAttendees, key) => ({
      attendees: meetingAttendees.sort(compareByMeetingResponse),
      buildingId: undefined,
      id: key,
      locationDescription: key,
      locationType: key as MeetingAttendeeType,
    }),
  );

  const buildingIdsForMeeting = Object.keys(rooms ?? {});

  const buildingsIdsWithoutAttendees = buildingIdsForMeeting?.filter(
    (buildingId) => !localAttendeesGroupedByLocation?.find((group) => group.buildingId === buildingId),
  );

  const roomOnlyGroups = buildingsIdsWithoutAttendees.map((buildingId) => ({
    attendees: [],
    id: buildingId,
    buildingId,
    locationDescription: rooms[buildingId]?.[0]?.buildingName,
    locationType: MeetingAttendeeType.Local,
  }));

  const locationArray = [...localAttendeesGroupedByLocation, ...generalAttendeesGroupedByLocation, ...roomOnlyGroups];

  const currentUserLocation = currentUser && getLocationForDate(currentUser, date);

  const defaultBuildingGroup = locationArray.find((location) => location.buildingId === defaultBuildingId);
  const myBuildingGroup = locationArray.find(
    (location) => location.buildingId === currentUserLocation?.shiftBuildingId,
  );

  const sortingOrder =
    currentUserLocation?.locationType === 'Local'
      ? [myBuildingGroup?.buildingId, defaultBuildingGroup?.buildingId, 'OtherBuildings', 'Remote', 'Unknown']
      : currentUserLocation?.locationType === 'Remote'
      ? ['Remote', defaultBuildingGroup?.buildingId, 'OtherBuildings', 'Unknown']
      : [defaultBuildingGroup?.buildingId, 'OtherBuildings', 'Remote', 'Unknown'];

  const otherBuildingsIndex = sortingOrder.indexOf('OtherBuildings');

  const returnedArray = locationArray.sort((a, b) =>
    compareByTemplateWithStringFallback(sortingOrder, otherBuildingsIndex, a, b),
  );

  return returnedArray;
};
