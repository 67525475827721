import {MapView} from '@molecules';
import {FlexCol, H2} from '@quarks';
import {useTranslation} from 'react-i18next';
import {MeetingRoomMapProps} from './types';
import {generateTestIdCoreString} from '@utils';
import {selectedRoomState} from '@lib/utils';
import {RenderModeToggle} from 'src/components/molecules/MapView/RenderModeToggle/RenderModeToggle';

export const MeetingRoomMap = ({room}: MeetingRoomMapProps) => {
  const {t} = useTranslation();
  const {buildingId, floorId, id} = room;
  const {state, markers} = selectedRoomState(id);
  const TEST_ID = generateTestIdCoreString(import.meta.url);
  return (
    <>
      <FlexCol gap={16}>
        <H2 data-testid={`${TEST_ID}_title`}>{t('translation:LocationTitle')}</H2>
        <div style={{position: 'relative'}}>
          <MapView
            buildingId={buildingId}
            floorId={floorId}
            buildingNodeStates={state}
            borderRadius={8}
            fullView={false}
            highlights={markers}
            disablePointerEvents={true}
            mapTypeNamespace="MeetingRoom"
          />
          <RenderModeToggle
            mapTypeNamespace="MeetingRoom"
            position="top-right"
            padding={8}
          />
        </div>
      </FlexCol>
    </>
  );
};
