import {useAppSelector} from '@hooks';
import {MapView} from '@molecules';
import {type Area, type MeetingRoom, getMarkerState, getMeetingRoomSuggestionByBuildingId} from '@lib/store';
import {RoomEventTarget} from '../../../../../../../submodules/map/mapiq-map/EventTarget';
import {useMemo} from 'react';
import {AbsoluteViewportRestriction} from '../../../../../../../submodules/map/mapiq-map/AbsoluteViewportRestriction';
import {createState, mergeState} from '@lib/utils';
import {useFacilityMarkers} from '../useFacilityMarkers';

type HaNRoomsMapProps = {
  rooms: MeetingRoom[];
  floorId: string;
  buildingId: string;
  onRoomClick: (e: RoomEventTarget) => void;
  onOutsideClick: () => void;
  hoverRoom: MeetingRoom | null;
  selectedRoom: MeetingRoom | Area | null;
  viewportRestrictions?: AbsoluteViewportRestriction;
};

export const HaNRoomsMap = ({
  rooms,
  buildingId,
  floorId,
  hoverRoom,
  onRoomClick,
  viewportRestrictions,
  selectedRoom,
  onOutsideClick,
}: HaNRoomsMapProps) => {
  const roomSuggestionDataEntities = useAppSelector((state) => getMeetingRoomSuggestionByBuildingId(state, buildingId));
  const facilityMarkers = useFacilityMarkers(buildingId, floorId);
  const roomMarkers = useMemo(
    () => rooms.filter((room) => room.floorId === floorId).map((room) => ({type: 'room' as const, nodeId: room.id})),
    [rooms, floorId],
  );

  const highlights = useMemo(() => [...roomMarkers, ...facilityMarkers], [roomMarkers, facilityMarkers]);

  const highlightedRooms = useMemo(
    () =>
      rooms.filter(
        (room) =>
          room.id !== selectedRoom?.id &&
          room.suggestionData &&
          getMarkerState(roomSuggestionDataEntities?.data[room.suggestionData]?.state) === 'highlighted',
      ),
    [rooms, roomSuggestionDataEntities, selectedRoom],
  );

  const dimmedRooms = useMemo(
    () =>
      rooms.filter(
        (room) =>
          room.suggestionData &&
          getMarkerState(roomSuggestionDataEntities?.data[room.suggestionData]?.state) === 'dimmed',
      ),
    [rooms, roomSuggestionDataEntities],
  );

  const allRooms = useMemo(() => {
    const availableRoomState = createState(
      highlightedRooms.filter((room) => room.id !== selectedRoom?.id).map((room) => room.id),
      'highlighted',
    );

    const dimmedRoomStates = createState(
      dimmedRooms.filter((room) => room.id !== selectedRoom?.id).map((room) => room.id),
      'dimmed',
    );

    const selectedRoomState = createState(selectedRoom ? [selectedRoom.id] : [], 'selected');

    return [dimmedRoomStates, availableRoomState, selectedRoomState].reduce(mergeState);
  }, [highlightedRooms, dimmedRooms, selectedRoom]);

  const handleClick = (e: RoomEventTarget) => {
    if (e.type === 'room') {
      onRoomClick(e);
    } else {
      onOutsideClick();
    }
  };

  const elementsToKeepInView = useMemo(() => (selectedRoom ? [selectedRoom.id] : undefined), [selectedRoom]);

  return (
    <MapView
      borderRadius={0}
      floorId={floorId}
      buildingId={buildingId}
      highlights={highlights}
      fullView={true}
      elementsToKeepInView={elementsToKeepInView}
      disablePointerEvents={false}
      buildingNodeStates={allRooms}
      buildingNodeTypeStates={new Map([['facility', 'dimmed']])}
      viewportRestrictions={viewportRestrictions}
      onClick={(e) => handleClick(e as RoomEventTarget)}
      spotlightId={hoverRoom?.floorId === floorId ? hoverRoom.id : null}
      mapTypeNamespace="HereAndNow_rooms"
    />
  );
};
