import {NotificationType} from '@lib/store';
import {IconNames} from 'mapiq-atoms';
import {ValidationType} from 'mapiq-molecules';

enum WorkspaceNotificationType {
  NotifyBookingOnBehalf = 'NotifyBookingOnBehalf',
  NotifyUpdateOnBehalf = 'NotifyUpdateOnBehalf',
  NotifyDeleteOnBehalf = 'NotifyDeleteOnBehalf',
  NotifyDeleteOnLocationClose = 'NotifyDeleteOnLocationClose',
  NotifyReservationReminder = 'NotifyReservationReminder',
}
enum NoShoNotificationType {
  NotifyNoShowDetected = 'NotifyNoShowDetected',
  NotifyNoShowRoomReleased = 'NotifyNoShowRoomReleased',
}

enum GroupBookingNotificationType {
  NotifyInAppReservationInvitationCreated = 'NotifyInAppReservationInvitationCreated',
  NotifyGroupInvitationCanceled = 'NotifyGroupInvitationCanceled',
}

type NotificationTypes =
  | typeof WorkspaceNotificationType
  | typeof GroupBookingNotificationType
  | typeof NoShoNotificationType;

//for now we only support notifications for two types of entities, but it can be useful later
const isNotificationTypeMatched = (notificationTypes: NotificationTypes, notificationType: NotificationType) =>
  Object.values(notificationTypes).some((val) => val === notificationType);

export const getNotificationIcon = (notificationType: NotificationType): IconNames => {
  if (isNotificationTypeMatched(WorkspaceNotificationType, notificationType)) return 'desk';
  if (isNotificationTypeMatched(NoShoNotificationType, notificationType)) return 'meetingRoom';

  return 'users';
};

export const getBadgeProps = (notificationType: NotificationType): {badge: ValidationType; icon: IconNames} => {
  switch (notificationType) {
    case GroupBookingNotificationType.NotifyInAppReservationInvitationCreated:
    case WorkspaceNotificationType.NotifyBookingOnBehalf:
      return {
        badge: 'success',
        icon: 'check',
      };
    case WorkspaceNotificationType.NotifyReservationReminder:
      return {
        badge: 'warning',
        icon: 'help',
      };
    case NoShoNotificationType.NotifyNoShowDetected:
      return {
        badge: 'warning',
        icon: 'help',
      };
    case NoShoNotificationType.NotifyNoShowRoomReleased:
    case GroupBookingNotificationType.NotifyGroupInvitationCanceled:
    case WorkspaceNotificationType.NotifyDeleteOnBehalf:
    case WorkspaceNotificationType.NotifyDeleteOnLocationClose:
      return {badge: 'error', icon: 'close'};
    default:
      return {badge: 'warning', icon: 'warning'};
  }
};
