import {ThreeColumnCell} from '@atoms';
import styled from 'styled-components';
import {StyledUserCellProps} from './types';

export const StyledUserCell = styled(ThreeColumnCell)<StyledUserCellProps>`
  display: flex;
  gap: 0 16px;
  justify-content: space-between;
  max-width: 100%;
`;
