import {Icon, Illustration} from '@atoms';
import {useAppDispatch, useAppSelector} from '@hooks';
import {
  getCalendarIntegrationType,
  redirectForCalendarConsent,
  updateUserClosedConsentTilePreference,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {Button} from '@molecules';
import {useTranslation} from 'react-i18next';
import {Tile} from '../Tile';
import {trackEvent} from '@lib/infrastructure';

export const MeetingConsentTile = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const calendarIntegrationType = useAppSelector(getCalendarIntegrationType);

  const handleButtonClick = () => {
    // Note: not sure if this event ever makes it out before the redirect
    trackEvent('Home_MeetingsConsentTile__ConnectClicked', calendarIntegrationType);
    dispatch(withAsyncThunkErrorHandling(() => redirectForCalendarConsent()));
  };

  const handleClose = () => {
    dispatch(withAsyncThunkErrorHandling(() => updateUserClosedConsentTilePreference(true)));
  };

  return (
    <Tile bgColor="bg-connecting-green-500*">
      <div className="size-full relative">
        <div
          tabIndex={1}
          onClick={handleClose}
          className="p-2 flex flex-col items-center justify-center absolute top-4 right-4 cursor-pointer rounded-md bg-connecting-green-400">
          <Icon
            icon="close"
            size="18px"
            color="white"
          />
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col justify-center items-center gap-4 py-14">
            <Illustration
              illustration="calendar"
              size="80px"
            />
            <h3 className="text-white">{t('MeetingConsentTileAgenda')}</h3>
            <p className="text-white">{t('MeetingConsentTileBodyText')}</p>
            <Button
              onClick={handleButtonClick}
              data-testid="organisms-calendar-CalendarEventsList_connect-calendar-button-fs"
              button={'primary light'}>
              {t('meeting:CalendarBannerButton', {calendarIntegrationType})}
            </Button>
          </div>
        </div>
      </div>
    </Tile>
  );
};
