import {getSupportLink} from '@lib/store';
import {useTranslation} from 'react-i18next';

import {useAppSelector} from '@hooks';
import {ProfileCell} from '../styles';
import {Link} from '@quarks';

export const SupportCell = () => {
  const {t} = useTranslation('profile');
  const supportLink = useAppSelector(getSupportLink);

  return (
    <ProfileCell>
      <Link
        data-testid="organisms-supportCell-link"
        to={supportLink}>
        {t('GetHelp')}
      </Link>
    </ProfileCell>
  );
};
