//// @ts-nocheck
import {
  css,
  CSSObject,
  DefaultTheme,
  FlattenSimpleInterpolation,
  SimpleInterpolation,
  ThemedCssFunction,
  ThemedStyledProps,
  FlattenInterpolation,
  Interpolation,
} from 'styled-components';

type BpLabels = 'xxxLarge' | 'xLarge' | 'large' | 'medium' | 'small';

type BreakpointSizesProps = {
  [Property in BpLabels]: number;
};

export const BREAKPOINT_SIZES: BreakpointSizesProps = {
  xxxLarge: 2000,
  xLarge: 1400, // 1170, min wide
  large: 1200, // 992, max medium
  medium: 800, // 768, max small
  small: 564, // 481, max narrow
};

export const BREAKPOINTS = {
  /** min-width: 1400px */
  xLarge: <T extends object>(
    ...interpolations: Array<TemplateStringsArray | Interpolation<ThemedStyledProps<T, DefaultTheme>>>
  ): FlattenInterpolation<ThemedStyledProps<T, DefaultTheme>> => {
    const remSize = BREAKPOINT_SIZES.xLarge / 16;

    return css`
      @media (min-width: ${remSize}rem) {
        ${css<ThemedCssFunction<T>>(
          //@ts-expect-error
          ...interpolations,
        )}
      }
    `;
  },
  /** max-width: 1200px */
  large: <T extends object>(
    ...interpolations: Array<TemplateStringsArray | Interpolation<ThemedStyledProps<T, DefaultTheme>>>
  ): FlattenInterpolation<ThemedStyledProps<T, DefaultTheme>> => {
    const remSize = BREAKPOINT_SIZES.large / 16;

    return css`
      @media (max-width: ${remSize}rem) {
        ${css<ThemedCssFunction<T>>(
          //@ts-expect-error
          ...interpolations,
        )}
      }
    `;
  },
  /** max-width: 800px */
  medium: <T extends object>(
    ...interpolations: Array<TemplateStringsArray | Interpolation<ThemedStyledProps<T, DefaultTheme>>>
  ): FlattenInterpolation<ThemedStyledProps<T, DefaultTheme>> => {
    const remSize = BREAKPOINT_SIZES.medium / 16;

    return css`
      @media (max-width: ${remSize}rem) {
        ${css<ThemedCssFunction<T>>(
          //@ts-expect-error
          ...interpolations,
        )}
      }
    `;
  },
  /** max-width: 564px */
  small: <T extends object>(
    ...interpolations: Array<TemplateStringsArray | Interpolation<ThemedStyledProps<T, DefaultTheme>>>
  ): FlattenInterpolation<ThemedStyledProps<T, DefaultTheme>> => {
    const remSize = BREAKPOINT_SIZES.small / 16;

    return css`
      @media (max-width: ${remSize}rem) {
        ${css<ThemedCssFunction<T>>(
          //@ts-expect-error
          ...interpolations,
        )}
      }
    `;
  },
};

const mediaTypes = ['hover'];

type MediaProps = {
  [hover: string]: (
    first: TemplateStringsArray | CSSObject,
    ...interpolations: SimpleInterpolation[]
  ) => FlattenSimpleInterpolation;
};

export const mediaHelper = mediaTypes.reduce((accumulator: MediaProps, label): MediaProps => {
  accumulator[label] = (...args) => css`
    @media (hover) {
      &:hover {
        ${css(...args)};
      }
    }
  `;

  return accumulator;
}, {});
