import {Div, FlexCol, FlexRow, P} from '@quarks';
import {useTranslation} from 'react-i18next';

import {ExploreState} from 'src/components/pages/HereAndNow/useExploreState';
import {HaNExploreListItem} from './HaNExploreListItem';
import {VariableSizeList} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {Illustration} from '@atoms';
import {GroupGrid, StyledResultsText} from './styles';
import {FacilityResource, resourceGroupOrder} from '@lib/store/src/data/buildingResources';
import {GroupItem} from './GroupItem';
import {useAppDispatch} from '@hooks';
import {trackEvent} from '@lib/infrastructure';

type Props = {
  exploreState: ExploreState;
  onClick: (facility: FacilityResource) => void;
};

export const HaNExploreList = ({exploreState, onClick}: Props) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const {
    buildingId,
    resourceData,
    loadResources,
    resourceGroups,
    setRawQuery,
    highlightedResources,
    hasSearchQuery,
    hasFilterSelected,
  } = exploreState;
  const resourcesHaveError = !resourceData || resourceData.status === 'failed';
  const resourcesAreLoading = resourceData?.status === 'loading';

  const showResourceGroupList = !hasSearchQuery && !hasFilterSelected && Object.keys(resourceGroups).length > 2;

  return (
    <FlexCol flex="1">
      {!showResourceGroupList && (
        <StyledResultsText fontWeight="bold">
          {t('hereAndNow:ResultCount', {count: highlightedResources.length})}
        </StyledResultsText>
      )}
      <FlexRow
        gap={16}
        flex="1">
        {showResourceGroupList ? (
          <FlexCol flex="1">
            <GroupGrid data-testid="HaNExploreList-Container">
              {resourceGroupOrder.map((resourceGroupKey) => {
                const resourceGroup = resourceGroups[resourceGroupKey];
                return resourceGroup ? (
                  <GroupItem
                    key={resourceGroupKey}
                    onClick={() => {
                      setRawQuery(resourceGroup.displayName);

                      trackEvent('HereAndNow_Explore__CategoryClick', {category: resourceGroupKey, buildingId});
                    }}
                    {...resourceGroup}
                  />
                ) : null;
              })}
            </GroupGrid>
          </FlexCol>
        ) : highlightedResources.length ? (
          <AutoSizer>
            {({height, width}) => (
              <VariableSizeList
                height={height}
                width={width}
                itemCount={highlightedResources.length}
                itemSize={(x) => 76}>
                {({index, style}) => (
                  <Div style={{...style, padding: '0 10px'}}>
                    <HaNExploreListItem
                      exploreState={exploreState}
                      resource={highlightedResources[index]}
                      onClick={onClick}
                    />
                  </Div>
                )}
              </VariableSizeList>
            )}
          </AutoSizer>
        ) : (
          <FlexCol
            justifyContent="center"
            alignItems="center"
            gap={16}
            padding={32}>
            <Illustration
              size="100px"
              illustration="noResults"
            />
            <P
              color="currentColor"
              textAlign="center">
              {t('hereAndNow:NoSearchResults')}
            </P>
          </FlexCol>
        )}
      </FlexRow>

      {resourcesAreLoading && <p>Loading...</p>}

      {resourcesHaveError && (
        <>
          <p>{resourceData?.errorMessage ?? t('screen:ResourcesNotLoaded')}</p>
          <button onClick={loadResources}>{t('screen:Retry')}</button>
        </>
      )}
    </FlexCol>
  );
};
