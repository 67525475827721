import {useMsal} from '@azure/msal-react';
import {AccountRoleIds} from '@constants';

export const useAdminPermission = () => {
  const {instance} = useMsal();
  const activeAccount = instance.getActiveAccount();

  const currentRoles = new Set(activeAccount?.idTokenClaims?.roles);
  const adminRoles = [
    AccountRoleIds.SubscriptionAdmin,
    AccountRoleIds.SubscriptionAdminOwner,
    AccountRoleIds.BuildingAdmin,
    AccountRoleIds.ShiftAdmin,
    AccountRoleIds.ShiftAssistant,
    AccountRoleIds.ShiftHost,
    AccountRoleIds.AnalyticsViewer,
  ];

  const hasAdminPermission = adminRoles.some((r) => currentRoles.has(r));
  return {hasAdminPermission};
};
