import {i18n} from 'i18next';
import {ITranslationService} from '@lib/infrastructure';
import {AppLanguage} from '@lib/utils';

export const translationService = (i18next: i18n): ITranslationService => ({
  lookup: (key) => {
    return i18next.t(key);
  },
  changeLanguage: (language) => {
    return new Promise<void>((resolve) => {
      i18next.changeLanguage(language, () => {
        resolve();
      });
    });
  },
  getLanguage: () => i18next.language as AppLanguage,
});
