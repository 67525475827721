import {ConnectionListProps} from './types';
import {H2} from '@quarks';
import {TwoColumnCell} from '@atoms';
import {Avatar} from '../Avatar';

export const ConnectionList = ({connections, separator = false, title, padding = 'small'}: ConnectionListProps) => {
  if (!connections.length) return <></>;

  return (
    <>
      {separator && <hr />}

      {title && <H2 as="h3">{title}</H2>}

      {connections.map((connection) => {
        return (
          <TwoColumnCell
            key={`connection-${connection.id}`}
            padding={padding}
            separator={false}
            left={<Avatar user={connection} />}
            right={<p>{connection.name || connection.email}</p>}
          />
        );
      })}
    </>
  );
};
