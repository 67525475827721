import {Select} from '@molecules';
import {FlexCol} from '@quarks';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  min-width: 0;
`;

export const NoRoomsAvailableWrapper = styled(FlexCol)`
  border: 1px solid ${({theme}) => theme.divider.grey};
  border-radius: 8px;
  max-width: 200px;
  padding: 8px;
`;
