import {Icon} from '@atoms';
import {ClickableCellWrapper} from '@molecules';
import styled from 'styled-components';

export const StyledIcon = styled(Icon)`
  align-self: flex-end;
`;

export const StyledClickableCellWrapper = styled(ClickableCellWrapper)`
  flex: 1;
`;
