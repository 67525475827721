import {Skeleton} from '@molecules';
import {FlexCol, FlexRow} from '@quarks';

export const ParkingSkeletonLoader = () => {
  return (
    <FlexRow
      alignItems="center"
      justifyContent="space-between"
      gap={8}>
      <FlexRow
        alignItems="center"
        gap={16}>
        <Skeleton className="h-12 w-12 bg-mapiq-black-200" />
        <FlexCol gap={8}>
          <Skeleton className="h-4 w-24 bg-mapiq-black-200" />
          <Skeleton className="h-3 max-w-14 bg-mapiq-black-200" />
        </FlexCol>
      </FlexRow>
      <Skeleton className="w-24 h-10 bg-mapiq-black-200" />
    </FlexRow>
  );
};
