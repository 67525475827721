import {EmptyListPlaceholder, SwiperRefType} from '@molecules';
import {forwardRef} from 'react';
import {FlexRow} from '@quarks';
import {StyledCellContainer, StyledSwiperHorizontal} from './styles';
import Swiper from 'swiper';
import {HaNWorkspaceCell} from '../HaNWorkspaceCell';
import {AreaWithSensorInfo} from '@lib/store';

export type HaNWorkspacesListHorizontalProps = {
  areas: AreaWithSensorInfo[];
  onSlideChange: (room: AreaWithSensorInfo) => void;
  onClick: (area: AreaWithSensorInfo) => void;
};

export const HaNWorkspacesListHorizontal = forwardRef<SwiperRefType, HaNWorkspacesListHorizontalProps>(
  ({areas, onSlideChange, onClick}, ref) => {
    const handleSlideChange = (swiper: Swiper) => {
      const visibleWorkspace = areas[swiper.activeIndex];
      onSlideChange(visibleWorkspace);
    };

    return (
      <FlexRow
        zIndex={2}
        columnGap={8}
        overflow="hidden"
        justifyContent="center">
        {areas.length ? (
          <StyledSwiperHorizontal
            freeMode={false}
            onSlideChange={handleSlideChange}
            ref={ref}
            centeredSlides={true}
            spaceBetween={16}>
            {areas.map((area) => (
              <StyledCellContainer key={area.id}>
                <HaNWorkspaceCell
                  onClick={onClick}
                  key={area.id}
                  area={area}
                />
              </StyledCellContainer>
            ))}
          </StyledSwiperHorizontal>
        ) : (
          <EmptyListPlaceholder />
        )}
      </FlexRow>
    );
  },
);
