import {IconNames} from '@atoms';
import {RoomEquipment} from '@lib/store';

export const getRoomEquipementIcon = (value: RoomEquipment): IconNames => {
  switch (value) {
    case RoomEquipment.Audio:
      return 'speaker';
    case RoomEquipment.PresentationScreen:
      return 'presentationScreen';
    case RoomEquipment.Webcam:
      return 'webcam';
    case RoomEquipment.Speakerphone:
      return 'speakerphone';
    case RoomEquipment.Flipboard:
      return 'flipboard';
    case RoomEquipment.InteractiveWhiteboard:
      return 'whiteboardInteractive';
    case RoomEquipment.Whiteboard:
      return 'whiteboard';
    case RoomEquipment.Smartboard:
      return 'whiteboardInteractive';
    case RoomEquipment.Projector:
      return 'projector';
  }
};
