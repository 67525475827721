import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

export const HeaderLayout = styled.header`
  align-items: stretch;
  display: flex;
  border-bottom: ${({theme}) => `1px solid ${theme.divider.grey}`};
  grid-area: header / fullbleed;
  justify-content: space-between;
  padding: 16px;
  position: sticky;
  background-color: ${({theme}) => theme.background.white};
  top: 0;
  z-index: 10;
  ${BREAKPOINTS.small`
    display: none;
  `};
`;
