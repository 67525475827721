import {AppInsights} from '@config';
import {AppEventProperty, IEventTracker, PageNames} from '@lib/infrastructure';

export const eventTracker: IEventTracker = {
  trackPageViewed: (name: (typeof PageNames)[keyof typeof PageNames], properties?: AppEventProperty) => {
    if (import.meta.env.MAPIQ_DEBUG_TRACKING) console.debug('AppInsights.trackPageView', name, properties);
    AppInsights.trackPageView({
      name: name,
      properties: properties,
    });
    return Promise.resolve();
  },
  trackEvent: (eventName: string, properties?: AppEventProperty) => {
    if (import.meta.env.MAPIQ_DEBUG_TRACKING) console.debug('AppInsights.trackEvent', eventName, properties);
    AppInsights.trackEvent({name: eventName, properties});
    return Promise.resolve();
  },
  trackError: (error: Error, properties?: AppEventProperty) => {
    AppInsights.trackException({
      exception: error,
      properties: properties,
    });

    return Promise.resolve();
  },
  startTrackEvent: (eventName: string) => {
    AppInsights.startTrackEvent(eventName);
    return Promise.resolve();
  },
  stopTrackEvent: (eventName: string, properties?: AppEventProperty) => {
    if (import.meta.env.MAPIQ_DEBUG_TRACKING) console.debug('AppInsights.trackEvent', eventName, properties);
    AppInsights.stopTrackEvent(eventName, properties);
    return Promise.resolve();
  },
};
