import {FlexRow} from '@quarks';
import styled from 'styled-components';

export const ModalPageWrapper = styled(FlexRow)`
  align-items: center;
  display: flex;
  gap: 0 16px;
  justify-content: space-between;
  padding: 16px var(--padding-y) 0;
`;
