import {BREAKPOINTS} from '@constants';
import {H1} from '@quarks';
import styled from 'styled-components';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 420px;
  min-height: 200px;
  padding: 48px 32px 32px;
  row-gap: 32px;

  // No idea why but could'nt use Card-component here, need to look into later
  background: ${({theme}) => theme.card.white.background};
  border-radius: 10px;
  box-shadow: ${({theme}) => theme.boxShadow.secondary};

  ${BREAKPOINTS.small`
    background: none;
    box-shadow: none;
    justify-content: space-between;
    max-width: unset;
    padding: 20px 20px 32px;
  `}
`;

export const StyledH1 = styled(H1)`
  font-size: 48px;
  line-height: 1;
`;
