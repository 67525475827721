import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {useEffect, useMemo, useState} from 'react';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {Div, FlexCol, H4} from '@quarks';
import {ModalCell} from '@molecules';
import {
  BookWorkspaceValidationError,
  ModalPageBottom,
  SelectWorkspaceButton,
  WorkspaceOnMapSection,
  SelectInviteesButton,
  AddWorkspaceInviteesCard,
} from '@organisms';
import {
  MeetingInviteeSearchResult,
  resetWorkspaceReservation,
  saveWorkspaceReservation,
  getBuildingById,
  getWorkspaceReservationByDate,
  getNodeTypeAndId,
  saveGroupWorkspaceReservation,
  ValidationError,
  getCanSaveWorkspaceReservation,
  WorkspaceReservation,
  getUser,
  getSelectedNodes,
  getSelectedBuildingNodeId,
  clearDesks,
  resetSuggestedDesk,
} from '@lib/store';
import {generateTestIdCoreString} from '@utils';
import {useTheme} from 'styled-components';
import {parseISO} from 'date-fns';
import {BookWorkspaceCardProps, ErrorsData} from './types';
import {reservationStateFromExisting, reservationStateFromUI, reservationStateEqual} from './ReservationState';
import {StyledFlexCol, StyledModalPage} from './styles';
import {RESERVATION_STATUS} from '@lib/utils';
import {trackEvent} from '@lib/infrastructure';

/*

  Responsibilities of this component:

  - Keep track of state
    - date
    - invitees
  - Create a combination of UI that allows users to select
    - Invitees
    - Workspace
    - Desks, if required
  - Combine date, location and invitees in to a reservation
  - Present option to save reservation
    - Check validity of reservation
    - If in edit mode, check if changes have been made

*/
export const BookWorkspaceCard = ({date}: BookWorkspaceCardProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {t} = useTranslation();

  const TEST_ID_CORE = generateTestIdCoreString(import.meta.url);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const {closeModal, addPages, removePages, modalPages} = useModal();

  const currentWorkspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, parseISO(date)));
  const {id: userId} = useAppSelector(getUser);

  //// LOCATION
  const {buildingId, floorId, areaId, deskIds} = useAppSelector(getSelectedNodes);

  //// PEOPLE
  // TODO: why do we not use the workspaceReservation ui store for this? There is an inviteesSelected...
  const initialInvitees = getInviteesFromReservation(currentWorkspaceReservation);
  const [invitees, setInvitees] = useState<MeetingInviteeSearchResult[]>(
    initialInvitees ? (initialInvitees as MeetingInviteeSearchResult[]) : [],
  );

  const {name: buildingName} = useAppSelector((state) => getBuildingById(state, buildingId)) ?? {};
  const {nodeIds, nodeType} = useMemo(() => {
    return getNodeTypeAndId(buildingId, floorId, areaId, deskIds);
  }, [areaId, buildingId, deskIds, floorId]);

  // Check validity of selections for saving
  const savedReservation = reservationStateFromExisting(currentWorkspaceReservation);
  const uiReservation = reservationStateFromUI(
    userId,
    invitees.map((inv) => inv.id),
    nodeIds,
  );
  const hasChanges = !reservationStateEqual(savedReservation, uiReservation);

  const locationIsValid = useAppSelector((state) =>
    getCanSaveWorkspaceReservation(state, {nodeId: areaId || floorId || buildingId, date: parseISO(date)}),
  );
  const canSubmit = hasChanges && locationIsValid;

  useEffect(
    function clearErrorMessages() {
      setErrorMessages([]);
    },
    [invitees, nodeIds],
  );

  const handleOnSuccess = () => {
    const isGroupBooking = !!invitees.length;
    const isEdit = currentWorkspaceReservation?.id;
    const getFeedbackMessage = () => {
      if (isGroupBooking && isEdit) {
        return t('workplace:WorkplaceUpdatedMessage');
      }
      if (isGroupBooking) {
        return t('workplace:GroupBookingSent');
      }
      if (nodeType === 'Desk') {
        return t('workplace:DeskBookedMessage');
      }
      if (isEdit) {
        return t('workplace:WorkplaceUpdatedMessage');
      }
      return t('workplace:WorkplaceBookedMessage');
    };
    dispatch(resetWorkspaceReservation());
    toast.success(getFeedbackMessage());
    closeModal();

    trackEvent(
      isEdit ? 'CalendarOverview_WorkplaceBooking__UpdateBooking' : 'CalendarOverview_WorkplaceBooking__CreateBooking',
      {
        date,
        isGroupBooking: isEdit ? !!currentWorkspaceReservation.groupReservationId : isGroupBooking,
      },
    );
  };

  const saveReservation = async () => {
    const saveWorkspaceReservationData = {
      location: {
        buildingId: buildingId,
        floorId: floorId,
        areaId: areaId,
        deskId: deskIds[0] ?? '',
      },
      selectedDate: date,
      nodeIds,
      workspaceReservationId: currentWorkspaceReservation?.id,
      invitees: invitees,
      buildingName: buildingName!,
    };

    if (invitees.length) {
      try {
        await dispatch(saveGroupWorkspaceReservation(saveWorkspaceReservationData)).unwrap();
        handleOnSuccess();
      } catch (error: unknown) {
        if (error instanceof ValidationError) {
          const errorValidationMessages = extractErrorMessages(error.data.errors);
          setErrorMessages(errorValidationMessages);
        }
      }
    } else {
      await dispatch(saveWorkspaceReservation(saveWorkspaceReservationData)).unwrap();
      handleOnSuccess();
    }
  };

  // Modal controls
  const onBack =
    modalPages.length > 1
      ? () => {
          removePages(1);
          dispatch(resetWorkspaceReservation());
          dispatch(clearDesks());
          dispatch(resetSuggestedDesk());
        }
      : undefined;

  const onClose = () => {
    closeModal();
    dispatch(resetWorkspaceReservation());
    dispatch(clearDesks());
    dispatch(resetSuggestedDesk());
  };

  return (
    <StyledModalPage
      title={t('workplace:BookAWorkspace')}
      bottom={
        <ModalPageBottom
          buttonDisabled={!canSubmit}
          buttonLabel={currentWorkspaceReservation ? t('workplace:Update') : t('translation:Book')}
          onClick={saveReservation}
        />
      }
      onBack={onBack}
      onClose={onClose}>
      <StyledFlexCol gap={8}>
        <Div>
          <FlexCol>
            {errorMessages.map((error, index) => (
              <Div
                key={`error-${index}`}
                background={theme.card.white.background}
                paddingLeft={16}
                paddingTop={16}
                paddingRight={16}>
                <BookWorkspaceValidationError text={error} />
              </Div>
            ))}
          </FlexCol>
          <FlexCol>
            <ModalCell>
              <H4
                as="h2"
                data-testid={`${TEST_ID_CORE}-modal-h2-invitees`}>
                {t('Invitees')}
              </H4>
            </ModalCell>
            <SelectInviteesButton
              placeholder={t('workplace:InviteesPlaceholder')}
              invitees={invitees}
              date={date}
              onClick={() =>
                addPages([
                  <AddWorkspaceInviteesCard
                    passedInvitees={invitees}
                    onSave={setInvitees}
                    onClose={closeModal}
                  />,
                ])
              }></SelectInviteesButton>
          </FlexCol>
        </Div>
        <FlexCol>
          <ModalCell>
            <H4
              as="h2"
              data-testid={`${TEST_ID_CORE}-modal-h2-Desk`}>
              {t('workplace:Workspace')}
            </H4>
          </ModalCell>

          <WorkspaceOnMapSection
            invitees={invitees}
            date={date}
          />

          <SelectWorkspaceButton
            date={date}
            inviteeCount={invitees.length}
          />
        </FlexCol>
      </StyledFlexCol>
    </StyledModalPage>
  );
};

function getInviteesFromReservation(reservation: WorkspaceReservation | undefined) {
  if (reservation === undefined) return null;
  return (
    reservation.members
      .filter(
        ({isOwner, status}) =>
          (!isOwner && status === RESERVATION_STATUS.Confirmed) || status === RESERVATION_STATUS.InvitationPending,
      )
      .map(({user}) => user) || []
  );
}

function extractErrorMessages(errors: ErrorsData): string[] {
  return Object.values(errors).flatMap((messages) => messages);
}
