import {useEffect} from 'react';

export const useCalculateCssVhVariable = () => {
  useEffect(function calculateCssVhVariable() {
    const resizeListener = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--1vh', `${vh}px`);
      document.documentElement.style.setProperty('--100vh', `${Math.floor(100 * vh)}px`);
    };

    resizeListener();
    window.addEventListener('resize', resizeListener);

    return () => window.removeEventListener('resize', resizeListener);
  }, []);
};
