import {pxToRem} from '@utils';
import styled from 'styled-components';
import {baseStyle} from '../styles';

export const H2 = styled.h2`
  ${baseStyle}

  color: ${({color, theme}) => color || theme.text.color.heading};
  font-size: ${pxToRem(20)};
  font-weight: ${({theme}) => theme.text.weight.bold};
  line-height: ${pxToRem(25)};
`;
