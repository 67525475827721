import {MapView, NoMapPlaceholder} from '@molecules';
import {getMarkerPriorities} from '@lib/store';
import {useCallback, useMemo} from 'react';
import {EventTarget} from '@map/mapiq-map/EventTarget';
import {AbsoluteViewportRestriction} from '@map/mapiq-map/AbsoluteViewportRestriction';
import {FacilityMarkerData, MarkerData} from '@map/mapiq-map/markers/NodeMarkerData';
import {ExploreState} from 'src/components/pages/HereAndNow/useExploreState';

type HaNExploreMapProps = {
  exploreState: ExploreState;
  viewportRestrictions?: AbsoluteViewportRestriction;
  onOutsideClick: () => void;
};

export const HaNExploreMap = ({viewportRestrictions, exploreState, onOutsideClick}: HaNExploreMapProps) => {
  const {
    buildingId,
    floorId,
    highlightedResources,
    spotlightedResource,
    selectedResource,
    setSelectedResource,
    setSpotlightedResource,
    allResources,
    activeTypeFilters,
  } = exploreState;

  // always show all resources for the current floor on the map
  const resourcesOnFloor = useMemo(() => allResources.filter((r) => r.floorId === floorId), [floorId, allResources]);
  const inListOnFloor = useMemo(
    () => highlightedResources.filter((r) => r.floorId === floorId),
    [floorId, highlightedResources],
  );

  const handleClick = useCallback(
    (e: EventTarget) => {
      if (!['area', 'room', 'facility'].includes(e.type)) {
        onOutsideClick();
      }

      const resourceId = e.areaId ?? e.facilityId ?? e.roomId ?? null;
      const resource = (resourceId && resourcesOnFloor.find((r) => r.id === resourceId)) || null;

      setSelectedResource(resource);
    },
    [resourcesOnFloor, setSelectedResource, onOutsideClick],
  );

  const handleHover = useCallback(
    (e: EventTarget) => {
      const resourceId = e.areaId ?? e.facilityId ?? e.roomId ?? null;
      const resource = (resourceId && resourcesOnFloor.find((r) => r.id === resourceId)) || null;

      setSpotlightedResource(resource);
    },
    [setSpotlightedResource, resourcesOnFloor],
  );

  const elementsToKeepInView = useMemo(
    () => (selectedResource && selectedResource.floorId === floorId ? [selectedResource.id] : undefined),
    [selectedResource, floorId],
  );

  const highlightedNodeIds: string[] = useMemo(() => {
    const someLimitingFilterActive = inListOnFloor.length < resourcesOnFloor.length;

    if (someLimitingFilterActive) {
      return inListOnFloor.map((r) => r.id);
    }

    return [];
  }, [inListOnFloor, resourcesOnFloor]);

  const selectedNodeId: string[] = useMemo(() => {
    if (!selectedResource || selectedResource.floorId !== floorId) return [];

    return [selectedResource.id];
  }, [selectedResource, floorId]);

  const nodeStates = useMemo(() => {
    return new Map([
      ...highlightedNodeIds.map((id) => [id, 'highlighted'] as const),
      ...selectedNodeId.map((id) => [id, 'selected'] as const),
    ]);
  }, [highlightedNodeIds, selectedNodeId]);

  const priority = useMemo(
    () => getMarkerPriorities(resourcesOnFloor, highlightedNodeIds, activeTypeFilters),
    [activeTypeFilters, highlightedNodeIds, resourcesOnFloor],
  );

  const markers: MarkerData[] = useMemo(
    () =>
      resourcesOnFloor.flatMap((r) => {
        switch (r.type) {
          case 'facility':
            return {
              type: 'facility',
              nodeId: r.id,
              // TODO: figure out best approach; probably a fallback to "Other" for new/unknown subtypes?
              subType: r.data.facilityType.id as FacilityMarkerData['subType'],
              priority: priority[r.id],
            };
          case 'room':
            return {
              type: 'facility',
              nodeId: r.id,
              subType: 'MeetingRoom',
              priority: priority[r.id],
            };
          case 'area':
            return {
              type: 'facility',
              nodeId: r.id,
              subType: 'Area',
              priority: priority[r.id],
            };
        }
      }),
    [resourcesOnFloor, priority],
  );

  return floorId ? (
    <MapView
      borderRadius={0}
      floorId={floorId}
      buildingId={buildingId}
      highlights={markers}
      fullView={true}
      elementsToKeepInView={elementsToKeepInView}
      disablePointerEvents={false}
      buildingNodeStates={nodeStates}
      viewportRestrictions={viewportRestrictions}
      onClick={handleClick}
      onHover={handleHover}
      spotlightId={spotlightedResource?.floorId === floorId ? spotlightedResource.id : null}
      mapTypeNamespace="HereAndNow_explore"
    />
  ) : (
    <NoMapPlaceholder />
  );
};
