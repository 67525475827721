import {getAppLanguage} from '@lib/store';
import {formatDateOptions} from '@lib/utils';
import {useAppSelector} from '@hooks';
import {parseISO, formatDuration, intervalToDuration} from 'date-fns';
import {DurationProps} from 'src/components/atoms/Duration/types';

export const useDuration = () => {
  const appLanguage = useAppSelector(getAppLanguage);

  const getFormattedDuration = ({startDate, endDate, number, timeUnit}: DurationProps): string => {
    const start = number !== undefined ? 0 : startDate !== undefined ? parseISO(startDate) : 0;
    const tempEnd = number !== undefined ? number : endDate !== undefined ? parseISO(endDate) : 0;

    const end =
      number && timeUnit === 'seconds'
        ? number * 1000
        : number && timeUnit === 'minutes'
        ? number * 60 * 1000
        : number && timeUnit === 'hours'
        ? number * 60 * 60 * 1000
        : number && timeUnit === 'days'
        ? number * 24 * 60 * 60 * 1000
        : tempEnd;

    const transformedDuration = intervalToDuration({start, end});
    return formatDuration(transformedDuration, {...formatDateOptions(appLanguage)});
  };

  return {getFormattedDuration};
};
