import {Icon} from '@atoms';
import styled from 'styled-components';

export const IconOverlay = styled(Icon)`
  background: ${({theme}) => theme.button.primary.background};
  border-radius: 50%;
  border: 1.5px solid transparent;
  bottom: -0.25rem;
  box-shadow: ${({theme}) => theme.boxShadow.secondary};
  color: ${({theme}) => theme.background.white};
  padding: 0.25rem;
  position: absolute;
  right: -0.5rem;
`;

export const CircularProfilePicButton = styled.button`
  background: transparent;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;

  @media (hover) {
    &:hover:not(:disabled) ${IconOverlay} {
      background: ${({theme}) => theme.button.primary.hover.background};
    }
  }

  &:focus-visible {
    outline: none;

    ${IconOverlay} {
      outline: 2px solid ${({theme}) => theme.brand};
      outline-offset: 2px;
    }
  }
`;
