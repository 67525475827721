import {CreateEventRoomSuggestionCellProps} from './types';
import {useTranslation} from 'react-i18next';
import {EventRoom} from '@molecules';
import {MeetingRoomDetailsCard, CreateEventSuggestionItem} from '@organisms';
import {useModal} from '@hooks';

export const CreateEventRoomSuggestionCell = ({
  room,
  selectedId,
  setSelectedId,
  onClick,
  suggestionsLength,
  id,
  onClose,
}: CreateEventRoomSuggestionCellProps) => {
  const {t} = useTranslation();
  const {addPages} = useModal();
  const {floorName, capacity, displayName} = room;

  return (
    <CreateEventSuggestionItem
      item={room}
      type="rooms"
      data-testid={`oraganisms-createEvent-CreateEventRoomSuggestionCell_${room.displayName}`}
      id={id}
      suggestionsLength={suggestionsLength}
      ariaLabel={t('meeting:CreateMeetingRoomSuggestionAria', {
        roomName: displayName,
        seats: capacity,
        floor: floorName,
      })}
      infoAriaLabel={t('meeting:CreateMeetingRoomSuggestionAriaLabel')}
      onClick={() => onClick(room)}
      selectedId={selectedId}
      onInfoClick={() =>
        addPages([
          <MeetingRoomDetailsCard
            room={room}
            onClose={onClose}
          />,
        ])
      }
      setSelectedId={setSelectedId}>
      <EventRoom room={room} />
    </CreateEventSuggestionItem>
  );
};
