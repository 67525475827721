import React, {PropsWithChildren} from 'react';
import {Icon} from '../Icon';
import {pxToRem} from '@utils';
import {StyledFlexRow, StyledIconWrapper} from './styled';

type Props = {
  type?: 'warning' | 'error';
};

export const ValidationCard = ({children, type = 'error'}: PropsWithChildren<Props>) => {
  return (
    <StyledFlexRow $type={type}>
      <StyledIconWrapper $type={type}>
        <Icon
          weight="regular"
          color="white"
          icon="warning"
          size={pxToRem(14)}
        />
      </StyledIconWrapper>
      {children}
    </StyledFlexRow>
  );
};
