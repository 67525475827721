import {AxiosError} from 'axios';
import {AuthorizationError, GenericError, ConnectionError, ValidationError} from '@lib/store';
import {ValidationProblemDetailsDto, trackError} from '@lib/infrastructure';
import {log} from '@lib/utils';

const genericErrorMessage = 'error:GenericError';
const authorizationErrorMessage = 'error:AuthorizationError';
const connectionErrorMessage = 'error:ConnectionError';

export type ErrorMessage =
  | typeof authorizationErrorMessage
  | typeof connectionErrorMessage
  | typeof genericErrorMessage
  | string;

/**
 * Transforms a known error on an error that the application can understand and rethrows it
 * @param error The source error
 * @param i18n The i18n service of the store
 */
export const handleApiError = (error: any): void => {
  log(`API ERROR: ${JSON.stringify(error, null, 2)}`);

  const getErrorMessage = (validationProblem: ValidationProblemDetailsDto): string => {
    const errorDescriptions = Object.entries(validationProblem.errors).map(([_, [firstMessage]]) => firstMessage);
    return errorDescriptions.length > 0 ? errorDescriptions[0] : genericErrorMessage;
  };

  const axiosError = error as AxiosError;
  if (axiosError) {
    const {response, request} = axiosError;
    if (response) {
      trackError({
        message: `${response.config.url}: ${error?.message}`,
        name: error.name,
        stack: error.stack ?? error,
      });

      const status = response.status;
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (status === 400) {
        const validationProblem = response.data as ValidationProblemDetailsDto;
        if (validationProblem && validationProblem.errors) {
          throw new ValidationError(getErrorMessage(validationProblem), validationProblem.title, response.data);
        }
        // The API failed with 400 but the response is not recognized
        throw new GenericError(genericErrorMessage);
      } else if (response.status === 401) {
        throw new AuthorizationError(authorizationErrorMessage);
      }
    } else if (request) {
      trackError({
        message: `${(<any>axiosError.toJSON()).config.url}: ${error?.message}`,
        name: error.name,
        stack: error.stack ?? error,
      });

      // The request was made but no response was received
      throw new ConnectionError(connectionErrorMessage);
    }
  }

  throw new GenericError(genericErrorMessage);
};
