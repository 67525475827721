import {ClickableCellWrapper} from '@molecules';
import {Icon} from '@atoms';
import {P} from '@quarks';
import {truncateString} from '@utils';
import styled from 'styled-components';

export const StyledText = styled(P)`
  ${truncateString};
  flex: 1;
  width: 100%;
  color: ${({theme}) => theme.text.color.subtitle};
  text-align: end;
`;

export const StyledClickableCellWrapper = styled(ClickableCellWrapper)`
  flex: 1;
`;

export const StyledIcon = styled(Icon)`
  align-self: flex-end;
`;
