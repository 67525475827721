import {useTranslation} from 'react-i18next';
import {Icon} from '@atoms';
import {useAppSelector, useBreakPoint, useModal} from '@hooks';
import {getMapDataByFloorId, getConnectedUsersByDate, WorkspaceReservation} from '@lib/store';
import {Avatar, AvatarStack, Button, ExpandMapButton, IconButton} from '@molecules';
import {BigMapModal, BookWorkspaceCard, BookingDetailsCard, WorkspaceMapView} from '@organisms';
import {FlexCol, FlexRow, H4, P} from '@quarks';
import {cn} from '@utils';
import {trackEvent} from '@lib/infrastructure';

type Props = {
  workspaceReservation: WorkspaceReservation | null;
  dateKey: string;
};

export const WorkspaceReservationRow = ({workspaceReservation, dateKey}: Props) => {
  const {t} = useTranslation();
  const breakPoint = useBreakPoint();
  const {openModal, setModalPages, setBigPages, addPages} = useModal();
  const {status: mapStatus} = useAppSelector(
    getMapDataByFloorId(
      workspaceReservation?.buildingId ?? '',
      workspaceReservation ? workspaceReservation.floorId : '',
      '2d',
    ),
  );
  const connections = useAppSelector((state) =>
    getConnectedUsersByDate(state, {
      date: dateKey,
      nodeId: workspaceReservation?.areaId ?? workspaceReservation?.floorId,
    }),
  );

  const smallBreakPoint = breakPoint === 'small';
  const mapIsLoading = mapStatus === 'Loading';
  const workspaceLabel = workspaceReservation
    ? [workspaceReservation.floorName, workspaceReservation.areaName, workspaceReservation.deskName]
        .filter((x) => x)
        .join(', ')
    : 'No workspace reservation';

  function expandMap() {
    if (!workspaceReservation) return;
    const mapView = (
      <BigMapModal
        title="Workspace"
        main={
          <WorkspaceMapView
            workspaceReservation={workspaceReservation}
            interactive={true}
          />
        }
        footer={
          <div className="flex gap-4 justify-start p-4">
            <div className="flex flex-col justify-center items-center bg-beige-500* h-12 w-12 rounded-lg flex-shrink-0">
              <Icon
                icon="desk"
                size="24px"
              />
            </div>
            <div className="flex flex-col">
              <H4>{workspaceReservation.areaName}</H4>
              <P>{workspaceLabel}</P>
            </div>
          </div>
        }
      />
    );

    trackEvent('Home_WorkdayTile__ExpandMap');
    setModalPages([mapView]);
    setBigPages([mapView]);
    openModal();
  }

  function handleClickWorkspaceInformationButton() {
    if (workspaceReservation) {
      addPages([
        <BookingDetailsCard
          date={dateKey}
          workspaceReservation={workspaceReservation}
        />,
      ]);
      openModal();
    }
  }

  return workspaceReservation ? (
    <>
      <FlexRow className="relative h-80 w-full ">
        <WorkspaceMapView workspaceReservation={workspaceReservation} />
        {!mapIsLoading ? (
          <ExpandMapButton
            onClick={expandMap}
            className={'absolute top-4 right-4'}
          />
        ) : null}
      </FlexRow>

      <FlexRow
        gap={16}
        alignItems="center"
        aria-label={t('ChangeOrBookWorkspaceReservation')}
        className="cursor-pointer"
        onClick={handleClickWorkspaceInformationButton}>
        <FlexCol
          justifyContent="center"
          alignItems="center"
          className="bg-beige-500* h-12 w-12 rounded-lg flex-shrink-0">
          <Icon
            icon="desk"
            size="24px"
          />
        </FlexCol>

        <div className="flex justify-between gap-4 gap-y-1 w-full flex-wrap">
          <FlexCol>
            <H4>{workspaceReservation.areaName}</H4>
            <P>{workspaceLabel}</P>
          </FlexCol>

          {connections.length === 1 ? <Avatar user={connections[0]} /> : null}
          {connections.length > 1 ? (
            <AvatarStack
              date={dateKey}
              users={connections}
              overlap={true}
            />
          ) : null}
        </div>

        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleClickWorkspaceInformationButton();
          }}
          icon="info"
          iconButton={'tertiary'}
          aria-label={t('profile:MoreInformation')}
          className="rounded-lg flex-shrink-0"
        />
      </FlexRow>
    </>
  ) : (
    <div
      className={cn('flex items-center justify-between', {
        'border-t-1 border-mapiq-black-150 pt-4': !smallBreakPoint,
      })}>
      <div className="flex gap-4 items-center">
        <div className="flex flex-col justify-center items-center bg-beige-500* size-12 rounded-lg shrink-0">
          <Icon
            icon="desk"
            size="24px"
          />
        </div>
        <div className="flex flex-col">
          <h4>{t('BookWorkspace')}</h4>
        </div>
      </div>
      <Button
        button={'tertiary'}
        onClick={() => {
          addPages([<BookWorkspaceCard date={dateKey} />]);
          openModal();
        }}>
        {t('Book')}{' '}
      </Button>
    </div>
  );
};
