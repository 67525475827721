import {Fragment, KeyboardEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {CalendarConnectionsListProps} from './types';
import {getAcceptedConnections, getConnectionsGroupedByWorkdayBuilding} from '@lib/store';
import {useAppSelector} from '@hooks';

import {FlexCol, Grid, P} from '@quarks';
import {Illustration} from '@atoms';
import {Button} from '@molecules';
import {CalendarConnectionsBuildingCell} from '@organisms';

export const CalendarConnectionsList = ({date}: CalendarConnectionsListProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const acceptedConnections = useAppSelector(getAcceptedConnections);
  // TODO : This selector is meant for the book a desk flow and should be optimized in the future.
  const connectionsGroupedByWorkdayBuilding = useAppSelector((state) =>
    getConnectionsGroupedByWorkdayBuilding(state, date),
  );

  const handleOnClick = () => navigate('/connections');

  const handleOnKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') handleOnClick();
  };

  if (connectionsGroupedByWorkdayBuilding.length === 0) {
    return (
      <Grid
        gap={16}
        justifyItems="center">
        <Illustration
          illustration="connections"
          size="100px"
        />
        {acceptedConnections.length > 0 ? (
          <P textAlign="center">{t('connection:NoColleaguesInOfficeEmptyMessage')}</P>
        ) : (
          <FlexCol
            alignItems="center"
            gap={16}>
            <P fontWeight="bold">{t('connection:NoConnectionsAtAllTitle')}</P>
            <P textAlign="center">{t('connection:NoConnectionsAtAllMessages')}</P>
            <Button
              as="a"
              tabIndex={0}
              button="tertiary"
              onKeyDown={handleOnKeyDown}
              onClick={handleOnClick}>
              {t('connection:NoConnectionsAtAllButtonLabel')}
            </Button>
          </FlexCol>
        )}
      </Grid>
    );
  }

  return (
    <FlexCol gap={16}>
      {connectionsGroupedByWorkdayBuilding.map((building, i) => (
        <Fragment key={building.buildingId}>
          <CalendarConnectionsBuildingCell
            building={building}
            date={date}
          />
          {connectionsGroupedByWorkdayBuilding.length - 1 > i && <hr />}
        </Fragment>
      ))}
    </FlexCol>
  );
};
