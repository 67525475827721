import {MeetingRoom} from '@lib/store';
import {MapView} from '@molecules';
import {useFacilityMarkers} from '../../hereAndNow/useFacilityMarkers';

export const MeetingRoomMapView = ({room, interactive = false}: {room: MeetingRoom; interactive?: boolean}) => {
  const buildingId = room.buildingId;
  const floorId = room.floorId;
  const facilityMarkers = useFacilityMarkers(buildingId, floorId);

  return (
    <MapView
      buildingId={buildingId}
      floorId={floorId}
      disablePointerEvents={interactive ? false : true}
      fullView={true}
      highlights={[...facilityMarkers, {type: 'facility', subType: 'MeetingRoom', nodeId: room.id, priority: 3}]}
      buildingNodeTypeStates={new Map([['facility', 'dimmed']])}
      buildingNodeStates={new Map([[room.id, 'selected']])}
    />
  );
};
