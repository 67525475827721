import styled from 'styled-components';
import {animated} from 'react-spring';
import {FlexCol} from '@quarks';

export const SensorInformationWrapper = styled(animated.div)`
  background: ${({theme}) => theme.background.white};
  display: flex;
  height: var(--modal-height);
  flex-direction: column;
`;

export const StyledContainer = styled(FlexCol)`
  gap: 32px;
  height: 100%;
  overflow: auto;
  justify-content: flex-start;
  padding: 20px;
`;
