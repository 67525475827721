import {useEffect, useRef} from 'react';
import {scrollParentForChild} from '@utils';

/**
 * @param activeIndex a number to keep track of which index of children is active.
 * @returns two refs to be attached to the element that should scroll and the element wrapping the children.
 */

export const useScrollParentForChild = (activeIndex?: number) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const childWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current && childWrapperRef.current && activeIndex !== undefined) {
      const activeOption = childWrapperRef.current.children[activeIndex] as HTMLElement;

      if (activeOption) scrollParentForChild(activeOption, scrollRef.current);
    }
  }, [scrollRef, childWrapperRef, activeIndex]);

  return [scrollRef, childWrapperRef];
};
