import {Children, isValidElement, useEffect, useState, useRef} from 'react';
import {SubMenuProps} from './types';

import {SubMenuItem} from './SubMenuItem';
import {StyledSubMenu} from './styles';

export const SubMenu = ({children}: SubMenuProps) => {
  const [borderOffset, setBorderOffset] = useState('0px');
  const [borderWidth, setBorderWidth] = useState('95px');
  const childrenRef = useRef<Array<HTMLButtonElement>>([]);

  useEffect(() => {
    const measureChildren = () => {
      if (childrenRef.current.length > 0) {
        Children.forEach(children, (element, i) => {
          if (isValidElement(element)) {
            if (element.props.active && childrenRef.current[i]) {
              const {offsetLeft, offsetWidth} = childrenRef.current[i];
              setBorderOffset(`${offsetLeft}px`);
              setBorderWidth(`${offsetWidth}px`);
            }
          }
        });
      }
    };

    measureChildren();

    window.addEventListener('resize', measureChildren);

    return () => window.removeEventListener('resize', measureChildren);
  }, [children]);

  return (
    <StyledSubMenu
      $borderWidth={borderWidth}
      $borderOffset={borderOffset}>
      {Children.map(children, (element, i) => {
        if (isValidElement(element)) {
          const {children: childchild} = element.props;
          return (
            <SubMenuItem
              ref={(el: HTMLButtonElement) => (childrenRef.current[i] = el)}
              {...element.props}>
              {childchild}
            </SubMenuItem>
          );
        }
      })}
    </StyledSubMenu>
  );
};
