import {Outlet} from 'react-router-dom';

import {getIsSignedIn, getIsSigningOut} from '@lib/store';

import {useAppSelector, useLoginRedirectResult} from '@hooks';
import {Login} from '@pages';

export const PrivateRoute = () => {
  const isSignedIn = useAppSelector(getIsSignedIn);
  const isSigningOut = useAppSelector(getIsSigningOut);

  useLoginRedirectResult();

  if (!isSignedIn || isSigningOut) {
    return <Login />;
  }
  return <Outlet />;
};
