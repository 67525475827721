import {Card} from '@atoms';
import {SwiperHorizontal} from '@molecules';
import {FlexRow} from '@quarks';
import styled from 'styled-components';

export const StyledSwiperHorizontal = styled(SwiperHorizontal).attrs((attrs) => ({...attrs}))`
  width: 100%;
  &.swiper > div.swiper-wrapper {
    padding: 0px;
  }
`;

export const StyledContainer = styled(FlexRow)`
  background: ${({theme}) => theme.background.white};
  border-radius: 12px;
  box-shadow: ${({theme}) => theme.boxShadow.secondary};
  align-items: center;
  gap: 16px;
  padding: 24px;
  width: 335px;
`;

export const StyledExploreCellContainer = styled(Card).attrs((attrs) => ({...attrs}))`
  width: 312px;
  display: grid;
  min-height: 100px;
  background: ${({theme}) => theme.background.white};
  border-radius: 8px;
`;

export const Wrapper = styled(FlexRow)`
  background: ${({theme}) => theme.card.transparent.background};
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  border-radius: 8px;
  padding: 6px;
  cursor: pointer;
  border: 2px solid transparent;
  width: 100%;

  @media (hover) {
    &:hover {
      background: ${({theme}) => theme.card.transparent.hover.background};
    }
  }

  &:focus-visible {
    border: ${({theme}) => `2px solid ${theme.brand}`};
    outline: none;
  }
`;
