import {useCallback, useContext} from 'react';
import {DialogActions, DialogTypes, DialogContext} from '@contexts';
import {IconNames} from '@atoms';

let resolveCallback: (value: boolean | PromiseLike<boolean>) => void;

export const useDialog = () => {
  const {state, dispatch} = useContext(DialogContext);

  const confirmationDialog = useCallback(
    (incomingTitle: string, incomingMessage: string, incomingIcon?: IconNames, incomingButtonLabel?: string) => {
      dispatch({
        type: DialogActions.show,
        payload: {
          buttonLabel: incomingButtonLabel,
          icon: incomingIcon,
          isDialogOpen: state.isDialogOpen,
          message: incomingMessage,
          title: incomingTitle,
          type: DialogTypes.confirm,
        },
      });

      return new Promise((res, _rej) => {
        resolveCallback = res;
      });
    },
    [dispatch, state.isDialogOpen],
  );

  const alertDialog = useCallback(
    (incomingTitle: string, incomingMessage: string, incomingIcon?: IconNames, incomingButtonLabel?: string) => {
      dispatch({
        type: DialogActions.show,
        payload: {
          buttonLabel: incomingButtonLabel,
          icon: incomingIcon,
          isDialogOpen: state.isDialogOpen,
          message: incomingMessage,
          title: incomingTitle,
          type: DialogTypes.alert,
        },
      });

      return new Promise((res, _rej) => {
        resolveCallback = res;
      });
    },
    [dispatch, state.isDialogOpen],
  );

  const destructiveDialog = useCallback(
    (incomingTitle: string, incomingMessage: string, incomingIcon?: IconNames, incomingButtonLabel?: string) => {
      dispatch({
        type: DialogActions.show,
        payload: {
          buttonLabel: incomingButtonLabel,
          icon: incomingIcon,
          isDialogOpen: state.isDialogOpen,
          message: incomingMessage,
          title: incomingTitle,
          type: DialogTypes.destroy,
        },
      });

      return new Promise((res, _rej) => {
        resolveCallback = res;
      });
    },
    [dispatch, state.isDialogOpen],
  );

  const onConfirm = () => {
    closeDialog();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeDialog();
    resolveCallback(false);
  };

  const closeDialog = () => {
    dispatch({type: DialogActions.hide});
    setTimeout(() => {
      clearDialog();
    }, 500);
  };

  const clearDialog = () => {
    dispatch({type: DialogActions.clear});
  };

  return {alertDialog, confirmationDialog, destructiveDialog, onConfirm, onCancel, state};
};
