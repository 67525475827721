import {CSSProperties, useEffect, useRef, useState} from 'react';
import {AddConnectionsCardCellProps} from './types';
import {useTranslation} from 'react-i18next';

import {Transition, TransitionStatus} from 'react-transition-group';

import {getConnectionById, loadConnectionSuggestions, sendConnection, withAsyncThunkErrorHandling} from '@lib/store';
import {useAppDispatch, useAppSelector} from '@hooks';

import {Button, UserCell} from '@molecules';

export const AddConnectionsCardCell = ({user, i, length, ...props}: AddConnectionsCardCellProps) => {
  const [connectionId, setConnectionId] = useState<string>('');
  const connection = useAppSelector((state) => getConnectionById(state, connectionId ?? ''));
  const [isSending, setIsSending] = useState<boolean>(false);
  const {t} = useTranslation();
  const ref = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user.connectionId) {
      setConnectionId(user.connectionId);
    }
  }, [user]);

  const sendInvite = async () => {
    setIsSending(true);

    const response = await dispatch(withAsyncThunkErrorHandling(() => sendConnection(user)));
    const {success} = response;

    if (success) {
      const newConnectionId = response.result.connectionUsers[user.id].connectionId!;
      setConnectionId(newConnectionId);
      dispatch(loadConnectionSuggestions());
    }

    setIsSending(false);
  };

  const animationTime = 500;
  const trailTime = 50;

  const style = (state: TransitionStatus) => {
    return {
      opacity: state === 'entered' ? 1 : 0,
      transition: `opacity ${animationTime}ms ${trailTime * i}ms`,
    } as CSSProperties;
  };

  return (
    <Transition
      nodeRef={ref}
      unmountOnExit={true}
      timeout={{
        appear: 0,
        enter: i * trailTime,
        exit: animationTime + length * trailTime,
      }}
      {...props}>
      {(state) => (
        <div ref={ref}>
          <UserCell
            right={
              <Button
                button="primary"
                data-testid="organisms-addConnectionsCardCell-button"
                loading={isSending}
                disabled={!!connection?.status}
                onClick={sendInvite}
                size="small">
                {t('connection:Connect')}
              </Button>
            }
            style={style(state)}
            subtitle={user.email}
            user={user}
          />
        </div>
      )}
    </Transition>
  );
};
