import styled from 'styled-components';
import {StyledComboBoxProps} from './types';

import {pxToRem} from '@utils';

export const StyledWrapper = styled.div`
  align-items: center;
  background: ${({theme}) => theme.card.transparent.background};
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  font-size: ${pxToRem(15)};
  gap: 4px 8px;
  padding: 4px;
  width: 100%;

  @media (hover) {
    &:hover {
      cursor: text;
      background: ${({theme}) => theme.card.transparent.hover.background};
    }
  }
`;

export const StyledInputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  min-height: ${pxToRem(46.5)};
`;

export const StyledComboBox = styled.textarea<StyledComboBoxProps>`
  background: transparent;
  border: none;
  flex: 1;
  resize: none;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    opacity: ${({$isPlaceholderTransparent}) => ($isPlaceholderTransparent ? 0 : 1)};
  }
`;
