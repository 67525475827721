import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

export const StyledHeader = styled.header``;

export const StyledNavLink = styled(NavLink)`
  border-radius: 2px;
  display: flex;
  align-items: center;

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.brand};
    outline-offset: 2px;
  }
`;
