import {StyledFull, StyledIcon, StyledWordMark} from './styles';

type MapiqLogoProps = {
  dark?: boolean;
  light?: boolean;
  theme?: 'dark' | 'light';
  type?: 'icon' | 'wordmark' | 'full';
  width?: string;
};

export const MapiqLogo = ({dark, light, theme = 'dark', type = 'icon', width = '10rem', ...props}: MapiqLogoProps) => {
  const actualTheme: 'light' | 'dark' = dark ? 'dark' : light ? 'light' : theme;

  switch (type) {
    case 'full': {
      return (
        <StyledFull
          $type={actualTheme}
          $width={width}
          aria-label="Mapiq Logo"
          role="img"
          {...props}
        />
      );
    }
    case 'icon': {
      return (
        <StyledIcon
          $type={actualTheme}
          $width={width}
          aria-label="Mapiq Logo"
          role="img"
          {...props}
        />
      );
    }
    case 'wordmark': {
      return (
        <StyledWordMark
          $type={actualTheme}
          $width={width}
          aria-label="Mapiq Logo"
          role="img"
          {...props}
        />
      );
    }
  }
};
