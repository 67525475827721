import {IllustrationProps} from './types';
import {illustrations} from './illustrations';
import {StyledIllustration} from './styles';

export const Illustration = ({className, illustration, size, style, ...props}: IllustrationProps) => {
  return (
    <StyledIllustration
      $size={size}
      aria-label={props['aria-label'] || illustration}
      className={className}
      role={props.role || 'img'}
      style={style}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      {illustrations[illustration]()}
    </StyledIllustration>
  );
};
