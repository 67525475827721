import {FlexCol} from '@quarks';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

export const CalendarCardCell = styled(NavLink)<{
  $isDisabled: boolean;
}>`
  background: ${({theme}) => theme.background.white};
  border: 2px solid transparent;
  color: ${({$isDisabled, theme}) => ($isDisabled ? theme.disabled.color : theme.text.color.body)};
  cursor: pointer;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 28px;
  padding: 16px;
  position: relative;
  transition: background var(--speed-superfast);

  & * {
    transition: background var(--speed-superfast), color var(--speed-superfast);
  }

  @media (hover) {
    &:hover {
      color: ${({$isDisabled, theme}) => ($isDisabled ? theme.disabled.color : theme.text.color.body)};
      background: ${({theme}) => theme.card['white'].hover.background};
      text-decoration: none;

      &[aria-disabled='true'] {
        background: ${({theme}) => theme.background.white};
      }
    }
  }

  &:focus-visible {
    border: 2px solid ${({theme}) => theme.brand};
    color: ${({$isDisabled, theme}) => ($isDisabled ? theme.disabled.color : theme.text.color.body)};
    text-decoration: none;
    outline: 2px solid ${({theme}) => theme.brand};
    outline-offset: 2px;
    outline: none;

    &::before {
      left: 0;
      top: 0;
      height: 100%;
    }
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
`;

export const DayWrapper = styled(FlexCol)<{$isActive: boolean; $isToday: boolean}>`
  align-items: center;

  background: ${({$isToday, theme}) => ($isToday ? theme.card.beige500.background : 'transparent')};
  border: 2px solid ${({$isActive, theme}) => ($isActive ? theme.text.color.body : 'transparent')};
  border-radius: 6px;
  justify-content: flex-start;
  gap: 2px;
  padding: 11px 4px;
`;
