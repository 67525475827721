import {StyledRangeInput} from './styles';
import {RangeInputProps} from './types';

export const RangeInput = (props: RangeInputProps) => {
  const {value, max, min} = props;

  const percentage = ((value - min) / (max - min)) * 100;

  return (
    <StyledRangeInput
      type="range"
      $trackWidth={percentage}
      {...props}
    />
  );
};
