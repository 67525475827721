import {getFloorById, getConnectedUsersByDate, ConnectionUser, AreaWithSensorInfo} from '@lib/store';
import {StyledModalCellItemIcon, StyledTruncatedText, Wrapper} from './styles';
import {FlexCol, FlexRow, P} from '@quarks';
import {useTheme} from 'styled-components';
import {useAppSelector, useAreaSensorInfo} from '@hooks';
import {useMemo} from 'react';
import {AvatarStack} from '@molecules';
import {HaNWorkspaceIcon} from '../HaNWorkspaceIcon';

type HaNWorkspaceCellProps = {
  area: AreaWithSensorInfo;
  onClick: (area: AreaWithSensorInfo) => void;
};

export const HaNWorkspaceCell = ({area, onClick}: HaNWorkspaceCellProps) => {
  const theme = useTheme();
  const floor = useAppSelector((state) => getFloorById(state, area.floorId))!;
  const selectorProps = useMemo(() => ({date: new Date().toISOString(), nodeId: area.id}), [area.id]);
  const connections: ConnectionUser[] = useAppSelector((state) => getConnectedUsersByDate(state, selectorProps));
  const {areaSensorInfo} = useAreaSensorInfo(area.id) ?? {};

  const memoizedAvatarStack = useMemo(
    () => (
      <AvatarStack
        date={selectorProps.date}
        users={connections}
        overlap={true}
      />
    ),
    [selectorProps.date, connections],
  );

  return (
    <Wrapper
      tabIndex={1}
      onClick={() => onClick(area)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onClick(area);
      }}>
      <FlexRow
        gap={8}
        alignItems="center"
        width="100%">
        <FlexRow gap={8}>
          <StyledModalCellItemIcon
            backgroundColor={theme.card.beige500.background}
            icon={'desk'}
          />
          <FlexCol
            justifyContent="center"
            gap={2}
            overflow="hidden">
            <StyledTruncatedText fontWeight="bold">{area.name}</StyledTruncatedText>
            <P>{floor.name}</P>
            {connections.length ? memoizedAvatarStack : null}
          </FlexCol>
        </FlexRow>
        {areaSensorInfo && (
          <FlexCol>
            <HaNWorkspaceIcon iconType={areaSensorInfo.status} />
          </FlexCol>
        )}
      </FlexRow>
    </Wrapper>
  );
};
