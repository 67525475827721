import {Link} from '@quarks';
import {ProfileCell} from '../styles';

export const FAQCell = () => {
  return (
    <ProfileCell>
      <Link
        data-testid="organisms-faqCell-link"
        to="https://support.mapiq.com/support/solutions/35000142390">
        FAQ
      </Link>
    </ProfileCell>
  );
};
