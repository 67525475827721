import styled from 'styled-components';
import {Toaster as T} from 'react-hot-toast';
import {Icon} from '@atoms';
import {pxToRem} from '@utils';
import {createPortal} from 'react-dom';

const toasterRoot = document.getElementById('toaster-portal')!;

export const Toaster = () => {
  return createPortal(<StyledToaster />, toasterRoot);
};

const StyledToaster = styled(T).attrs(({theme, ...attrs}) => ({
  ...attrs,
  position: 'bottom-center',
  toastOptions: {
    duration: 3000,
    style: {padding: 16, gap: 8, borderRadius: 8, maxWidth: 'fit-content'},
    success: {
      style: {color: theme.toast.success.color, background: theme.toast.success.background},
      icon: (
        <Icon
          icon="check"
          color={theme.icon.duotone.color}
          size={pxToRem(24)}
        />
      ),
    },
    error: {
      style: {color: theme.toast.error.color, background: theme.toast.error.background},
      icon: (
        <Icon
          icon="warning"
          color={theme.icon.duotone.color}
          size={pxToRem(24)}
        />
      ),
    },
  },
}))``;
