import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {CreateMeetingEventProps} from './types';

import {getLastCalendarDayAsDate, MeetingInviteeSearchResult} from '@lib/store';

import {useAppSelector, useDestructiveDialog, useCreateEvent, useModal} from '@hooks';

import {FlexCol, H2} from '@quarks';
import {Textarea} from '@atoms';
import {ModalCell, ToggleCell} from '@molecules';
import {
  CreateEventTimeSlotSuggestionsList,
  ModalPageBottom,
  CreateEventRoomSuggestionsList,
  AddInviteesCard,
  SelectInviteesButton,
} from '@organisms';
import {formatDate, useFilters} from '@lib/utils';
import {isAfter, isSameDay, parseISO} from 'date-fns';
import {StyledFlexCol, StyledModalPage} from './styles';

const initialEvent: {
  attendees: MeetingInviteeSearchResult[];
  endDateTime: string;
  isAllDay: boolean;
  isOnlineMeeting: boolean;
  message: string;
  organizerTimeZone: string;
  rooms: string[];
  startDateTime: string;
  title: string;
} = {
  attendees: [],
  endDateTime: '',
  isAllDay: false,
  isOnlineMeeting: true,
  message: '',
  organizerTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  rooms: [],
  startDateTime: '',
  title: '',
};

export const CreateEventCard = ({date}: CreateMeetingEventProps) => {
  const {addPages, closeModal, setOnCloseCallback} = useModal();
  const {destructiveDialog} = useDestructiveDialog();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {setConfig} = useFilters('CalendarOverview_NewEventRoom');
  useEffect(() => {
    return () => {
      setConfig({});
    };
  }, [setConfig]);

  const lastDayOnCalendar = useAppSelector(getLastCalendarDayAsDate);
  // * We have a few vars that are not being used just yet but I put them here since we will be using them later.
  const [
    {attendees, endDateTime, isAllDay, isOnlineMeeting, message, organizerTimeZone, rooms, startDateTime, title},
    setNewEvent,
  ] = useState(initialEvent);

  const updateEvent = (e: any) => {
    setNewEvent((prevState) => ({...prevState, [e.target.name]: e.target.value}));
  };

  const hasUnsavedChanges = useMemo(() => {
    return (
      title !== '' ||
      message !== '' ||
      attendees.length > 0 ||
      !isOnlineMeeting ||
      endDateTime !== '' ||
      startDateTime !== ''
    );
  }, [title, message, attendees, isOnlineMeeting, endDateTime, startDateTime]);

  const onClose = useCallback(async () => {
    if (!hasUnsavedChanges) {
      closeModal();
    } else {
      const confirmation = await destructiveDialog(
        t('meeting:DiscardEventChangesTitle'),
        t('meeting:DiscardEventChangesMessage'),
        'warning',
        t('meeting:DiscardEventChangesConfirm'),
      );
      if (confirmation) closeModal();
    }
  }, [closeModal, destructiveDialog, t, hasUnsavedChanges]);

  useEffect(() => {
    setOnCloseCallback(() => () => onClose());
  }, [setOnCloseCallback, onClose]);

  const handleOnSuccess = () => {
    if (lastDayOnCalendar) {
      const startDateTimeAsDate = parseISO(startDateTime);
      if (!isSameDay(parseISO(date), startDateTimeAsDate) && !isAfter(startDateTimeAsDate, lastDayOnCalendar))
        navigate(formatDate(startDateTimeAsDate, 'yyyy-MM-dd', 'en'));
    }
  };

  const {createEvent, isCreatingEvent} = useCreateEvent(handleOnSuccess);

  return (
    <StyledModalPage
      bottom={
        <ModalPageBottom
          buttonLabel={t('meeting:CreateMeetingCreateEventButton')}
          buttonDisabled={!title || !startDateTime || !endDateTime}
          buttonLoading={isCreatingEvent}
          onClick={() =>
            createEvent(
              {
                title,
                message,
                attendees: attendees.map((a) => a.email),
                startDateTime,
                endDateTime,
                isOnlineMeeting,
                rooms,
                isAllDay,
                organizerTimeZone,
                origin: 'NewMeeting',
              },
              t('meeting:CreateMeetingSuccessMessageWithoutDate'),
            )
          }
        />
      }
      onClose={onClose}
      title={title ? title : t('screen:NewEvent')}>
      <StyledFlexCol
        as="form"
        gap={8}
        onSubmit={(e) => e.preventDefault()}>
        <FlexCol gap={1}>
          <ModalCell fontWeight="bold">
            <Textarea
              data-testid="organisms-createEvent-CreateEventCard__title"
              maxLength={255}
              name="title"
              onChange={updateEvent}
              placeholder={t('meeting:CreateMeetingTitlePlaceholder')}
              value={title}
            />
          </ModalCell>
          <ModalCell>
            <Textarea
              maxLength={600}
              data-testid="organisms-createEvent-CreateEventCard__message"
              name="message"
              onChange={updateEvent}
              placeholder={t('meeting:CreateMeetingMessagePlaceholder')}
              value={message}
            />
          </ModalCell>
        </FlexCol>

        <FlexCol gap={1}>
          <ModalCell>
            <H2>{t('Invitees')}</H2>
          </ModalCell>
          <SelectInviteesButton
            placeholder={t('meeting:CreateMeetingInviteesPlaceholder')}
            data-testid="organisms-createEvent-CreateEventCard_invite-people-button"
            invitees={attendees}
            date={startDateTime}
            onClick={() =>
              addPages([
                <AddInviteesCard
                  invitees={attendees}
                  onClose={onClose}
                  onSave={(invitees) => {
                    updateEvent({
                      target: {
                        name: 'attendees',
                        value: invitees,
                      },
                    });
                  }}
                />,
              ])
            }></SelectInviteesButton>
        </FlexCol>

        <CreateEventTimeSlotSuggestionsList
          attendees={attendees}
          date={date}
          endDateTime={endDateTime}
          startDateTime={startDateTime}
          onClose={onClose}
          onSelectTimeSlot={(timeSlotStart, timeSlotEnd) => {
            updateEvent({
              target: {
                name: 'startDateTime',
                value: timeSlotStart,
              },
            });
            updateEvent({
              target: {
                name: 'endDateTime',
                value: timeSlotEnd,
              },
            });
          }}
        />

        <CreateEventRoomSuggestionsList
          attendees={attendees}
          selectedEndDateTime={endDateTime}
          selectedStartDateTime={startDateTime}
          rooms={rooms}
          onClose={onClose}
          onSelectRoom={(value) => {
            updateEvent({
              target: {
                name: 'rooms',
                value: value,
              },
            });
          }}
        />

        <FlexCol gap={1}>
          <ModalCell>
            <H2>{t('meeting:OnlineEvent')}</H2>
          </ModalCell>
          <ModalCell>
            <ToggleCell
              label={t('meeting:CreateMeetingOnlineMeetingToggleLabel')}
              checked={isOnlineMeeting}
              name="isOnlineMeeting"
              onToggle={(e) => updateEvent({target: {name: e.target.name, value: e.target.checked}})}
            />
          </ModalCell>
        </FlexCol>
      </StyledFlexCol>
    </StyledModalPage>
  );
};
