import styled from 'styled-components';
import {StyledMiddleProps, StyledThreeColumnCellProps} from './types';

import {pxToRem, truncateString} from '@utils';

export const StyledThreeColumnCell = styled.div<StyledThreeColumnCellProps>`
  align-items: ${({$alignItems}) => $alignItems ?? 'center'};
  display: flex;
  gap: 0 16px;
  justify-content: space-between;
  max-width: 100%;
  padding: ${({$padding}) =>
    $padding === 'none' ? '0' : $padding === 'small' ? '8px 0' : $padding === 'medium' ? '12px 0' : '16px 0'};
  border-bottom: ${({$separator, theme}) => $separator === 'both' && `1px solid ${theme.divider.grey}`};
  border-top: ${({$separator, theme}) => $separator === 'both' && `1px solid ${theme.divider.grey}`};

  &:not(:last-child) {
    border-bottom: ${({$separator, theme}) => $separator && `1px solid ${theme.divider.grey}`};
  }
`;

export const Left = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: ${pxToRem(32)};
  min-width: ${pxToRem(32)};
`;

export const Middle = styled.div<StyledMiddleProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  & > p {
    ${({$truncate}) => $truncate && truncateString}
  }

  & > small {
    ${({$truncate}) => $truncate && truncateString}
    color: ${({theme}) => theme.text.color.subtitle};
  }
`;

export const Right = styled.div`
  align-items: center;
  display: flex;
  gap: 0 8px;
  justify-content: flex-end;
  min-height: ${pxToRem(32)};
  min-width: ${pxToRem(32)};
`;
