import {useModal} from '@hooks';
import {ModalPage, ModalPageBottom} from '@organisms';
import {FlexCol, Span} from '@quarks';
import {StyledInputLabel} from './styles';
import {Input} from '@molecules';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type Entity = {id: string; label: string};

type Props = {
  defaultId: string;
  preselectedId: string;
  entities: Entity[];
  headerTitle: string;
  onSelect: (entityId: string) => void;
};

export const SingleSelectCard = ({entities, onSelect, defaultId, preselectedId, headerTitle}: Props) => {
  const [selectedEntityId, setSelectedEntityId] = useState(preselectedId);
  const {closeModal} = useModal();
  const {t} = useTranslation();

  return (
    <ModalPage
      onClose={closeModal}
      title={headerTitle}
      bottom={
        <ModalPageBottom
          buttonLabel={t('translation:ApplyFilter')}
          cancelButtonLabel={t('translation:ClearFilter')}
          onClick={() => {
            onSelect(selectedEntityId);
            closeModal();
          }}
        />
      }>
      <FlexCol gap={32}>
        {entities.map(({id, label}) => (
          <StyledInputLabel
            id={id}
            key={id + label}>
            <Span>
              {label} {id === defaultId ? `(${t('Default')})` : null}
            </Span>
            <Input
              name={label}
              type={'radio'}
              value={id}
              checked={id === selectedEntityId}
              onChange={() => setSelectedEntityId(id)}
            />
          </StyledInputLabel>
        ))}
      </FlexCol>
    </ModalPage>
  );
};
