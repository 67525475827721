import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

type DragAndDropAreaProps = {
  $dragging: boolean;
};

export const DragAndDropArea = styled.div<DragAndDropAreaProps>`
  border: 2px dashed
    ${({$dragging, theme}) =>
      $dragging ? theme.border.color.dragAndDropActive : theme.border.color.dragAndDropInactive};
  background-color: ${({$dragging, theme}) => ($dragging ? theme.background.dragAndDropAreaDragging : '')};
  transition: border var(--speed-fast), background-color var(--speed-fast);
  width: 100%;
  height: 100%;

  ${BREAKPOINTS.large`
  border: none;
  background-color: transparent;
  transition: none;
 `}
`;
