import {InputProps} from './types';

import {Icon, Loader} from '@atoms';
import {assertNever, pxToRem} from '@utils';
import {StyledInput, Wrapper} from './styles';

export const Input = ({
  iconWeight = 'light',
  className,
  showLoader,
  size,
  style,
  type,
  value,
  invalid,
  ...props
}: InputProps) => {
  switch (type) {
    case 'color':
    case 'date':
    case 'datetime-local':
    case 'email':
    case 'file':
    case 'hidden':
    case 'image':
    case 'month':
    case 'radio':
    case 'checkbox':
    case 'range':
    case 'reset':
    case 'tel':
    case 'time':
    case 'url':
    case 'week':
    case 'password':
      return (
        <StyledInput
          className={className}
          style={style}
          type={type}
          value={value}
          {...props}
        />
      );
    case 'number':
      return (
        <Wrapper
          $size="default"
          $type={type}
          $value={value}
          className={className}
          style={style}>
          <StyledInput
            placeholder={props.placeholder || '0'}
            type="number"
            value={value}
            {...props}
          />
        </Wrapper>
      );
    case 'search':
      return (
        <Wrapper
          $size={size}
          $type={type}
          $value={value}
          className={className}
          style={style}>
          <Icon
            icon="search"
            size={pxToRem(20)}
            color="currentColor"
            weight={iconWeight}
          />
          <StyledInput
            placeholder={props.placeholder || 'Search'}
            type="search"
            value={value}
            {...props}
          />
          {showLoader && <Loader size={pxToRem(28)} />}
        </Wrapper>
      );
    case 'text':
      return (
        <Wrapper
          $size={size}
          $type={type}
          $value={value}
          className={className}
          $invalid={!!invalid}
          style={style}>
          <StyledInput
            placeholder={props.placeholder || 'Text'}
            type="text"
            value={value}
            {...props}
          />
        </Wrapper>
      );
    default:
      return assertNever(type);
  }
};
