import {useAppDispatch, useAppSelector, useModal, useProfilePic} from '@hooks';
import {ChangeEvent, useRef, useState, DragEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {ModalPage, ModalPageBottom, ProfilePictureEditor} from '@organisms';
import AvatarEditor from 'react-avatar-editor';
import {
  changeProfilePicture,
  deleteProfilePicture,
  getUserEmail,
  getUserFriendlyName,
  getUserImageHash,
  getUserInitials,
  postProfilePicture,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {Avatar, Button, avatarSizeToPx} from '@molecules';
import {FlexCol, FlexRow, H2, P, Subtitle} from '@quarks';
import {AvatarSize} from 'mapiq-molecules';
import {DragAndDropArea} from './styles';

// Note: due to a bug in the back-end, we need to resize all images we PUT or POST
// to be exactly 512x512, even if the source images is actually of lower resolution
const REQUIRED_IMAGE_DIMENSION = 512;
const IMG_TYPE = 'image/jpeg';
const JPG_QUALITY = 0.7;

export const ProfilePictureModal = () => {
  const {t} = useTranslation();
  const {closeModal} = useModal();
  const dispatch = useAppDispatch();

  const imageEditorRef = useRef<AvatarEditor>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const userName = useAppSelector(getUserFriendlyName);
  const email = useAppSelector(getUserEmail);
  const initials = useAppSelector(getUserInitials);
  const imageHash = useAppSelector(getUserImageHash);

  const previewSize: AvatarSize = 'xxlarge';
  const currentPic = useProfilePic(imageHash, avatarSizeToPx(previewSize));

  const [newPic, setNewPic] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragging = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragging(true);
    } else if (e.type === 'dragleave') {
      setIsDragging(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files?.[0]) {
      const imgFile = e.dataTransfer.files[0];
      handleFile(imgFile);
    }
  };

  const uploadPhotoFromCanvas = async (cvs: HTMLCanvasElement) => {
    try {
      const action = currentPic ? changeProfilePicture : postProfilePicture;
      const imageBlob = await canvasToBlobOfSize(cvs, REQUIRED_IMAGE_DIMENSION);

      const formData = new FormData();
      formData.append('UserImage', imageBlob);

      const {success} = await dispatch(withAsyncThunkErrorHandling(() => action(formData)));

      if (success) closeModal();
    } catch (err) {
      if (err instanceof Error) {
        setIsUploading(false);
      }
    }
  };

  const deletePhoto = async () => {
    const {success} = await dispatch(withAsyncThunkErrorHandling(deleteProfilePicture));
    if (success) closeModal();
  };

  // Event handlers
  const handleConfirm = async () => {
    // This will always return a square image at the maximum
    // size its original resolution supports
    let cvs = imageEditorRef.current?.getImage();
    if (!cvs) return;

    setIsUploading(true);
    await uploadPhotoFromCanvas(cvs);
    setIsUploading(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    await deletePhoto();
    setIsDeleting(false);
  };

  const handleClose = closeModal;

  const handleCancel = () => {
    setNewPic('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSelectFile = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  };

  const handleFile = (imgFile: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(imgFile);
    reader.onloadend = () => {
      const dataUrl = reader.result as string;
      setNewPic(dataUrl);
    };
  };

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const imgFile = e.target.files?.[0];
    if (imgFile) {
      handleFile(imgFile);
    }
  };

  return (
    <ModalPage
      bottom={
        newPic ? (
          <ModalPageBottom
            onCancel={handleCancel}
            onClick={handleConfirm}
            buttonDisabled={isUploading}
            buttonLoading={isUploading}
            cancelButtonDisabled={isUploading}
            buttonLabel={t('profile:SavePhoto')}
          />
        ) : (
          <></>
        )
      }
      onClose={handleClose}
      title={t('screen:YourProfilePhoto')}>
      {newPic ? (
        <ProfilePictureEditor
          ref={imageEditorRef}
          imageSrc={newPic}
        />
      ) : (
        <FlexCol
          height="100%"
          alignItems="center"
          justifyContent="flex-start"
          gap={16}>
          <P>{t('profile:ProfileImageDescription')}</P>
          <DragAndDropArea
            onDragEnter={handleDragging}
            onDragOver={handleDragging}
            onDragLeave={handleDragging}
            onDrop={handleDrop}
            $dragging={isDragging}>
            <FlexCol
              height="100%"
              alignItems="center"
              justifyContent="space-around"
              md={{justifyContent: 'space-between'}}
              sm={{gap: 16}}>
              <Avatar
                user={{
                  initials,
                  imageHash,
                  email,
                  name: userName,
                }}
                size={previewSize}
              />
              <FlexCol
                alignItems="center"
                gap={16}>
                <FlexCol
                  alignItems="center"
                  lg={{display: 'none'}}
                  gap={8}>
                  <H2>{t('screen:DragYourPhoto')}</H2>
                  <Subtitle fontSize="lg">{t('translation:Or')}</Subtitle>
                </FlexCol>
                <FlexRow
                  gap={16}
                  sm={{flexDirection: 'column-reverse'}}>
                  {currentPic && (
                    <Button
                      button="secondary destructive"
                      onClick={handleDelete}
                      loading={isDeleting}
                      disabled={isDeleting}>
                      {t('profile:DeletePhoto')}
                    </Button>
                  )}
                  <Button
                    button="primary"
                    disabled={isDeleting}
                    onClick={handleSelectFile}>
                    {t('profile:EditPhotoButtonText')}
                  </Button>
                  <input
                    hidden
                    ref={fileInputRef}
                    onChange={handleFileSelected}
                    type="file"
                    accept=".jpg, .jpeg, .png"
                  />
                </FlexRow>
              </FlexCol>
            </FlexCol>
          </DragAndDropArea>
        </FlexCol>
      )}
    </ModalPage>
  );
};

const canvasToBlobOfSize = (cvs: HTMLCanvasElement, _size: number) => {
  return new Promise<Blob>((res, rej) => {
    try {
      resizedCanvas(cvs, REQUIRED_IMAGE_DIMENSION).toBlob(
        (blob) => {
          if (!blob) rej();
          else res(blob);
        },
        IMG_TYPE,
        JPG_QUALITY,
      );
    } catch (err) {
      rej();
    }
  });
};

const resizedCanvas = (cvs: HTMLCanvasElement, size: number) => {
  const resizeCvs = document.createElement('canvas');
  resizeCvs.width = size;
  resizeCvs.height = size;
  resizeCvs.getContext('2d')?.drawImage(cvs, 0, 0, size, size);

  return resizeCvs;
};
