import styled from 'styled-components';
import {BREAKPOINTS} from '@constants';
import {Div} from '@quarks';

export const WorkspacesListWrapper = styled.div`
  background: ${({theme}) => theme.background.white};
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  height: calc(var(--100vh, 100vh) - 60px - 60px - 60px - var(--calendar-sync-tile-height));
  border-right: 1px solid ${({theme}) => theme.divider.grey};

  ${BREAKPOINTS.small`
    height: calc(var(--100vh, 100vh) - 124px - 60px);
  `}
`;

export const FilterWrapper = styled(Div)`
  display: none;
  padding: 16px 20px 16px 20px;

  ${BREAKPOINTS.small`
    display: block;
  `}
`;
