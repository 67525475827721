import {FlexCol} from '@quarks';
import {useTheme} from 'styled-components';
import {ExtrasTile} from '@organisms';
import {ExtrasTypes} from '@lib/store';

export const ExtrasCell = () => {
  return (
    <FlexCol background={useTheme().background.white}>
      <ExtrasTile
        extrasType={ExtrasTypes.USER_PROFILE}
        max={3}
      />
    </FlexCol>
  );
};
