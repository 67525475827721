import {FlexCol, H2, P} from '@quarks';
import {useTranslation} from 'react-i18next';
import capitalize from 'lodash/capitalize';
import {MeetingRoomActivitiesProps} from './types';

export const MeetingRoomActivities = ({activities}: MeetingRoomActivitiesProps) => {
  const {t} = useTranslation();

  return (
    <>
      {!!activities.length && (
        <FlexCol gap={16}>
          <H2 as="h3">{t('meeting:SuitsForTitle')}</H2>
          <P>{capitalize(activities.map((item) => t(`meetingCharacteristic:${item}`)).join(', '))}</P>
        </FlexCol>
      )}
    </>
  );
};
