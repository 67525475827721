import {CardProps} from '@atoms';
import {useTranslation} from 'react-i18next';

import {MoreInformationCell, QuestionsCell, QuotaCard} from '@organisms';
import {CellWrapper, ProfileCard} from '../styles';
import {FlexCol, H2} from '@quarks';

export const ShiftsCard = ({className, style, ...props}: CardProps) => {
  const {t} = useTranslation('profile');

  return (
    <ProfileCard
      as="section"
      className={className}
      style={style}
      {...props}>
      <FlexCol gap={32}>
        <H2>{t('YourQuota')}</H2>
        <QuotaCard />
      </FlexCol>
      <CellWrapper>
        <QuestionsCell />
        <MoreInformationCell />
      </CellWrapper>
    </ProfileCard>
  );
};
