import {useBreakPoint} from '@hooks';
import {cn} from '@utils';
import {PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

export const Tile = ({
  children,
  title,
  titleNote,
  viewAllAction,
  bgColor,
}: PropsWithChildren<{
  title?: string;
  titleNote?: string;
  viewAllAction?: () => void;
  bgColor?: string;
}>) => {
  const breakpoint = useBreakPoint();
  const {t} = useTranslation();

  const cardStyling = breakpoint === 'small' ? '' : 'rounded-lg shadow-mapiq-base';
  const colorStyling = bgColor === undefined ? 'bg-white p-6' : bgColor;
  const testId = `HomePage-Tile-${title?.replaceAll(' ', '_')}`;

  return (
    <div
      className={cn(`flex flex-col items-start gap-6`, cardStyling, colorStyling)}
      data-testid={testId}>
      {title && (
        <div className="flex flex-col gap-1 w-full">
          <h3 className="flex justify-between w-full">
            <span>{title}</span>

            {titleNote && !viewAllAction && (
              <span className="text-sm font-medium text-mapiq-black-500">{titleNote}</span>
            )}

            {viewAllAction && (
              <button
                className="text-collaborative-blue-500* text-sm"
                onClick={viewAllAction}>
                {t('ViewAll')}
              </button>
            )}
          </h3>
          {titleNote && viewAllAction && <span className="text-sm font-medium text-mapiq-black-500">{titleNote}</span>}
        </div>
      )}
      {children}
    </div>
  );
};

const defaultRows = [[320], [64]];

export const LoaderTile = ({rows = defaultRows}) => {
  return (
    <Tile>
      <div className="animate-pulse rounded-md bg-slate-100 w-2/3 h-6"></div>

      {rows.map((row, r) => (
        <div
          key={`rows-${r}`}
          className="flex gap-4 w-full">
          {row.map((height, c) => (
            <StyledPulseBlock
              $delay={(1 + r + c) * 300}
              $height={height}
              key={`row-${c}`}
              className={`flex-grow animate-pulse rounded-md bg-slate-100`}
              style={{}}
            />
          ))}
        </div>
      ))}
    </Tile>
  );
};

const StyledPulseBlock = styled.div<{$height: number; $delay: number}>`
  // Note: using styled component here because struggling with tailwind dynamic class names
  height: ${({$height}) => `${$height}px !important`};
  animation-delay: ${({$delay}) => `${$delay}ms !important`};
  transition-delay: ${({$delay}) => `${$delay}ms !important`};
`;
