import {ToggleCellProps} from './types';

import {StyledToggleCell} from './styles';

import {P} from '@quarks';
import {Toggle} from '@atoms';

export const ToggleCell = ({label, checked, className, name, style, onToggle, ...props}: ToggleCellProps) => (
  <StyledToggleCell
    className={className}
    style={style}
    {...props}>
    <P id={name}>{label}</P>
    <Toggle
      aria-labelledby={name}
      checked={checked}
      name={name}
      onChange={onToggle}
      data-testid={`molecules-ToggleCell_toggle-${name}`}
    />
  </StyledToggleCell>
);
