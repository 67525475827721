import {IconButton} from '@molecules';
import {FlexCol, FlexRow} from '@quarks';
import {ChangeEvent, ForwardedRef, forwardRef, useState} from 'react';
import AvatarEditor from 'react-avatar-editor';
import {useTranslation} from 'react-i18next';
import {RangeInput} from 'src/components/molecules/Input/RangeInput';

const SIZE = 250;
const STEP = 0.1;
// Note: it would be nice if these were dynamic based on the selected image size. E.g. for a 1024px image
// max would be 2, but for a 2048 image, max would be 4.
const MIN_ZOOM = 1;
const MAX_ZOOM = 3;

export const ProfilePictureEditor = forwardRef(
  ({imageSrc}: {imageSrc: string}, editorRef: ForwardedRef<AvatarEditor>) => {
    const {t} = useTranslation();
    const [scale, setScale] = useState(1);

    const handleZoom = (e: ChangeEvent<HTMLInputElement>) => {
      const newZoom = e.target.valueAsNumber;
      setScale(newZoom);
    };

    const handleZoomIn = () => {
      setScale((s) => Math.min(MAX_ZOOM, s + STEP));
    };

    const handleZoomOut = () => {
      setScale((s) => Math.max(MIN_ZOOM, s - STEP));
    };

    return (
      <>
        <FlexCol gap={32}>
          <FlexRow justifyContent="center">
            <AvatarEditor
              image={imageSrc}
              borderRadius={SIZE / 2}
              width={SIZE}
              height={SIZE}
              border={0}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={scale}
              rotate={0}
              ref={editorRef}
            />
          </FlexRow>
          <FlexRow>
            <IconButton
              aria-label={t('profile:ZoomOut')}
              iconButton="tertiary"
              onClick={handleZoomOut}
              disabled={scale === MIN_ZOOM}
              icon="minus"
            />
            <RangeInput
              min={MIN_ZOOM}
              max={MAX_ZOOM}
              step={0.05}
              value={scale}
              onChange={handleZoom}
            />
            <IconButton
              aria-label={t('profile:ZoomIn')}
              iconButton="tertiary"
              onClick={handleZoomIn}
              disabled={scale === MAX_ZOOM}
              icon="plus"
            />
          </FlexRow>
        </FlexCol>
      </>
    );
  },
);
