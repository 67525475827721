import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {useAppDispatch, useAppSelector} from '@hooks';
import {
  getAllNotifications,
  getUnreadNotifications,
  getNotificationsLoadingStatus,
  markNotificationsAsRead,
} from '@lib/store';

import {Loader} from '@atoms';
import {PageHeader} from '@molecules';
import {NotificationsCard} from '@organisms';
import {Canvas, Content, Heading} from '@templates';
import {trackPageComponentedRendered} from '@lib/infrastructure';

export const Notifications = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const notifications = useAppSelector(getAllNotifications);
  const isLoading = useAppSelector(getNotificationsLoadingStatus) === 'Loading';
  const unreadNotifications = useAppSelector(getUnreadNotifications);

  useEffect(() => {
    return () => {
      if (!unreadNotifications.length) return;
      dispatch(markNotificationsAsRead(unreadNotifications));
    };
  }, [dispatch, unreadNotifications]);

  useEffect(() => {
    trackPageComponentedRendered('notifications', !isLoading);
  }, [isLoading]);

  if (isLoading) {
    return (
      <Canvas centerContent>
        <Loader />
      </Canvas>
    );
  }

  return (
    <>
      <Canvas />
      <Heading>
        <PageHeader title={t('screen:Notifications')} />
      </Heading>

      <Content>
        <NotificationsCard notifications={notifications} />
      </Content>
    </>
  );
};
