import {useTranslation} from 'react-i18next';

import {getIsLoadingConnections} from '@lib/store';

import {useAppSelector, useModal} from '@hooks';

import {Loader} from '@atoms';
import {PageHeader} from '@molecules';
import {AddConnectionsCard, ConnectionsCards} from '@organisms';
import {Canvas, Content, Heading} from '@templates';
import {useEffect} from 'react';
import {trackPageComponentedRendered} from '@lib/infrastructure';

export const Connections = () => {
  const {addPages, openModal} = useModal();
  const isLoading = useAppSelector(getIsLoadingConnections);

  const {t} = useTranslation();

  const handleOpenAddConnections = () => {
    addPages([<AddConnectionsCard />]);
    openModal();
  };

  useEffect(() => {
    trackPageComponentedRendered('connections', !isLoading);
  }, [isLoading]);

  if (isLoading) {
    return (
      <Canvas centerContent>
        <Loader />
      </Canvas>
    );
  }

  return (
    <>
      <Canvas />
      <Heading>
        <PageHeader
          buttonIcons={[
            {
              icon: 'plus',
              label: t('screen:AddConnection'),
              onClick: handleOpenAddConnections,
              showButton: 'all',
            },
          ]}
          title={t('connection:YourConnections')}
        />
      </Heading>

      <Content>
        <ConnectionsCards />
      </Content>
    </>
  );
};
