import styled from 'styled-components';

import {Div, FlexCol} from '@quarks';

export const StyledFlexCol = styled(FlexCol).attrs(({theme, ...attrs}) => ({
  background: theme.divider.grey,
  gap: 1,
  ...attrs,
}))``;

export const StyledExtraItemWrapper = styled(Div)`
  padding: 5px 0;
  background: ${({theme}) => theme.card.white.background};
`;
