import {forwardRef} from 'react';
import {SubMenuItemProps} from './types';

import {StyledSubMenuItem} from './styles';

export const SubMenuItem = forwardRef<HTMLButtonElement, SubMenuItemProps>(({active, children, ...props}, ref) => {
  return (
    <StyledSubMenuItem
      $active={active}
      ref={ref}
      {...props}>
      {children}
    </StyledSubMenuItem>
  );
});
