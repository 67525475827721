import {CardProps} from '@atoms';
import {useTranslation} from 'react-i18next';

import {EULACell, FAQCell, PrivacyPolicyCell, SupportCell, VersionCell} from '@organisms';
import {H2} from '@quarks';
import {CellWrapper, ProfileCard} from '../styles';

export const AboutCard = ({className, style, ...props}: CardProps) => {
  const {t} = useTranslation('profile');

  return (
    <ProfileCard
      as="section"
      className={className}
      style={style}
      {...props}>
      <H2>{t('AboutMapiq')}</H2>
      <CellWrapper>
        <SupportCell />
        <FAQCell />
        <PrivacyPolicyCell />
        <EULACell />
        <VersionCell />
      </CellWrapper>
    </ProfileCard>
  );
};
