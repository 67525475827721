import {TimePickerProps} from './types';
import {useTranslation} from 'react-i18next';

import {getAppLanguage} from '@lib/store';
import {isToday, parseISO, setHours, setMinutes} from 'date-fns';
import {nl, fr, enUS} from 'date-fns/locale';

import {default as ReactDatePicker, registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from '../styles.module.scss';

import {useAppSelector} from '@hooks';
import {Div} from '@quarks';
import {FocusEvent} from 'react';

registerLocale('en', enUS);
registerLocale('fr', fr);
registerLocale('nl', nl);

export const TimePicker = ({date, minDate, setDate, ...props}: TimePickerProps) => {
  const locale = useAppSelector(getAppLanguage);
  const {t} = useTranslation();

  const handleOnChangeRaw = (rawValue: FocusEvent<HTMLInputElement, Element>) => {
    const value: string = rawValue.target.value;
    const [hours, minutes] = value.split(':');

    if (value.length === 2 && !isNaN(parseInt(hours))) {
      rawValue.target.value += ':';
    }

    if (
      (!(value.split(':').length >= 3) || !value.includes(':')) &&
      !isNaN(parseInt(hours)) &&
      !isNaN(parseInt(minutes))
    ) {
      if (value.length === 2) {
        setDate((prevDate) => setHours(setMinutes(parseISO(prevDate), 0), parseInt(hours)).toISOString());
      }

      if (value.length === 5) {
        setDate((prevDate) =>
          setHours(setMinutes(parseISO(prevDate), parseInt(minutes)), parseInt(hours)).toISOString(),
        );
      }
    }
  };

  const dataTestId = props['data-testid' as keyof typeof props];

  return (
    <Div
      position="relative"
      data-testid={dataTestId}>
      <ReactDatePicker
        calendarClassName={styles.datePicker}
        className={styles.input}
        dateFormat="HH:mm"
        enableTabLoop={false}
        locale={locale}
        maxTime={setMinutes(setHours(parseISO(date), 23), 59)}
        minDate={parseISO(minDate)}
        minTime={isToday(parseISO(date)) ? parseISO(minDate) : setMinutes(setHours(parseISO(date), 0), 0)}
        onChange={() => void 0}
        onChangeRaw={handleOnChangeRaw}
        onKeyDown={(e: any) => {
          if (e.key === 'Backspace' && e.currentTarget.value.length === 3) {
            e.currentTarget.value = e.currentTarget.value.substring(0, 2);
          }
        }}
        open={false}
        popperPlacement="bottom"
        preventOpenOnFocus={true}
        selected={parseISO(date)}
        showPopperArrow={false}
        showTimeSelect={false}
        showTimeSelectOnly={true}
        timeCaption={t('meeting:Time')}
        timeIntervals={15}
        {...props}
      />
    </Div>
  );
};
