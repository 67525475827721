import {SensorIcon} from '@map/logic';
import {StyledContainer} from './style';
import {Icon} from '@atoms';
import {useTheme} from 'styled-components';

export type WorkspaceIcon = SensorIcon | 'desk' | 'area' | null | 'NonBookable' | 'Booking';

export type HaNWorkspaceIcon = {
  iconType: WorkspaceIcon;
};

export const HaNWorkspaceIcon = ({iconType}: HaNWorkspaceIcon) => {
  const width = 14;
  const height = 10;

  const theme = useTheme();

  return (
    <StyledContainer $iconType={iconType}>
      {iconType === 'quiet' ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.27273 2.87877C1.97563 2.87877 2.54545 2.30895 2.54545 1.60604C2.54545 0.903132 1.97563 0.333313 1.27273 0.333313C0.569819 0.333313 0 0.903132 0 1.60604C0 2.30895 0.569819 2.87877 1.27273 2.87877ZM1.27273 3.72721C0.569819 3.72721 0 4.29703 0 4.99994V9.66661H2.54545V4.99994C2.54545 4.29703 1.97564 3.72721 1.27273 3.72721Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.09092 2.87877C5.79383 2.87877 6.36365 2.30895 6.36365 1.60604C6.36365 0.903132 5.79383 0.333313 5.09092 0.333313C4.38801 0.333313 3.81819 0.903132 3.81819 1.60604C3.81819 2.30895 4.38801 2.87877 5.09092 2.87877ZM5.09093 3.72721C4.38803 3.72721 3.81821 4.29703 3.81821 4.99994V9.66661H6.36366V4.99994C6.36366 4.29703 5.79384 3.72721 5.09093 3.72721ZM8.90909 3.72721C8.20618 3.72721 7.63636 4.29703 7.63636 4.99994V9.66661H10.1818V4.99994C10.1818 4.29703 9.612 3.72721 8.90909 3.72721ZM11.4546 4.99994C11.4546 4.29703 12.0244 3.72721 12.7273 3.72721C13.4302 3.72721 14 4.29703 14 4.99994V9.66661H11.4546V4.99994ZM10.1818 1.60604C10.1818 2.30895 9.612 2.87877 8.90909 2.87877C8.20618 2.87877 7.63636 2.30895 7.63636 1.60604C7.63636 0.903132 8.20618 0.333313 8.90909 0.333313C9.612 0.333313 10.1818 0.903132 10.1818 1.60604ZM12.7273 2.87877C13.4302 2.87877 14 2.30895 14 1.60604C14 0.903132 13.4302 0.333313 12.7273 0.333313C12.0244 0.333313 11.4546 0.903132 11.4546 1.60604C11.4546 2.30895 12.0244 2.87877 12.7273 2.87877Z"
            fill="white"
            fillOpacity="0.4"
          />
        </svg>
      ) : iconType === 'lively' ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.54545 1.60604C2.54545 2.30895 1.97564 2.87877 1.27273 2.87877C0.569819 2.87877 0 2.30895 0 1.60604C0 0.903132 0.569819 0.333313 1.27273 0.333313C1.97564 0.333313 2.54545 0.903132 2.54545 1.60604ZM1.27273 3.72721C0.569819 3.72721 0 4.29703 0 4.99994V9.66661H2.54545V4.99994C2.54545 4.29703 1.97564 3.72721 1.27273 3.72721ZM5.09092 3.72721C4.38801 3.72721 3.81819 4.29703 3.81819 4.99994V9.66661H6.36365V4.99994C6.36365 4.29703 5.79383 3.72721 5.09092 3.72721ZM5.09092 2.87877C5.79383 2.87877 6.36365 2.30895 6.36365 1.60604C6.36365 0.903132 5.79383 0.333313 5.09092 0.333313C4.38801 0.333313 3.81819 0.903132 3.81819 1.60604C3.81819 2.30895 4.38801 2.87877 5.09092 2.87877Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.1818 1.60604C10.1818 2.30895 9.61199 2.87877 8.90908 2.87877C8.20618 2.87877 7.63636 2.30895 7.63636 1.60604C7.63636 0.903132 8.20618 0.333313 8.90908 0.333313C9.61199 0.333313 10.1818 0.903132 10.1818 1.60604ZM8.90908 3.72721C8.20617 3.72721 7.63635 4.29703 7.63635 4.99994V9.66661H10.1818V4.99994C10.1818 4.29703 9.61199 3.72721 8.90908 3.72721ZM12.7273 3.72721C12.0244 3.72721 11.4545 4.29703 11.4545 4.99994V9.66661H14V4.99994C14 4.29703 13.4302 3.72721 12.7273 3.72721ZM12.7273 2.87877C13.4302 2.87877 14 2.30895 14 1.60604C14 0.903132 13.4302 0.333313 12.7273 0.333313C12.0244 0.333313 11.4545 0.903132 11.4545 1.60604C11.4545 2.30895 12.0244 2.87877 12.7273 2.87877Z"
            fill="white"
            fillOpacity="0.4"
          />
        </svg>
      ) : iconType === 'full' ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.81819 4.99993C3.81819 4.29702 4.38801 3.7272 5.09092 3.7272C5.79383 3.7272 6.36365 4.29702 6.36365 4.99993V9.6666H3.81819V4.99993Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.54545 1.60604C2.54545 2.30895 1.97564 2.87877 1.27273 2.87877C0.569819 2.87877 0 2.30895 0 1.60604C0 0.903132 0.569819 0.333313 1.27273 0.333313C1.97564 0.333313 2.54545 0.903132 2.54545 1.60604ZM0 4.99994C0 4.29703 0.569819 3.72721 1.27273 3.72721C1.97564 3.72721 2.54545 4.29703 2.54545 4.99994V9.66661H0V4.99994ZM7.63635 4.99994C7.63635 4.29703 8.20617 3.72721 8.90907 3.72721C9.61198 3.72721 10.1818 4.29703 10.1818 4.99994V9.66661H7.63635V4.99994ZM12.7273 3.72721C12.0244 3.72721 11.4545 4.29703 11.4545 4.99994V9.66661H14V4.99994C14 4.29703 13.4302 3.72721 12.7273 3.72721ZM5.09092 2.87877C5.79383 2.87877 6.36365 2.30895 6.36365 1.60604C6.36365 0.903132 5.79383 0.333313 5.09092 0.333313C4.38801 0.333313 3.81819 0.903132 3.81819 1.60604C3.81819 2.30895 4.38801 2.87877 5.09092 2.87877ZM10.1818 1.60604C10.1818 2.30895 9.61198 2.87877 8.90907 2.87877C8.20617 2.87877 7.63635 2.30895 7.63635 1.60604C7.63635 0.903132 8.20617 0.333313 8.90907 0.333313C9.61198 0.333313 10.1818 0.903132 10.1818 1.60604ZM12.7273 2.87877C13.4302 2.87877 14 2.30895 14 1.60604C14 0.903132 13.4302 0.333313 12.7273 0.333313C12.0244 0.333313 11.4545 0.903132 11.4545 1.60604C11.4545 2.30895 12.0244 2.87877 12.7273 2.87877Z"
            fill="white"
          />
        </svg>
      ) : iconType === 'desk' ? (
        <Icon
          icon="desk"
          size="15px"
        />
      ) : iconType === 'area' ? (
        <Icon
          icon="area"
          size="15px"
        />
      ) : iconType === 'busy' ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.27273 2.87877C1.97564 2.87877 2.54545 2.30895 2.54545 1.60604C2.54545 0.903132 1.97564 0.333313 1.27273 0.333313C0.569819 0.333313 0 0.903132 0 1.60604C0 2.30895 0.569819 2.87877 1.27273 2.87877ZM1.27273 3.72721C0.569819 3.72721 0 4.29703 0 4.99994V9.66661H2.54545V4.99994C2.54545 4.29703 1.97564 3.72721 1.27273 3.72721ZM5.09092 3.72721C4.38801 3.72721 3.81819 4.29703 3.81819 4.99994V9.66661H6.36365V4.99994C6.36365 4.29703 5.79383 3.72721 5.09092 3.72721ZM7.63635 4.99994C7.63635 4.29703 8.20617 3.72721 8.90907 3.72721C9.61198 3.72721 10.1818 4.29703 10.1818 4.99994V9.66661H7.63635V4.99994ZM6.36365 1.60604C6.36365 2.30895 5.79383 2.87877 5.09092 2.87877C4.38801 2.87877 3.81819 2.30895 3.81819 1.60604C3.81819 0.903132 4.38801 0.333313 5.09092 0.333313C5.79383 0.333313 6.36365 0.903132 6.36365 1.60604ZM8.90907 2.87877C9.61198 2.87877 10.1818 2.30895 10.1818 1.60604C10.1818 0.903132 9.61198 0.333313 8.90907 0.333313C8.20617 0.333313 7.63635 0.903132 7.63635 1.60604C7.63635 2.30895 8.20617 2.87877 8.90907 2.87877Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7273 2.87877C13.4302 2.87877 14 2.30895 14 1.60604C14 0.903132 13.4302 0.333313 12.7273 0.333313C12.0244 0.333313 11.4545 0.903132 11.4545 1.60604C11.4545 2.30895 12.0244 2.87877 12.7273 2.87877ZM12.7273 3.72721C12.0244 3.72721 11.4546 4.29703 11.4546 4.99994V9.66661H14V4.99994C14 4.29703 13.4302 3.72721 12.7273 3.72721Z"
            fill="white"
            fillOpacity="0.4"
          />
        </svg>
      ) : iconType === 'unknown' ? (
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.54545 1.60604C2.54545 2.30895 1.97564 2.87877 1.27273 2.87877C0.569819 2.87877 0 2.30895 0 1.60604C0 0.903132 0.569819 0.333313 1.27273 0.333313C1.97564 0.333313 2.54545 0.903132 2.54545 1.60604ZM1.27273 3.72736C0.569819 3.72736 0 4.29718 0 5.00008V9.66675H2.54545V5.00008C2.54545 4.29718 1.97564 3.72736 1.27273 3.72736ZM5.09103 3.72736C4.38812 3.72736 3.8183 4.29718 3.8183 5.00008V9.66675H6.36375V5.00008C6.36375 4.29718 5.79393 3.72736 5.09103 3.72736ZM7.63617 5.00008C7.63617 4.29718 8.20599 3.72736 8.9089 3.72736C9.6118 3.72736 10.1816 4.29718 10.1816 5.00008V9.66675H7.63617V5.00008ZM12.7272 3.72736C12.0243 3.72736 11.4545 4.29718 11.4545 5.00008V9.66675H13.9999V5.00008C13.9999 4.29718 13.4301 3.72736 12.7272 3.72736ZM5.09103 2.87877C5.79393 2.87877 6.36375 2.30895 6.36375 1.60604C6.36375 0.903132 5.79393 0.333313 5.09103 0.333313C4.38812 0.333313 3.8183 0.903132 3.8183 1.60604C3.8183 2.30895 4.38812 2.87877 5.09103 2.87877ZM10.1816 1.60604C10.1816 2.30895 9.6118 2.87877 8.9089 2.87877C8.20599 2.87877 7.63617 2.30895 7.63617 1.60604C7.63617 0.903132 8.20599 0.333313 8.9089 0.333313C9.6118 0.333313 10.1816 0.903132 10.1816 1.60604ZM12.7272 2.87877C13.4301 2.87877 13.9999 2.30895 13.9999 1.60604C13.9999 0.903132 13.4301 0.333313 12.7272 0.333313C12.0243 0.333313 11.4545 0.903132 11.4545 1.60604C11.4545 2.30895 12.0243 2.87877 12.7272 2.87877Z"
            fill="#949494"
          />
        </svg>
      ) : iconType === 'NonBookable' ? (
        <Icon
          icon="noCalendar"
          size="19px"
          color={theme.icon.inactive}
        />
      ) : iconType === 'Booking' ? (
        <Icon
          icon="calendar"
          size="19px"
          color={theme.icon.inactive}
        />
      ) : (
        <Icon
          icon="noBroadcast"
          size="19px"
          color={theme.icon.inactive}
        />
      )}
    </StyledContainer>
  );
};
