import {css, CSSObject} from 'styled-components';
import {HTMLAttributes} from 'react';
import {BREAKPOINTS} from '@constants';
import {
  BorderRadius,
  BoxShadow,
  Cursor,
  FlexWrap,
  FontSize,
  FontWeight,
  Gap,
  LineHeight,
  Padding,
  Position,
  TextAlign,
  TextTransform,
} from 'mapiq-quarks';

export type BaseProps = HTMLAttributes<HTMLElement> &
  Breakpoints & {
    alignSelf?: 'center' | 'flex-end' | 'flex-start' | 'stretch';
    background?: string;
    border?: string;
    borderRadius?: BorderRadius;
    bottom?: string;
    boxShadow?: BoxShadow;
    color?: string;
    cursor?: Cursor;
    display?: string;
    filter?: string;
    flex?: string;
    flexGrow?: number;
    flexShrink?: number;
    // focusVisible?: any;
    // focusWithin?: any;
    font?: string;
    /**
     * xs: 10px | sm: 13px | md: 15px
     */
    fontSize?: FontSize;
    fontWeight?: FontWeight;
    gridArea?: string;
    gridColumn?: string;
    gridRow?: string;
    height?: string;
    href?: string;
    // hover?: any;
    justifySelf?: 'center' | 'flex-end' | 'flex-start' | 'stretch';
    left?: string;
    lineHeight?: LineHeight | string;
    maxHeight?: string;
    maxWidth?: string;
    minHeight?: string;
    minWidth?: string;
    // objectFit?: any;
    opacity?: number;
    overflow?: string;
    overflowX?: 'clip' | 'visible' | 'hidden' | 'scroll' | 'auto';
    overflowY?: 'clip' | 'visible' | 'hidden' | 'scroll' | 'auto';
    padding?: Padding;
    paddingBottom?: Padding;
    paddingLeft?: Padding;
    paddingRight?: Padding;
    paddingTop?: Padding;
    position?: Position;
    rel?: string;
    // resize?: any;
    right?: string;
    target?: string;
    textAlign?: TextAlign;
    textDecoration?: string;
    textTransform?: TextTransform;
    to?: string;
    top?: string;
    transform?: string;
    transition?: string;
    width?: string;
    zIndex?: number;

    // * Pseudo Elements
    before?: CSSObject;
    after?: CSSObject;
  };

export type Breakpoints = {
  xl?: CSSObject;
  lg?: CSSObject;
  md?: CSSObject;
  sm?: CSSObject;
};

export const breakpointsStyle = css<BaseProps>`
  ${({xl}) => xl && BREAKPOINTS.xLarge(xl)}

  ${({lg}) => lg && BREAKPOINTS.large(lg)}

  ${({md}) => md && BREAKPOINTS.medium(md)}

  ${({sm}) => sm && BREAKPOINTS.small(sm)}
`;

export const baseStyle = css<BaseProps>`
  align-self: ${({alignSelf}) => alignSelf};
  background: ${({background}) => background};
  border: ${({border}) => border};
  border-radius: ${({borderRadius}) => borderRadius && `${borderRadius}px`};
  bottom: ${({bottom}) => bottom};
  box-shadow: ${({boxShadow}) => boxShadow};
  color: ${({color}) => color};
  cursor: ${({cursor}) => cursor};
  display: ${({display}) => display};
  filter: ${({filter}) => filter};
  flex: ${({flex}) => flex};
  flex-grow: ${({flexGrow}) => flexGrow};
  flex-shrink: ${({flexShrink}) => flexShrink};
  font: ${({font}) => font};
  font-size: ${({fontSize, theme}) => fontSize && theme.text.size[fontSize]};
  font-weight: ${({fontWeight}) => (fontWeight === 'bold' ? 600 : fontWeight === 'regular' ? 400 : undefined)};
  grid-area: ${({gridArea}) => gridArea};
  grid-column: ${({gridColumn}) => gridColumn};
  grid-row: ${({gridRow}) => gridRow};
  height: ${({height}) => height};
  justify-self: ${({justifySelf}) => justifySelf};
  left: ${({left}) => left};
  line-height: ${({lineHeight}) =>
    lineHeight === 'body' ? '1.5rem' : lineHeight === 'body short' ? '1.25rem' : lineHeight};
  max-height: ${({maxHeight}) => maxHeight};
  max-width: ${({maxWidth}) => maxWidth};
  min-height: ${({minHeight}) => minHeight};
  min-width: ${({minWidth}) => minWidth};
  opacity: ${({opacity}) => opacity};
  overflow: ${({overflow}) => overflow};
  overflow-x: ${({overflowX}) => overflowX};
  overflow-y: ${({overflowY}) => overflowY};
  padding: ${({padding, paddingBottom, paddingLeft, paddingRight, paddingTop}) =>
    padding
      ? `${padding}px ${padding}px ${padding}px ${padding}px`
      : paddingBottom || paddingLeft || paddingRight || paddingTop
      ? `${paddingTop || 0}px ${paddingRight || 0}px ${paddingBottom || 0}px ${paddingLeft || 0}px`
      : undefined};

  position: ${({position}) => position};
  right: ${({right}) => right};
  text-align: ${({textAlign}) => textAlign};
  text-decoration: ${({textDecoration}) => textDecoration};
  text-transform: ${({textTransform}) => textTransform};
  top: ${({top}) => top};
  transform: ${({transform}) => transform};
  transition: ${({transition}) => transition};
  width: ${({width}) => width};
  z-index: ${({zIndex}) => zIndex};

  ${breakpointsStyle}

  ${({after}) =>
    after &&
    css`
      &::after {
        ${!after.content && 'content: "";'}
        ${after}
      }
    `}

    ${({before}) =>
    before &&
    css`
      &::before {
        ${!before.content && 'content: "";'}
        ${before}
      }
    `}
`;

type FlexBaseProps = BaseProps & {
  alignItems?: 'center' | 'flex-end' | 'flex-start' | 'stretch';
  columnGap?: Gap;
  justifyContent?: 'center' | 'flex-end' | 'flex-start' | 'space-between' | 'space-around';
  flexWrap?: FlexWrap;
  gap?: Gap;
  rowGap?: Gap;
};

export const flexStyle = css<FlexBaseProps>`
  ${baseStyle}
  align-items: ${({alignItems}) => alignItems || 'stretch'};
  display: ${({display}) => display || 'flex'};
  flex: ${({flex}) => flex};
  flex-wrap: ${({flexWrap}) => flexWrap};
  gap: ${({gap, columnGap, rowGap}) =>
    gap ? `${gap}px ${gap}px` : columnGap || rowGap ? `${rowGap || 0}px ${columnGap || 0}px` : undefined};
  justify-content: ${({justifyContent}) => justifyContent || 'space-between'};
  width: ${({width}) => width};
`;

export type GridProps = BaseProps & {
  alignItems?: 'center' | 'flex-end' | 'flex-start' | 'stretch';
  columnGap?: Gap;
  gap?: Gap;
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  justifyItems?: 'center' | 'flex-end' | 'flex-start' | 'space-between' | 'space-around';
  placeItems?: 'center';
  rowGap?: Gap;
};

export const gridStyle = css<GridProps>`
  ${baseStyle}
  align-items: ${({alignItems}) => alignItems || 'stretch'};
  display: ${({display}) => display || 'grid'};
  gap: ${({gap, columnGap, rowGap}) => (gap ? `${gap}px ${gap}px` : `${rowGap || 0}px ${columnGap || 0}px`)};
  grid-template-columns: ${({gridTemplateColumns}) => gridTemplateColumns};
  grid-template-rows: ${({gridTemplateRows}) => gridTemplateRows};
  justify-items: ${({justifyItems}) => justifyItems || 'stretch'};
`;
