import {Icon} from '@atoms';
import {Div, FlexRow, P} from '@quarks';
import {pxToRem} from '@utils';
import {useTheme} from 'styled-components';
import {BookWorkspaceValidationErrorProps} from './types';

export const BookWorkspaceValidationError = ({text}: BookWorkspaceValidationErrorProps) => {
  const theme = useTheme();
  return (
    <FlexRow
      justifyContent="flex-start"
      padding={16}
      background={theme.toast.error.background}
      borderRadius={8}
      color={theme.toast.error.color}
      gap={16}
      alignItems="center">
      <Div
        padding={2}
        borderRadius={9999}
        background={theme.toast.error.background}>
        <Icon
          color="currentColor"
          icon="close"
          size={pxToRem(16)}
        />
      </Div>
      <P color={theme.toast.error.color}>{text}</P>
    </FlexRow>
  );
};
