import {useTranslation} from 'react-i18next';
import {Toggle} from '@atoms';
import {useFeatureToggle, useTheme} from '@hooks';
import {ProfileCell} from '../styles';
import {P} from '@quarks';

export const ThemeCell = () => {
  const {t} = useTranslation('profile');
  const {theme, toggleTheme} = useTheme();
  const {DarkMode} = useFeatureToggle();

  if (!DarkMode) {
    return <></>;
  }

  return (
    <ProfileCell>
      <P id="label-darkmode">{t('DarkMode')}</P>
      <Toggle
        aria-labelledby="label-darkmode"
        onChange={toggleTheme}
        checked={theme === 'dark'}
      />
    </ProfileCell>
  );
};
