import {Icon} from '@atoms';
import {HaNControlsProps} from './types';
import {pxToRem} from '@utils';
import {useTranslation} from 'react-i18next';
import {P, Span} from '@quarks';

/**
 * Shows floor number and allows user to zoom in and out of map.
 */

export const HaNControls = ({floor}: HaNControlsProps) => {
  const {t} = useTranslation();
  return (
    <>
      <Icon
        size={pxToRem(24)}
        icon="floor"
      />
      <P fontWeight="bold">
        <Span sm={{display: 'none'}}>{t('translation:Floor')} </Span>
        {floor}
      </P>
    </>
  );
};
