import {useTranslation} from 'react-i18next';
import {CalendarWorkspaceTileBookingInfoProps} from './types';

import {
  getAccessProfile,
  getAreAllNodesClosed,
  getAreAllNodesFull,
  getIsWorkspaceReservationButtonEnabled,
  getNodeInformationByNodeId,
  getWorkdayByDate,
  getWorkspaceQuotaInfo,
  getWorkspaceReservationByDate,
  getWorkspaceReservationCountByWeekNumber,
} from '@lib/store';
import {useAppSelector} from '@hooks';
import {parseISO, getISOWeek} from 'date-fns';

import {P, Subtitle} from '@quarks';

export const CalendarWorkspaceTileBookingInfo = ({date}: CalendarWorkspaceTileBookingInfoProps) => {
  const {t} = useTranslation();
  const dateAsDate = parseISO(date);
  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, dateAsDate));
  const {daysPerWeek} = useAppSelector(getAccessProfile);
  const bookingCount = useAppSelector((state) =>
    getWorkspaceReservationCountByWeekNumber(state, getISOWeek(dateAsDate)),
  );

  const isEnabled = useAppSelector((state) => getIsWorkspaceReservationButtonEnabled(state, dateAsDate));
  const workday = useAppSelector((state) => getWorkdayByDate(state, date));
  const areAllBuildingsClosed = useAppSelector((state) => getAreAllNodesClosed(state, dateAsDate));
  const areAllBuildingsFull = useAppSelector((state) => getAreAllNodesFull(state, dateAsDate));
  const {isFull: isWorkdayBuildingFull} = useAppSelector((state) =>
    getNodeInformationByNodeId(state, {date: dateAsDate, nodeId: workday?.nodeId}),
  );
  const {isSelectedDateInPast, isSelectedDateFuture, quotaReached, isDayAllowed} = useAppSelector((state) =>
    getWorkspaceQuotaInfo(state, dateAsDate),
  );

  const {deskId, areaId, areaName, floorId, floorName, deskName} = workspaceReservation ?? {};
  const anyAvailableDeskText = t('workplace:AnyAvailableDesk', {count: 1});
  const bookingsLeft = daysPerWeek - bookingCount;

  const getTitle = () => {
    if (workspaceReservation) {
      if (deskId || areaId) return areaName;

      if (floorId) return floorName;

      return anyAvailableDeskText;
    } else {
      if (isEnabled) return t('workplace:BookAWorkspace');
      else {
        if (isSelectedDateInPast) return t('workplace:NotAllowedToBook');

        if (isSelectedDateFuture) return t('workplace:LocationTooFarAhead');

        if (quotaReached) return t('QuotaHasBeenReached');

        if (!isDayAllowed) return t('workplace:NotAllowedToBook');

        if (areAllBuildingsClosed) return t('workplace:AllLocationsClosed');

        if (areAllBuildingsFull) return t('workplace:AllLocationsFull');

        if (isWorkdayBuildingFull) return t('workplace:NoDesksAvailable');
      }
    }
  };

  const getBody = () => {
    if (workspaceReservation) {
      if (deskId) return floorName + ', ' + deskName;

      if (areaId) return floorName + ', ' + anyAvailableDeskText.toLowerCase();

      if (floorId) return anyAvailableDeskText;
    } else {
      if (isEnabled) return t('parking:ParkingBookingLeftPerWeek', {count: bookingsLeft});
    }
  };

  return (
    <>
      <P fontWeight="bold">{getTitle()}</P>
      <P
        fontWeight="regular"
        color="currentColor">
        {getBody()}
      </P>
      {workspaceReservation?.groupReservationId && (
        <Subtitle fontWeight="regular">{t('workplace:GroupBooking')}</Subtitle>
      )}
    </>
  );
};
