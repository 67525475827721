import {AttendeeDetailsCellProps} from './types';
import {ThreeColumnCell} from '@atoms';
import {Avatar, RSVP} from '@molecules';

export const AttendeeDetailsCell = ({attendee}: AttendeeDetailsCellProps) => {
  return (
    <ThreeColumnCell
      key={attendee.email}
      left={<Avatar user={{...attendee, name: attendee.displayName || attendee.email}} />}
      middle={
        <p data-testid="organisms-eventDetails-AttendeeDetailsCell_attendee-name">
          {attendee.displayName || attendee.email}
        </p>
      }
      padding="medium"
      right={<RSVP rsvp={attendee.responseStatus} />}
      separator={false}
    />
  );
};
