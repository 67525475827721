import {getEulaLink} from '@lib/store';
import {useTranslation} from 'react-i18next';

import {useAppSelector} from '@hooks';
import {ProfileCell} from '../styles';
import {Link} from '@quarks';

export const EULACell = () => {
  const {t} = useTranslation('profile');
  const eulaLink = useAppSelector(getEulaLink);

  return (
    <ProfileCell>
      <Link
        data-testid="organisms-eulaCell-link"
        to={eulaLink}>
        {t('EULA')}
      </Link>
    </ProfileCell>
  );
};
