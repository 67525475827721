import {useTranslation} from 'react-i18next';

import {IconButton} from '@molecules';
import {HaNModalHeaderProps} from './types';
import {Title, Wrapper} from './styles';

/**
 * Header for modal on Here And Now.
 */

export const HaNModalHeader = ({onBack, onClose, title}: HaNModalHeaderProps) => {
  const {t} = useTranslation();
  return (
    <Wrapper>
      <div>
        {onBack && (
          <IconButton
            size="small"
            aria-label={t('workplace:GoBack')}
            iconButton="tertiary"
            icon="caretLeft"
            onClick={onBack}
          />
        )}
      </div>
      <Title>{title}</Title>
      <div>
        {onClose && (
          <IconButton
            size="small"
            aria-label={t('translation:Close')}
            iconButton="tertiary"
            icon="close"
            onClick={onClose}
          />
        )}
      </div>
    </Wrapper>
  );
};
