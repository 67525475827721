import {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';

import {IconButton} from '@molecules';

import {ModalPageProps} from './types';
import {H4} from '@quarks';
import {Body, ModalPageWrapper, Top, TopMiddle, TopRightLeft} from './styles';

export const ModalPage = forwardRef<HTMLDivElement, ModalPageProps>(
  ({bottom, children, className, onBack, onClose, style, title}, ref) => {
    const {t} = useTranslation();

    return (
      <ModalPageWrapper
        className={className}
        style={style}
        data-testid="organisms-ModalPage">
        <Top>
          <TopRightLeft>
            {!!onBack && (
              <IconButton
                aria-label={t('workplace:GoBack')}
                icon="caretLeft"
                iconButton="tertiary"
                onClick={onBack}
                size="small"
                data-testid="organisms-ModalPage_go-back-button"
              />
            )}
          </TopRightLeft>
          <TopMiddle>
            <H4 as="h1">{title}</H4>
          </TopMiddle>
          <TopRightLeft>
            <IconButton
              data-testid="organisms-modalPage-close-button"
              aria-label={t('translation:Close')}
              icon="close"
              iconButton="tertiary"
              onClick={onClose}
              size="small"
            />
          </TopRightLeft>
        </Top>
        <Body ref={ref}>{children}</Body>
        {!!bottom && bottom}
      </ModalPageWrapper>
    );
  },
);
