import {
  HaNExploreListHorizontal,
  HaNRoomListHorizontal,
  HaNWorkspacesListHorizontal,
  useTabByPathname,
} from '@organisms';
import {forwardRef} from 'react';
import {SwiperRefType} from '@molecules';
import {AreaWithSensorInfo, MeetingRoom} from '@lib/store';
import {ExploreState} from 'src/components/pages/HereAndNow/useExploreState';

type HaNHorizontalListContentProps = {
  buildingId: string;
  rooms: MeetingRoom[];
  areas: AreaWithSensorInfo[];
  onClick: (resource: MeetingRoom | AreaWithSensorInfo) => void;
  startDateTime: string;
  showCalendarConsentMessageNoOccupancy: boolean;
  endDateTime: string;
  onSlideChange: (room: MeetingRoom | AreaWithSensorInfo) => void;
  exploreState: ExploreState;
};

export const HaNHorizontalListContent = forwardRef<SwiperRefType, HaNHorizontalListContentProps>(
  (
    {
      buildingId,
      endDateTime,
      onClick,
      rooms,
      onSlideChange,
      showCalendarConsentMessageNoOccupancy,
      startDateTime,
      areas,
      exploreState,
    },
    ref,
  ) => {
    const tab = useTabByPathname();

    switch (tab) {
      case 'explore':
        return (
          <HaNExploreListHorizontal
            exploreState={exploreState}
            ref={ref}
          />
        );
      case 'rooms':
        return (
          <HaNRoomListHorizontal
            onClick={onClick}
            buildingId={buildingId}
            rooms={rooms}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
            onSlideChange={onSlideChange}
            showCalendarConsentMessageNoOccupancy={showCalendarConsentMessageNoOccupancy}
            ref={ref}
          />
        );
      case 'workspaces':
        return (
          <HaNWorkspacesListHorizontal
            areas={areas}
            onClick={onClick}
            onSlideChange={onSlideChange}
            ref={ref}
          />
        );
      default:
        return (
          <HaNRoomListHorizontal
            buildingId={exploreState.buildingId}
            onClick={onClick}
            rooms={rooms}
            startDateTime={startDateTime}
            showCalendarConsentMessageNoOccupancy={showCalendarConsentMessageNoOccupancy}
            endDateTime={endDateTime}
            onSlideChange={onSlideChange}
            ref={ref}
          />
        );
    }
  },
);
