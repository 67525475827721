import {useRef, useState} from 'react';
import {DatePickerProps} from './types';

import {getAppLanguage} from '@lib/store';
import {parseISO} from 'date-fns';
import {nl, fr, enUS} from 'date-fns/locale';

import {default as ReactDatePicker, registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from '../styles.module.scss';

import {useAppSelector} from '@hooks';
import {Div} from '@quarks';
import {DatePickerHeader} from './DatePickerHeader';

registerLocale('en', enUS);
registerLocale('fr', fr);
registerLocale('nl', nl);

export const DatePicker = ({date, minDate, setDate, ...props}: DatePickerProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const locale = useAppSelector(getAppLanguage);

  const handleOnBlur = () => {
    requestAnimationFrame(() => {
      if (!ref.current?.contains(document.activeElement)) {
        setOpen(false);
      }
    });
  };

  const handleOnFocus = () => {
    setOpen(true);
  };

  const dataTestId = props['data-testid' as keyof typeof props];

  return (
    <div
      ref={ref}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}>
      <Div
        position="relative"
        data-testid={dataTestId ?? 'molecules-DateTimePicker-DatePicker'}>
        <ReactDatePicker
          calendarClassName={styles.datePicker}
          className={styles.input}
          dateFormat="EEE dd MMM"
          enableTabLoop={false}
          locale={locale}
          minDate={parseISO(minDate)}
          onChange={(value) => (value ? setDate(value.toISOString()) : setDate(minDate))}
          open={open}
          popperPlacement="bottom"
          preventOpenOnFocus={true}
          renderCustomHeader={({...headerprops}) => <DatePickerHeader {...headerprops} />}
          selected={parseISO(date)}
          showDisabledMonthNavigation
          showPopperArrow={false}
          {...props}
        />
      </Div>
    </div>
  );
};
