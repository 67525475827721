import {AreaDetailsType} from './types';
import {useTranslation} from 'react-i18next';
import {MapDetailsModalPage, MapPreviewCard, ModalPage} from '@organisms';
import {FlexCol, FlexRow, H1, H2, H4, P} from '@quarks';

import {
  getFloorById,
  getSelectedBuildingName,
  getConnectedUsersByDate,
  getSelectedBuildingNodeId,
  getFloorHasMap,
  getNodeInformationByNodeId,
  getAreaById,
} from '@lib/store';

import {useAppSelector, useModal} from '@hooks';
import {CapacityIndicator, ConnectionList} from '@molecules';
import {parseISO} from 'date-fns';
import {Icon} from '@atoms';
import {pxToRem, getDeskAmenityIcon} from '@utils';
import {StyledSubtitle} from '../../hereAndNow/HaNWorkspaceInformation/styles';
import {useMemo} from 'react';
import {SectionDescription} from './styles';

export const AreaDetailsModalPage = ({date, workspace, inviteeCount}: AreaDetailsType) => {
  const {hasBookableDesks, areaId = '', floorId = ''} = workspace;
  const {t} = useTranslation();
  const {addPages, closeModal, removePages} = useModal();

  const nodeId = areaId || floorId;

  const groupSize = inviteeCount;
  const floor = useAppSelector((state) => floorId && getFloorById(state, floorId)) || null;
  const selectedBuildingId = useAppSelector(getSelectedBuildingNodeId);
  const buildingName = useAppSelector(getSelectedBuildingName);
  const connections = useAppSelector((state) => getConnectedUsersByDate(state, {date, nodeId}));
  const floorHasMap = useAppSelector(getFloorHasMap(floorId));
  const selectorProps = useMemo(() => ({nodeId: areaId, date: parseISO(date)}), [areaId, date]);
  const areaFullnessInfo = useAppSelector((state) => getNodeInformationByNodeId(state, selectorProps));
  const areaInfo = useAppSelector((state) => getAreaById(state, areaId));
  const {occupation, capacity} = areaFullnessInfo;
  const deskFullyBooked = capacity - occupation <= 0;

  return (
    <ModalPage
      onBack={() => removePages(1)}
      onClose={closeModal}
      title={t('workplace:Workspace')}>
      <FlexCol gap={16}>
        <FlexCol gap={8}>
          <H1>{areaInfo?.name}</H1>
          <P>
            {[
              t('meeting:MeetingRoomSeat', {
                count: areaInfo?.workspaceBookingPolicyType === 'NonBookable' ? areaInfo.adHocCapacity : capacity,
              }),
              floor?.name,
              buildingName,
            ].join(', ')}
          </P>
        </FlexCol>
        <FlexCol gap={32}>
          {areaInfo && (
            <FlexRow
              gap={16}
              alignItems="center"
              justifyContent="flex-start">
              {areaInfo?.workspaceBookingPolicyType === 'NonBookable' ? (
                <FlexRow
                  gap={16}
                  alignItems="center">
                  <Icon
                    icon="noCalendar"
                    size="24px"
                  />
                  <H4>{t('hereAndNow:AdHocUse')}</H4>
                </FlexRow>
              ) : (
                <>
                  <Icon
                    icon="calendar"
                    size="24px"
                  />
                  <FlexCol>
                    <H4>{t(hasBookableDesks ? 'screen:DeskDetail' : 'hereAndNow:AreaBooking')}</H4>
                    <FlexRow gap={8}>
                      <CapacityIndicator
                        capacity={capacity}
                        occupation={occupation}
                        displayDescription={false}
                      />
                      <StyledSubtitle $alertState={deskFullyBooked}>
                        {!deskFullyBooked
                          ? `${capacity - occupation}/${capacity} ${t('workplace:WorkspaceGroupAvailable')}`
                          : `${t('translation:FullyBooked')}`}
                      </StyledSubtitle>
                    </FlexRow>
                  </FlexCol>
                </>
              )}
            </FlexRow>
          )}

          {floorHasMap && (
            <MapPreviewCard
              buildingId={selectedBuildingId}
              floorId={floorId}
              areaId={areaId}
              groupSize={groupSize}
              onClick={() => {
                addPages([
                  <MapDetailsModalPage
                    date={date}
                    areaId={areaId}
                    buildingId={selectedBuildingId}
                    floorId={floorId}
                    groupSize={groupSize}
                  />,
                ]);
              }}
            />
          )}
          {areaInfo?.activityTypes.length ? (
            <FlexCol gap={16}>
              <H2 as="h3">{t('workplace:ActivityTypesFilterSectionTitle')}</H2>
              <SectionDescription>
                {areaInfo?.activityTypes
                  .map((activity, index) =>
                    index === 0
                      ? t(`activityTypes:activityType`, {context: activity})
                      : t(`activityTypes:activityType`, {context: activity}).toLowerCase(),
                  )
                  .join(', ')}
              </SectionDescription>
            </FlexCol>
          ) : null}
          {areaInfo?.deskAmenities.length ? (
            <FlexCol gap={16}>
              <H2 as="h3">{t('deskAmenities:deskAmenity_plural')}</H2>
              <SectionDescription>{t('workplace:DeskAmenities')}</SectionDescription>
              {areaInfo?.deskAmenities.map((amenity) => {
                return (
                  <FlexRow
                    gap={8}
                    key={amenity}
                    alignItems="center"
                    justifyContent="flex-start">
                    <Icon
                      weight="thin"
                      size={pxToRem(24)}
                      icon={getDeskAmenityIcon(amenity)}></Icon>
                    <P>{t(`deskAmenities:deskAmenity`, {context: amenity})}</P>
                  </FlexRow>
                );
              })}
            </FlexCol>
          ) : null}
          <ConnectionList
            padding={'none'}
            connections={connections}
            title={t('connection:ConnectionsWorkingHere')}
          />
        </FlexCol>
      </FlexCol>
    </ModalPage>
  );
};
