import {ConnectionsCardCellProps} from './types';
import {useTranslation} from 'react-i18next';

import {
  acceptConnectionRequest,
  deleteConnection,
  ignoreConnection,
  withAsyncThunkErrorHandling,
  withThunkErrorHandling,
} from '@lib/store';

import {useDestructiveDialog, useAppDispatch} from '@hooks';

import {IconButton, UserCell} from '@molecules';

export const ConnectionsCardCell = ({user, status}: ConnectionsCardCellProps) => {
  const {t} = useTranslation('connection');
  const {destructiveDialog} = useDestructiveDialog();
  const dispatch = useAppDispatch();

  if (!user) {
    return <></>;
  }

  const deleteColleague = async () => {
    const confirmation = await destructiveDialog(t('DeleteConnectionTitle'), t('DeleteConnectionMessage'), 'warning');

    if (confirmation) {
      dispatch(withAsyncThunkErrorHandling(() => deleteConnection(user)));
    }
  };

  const cancelRequest = () => {
    dispatch(withAsyncThunkErrorHandling(() => deleteConnection(user)));
  };

  const acceptColleague = () => {
    dispatch(withThunkErrorHandling(() => acceptConnectionRequest(user)));
  };

  const ignoreColleague = () => {
    dispatch(withAsyncThunkErrorHandling(() => ignoreConnection(user)));
  };

  return (
    <UserCell
      right={
        <>
          {status === 'accepted' && (
            <IconButton
              aria-label={t('DeleteConnection')}
              data-testid="organisms-connectionsCardCell-deleteConnection-button"
              iconButton="tertiary"
              icon="trash"
              onClick={deleteColleague}
              size="small"
            />
          )}
          {status === 'outgoing' && (
            <IconButton
              aria-label={t('CancelRequest')}
              iconButton="tertiary"
              icon="close"
              onClick={cancelRequest}
              size="small"
            />
          )}
          {status === 'incoming' && (
            <>
              <IconButton
                aria-label={t('AcceptRequest')}
                data-testid="organisms-connectionsCardCell-acceptRequest-button"
                iconButton="primary"
                icon="check"
                size="small"
                onClick={acceptColleague}
              />
              <IconButton
                aria-label={t('DenyRequest')}
                iconButton="tertiary"
                icon="close"
                size="small"
                onClick={ignoreColleague}
              />
            </>
          )}
        </>
      }
      subtitle={user.email}
      user={user}
      separator={false}
    />
  );
};
