import styled from 'styled-components';
import {StyledAnimatedButtonProps} from './types';

export const StyledAnimatedButton = styled.div<StyledAnimatedButtonProps>`
  border-radius: 4px;
  border: 2px solid transparent;
  padding: 0px 4px;
  color: inherit;
  cursor: ${({$disabled}) => ($disabled ? 'not-allowed' : 'pointer')};
  display: block;

  &:hover {
    background: ${({$disabled, theme}) => ($disabled ? 'transparent' : theme.card['transparent'].hover.background)};
    color: inherit !important;
    text-decoration: none !important;
  }

  &:focus-visible {
    border: ${({$disabled, theme}) => ($disabled ? '2px solid transparent' : `2px solid ${theme.brand}`)};
    color: inherit !important;
    text-decoration: none !important;
    outline: none;
  }
`;
