import styled from 'styled-components';

export const StyledWrapper = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.divider.grey};
  padding: 6px 0;
`;

export const StyledClickableCellWrapper = styled.div`
  border-radius: 4px;
  border: 2px solid transparent;
  padding: 4px;
  margin: 0 -4px;
  color: inherit;
  cursor: pointer;
  display: block;
  transition: background var(--speed-fast), border var(--speed-fast);

  @media (hover) {
    &:hover {
      background: ${({theme}) => theme.card.opaque.hover.background};
      color: inherit !important;
      text-decoration: none !important;
    }
  }

  &:focus-visible {
    border: 2px solid ${({theme}) => theme.brand};
    color: inherit !important;
    text-decoration: none !important;
    outline: none;

    & > div > div:nth-child(3) > svg {
      transform: translate3d(4px, 0, 0);
    }
  }
`;
