import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';

import {
  getAllBuildings,
  getDefaultBuildingId,
  getIsLoadingCalendar,
  getIsNew,
  savePreferredBuilding,
  withAsyncThunkErrorHandling,
} from '@lib/store';

import {useAppSelector, useAppDispatch} from '@hooks';
import {Loader} from '@atoms';
import {OnboardingBuildingsCard, OnboardingWelcomeCard} from '@organisms';
import {Canvas, OnboardingCard, OnboardingLayout} from '@templates';

/**
 * * INSTRUCTIONS
 * * isNew = true
 * * buildings.length > 1
 * Show welcome card, pass function that flips page to building selection, once building is selected and continue is pressed save the selected building, set isNew to false and then end the flow.
 *
 * * isNew = true
 * * buildings.length === 1
 * Show welcome card, but pass a function that saves the single building and sets isNew to false when continue is pressed and then end the onboarding flow.
 *
 * * isNew = true
 * * buildings.length === 0
 * Show welcome card, pass a function that saves an empty string instead of a building id when continue is pressed, set isNew to false and then end the onboarding flow.
 *
 * * isNew = false
 * * buildings.length > 1
 * * defaultBuilding = ''
 * Show the building selection screen, save selected building and then end the flow.
 *
 * * isNew = false
 * * buildings.length === 1
 * * defaultBuilding = ''
 * Don't go through onboarding flow, but save the sole building as default.
 *
 * * isNew = false
 * * defaultBuilding = buildingId
 * Don't go through onboarding flow.
 */

export const Onboarding = () => {
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();

  // TODO: Replace this loading function with something better.
  const isLoading = useAppSelector(getIsLoadingCalendar);
  const buildings = useAppSelector(getAllBuildings);
  const isNew = useAppSelector(getIsNew);
  const defaultBuilding = !!useAppSelector(getDefaultBuildingId);

  useEffect(() => {
    if (!isNew && !defaultBuilding && buildings.length > 1) {
      setPage(1);
    }
  }, [buildings, defaultBuilding, isNew]);

  const handleContinue = () => {
    if (buildings.length === 1) {
      dispatch(withAsyncThunkErrorHandling(() => savePreferredBuilding(buildings[0].id)));
      return;
    }

    if (buildings.length === 0) {
      dispatch(withAsyncThunkErrorHandling(() => savePreferredBuilding(null)));
      return;
    }

    setPage(1);
    return;
  };

  if (isNew || (!defaultBuilding && buildings.length > 1)) {
    if (isLoading) {
      return (
        <Canvas centerContent>
          <Loader />
        </Canvas>
      );
    }

    return (
      <>
        <Canvas />
        <OnboardingLayout>
          <OnboardingCard>
            <OnboardingWelcomeCard
              handleContinue={handleContinue}
              page={page}
            />
          </OnboardingCard>
          <OnboardingCard>
            <OnboardingBuildingsCard
              buildings={buildings}
              page={page}
            />
          </OnboardingCard>
        </OnboardingLayout>
      </>
    );
  }

  return <Outlet />;
};
