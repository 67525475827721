import {RSVPProps} from './types';
import {useTranslation} from 'react-i18next';

import {pxToRem} from '@utils';

import {Icon, Label} from '@atoms';
import {Badge} from '@molecules';

export const RSVP = ({asIcon, rsvp}: RSVPProps) => {
  const {t} = useTranslation('meeting');

  if (rsvp === 'Organizer') {
    if (asIcon) {
      return (
        <Icon
          icon="pencil"
          size={pxToRem(24)}
        />
      );
    }
    return <Label label="beige">{rsvp}</Label>;
  }

  return (
    <Badge
      aria-label={t(`MeetingStatus${rsvp}`)}
      badge={rsvp === 'Accepted' ? 'success' : rsvp === 'Declined' ? 'error' : 'unknown'}
      icon={rsvp === 'Accepted' ? 'check' : rsvp === 'Declined' ? 'close' : 'help'}
      role="img"
      size="xlarge"
    />
  );
};
