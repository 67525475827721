/**
 * @param activeElement - Element that should be within view of its parent
 * @param scrollParent - Element that should scroll
 */

export const scrollParentForChild = (activeElement: HTMLElement, scrollParent: HTMLElement) => {
  const {offsetHeight, offsetTop} = activeElement;
  const {offsetHeight: parentOffsetHeight, scrollTop} = scrollParent;

  const isAbove = offsetTop < scrollTop;
  const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;

  if (isAbove) {
    scrollParent.scrollTo({top: offsetTop, left: 0, behavior: 'smooth'});
  } else if (isBelow) {
    scrollParent.scrollTo({top: offsetTop - parentOffsetHeight + offsetHeight, left: 0, behavior: 'smooth'});
  }
};
