import {Illustration} from '@atoms';
import {FlexRow} from '@quarks';
import styled from 'styled-components';
import {StyledContainerProps} from './types';

export const StyledContainer = styled(FlexRow)<StyledContainerProps>`
  background: ${({theme}) => theme.background.white};
  border-radius: 12px;
  box-shadow: ${({theme}) => theme.boxShadow.secondary};
  align-items: center;
  gap: 16px;
  width: 335px;
  padding: ${({$padding}) => $padding + `px`};
`;

export const StyledIllustration = styled(Illustration).attrs((attrs) => ({...attrs}))`
  flex-shrink: 0;
`;
