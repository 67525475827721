import {KeyboardEvent, useRef, useState} from 'react';
import {ChipSelectProps} from './types';

import {MeetingInviteeSearchResult} from '@lib/store';
import {findByEmail, isEmailValid, sanitizeEmail} from '@lib/utils';

import {Loader} from '@atoms';
import {Chip} from '@molecules';
import {StyledComboBox, StyledInputWrapper, StyledWrapper} from './styles';
import {pxToRem} from '@utils';

export const ChipSelect = ({
  className,
  isLoading,
  isOutsideInviteesAllowed = true,
  reachedMaxSelection = false,
  onChange,
  optionsArray,
  placeholder,
  isPlaceholderTransparent,
  selectedOptionIndex,
  selection,
  setSelectedOptionIndex,
  setSelection,
  setValue,
  style,
  value,
}: ChipSelectProps) => {
  const [selectedChipIndex, setSelectedChipIndex] = useState<number | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  // * KEYBOARD CONTROLS
  const handleComboBoxKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const key = e.key;
    const cursorPosition = e.currentTarget.selectionStart;
    const hasChips = selection.length > 0;
    const hasSearchResults = optionsArray.length > 0;

    // Controls for left arrow
    if (hasChips && cursorPosition === 0 && key === 'ArrowLeft') {
      if (selectedOptionIndex !== null) setSelectedOptionIndex(undefined);
      if (selectedChipIndex === null) {
        setSelectedChipIndex(selection.length - 1);
      } else if (selectedChipIndex === 0) {
        setSelectedChipIndex(null);
      } else {
        setSelectedChipIndex((prevSelectedChip) => prevSelectedChip! - 1);
      }
    }

    // Control for selecting the last chip with backspace
    if (hasChips && cursorPosition === 0 && key === 'Backspace') {
      if (selectedChipIndex === null) {
        setSelectedChipIndex(selection.length - 1);
      }
    }

    // Controls for right arrow
    if (hasChips && cursorPosition === 0 && key === 'ArrowRight') {
      if (selectedOptionIndex !== null) setSelectedOptionIndex(undefined);
      if (selectedChipIndex === selection.length - 1) {
        e.preventDefault();
        setSelectedChipIndex(null);
      } else if (selectedChipIndex !== null) {
        e.preventDefault();
        setSelectedChipIndex((prevSelectedChip) => prevSelectedChip! + 1);
      }
    }

    // Controls for deleting a chip with backspace or delete
    if (hasChips && selectedChipIndex !== null && (key === 'Backspace' || key === 'Delete')) {
      e.preventDefault();
      setSelection((prevSelection) => prevSelection.filter((c) => c.email !== selection[selectedChipIndex].email));
      if (selection.length <= 1) {
        setSelectedChipIndex(null);
      } else if (selectedChipIndex === selection.length - 1) {
        setSelectedChipIndex((prevSelectedChip) => prevSelectedChip! - 1);
      }
    }

    // Controls for down arrow
    if (hasSearchResults && key === 'ArrowDown') {
      e.preventDefault();
      if (selectedChipIndex !== null) setSelectedChipIndex(null);
      if (selectedOptionIndex === optionsArray.length - 1) {
        setSelectedOptionIndex(0);
      } else {
        setSelectedOptionIndex((prevSelectedInvitee) =>
          prevSelectedInvitee === undefined ? 0 : prevSelectedInvitee + 1,
        );
      }
    }

    // Controls for up arrow
    if (hasSearchResults && key === 'ArrowUp') {
      e.preventDefault();
      if (selectedChipIndex !== null) setSelectedChipIndex(null);
      if (selectedOptionIndex === 0) {
        setSelectedOptionIndex(optionsArray.length - 1);
      } else {
        setSelectedOptionIndex((prevSelectedInvitee) =>
          prevSelectedInvitee === undefined ? optionsArray.length - 1 : prevSelectedInvitee - 1,
        );
      }
    }

    // General control to prevent row breaks
    if (key === 'Enter') {
      e.preventDefault();
    }

    // Controls for adding a searchresult to the array of chips
    if (hasSearchResults && selectedOptionIndex !== undefined && key === 'Enter' && !reachedMaxSelection) {
      e.preventDefault();
      setSelection((prevSelection) => [...prevSelection, optionsArray[selectedOptionIndex]]);
      if (optionsArray.length <= 1) {
        setSelectedOptionIndex(undefined);
      } else if (selectedOptionIndex === optionsArray.length - 1) {
        setSelectedOptionIndex((prevSelectedInvitee) => prevSelectedInvitee! - 1);
      }
      setValue('');
    }

    // Controls for adding an email from outside the organisation
    if (
      isEmailValid(sanitizeEmail(value)) &&
      !findByEmail(selection, sanitizeEmail(value)) &&
      key === 'Enter' &&
      isOutsideInviteesAllowed
    ) {
      const foundResult = findByEmail(optionsArray, sanitizeEmail(value));

      if (!foundResult)
        setSelection((prevSelection) => [
          ...prevSelection,
          {email: sanitizeEmail(value), name: sanitizeEmail(value)} as MeetingInviteeSearchResult,
        ]);

      setValue('');
    }

    // Controls for resetting our state if user doesn't click any of our control keys
    if (
      key !== 'ArrowLeft' &&
      key !== 'ArrowRight' &&
      key !== 'Backspace' &&
      key !== 'Delete' &&
      key !== 'ArrowDown' &&
      key !== 'ArrowUp' &&
      key !== 'Enter'
    ) {
      if (selectedChipIndex !== null) setSelectedChipIndex(null);
      if (selectedOptionIndex !== null) setSelectedOptionIndex(undefined);
    }
  };

  const handleComboBoxClick = () => {
    if (selectedChipIndex !== null) setSelectedChipIndex(null);
    if (selectedOptionIndex !== null) setSelectedOptionIndex(undefined);
  };

  const handleFocusTextareaOnClick = () => textAreaRef.current?.focus();

  const handleRemoveChipOnClick = (user: MeetingInviteeSearchResult) =>
    setSelection((prevSelection) => prevSelection.filter((prevUser) => prevUser.email !== user.email));

  return (
    <StyledWrapper
      className={className}
      onClick={handleFocusTextareaOnClick}
      style={style}>
      {selection.map((user, i) => (
        <Chip
          chip={user.id ? 'colored' : 'tertiary'}
          email={user.email}
          key={user.email}
          onClick={() => setSelectedChipIndex(i)}
          onDelete={() => handleRemoveChipOnClick(user)}
          selected={selectedChipIndex === i}
          square={false}>
          {user.name || user.email}
        </Chip>
      ))}
      <StyledInputWrapper>
        <StyledComboBox
          disabled={reachedMaxSelection}
          aria-disabled={reachedMaxSelection}
          aria-label={placeholder}
          onChange={onChange}
          onClick={handleComboBoxClick}
          onKeyDown={handleComboBoxKeyDown}
          placeholder={placeholder}
          $isPlaceholderTransparent={isPlaceholderTransparent}
          ref={textAreaRef}
          role="searchbox"
          rows={1}
          value={value}
          data-testid="molecules-ChipSelect_text-input"
        />
        {isLoading && <Loader size={pxToRem(28)} />}
      </StyledInputWrapper>
    </StyledWrapper>
  );
};
