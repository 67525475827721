import {getIsSigningIn, getIsSigningOut, getSignInChecked} from '@lib/store';

import {useAppSelector} from '@hooks';

import {Loader} from '@atoms';
import {LoginCard} from '@organisms';
import {Canvas, LoginContent} from '@templates';

export const Login = () => {
  const isSigningIn = useAppSelector(getIsSigningIn);
  const isSigningOut = useAppSelector(getIsSigningOut);
  const signInChecked = useAppSelector(getSignInChecked);

  if (isSigningIn || isSigningOut || !signInChecked) {
    return (
      <Canvas centerContent>
        <Loader />
      </Canvas>
    );
  }

  return (
    <LoginContent>
      <LoginCard />
    </LoginContent>
  );
};
