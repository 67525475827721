import {P} from '@quarks';
import {StyledContainer, StyledIllustration} from './styles';
import {useTranslation} from 'react-i18next';
import {EmptyListPlaceholderProps} from './types';

export const EmptyListPlaceholder = ({text, padding = 24}: EmptyListPlaceholderProps) => {
  const {t} = useTranslation();
  const textMessage = text ?? t('hereAndNow:NothingToShow');
  return (
    <StyledContainer $padding={padding}>
      <StyledIllustration
        size="80px"
        illustration="noResults"
      />
      <P
        color="currentColor"
        textAlign="left">
        {textMessage}
      </P>
    </StyledContainer>
  );
};
