import {useTranslation} from 'react-i18next';
import {NotificationsCardProps} from './types';
import {StyledNotificationCard} from './styles';

import {P} from '@quarks';
import {NotificationsCardCell} from '@organisms';

export const NotificationsCard = ({notifications}: NotificationsCardProps) => {
  const {t} = useTranslation();

  return (
    <StyledNotificationCard>
      {notifications.length ? (
        notifications.map((notification) => (
          <NotificationsCardCell
            notification={notification}
            key={notification.id}
          />
        ))
      ) : (
        <P>{t('notifications:NoNotifications')}</P>
      )}
    </StyledNotificationCard>
  );
};
