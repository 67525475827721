import {Configuration, PublicClientApplication} from '@azure/msal-browser';

export const createMsalInstance = () => {
  const msalConfig: Configuration = {
    auth: {
      clientId: import.meta.env.MAPIQ_AUTH_CLIENT_ID ?? '',
      authority: import.meta.env.MAPIQ_AUTH_AUTHORITY,
      knownAuthorities: [import.meta.env.MAPIQ_AUTH_B2C_DOMAIN_HOST ?? ''],
      protocolMode: import.meta.env.MAPIQ_AUTH_MSAL_PROTOCOL,
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };

  return new PublicClientApplication(msalConfig);
};
