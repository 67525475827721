import {CalendarHorizontalWeekOverviewProps} from './types';
import {StyledSwiperHorizontal} from './styles';
import {CalendarHorizontalWeekDay} from '@organisms';
import {BREAKPOINT_SIZES} from '@constants';

export const CalendarHorizontalWeekOverview = ({calendarWeeks}: CalendarHorizontalWeekOverviewProps) => {
  return (
    <StyledSwiperHorizontal
      spaceBetween={0}
      slidesPerGroup={7}
      slidesPerView={7}
      freeMode={{enabled: false}}
      breakpoints={{
        [BREAKPOINT_SIZES.small]: {
          freeMode: {enabled: true},
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          spaceBetween: 10,
          slidesOffsetBefore: 16,
          slidesOffsetAfter: 16,
        },
      }}>
      {calendarWeeks.map((week) =>
        week.data.map((day) => (
          <CalendarHorizontalWeekDay
            key={day.date}
            day={day}
          />
        )),
      )}
    </StyledSwiperHorizontal>
  );
};
