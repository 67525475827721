import styled from 'styled-components';
import {BREAKPOINTS} from '@constants';
import {Card} from '@atoms';
import {FlexCol} from '@quarks';
import {truncateString} from '@utils';
import {Select} from '@molecules';

export const ProfileCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 40px 16px;

  ${BREAKPOINTS.medium`
    padding: 48px 56px 24px;
  `}

  ${BREAKPOINTS.small`
    border-radius: 0;
    padding: 32px 16px 8px;
  `}
`;

export const CellWrapper = styled(FlexCol).attrs(({theme, ...attrs}) => ({
  background: theme.divider.grey,
  gap: 1,
  ...attrs,
}))``;

export const ProfileCell = styled.div`
  display: flex;
  background: ${({theme}) => theme.card.white.background};
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  min-height: 80px;
  padding: calc(var(--gap-y) / 2) 0;

  & > p {
    ${truncateString}
  }

  ${BREAKPOINTS.small`
    min-height: 72px;
  `}
`;

export const ProfileSelect = styled(Select)`
  --select-width: 310px;

  ${BREAKPOINTS.medium`
    --select-width: 230px;
  `}

  ${BREAKPOINTS.small`
    --select-width: 220px;
  `}
`;
