import {ModalCellItemIcon} from '@molecules';
import {FlexRow, P} from '@quarks';
import {pxToRem} from '@utils';
import styled from 'styled-components';

export const GroupGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  width: 100%;
  padding: 8px 20px;
`;
export const StyledGroupItem = styled.div`
  align-items: center;
  background: ${({theme}) => theme.card.beige500.background};
  border-radius: 6px;
  color: ${({theme}) => theme.card.beige500.color};
  cursor: pointer;
  display: flex;
  font-size: ${({theme}) => theme.text.size.md};
  font-weight: ${({theme}) => theme.text.weight.bold};
  box-shadow: ${({theme}) => theme.boxShadow};
`;

type WrapperProps = {
  $selected: boolean;
};

export const Wrapper = styled(FlexRow)<WrapperProps>`
  background: ${({theme, $selected}) => ($selected ? theme.card.transparent.hover.background : 'inherit')};
  max-height: 68px;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  border: 2px solid transparent;
  width: 100%;

  @media (hover) {
    &:hover {
      background: ${({theme}) => theme.card.transparent.hover.background};
    }
  }

  &:focus-visible {
    border: ${({theme}) => `2px solid ${theme.brand}`};
    outline: none;
  }
`;

export const StyledModalCellItemIcon = styled(ModalCellItemIcon)`
  flex: none;
  align-self: flex-start;
  width: calc(3rem + 4px);
  height: calc(3rem + 4px);
`;

export const GroupItemIconWrapper = styled.div`
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
`;

export const StyledFacilityIcon = styled.div<{$imageURL?: string}>`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: ${({$imageURL, theme}) =>
    $imageURL && $imageURL !== '' ? `scroll center/cover no-repeat url('${$imageURL}')` : 'none'};
  border-radius: 8px;
`;

export const StyledResultsText = styled(P)`
  padding: 8px 20px;
`;
