import {useState} from 'react';
import {CalendarDayOverviewCardProps} from './types';
import {useTranslation} from 'react-i18next';

import {CalendarConnectionsList, CalendarEventsList, CalendarWorkdayTile, SubMenu, SubMenuItem} from '@organisms';
import {Div, Grid} from '@quarks';
import {useAppSelector, useFeatureToggle} from '@hooks';
import {getIsMeetingsCalendarEnabled} from '@lib/store';
import {StyledCard} from './styles';

export const CalendarDayOverviewCard = ({className, date}: CalendarDayOverviewCardProps) => {
  const [page, setPage] = useState<0 | 1>(0);
  const {t} = useTranslation();
  const {HybridMeetingsCalendarView} = useFeatureToggle();
  const isMeetingCalendarEnabled = useAppSelector(getIsMeetingsCalendarEnabled);

  return (
    <StyledCard className={className}>
      <Grid rowGap={16}>
        <CalendarWorkdayTile date={date} />
      </Grid>

      {isMeetingCalendarEnabled && HybridMeetingsCalendarView && (
        <>
          <SubMenu>
            <SubMenuItem
              active={page === 0}
              onClick={() => setPage(0)}>
              {t('meeting:CalendarTabTitle')}
            </SubMenuItem>
            <SubMenuItem
              active={page === 1}
              onClick={() => setPage(1)}>
              {t('meeting:ConnectionsTabTitle')}
            </SubMenuItem>
          </SubMenu>
        </>
      )}

      {isMeetingCalendarEnabled && HybridMeetingsCalendarView ? (
        page === 0 ? (
          <CalendarEventsList date={date} />
        ) : (
          <CalendarConnectionsList date={date} />
        )
      ) : (
        <Div paddingTop={32}>
          <CalendarConnectionsList date={date} />
        </Div>
      )}
    </StyledCard>
  );
};
