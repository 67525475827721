import {CalendarWeekProps} from './types';
import {useTranslation} from 'react-i18next';

import {H2} from '@quarks';
import {CalendarCardHeading, CalendarCardPadding, StyledCalendarCard} from './styles';

export const CalendarWeek = ({children, className, style, weekNumber, weekType}: CalendarWeekProps) => {
  const {t} = useTranslation('workplace');

  const textTitle =
    weekType === 'thisWeek'
      ? t('ThisWeek')
      : weekType === 'nextWeek'
      ? t('NextWeek')
      : t('WeekNumber', {weekNumber: weekNumber});

  return (
    <StyledCalendarCard
      className={className}
      style={style}>
      <CalendarCardHeading
        alignItems="center"
        justifyContent="space-between"
        padding={16}>
        <H2>{textTitle}</H2>
      </CalendarCardHeading>
      {children}
      <CalendarCardPadding padding={16} />
    </StyledCalendarCard>
  );
};
