import {Icon} from '@atoms';
import {Badge} from '@molecules';
import {Div} from '@quarks';
import {pxToRem} from '@utils';
import {useTheme} from 'styled-components';
import {ScheduleDataIconsProps} from './types';

export const HaNScheduleDataIcon = ({status, icon, ...props}: ScheduleDataIconsProps) => {
  const theme = useTheme();

  const badge =
    status === 'available'
      ? 'success'
      : status === 'pending'
      ? 'warning'
      : status === 'unavailable'
      ? 'error'
      : status === 'none'
      ? 'none'
      : 'unknown';

  return (
    <Div
      position="relative"
      {...props}>
      {badge !== 'none' && (
        <Badge
          data-testid={`${props['data-testid']}-Indicator`}
          badge={badge}
          right="0"
          size="small"
          top="0"
        />
      )}
      <Icon
        icon={icon}
        size={pxToRem(20)}
        color={theme.icon.inactive}
      />
    </Div>
  );
};
