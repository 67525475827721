import {BREAKPOINTS} from '@constants';
import {animated} from 'react-spring';
import styled from 'styled-components';

export const SidePanelLayout = styled(animated.dialog)`
  background: ${({theme}) => theme.background.white};
  height: 100%;
  left: unset;
  position: fixed;
  right: 0;
  top: 0;
  width: 440px;

  ${BREAKPOINTS.small`
    display: none;
  `}
`;
