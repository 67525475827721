import {AddConnectionsCardHintProps} from './types';
import {useTranslation} from 'react-i18next';

import {isEmailValid, sanitizeEmail} from '@lib/utils';

import {animated, config, useTransition} from 'react-spring';

import {P} from '@quarks';

export const AddInviteesCardHint = ({
  debouncedSearchTerm,
  isSearching,
  length,
  searchTerm,
}: AddConnectionsCardHintProps) => {
  const {t} = useTranslation();
  const show: boolean = searchTerm.length > 0 && debouncedSearchTerm.length >= 1;
  const transition = useTransition(show, {
    initial: {opacity: 1, transform: 'translate3d(0, 0px, 0) scale(1)', transformOrigin: 'top center'},
    from: {opacity: 0, transform: 'translate3d(0, 10px, 0) scale(1)', transformOrigin: 'top center'},
    enter: {opacity: 1, transform: 'translate3d(0, 0px, 0) scale(1)', transformOrigin: 'top center'},
    leave: {opacity: 0, transform: 'translate3d(0, 10px, 0) scale(1)', transformOrigin: 'top center'},
    config: config.stiff,
  });

  const sanitizedEmail = sanitizeEmail(debouncedSearchTerm);
  const validEmail = isEmailValid(sanitizedEmail);

  return transition(
    (style, item) =>
      item && (
        <animated.div style={style}>
          {!isSearching && length === 0 ? (
            <>
              <P fontWeight="bold">
                {debouncedSearchTerm.length === 1
                  ? t('connection:NoUsersFound')
                  : !validEmail && t('meeting:InvalidEmailAddressTitle')}
              </P>
              <P>
                {debouncedSearchTerm.length === 1
                  ? t('connection:TypeMoreCharsToFindColleagues')
                  : debouncedSearchTerm.length >= 2 && validEmail
                  ? t('meeting:CreateMeetingInviteeEmptyMessage')
                  : t('meeting:InvalidEmailAddressMessage')}
              </P>
            </>
          ) : null}
        </animated.div>
      ),
  );
};
