import styled from 'styled-components';
import {FlexCol, FlexRow, Padding} from '@quarks';
import {BREAKPOINTS} from '@constants';

export const StyledCalendarCard = styled(FlexCol)`
  align-items: stretch;
  background: ${({theme}) => theme.divider.grey};
  border-right: 1px solid ${({theme}) => theme.divider.grey};
  gap: 1px 0;

  ${BREAKPOINTS.small`
    border: none;
  `}
`;

export const CalendarCardHeading = styled(FlexRow)`
  background: ${({theme}) => theme.background.white};
`;

export const CalendarCardPadding = styled(Padding)`
  background: ${({theme}) => theme.background.white};
  padding: 16px;
`;
