import {BREAKPOINTS} from '@constants';
import {Div} from '@quarks';
import styled from 'styled-components';

type StyledWrapperProps = {
  stickToTheTop: boolean;
};

export const StyledWrapper = styled(Div)<StyledWrapperProps>`
  display: flex;
  height: 176px;
  padding: 24px 20px;
  align-items: center;
  align-self: stretch;
  background: ${({theme}) => theme.card.connectingGreen.background};
  color: ${({theme}) => theme.card.connectingGreen.color};

  ${({stickToTheTop}) =>
    stickToTheTop
      ? BREAKPOINTS.small`
      display: relative;
      position: sticky;
      top: 0;
      z-index: 1;
  `
      : ''}
`;
