import {useAppSelector, useModal} from '@hooks';
import {ModalCell} from '@molecules';
import {FlexCol, FlexRow} from '@quarks';
import {useTranslation} from 'react-i18next';
import {StyledModalPage} from './styles';
import {getNotificationCategoryData, getNotificationPreferencesLoadingStatus} from '@lib/store';
import {Loader} from '@atoms';
import {pxToRem} from '@utils';
import {NotificationSettingsSection} from './NotificationSettingsSection';

export const NotificationSettingsModalPage = () => {
  const {t} = useTranslation();
  const {closeModal} = useModal();
  const preferencesLoadingStatus = useAppSelector(getNotificationPreferencesLoadingStatus);
  const {status, categories} = useAppSelector(getNotificationCategoryData);

  const isLoaded = status === 'Loaded' && preferencesLoadingStatus === 'Loaded';

  return (
    <StyledModalPage
      title={t('screen:NotificationSettings')}
      onClose={closeModal}>
      {isLoaded ? (
        <FlexCol gap={16}>
          {categories.map(({category, types}, sectionIndex) => {
            return (
              <NotificationSettingsSection
                key={`section-${sectionIndex}`}
                category={category}
                types={types}
              />
            );
          })}
        </FlexCol>
      ) : (
        <ModalCell>
          <FlexRow
            alignItems="center"
            justifyContent="center">
            <Loader size={pxToRem(24)} />
          </FlexRow>
        </ModalCell>
      )}
    </StyledModalPage>
  );
};
