import {useState} from 'react';
import {HaNOccupancyInformation, HaNWorkspaceInformation, HaNWorkspaceModalPage} from '../../hereAndNow';
import {useModal} from '@hooks';
import {AreaWithSensorInfo} from '@lib/store';

type Props = {
  workspaceWithSensorData: AreaWithSensorInfo;
};

export const AvailableWorkspacesModal = ({workspaceWithSensorData}: Props) => {
  const {closeModal} = useModal();
  const [page, setPage] = useState<HaNWorkspaceModalPage>('workspaceInformation');

  function handleOnBack() {
    setPage('workspaceInformation');
  }
  return (
    <>
      <HaNWorkspaceInformation
        page={page}
        onClose={closeModal}
        workspace={workspaceWithSensorData}
        setPage={setPage}
        showMapTile="always"
      />
      <HaNOccupancyInformation
        page={page}
        onClose={closeModal}
        onBack={handleOnBack}
      />
    </>
  );
};
