import isEqual from 'lodash/isEqual';
import {useAppDispatch, useAppSelector, useModal, useUserProfilePics} from '@hooks';
import {useTranslation} from 'react-i18next';
import {FlexCol, Span, Subtitle} from '@quarks';
import {ModalCell, ModalCellItemIcon} from '@molecules';
import {ModalPageBottom, MapSelectorCard} from '@organisms';
import {
  getSelectedNodes,
  resetWorkspaceReservation,
  getUserImageHash,
  getUserInitials,
  getDesksByAreaId,
  getUser,
  selectDesks,
  getAreaById,
  getFloorById,
  getAllSuggestedDesks,
  Desk,
  SuggestedDesk,
} from '@lib/store';
import {pxToRem} from '@utils';
import {DeskSelectionModalPageProps, SelectedDesk, SelectedDeskUser} from './types';
import {TwoColumnCell} from '@atoms';
import {EventTarget} from '../../../../../../../submodules/map/mapiq-map/EventTarget';
import {useState} from 'react';
import {StyledModalPage} from './styles';

export const DeskSelectionModalPage = ({date, invitees}: DeskSelectionModalPageProps) => {
  const {closeModal, removePages, modalPages} = useModal();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {buildingId, floorId, areaId, deskIds} = useAppSelector(getSelectedNodes);

  const user = useAppSelector(getUser);
  const userImageHash = useAppSelector(getUserImageHash) ?? '';
  const userInitials = useAppSelector(getUserInitials);

  const areaNode = useAppSelector((state) => getAreaById(state, areaId));
  const floorNode = useAppSelector((state) => getFloorById(state, floorId));
  const areaDesks = useAppSelector((state) => getDesksByAreaId(state, areaId));

  const members: SelectedDeskUser[] = [
    {id: user.id, email: user.email, initials: userInitials, imageHash: userImageHash},
  ].concat(invitees);

  const groupSize = members.length;
  const isGroupBooking = invitees.length > 0;

  const memberProfileImages = useUserProfilePics(
    members.map((_member) => ({
      userId: _member.id,
      imageHash: _member.imageHash ?? '',
    })),
  );

  const membersWithProfilePic: SelectedDeskUser[] = members.map((_member) => ({
    ..._member,
    imageUrl: memberProfileImages[_member.id],
  }));

  // Any currently selected desks should be at the top of the list
  const selectionRanks = new Map(deskIds.map((id, idx) => [id, idx]));
  const rankedDesks = Array.from(useAppSelector(getAllSuggestedDesks)).sort((s1, s2) => {
    const r1 = selectionRanks.get(s1.id) ?? Infinity;
    const r2 = selectionRanks.get(s2.id) ?? Infinity;

    if (r1 === r2) return 0;

    return r1 - r2;
  });

  // This is our internal state, it has everything we need to determine highlights _and_ update the
  // UI surrounding the map
  // TODO: make deskStates a Map of <deskId, SelectedDesk> for faster lookups
  const [deskStates, setDeskStates] = useState<SelectedDesk[]>(
    createInitialSelection(rankedDesks, areaDesks, membersWithProfilePic),
  );

  const currentDeskSelection = deskStates.filter((selectedDesk) => selectedDesk.user);

  const [currentAssignedDeskIds, currentAssignedMembers] = splitAssignedDesksInMembersAndDeskIds(
    membersWithProfilePic,
    deskStates,
  );
  const currentDeskSelectionCount = currentAssignedDeskIds.length;

  // The main toggle logic:
  const setSelectedDesk = ({deskId}: EventTarget) => {
    const state = deskStates.find((d) => d.deskId === deskId);

    // Not all desks are interactive
    if (!state || state.booked || !state.enabled) {
      return;
    }

    setDeskStates((prevState) => {
      if (isGroupBooking) {
        const assignedMemberIds = new Set(prevState.flatMap((s) => (s.user ? [s.user.id] : [])));
        const memberPool = membersWithProfilePic.filter((m) => !assignedMemberIds.has(m.id));

        return prevState.map((d) => {
          if (d.deskId !== deskId) return d;

          return {
            ...d,
            user: d.user ? null : memberPool.shift() || null,
          };
        });
      } else {
        return prevState.map((d) => ({
          ...d,
          user: d.deskId === deskId ? membersWithProfilePic[0] : null,
        }));
      }
    });
  };

  // Check if the selection can be saved
  const isValidSelection = currentDeskSelectionCount === groupSize;
  const deskSelectionIsDirty = !isEqual(currentAssignedDeskIds, deskIds);
  const buttonDisabled = !isValidSelection || !deskSelectionIsDirty;

  const getLocationText = () => {
    if (groupSize > 1) {
      if (currentDeskSelectionCount < groupSize) {
        return t('workplace:DeskIncomplete', {count: currentDeskSelectionCount, max: groupSize});
      } else {
        return t('workplace:Desk', {count: groupSize});
      }
    }

    const deskName = currentDeskSelection.at(0)?.deskName;
    if (deskName) {
      return deskName;
    }
    return t('workplace:UseAnyFreeDesk');
  };

  const subtitle = areaNode && floorNode ? `${areaNode.name}, ${floorNode.name}` : floorNode?.name ?? '';

  const onSaveHandler = () => {
    if (buttonDisabled) return;

    dispatch(selectDesks(currentAssignedDeskIds));
    removePages(1);
  };

  return (
    <StyledModalPage
      title={t('workplace:BookAWorkspace')}
      bottom={
        <ModalPageBottom
          buttonDisabled={buttonDisabled}
          buttonLabel={t('translation:Confirm')}
          onClick={onSaveHandler}
        />
      }
      onBack={modalPages.length > 1 ? () => removePages(1) : undefined}
      onClose={() => {
        closeModal();
        dispatch(resetWorkspaceReservation());
      }}>
      <FlexCol
        height={'100%'}
        gap={8}>
        <MapSelectorCard
          date={date}
          buildingId={buildingId}
          floorId={floorId}
          areaId={areaId}
          deskIds={currentAssignedDeskIds}
          users={currentAssignedMembers}
          onClick={setSelectedDesk}
        />
        <ModalCell>
          <TwoColumnCell
            padding="small"
            alignItems="flex-start"
            separator={false}
            left={
              <ModalCellItemIcon
                // backgroundColor={theme.label.blue.background}
                icon="desk"
              />
            }
            right={
              <FlexCol minHeight={pxToRem(48)}>
                <Span fontWeight="bold">{getLocationText()}</Span>
                <Subtitle>{subtitle}</Subtitle>
              </FlexCol>
            }
          />
        </ModalCell>
      </FlexCol>
    </StyledModalPage>
  );
};

const splitAssignedDesksInMembersAndDeskIds = (
  members: SelectedDeskUser[],
  deskStates: SelectedDesk[],
): [string[], SelectedDeskUser[]] => {
  // This component lets us remove desks from members, which means we can end up with a state like this:
  // members: [ 'John',  'Jane'  ]
  // deskIds: [   null, 'desk 1' ]
  // Because that gap in deskIds is kind of weird, we'll change this to be:
  // members: [   'Jane', 'John' ] (length 2)
  // deskIds: [ 'desk 1'         ] (length 1)

  const deskIdsByUserId = new Map(deskStates.map((d) => [d.user?.id, d.deskId]));

  const currentAssignedDeskIds: string[] = [];
  const currentAssignedMembers: SelectedDeskUser[] = [];
  for (const member of members) {
    // Note: if this gets too slow for large numbers of desks,
    const deskId = deskIdsByUserId.get(member.id);

    if (deskId) {
      currentAssignedDeskIds.push(deskId);
      currentAssignedMembers.push(member);
    }
  }

  return [currentAssignedDeskIds, currentAssignedMembers];
};

// Take enabled, non-booked desks and fill them up with members
const createInitialSelection = (
  suggestions: SuggestedDesk[],
  allDesks: Desk[],
  users: SelectedDeskUser[],
): SelectedDesk[] => {
  const desksById = new Map(allDesks.map((d) => [d.id, d]));
  const membersToAssign = Array.from(users);

  return suggestions.flatMap((suggestedDesk) => {
    const desk = desksById.get(suggestedDesk.id);

    if (!desk) {
      console.warn('Desk suggestion for desk that is not in area');
      return [];
    }

    // Disabled desks just don't appear in our map
    if (!desk.isEnabled) return [];

    return [
      {
        deskId: suggestedDesk.id,
        deskName: desk.name,
        enabled: desk.isEnabled,
        booked: suggestedDesk.booked,
        user: membersToAssign.shift() || null,
      },
    ];
  });
};
