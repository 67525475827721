import {Card} from '@atoms';
import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: var(--padding-y) var(--padding-x);

  ${BREAKPOINTS.small`
    border-radius: 0;
  `}
`;
