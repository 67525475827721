import {GlobalImageCacheProvider} from '@lib/utils';
import {FiltersProvider} from '@lib/utils';
import {ContextsProps} from './types';

import {DialogProvider, ModalProvider, ThemeProvider, SidePanelProvider} from '@contexts';

export const Contexts = ({children}: ContextsProps) => {
  return (
    <ThemeProvider>
      <GlobalImageCacheProvider>
        <DialogProvider>
          <ModalProvider>
            <FiltersProvider>
              <SidePanelProvider>{children}</SidePanelProvider>
            </FiltersProvider>
          </ModalProvider>
        </DialogProvider>
      </GlobalImageCacheProvider>
    </ThemeProvider>
  );
};
