import {Card, Icon} from '@atoms';
import {AvatarStack} from '@molecules';
import {Div} from '@quarks';
import {pxToRem, truncateString} from '@utils';
import styled from 'styled-components';

export const BuildingWrapper = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  gap: 1px 0;
`;

export const BuildingButton = styled(Card)<{$hasMeetingRooms: boolean}>`
  align-items: center;
  border-radius: inherit;
  display: grid;
  gap: 16px 8px;
  grid-template-columns: ${pxToRem(24)} 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: flex-start;
  padding: 14px;
  width: 100%;
  transition: background var(--speed-fast), border var(--speed-fast);

  border-radius: ${({$hasMeetingRooms}) => $hasMeetingRooms && '10px 10px 0 0'};

  &:focus-visible {
    border: 2px solid ${({theme}) => theme.brand};
    outline: none;
  }

  &:disabled {
    cursor: default;
    grid-template-rows: none;
  }
`;

export const BuildingButtonIcon = styled(Icon)`
  grid-area: 1 / 1 / span 1 / span 1;
`;

export const BuildingButtonTitle = styled.p`
  grid-area: 1 / 2 / span 1 / span 1;
  font-weight: 600;
`;

export const BuildingButtonAvatarStack = styled(AvatarStack)`
  grid-area: 2 / 2 / span 1 / span 1;
`;

export const MeetingRoomButton = styled(Card)`
  align-items: center;
  border-radius: 0;
  display: grid;
  gap: 0 8px;
  grid-template-columns: ${pxToRem(24)} 1fr ${pxToRem(48)};
  grid-template-rows: 1fr;
  padding: 14px;
  transition: background var(--speed-fast), border var(--speed-fast);
  width: 100%;

  div:last-child > & {
    border-radius: 0 0 10px 10px;
  }

  &:focus-visible {
    border: 2px solid ${({theme}) => theme.brand};
    outline: none;
  }
`;

export const MeetingRoomButtonInformationWrapper = styled.div`
  grid-area: 1 / 2 / span 1 / span 1;
  ${truncateString};
`;

export const Name = styled.p`
  ${truncateString};
`;

export const MeetingRoomButtonIcon = styled.div`
  position: absolute;
  right: 14px;
  height: 100%;
  bottom: 0;
  align-items: center;
  display: flex;
`;

export const StyledMeetingRoomButtonWrapper = styled(Div)<{$sameBuilding: boolean}>`
  position: relative;
  color: ${({$sameBuilding, theme}) => ($sameBuilding ? theme.card.energizingYellow.color : theme.card.beige200.color)};
`;

export const MeetingRoomButtonWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;

  @media (hover) {
    &:hover {
      color: ${({theme}) => theme.text.color.subtitle};
    }
  }
`;
