import styled from 'styled-components';

import {pxToRem, truncateString} from '@utils';

import {Icon} from '@atoms';
import {ComboBoxProps, SelectIconProps, SelectValue, StyledListBoxProps, StyledOptionProps} from './types';

export const Wrapper = styled.div`
  --select-width: ${pxToRem(310)};
  font-size: ${pxToRem(15)};
  position: relative;
  user-select: none;
  width: var(--select-width);
`;

export const ComboBox = styled.div<ComboBoxProps>`
  align-items: center;
  background: ${({theme}) => theme.input.background};
  border-radius: 4px;
  border: 1px solid ${({$open, theme}) => (!$open ? theme.input.border.default : theme.input.hover.border)};
  color: ${({theme}) => theme.input.color};
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 8px 0 16px;

  & > span {
    ${truncateString};
    flex: 1;
  }

  &:focus-visible {
    outline: none;
    border: 1px solid ${({theme}) => theme.brand};
  }

  @media (hover) {
    &:hover {
      border: 1px solid ${({theme}) => theme.input.hover.border};
    }
  }
`;

export const SelectIcon = styled(Icon)<SelectIconProps>`
  height: 24px;
  transform-origin: center;
  transform: rotate3d(0, 0, 1, ${({$open}) => ($open ? '-180deg' : '0deg')});
  transition: transform var(--speed-fast) ease-out;
  width: 24px;
`;

/* TODO: Try to find a workaround to the z-index */
export const ListBox = styled.div<StyledListBoxProps>`
  background: ${({theme}) => theme.input.select.background};
  border-radius: 4px;
  box-shadow: ${({theme}) => theme.boxShadow.secondary};
  left: 0;
  max-height: ${pxToRem(192)};
  overflow: hidden auto;
  position: absolute;
  top: ${({$listBoxIsInView, $listBoxHeight}) =>
    $listBoxIsInView ? 'calc(100% + 4px)' : `calc(-1 * ${$listBoxHeight}px - 4px)`};
  transition: top var(--speed-fast);
  width: var(--select-width);
  max-width: 100%;
  z-index: 1;
`;

export const StyledOption = styled.div<StyledOptionProps<SelectValue>>`
  ${truncateString};
  background: ${({$activeIndex, $index, $value, $selectedValue, theme}) =>
    $activeIndex === $index
      ? theme.input.option.hover.background
      : $value === $selectedValue
      ? theme.input.option.selected.background
      : theme.input.option.background};
  color: ${({$activeIndex, $index, $selectedValue, $value, theme}) =>
    $activeIndex === $index
      ? theme.input.option.hover.color
      : $value === $selectedValue
      ? theme.input.option.selected.color
      : theme.input.option.color};
  cursor: default;
  padding: 4px 16px;

  @media (hover) {
    &:hover {
      background: ${({$activeIndex, $index, theme}) =>
        $activeIndex === $index ? theme.input.option.hover.background : theme.input.option.background};
    }
  }
`;
