import {type ReactNode, useEffect, useState} from 'react';
import type {SubPageAutoControlProps} from './types';

import {config, useSpring} from 'react-spring';
import {Page, Slider, SubPageWrapper} from './styles';

/**
 * @param pages: This is the only array that is passed to the component, the pages are controlled by manipulating the array. Please remember to return a new array to the setter and not mutate the current state array.
 */

export const SubPageAutoControl = ({pages}: SubPageAutoControlProps) => {
  const [debouncedPages, setDebouncedPages] = useState<ReactNode[]>([]);
  const count = debouncedPages.length;
  const activePage = pages.length - 1;
  const [debouncedActivePage, setDebouncedActivePage] = useState<number | null>(null);
  const style = useSpring({
    to: {transform: `translate3d(${(-100 / count) * activePage}%, 0, 0)`},
    config: {...config.stiff, clamp: true},
    onStart: () => {
      setDebouncedPages(pages);

      setDebouncedActivePage(null);
    },
    onRest: () => {
      setDebouncedActivePage(activePage);
    },
  });

  useEffect(() => {
    if (debouncedPages.length === 0) {
      setDebouncedPages(pages);
    }

    return () => {
      if (pages.length === 0) {
        setDebouncedPages([]);
      }
    };
  }, [debouncedPages, pages]);

  const pageWidth = `${100 / debouncedPages.length}%`;
  const width = `${debouncedPages.length * 100}%`;

  return (
    <SubPageWrapper tabIndex={debouncedPages.length === 0 ? 0 : -1}>
      <Slider
        $width={width}
        style={style}>
        {debouncedPages.map((component, i) => (
          <Page
            aria-hidden={debouncedActivePage !== null && debouncedActivePage !== i}
            key={`SubPageAC-${i}`}
            $pageWidth={pageWidth}>
            {component}
          </Page>
        ))}
      </Slider>
    </SubPageWrapper>
  );
};
