import isEqual from 'lodash/isEqual';
import {WorkspaceReservation} from '@lib/store';
import {ReservationState} from './types';

export function reservationStateFromExisting(reservation?: WorkspaceReservation): ReservationState | null {
  if (!reservation) return null;

  return reservation.members.map((m) => ({
    nodeId: m.nodeId,
    userId: m.userId,
    isOwner: m.isOwner,
  }));
}
export function reservationStateFromUI(
  userId: string,
  inviteeIds: string[],
  nodeIds: string[],
): ReservationState | null {
  if (nodeIds.length === 0) return null;

  if (nodeIds.length > 1 && nodeIds.length !== inviteeIds.length + 1) {
    // This happens when you are modifing invitees for an existing reservation
    // (nodeIds are suggested async, so temporarily out of sync)
    return null;
  }

  return [userId].concat(inviteeIds).map((id, idx) => ({
    userId: id,
    isOwner: id === userId,
    nodeId: nodeIds[idx % nodeIds.length],
  }));
}
export function reservationStateEqual(rs1: ReservationState | null, rs2: ReservationState | null) {
  return isEqual(rs1, rs2);
}
