import {BREAKPOINT_SIZES} from '@constants';
import {useEffect, useState} from 'react';

export const useBreakPoint = () => {
  const [breakPoint, setBreakPoint] = useState<keyof typeof BREAKPOINT_SIZES>(getBreakPoint(window.innerWidth));

  function getBreakPoint(width: number) {
    if (width <= BREAKPOINT_SIZES.small) return 'small';
    else if (width <= BREAKPOINT_SIZES.medium) return 'medium';
    else if (width <= BREAKPOINT_SIZES.large) return 'large';
    else {
      return 'xLarge';
    }
  }

  useEffect(() => {
    function updateBreakPoint() {
      const currentBreakPoint = getBreakPoint(window.innerWidth);
      if (breakPoint !== currentBreakPoint) setBreakPoint(currentBreakPoint);
    }

    window.addEventListener('resize', updateBreakPoint);

    return () => window.removeEventListener('resize', updateBreakPoint);
  }, [breakPoint]);

  return breakPoint;
};
