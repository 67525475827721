import {LinkProps} from './types';
import {NavLink} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {baseStyle} from '../styles';

const linkStyle = css`
  ${baseStyle}
  color: ${({theme}) => theme.link.default};
  font-weight: 600;
  text-decoration: none;
  text-underline-offset: 2px;
  transition: color var(--speed-fast);

  &:focus-visible {
    color: ${({theme}) => theme.link.hover};
    outline: none;
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

  @media (hover) {
    &:hover {
      color: ${({theme}) => theme.link.hover};
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }
  }
`;

const InternalLink = styled(NavLink)`
  ${linkStyle}
`;

const OutboundLink = styled.a`
  ${linkStyle}
`;

export const Link = ({className, children, style, to, ...props}: LinkProps) => {
  const parsedLink: string = to.startsWith('http') ? 'outbound' : 'internal';

  if (parsedLink === 'internal') {
    return (
      <InternalLink
        className={className}
        style={style}
        to={to}
        {...props}>
        {children}
      </InternalLink>
    );
  }

  return (
    <OutboundLink
      className={className}
      href={to}
      rel={'noopened noreferrer'}
      target="_blank"
      style={style}
      {...props}>
      {children}
    </OutboundLink>
  );
};
