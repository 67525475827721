import {useState} from 'react';
import {useAppDispatch} from './useAppDispatch';
import {
  WorkspaceReservationStatus,
  updateWorkspaceReservationStatus,
  withAsyncThunkErrorHandling,
  getIsUserOwnerOfGroupWorkspaceReservationByDate,
  getWorkspaceReservationByDate,
  getWorkspaceReservationById,
} from '@lib/store';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from './useAppSelector';
import {parseISO} from 'date-fns';
import {useConfirmationDialog} from './useConfirmationDialog';
import {toast} from 'react-hot-toast';

export const useAcceptDeclineWorkdayInvitation = ({
  date,
  workspaceReservationId,
  onSuccess,
}: {
  date: string;
  workspaceReservationId: string;
  onSuccess?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {confirmationDialog} = useConfirmationDialog();
  const [isLoading, setIsLoading] = useState(false);
  const pendingWorkspaceReservation = useAppSelector((state) =>
    getWorkspaceReservationById(state, workspaceReservationId),
  );
  const currentWorkspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, parseISO(date)));

  // GET BY ID?
  const isUserOwnerOfGroupWorkspaceReservation = useAppSelector((state) =>
    getIsUserOwnerOfGroupWorkspaceReservationByDate(state, parseISO(date)),
  );

  const handleUpdateGroupWorkspaceReservationStatus = async (
    reservationStatus: Extract<WorkspaceReservationStatus, 'InvitationDeclined' | 'Confirmed'>,
  ) => {
    if (!pendingWorkspaceReservation?.groupReservationId) return;
    setIsLoading(true);

    const {success} = await dispatch(
      withAsyncThunkErrorHandling(() =>
        updateWorkspaceReservationStatus({
          reservationId: pendingWorkspaceReservation.id,
          reservationStatus,
          groupReservationId: pendingWorkspaceReservation.groupReservationId!,
          currentWorkspaceReservationId: currentWorkspaceReservation?.id,
          selectedDate: date,
        }),
      ),
    );

    if (success && reservationStatus === 'Confirmed') {
      const getMessage = () => {
        if (isUserOwnerOfGroupWorkspaceReservation) {
          return t('workplace:ReplacedGroupBookingMessage');
        }
        if (currentWorkspaceReservation) {
          return t('workplace:ReplacedBookingMessage');
        }
        return t('workplace:DeskHasBeenBooked');
      };

      toast.success(getMessage());

      onSuccess?.();

      return;
    }

    setIsLoading(false);
  };

  const handleDeclineInvitation = async () => {
    await handleUpdateGroupWorkspaceReservationStatus('InvitationDeclined');
  };

  const handleAcceptInvitation = async () => {
    if (currentWorkspaceReservation) {
      const dialogText = isUserOwnerOfGroupWorkspaceReservation
        ? t('workplace:ReplaceGroupBookingDialogText')
        : t('workplace:ReplaceBookingDialogText');

      const dialogTitle = isUserOwnerOfGroupWorkspaceReservation
        ? t('workplace:ReplaceGroupBookingDialogTitle')
        : t('workplace:ReplaceCurrentBookingDialogTitle');

      const result = await confirmationDialog(
        dialogTitle,
        dialogText,
        'warning',
        t('workplace:ReplaceBookingDialogPositiveButton'),
      );

      if (!result) return;
    }

    await handleUpdateGroupWorkspaceReservationStatus('Confirmed');
  };

  return {
    acceptInvitation: handleAcceptInvitation,
    declineInvitation: handleDeclineInvitation,
    loading: isLoading,
  };
};
